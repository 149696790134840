<template>
  <!-- Comments section -->
  <div class="home12">
    <div class="home13">
      <!-- <div v-for="comment in ShowComments[post.id]" :key="comment.id" class="user_comments"> -->
      <div
        v-for="comment in ShowComments.slice(0, commentsToShow)"
        :key="comment.id"
        class="user_comments"
      >
        <img
          src="./assets/cover.png"
          alt
          v-if="comment.user.profile_photo === ''"
          style=" border-radius: 50%"
        />
        <img
          :src="comment.user.profile_photo"
          v-else
          style=" border-radius: 50%"
        />
        <div class="comments">
          {{ comment.user.name }}
          <br />
          {{ comment.comment }}
        </div>
      </div>
    </div>
  </div>

  <!-- "Add Comments" section -->
  <div class="add_comments">
    <router-link
      to="/login"
      class="mb-3"
      style="text-decoration: none; color: black; width: 100%"
    >
      <!-- <h5 class="addComments-heading">View more comments {{ post.id }}</h5> -->
      <h5 class="addComments-heading">
        <span
          style="
            transition: box-shadow 0.3s, transform 0.3s;
            border-bottom: 1px solid #707070;
          "
          >View more comments</span
        >
      </h5>
    </router-link>
    <!-- <input class="add_comments_input" type="text" v-model="post.comments" placeholder="Add Comments" /> -->
    <input
      class="add_comments_input"
      placeholder="Add Comments"
      @click="$router.push({ name: 'Signin' })"
    />
    <!-- <button class="add_comments_btn text-dark" @click="AddComments(post.id, post.comments)">Post</button> -->
    <button
      class="add_comments_btn text-dark"
      @click="$router.push({ name: 'Signin' })"
    >
      Post
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DummyCommentSec",
  data() {
    return {
      ShowComments: [],
      commentsToShow: 2,
      // apiUrl: "http://uatserver.redprix.com/public/api/",
      // apiUrl: "https://api.redprix.com/api/",
    };
  },
  props: {
    postId: String,
  },
  methods: {
    getCommentsForPost(id) {
      try {
        axios
          .post(
            `customers/posts/comments`,
            {
              post_id: id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          )
          .then((response) => {
            // console.log("REsponse of Commnets: ", response);
            this.ShowComments = response.data.data.comments || [];
          })
          .catch(() => {
            console.log("No comment data found in the response.");
          });

        // console.log("Comment Response: ", response);

        // if (
        //     response.data &&
        //     response.data.data &&
        //     response.data.data.comments
        // ) {
        //     // Update the ShowComments property with the fetched comments
        //     this.ShowComments = response.data.data.comments || [];

        //     // this.$set(this.ShowComments, postId, response.data.data.comments);

        // } else {
        //     console.log("No comment data found in the response.");
        // }
        // console.log("Comments Array: ", this.ShowComments, " Length: ", this.ShowComments.length);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    // console.log("Post Id: ", this.postId);
    this.getCommentsForPost(this.postId);
  },
};
</script>

<style scoped>
.home12 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home13 {
  width: 100%;
  /* margin-left: 8%; */
  /* background: rgb(243, 241, 241); */
  border-radius: 6px;
}

.user_comments {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 0.2rem 0.2rem;
}

.user_comments img {
  width: 10%;
  padding: 1%;
}

.comments {
  display: block;
  background-color: rgb(228, 228, 228);
  padding: 2%;
  width: 100%;
  margin: 1%;
  border-radius: 0.5rem;
}

.add_comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  flex-wrap: wrap;
}

.addComments-heading {
  font-size: 0.9rem;
  /* border-bottom: 1px solid #707070; */
  width: 100%;
  cursor: pointer;
  color: #707070;
  display: inline;
}

.addComments-heading:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  color: red;
}

.add_comments input {
  border: 1px solid black;
  border-radius: 0.5rem;
}

.add_comments_btn {
  border: 1px solid black;
  outline: 0;
  background-color: #fff;
  color: black;
  padding: 1.5%;
  width: 20%;
  border-radius: 0.3rem;
}

.add_comments_input {
  width: 74%;
  padding: 0.3rem;
  border-radius: 0.5rem;
}
</style>
