<template>
  <div class="animate__animated animate__fadeIn">
    <DummyNavbar class="Nav-bar mt-0" />
    <div class="container-fluid pt-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="sign1">
              <div v-if="showAlert" class="alert">{{ alertMessage }}</div>
              <div v-else class="d-none"></div>
              <div>
                <!-- <div id="alert-message"></div> -->
                <h2 class="" style="color: #e1101a">Sign In</h2>
              </div>
              <span>Feast on Flavor, Feast on Savings with RedPrix!</span>

              <form>
                <div class="sign2">
                  <input type="email" v-model="email" required />
                  <span></span>
                  <label>Email Address</label>
                </div>
                <div class="sign3">
                  <!-- <input type="Password" id="password" v-model="password" required />-->
                  <input
                    v-if="showPassword"
                    type="text"
                    id="password"
                    v-model="password"
                  />
                  <input
                    v-else
                    type="password"
                    id="password"
                    v-model="password"
                    @keyup.enter="signIn"
                    required
                  />
                  <span></span>
                  <label>Set Password</label>

                  <i
                    class="fas"
                    @click="toggleShow"
                    style="margin-left: 3%; cursor: pointer"
                    :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"
                  ></i>
                </div>

                <div class="sign4">
                  <div class="remeberme_check">
                    <input type="checkbox" />
                    <label>Remember me</label>
                  </div>

                  <div class="sign5">
                    <router-link
                      style="text-decoration: none; color: #000"
                      to="/Forgotpassword"
                    >
                      <span>Forgot Password</span>
                    </router-link>
                  </div>
                </div>

                <div class="sigin-btn">
                  <button
                    class="btn btn-lg"
                    type="button"
                    v-on:click="signIn()"
                  >
                    <span v-if="!loadingSpinner">Sign-in</span>
                    <div v-else class="loading-spinner mx-auto">
                      <div class="spinner"></div>
                    </div>
                  </button>
                </div>
                <div class="sign6">
                  <span class="me-2" style="color: #000">New to Redprix?</span>
                  <router-link
                    to="/Register"
                    style="text-decoration: none; color: #e1101a"
                  >
                    <span @click="navigate" role="link">Register Here</span>
                  </router-link>
                </div>

                <!-- 
              <div class="sign7">
                <router-link to="/Register" custom v-slot="{ navigate }">
                  <button @click="navigate" role="link">Register Here</button>
                </router-link>
              </div>-->
              </form>
            </div>
          </div>
          <!-- <h4 style="text-align: center; color: white; margin-top: 13px;">FIRST GLOBAL SOCIAL MEDIA PLATFORM FOR FOOD LOVERS
        </h4> -->
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <signup-second />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SignupSecond from "./Signup-Second.vue";
import DummyNavbar from "./DummyUser/DummyNavbar.vue";

export default {
  name: "SignIn",
  data() {
    return {
      email: "",
      password: "",
      Authorization: "",
      showPassword: false,
      showAlert: false,
      alertMessage: "",
      loadingSpinner: false,
    };
  },
  components: {
    SignupSecond,
    DummyNavbar,
  },
  mounted() {},
  methods: {
    signIn() {
      this.loadingSpinner = true;
      axios
        .post("login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.loadingSpinner = false;
          localStorage.setItem("id", response.data.data.id);
          localStorage.setItem("token", response.data.data.authorization);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("user_type", response.data.data.user_type);
          if (localStorage.getItem("token")) {
            // console.log(
            //   "this is locoal storage token",
            //   localStorage.getItem("token")
            // );
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
            let resultfinal = axios.get("customers/posts", {
              headers: {
                DeviceID: "1111",
                versionName: "1.0.0",
                DeviceType: "0",
              },
            });
            this.listdef = resultfinal.data;
          }
          if (
            response.data.data.user_type == 1 &&
            response.data.data.user_type == 2 &&
            response.data.user_type == 3
          ) {
            this.$router.push("/home");
          } else {
            this.$router.push("/");
          }
          this.$router.push("/home");
        })
        .catch((error) => {
          this.loadingSpinner = false;
          if (error.response.status === 422) {
            // Handle validation errors or other issues with the request
            if (error.response.data && error.response.data.errors) {
              this.alertMessage = error.response.data.errors.join(", ");
            } else {
              this.alertMessage =
                "Invalid email or password. Please try again.";
            }
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 2000);
          } else {
            // Handle other error cases
            this.alertMessage = "An error occurred while logging in.";
            this.showAlert = true;
          }
        });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
};
</script>

<style scoped>
.loading-spinner {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: auto;
  border: 6px solid #ffffff;
  border-radius: 50%;
  animation: loading-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #ffffff transparent transparent transparent;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Nav-bar {
  position: relative;
  top: 0;
}

.col-lg-12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* This will center the form vertically */
}

.container-fluid {
  /*background-image: url("../assets/wall.jpeg");*/
  background-image: url("../assets/backgroungImageOfRedprix_1.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

/* .row {
  height: 88vh;
} */
.sign {
  margin-top: 35.5%;
  margin-left: -34%;
}

.sign img {
  margin-left: 8%;
  margin-top: 11.5%;
}

.social_media_text {
  font-size: 229%;
  color: white;
  font-weight: 800;
  width: 100%;
  text-align: center;
}

.sign h5 {
  margin-left: 8.5%;
  width: 100%;
  margin-top: 3%;
  font-size: 117%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: gray;
  border-top: 1px solid;
  padding-top: 2%;
}

.sign1 {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 7px #fbfbfb;
  /* margin: 0 auto; */
  /* max-width: 50%; */
  padding: 23px 19px;
  /* width: 100%; */
}

.alert {
  text-align: center;
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 0;
  --bs-alert-margin-bottom: 0;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  padding: 0rem;
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  color: red;
}

/* #alert-message {
  width:100%

} */
.sign1 header {
  color: red;
  /* width: 52%; */
  padding-left: 9%;
  /* margin-top: 8%; */
  padding-top: 3%;
}

.sign1 h5 {
  margin-top: 2%;
  width: 100%;
  border-top: none;
  font-size: 91%;
  padding: 0% 10% 0%;
}

.sign2 {
  position: relative;
  border-bottom: 1px solid black;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 11.3%;
  position: relative;
  border-bottom: 1px solid black;
  width: 75%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 11.3%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.sign2 input {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 16px;
}

.sign2 label {
  position: absolute;
  top: 40%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.sign2 span::before {
  content: "";
  position: absolute;
  top: 99%;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.sign2 input:focus ~ label,
.sign2 input:valid ~ label {
  top: 0%;
  color: #eb0e19;
  font-size: 12px;
}

.sign2 input:focus ~ span::before,
.sign2 input:valid ~ span::before {
  width: 100%;
}

.sign3 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  width: 75%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 11.3%;
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  width: 75%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 11.3%;
  margin-top: 2%;
}

.sign3 input {
  width: 82%;
  padding: 5px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 16px;
}

.sign3 label {
  position: absolute;
  top: 40%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.sign3 span::before {
  content: "";
  position: absolute;
  top: 99%;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.sign3 input:focus ~ label,
.sign3 input:valid ~ label {
  top: 0%;
  color: #eb0e19;
  font-size: 12px;
}

.sign3 input:focus ~ span::before,
.sign3 input:valid ~ span::before {
  width: 100%;
}

.sign4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12%;
}

.sign4 input[type="checkbox"] {
  accent-color: red;
}

.sign4 label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell;
  font-weight: 500;
  font-size: 72%;
  color: gray;
  position: absolute;
  margin-top: 0.2%;
  margin-left: 0.2%;
}

.sign5 {
  width: fit-content;
  font-size: 74%;
  text-align: left;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  padding: 0.5rem;
  padding-right: 15%;
}

.sign5 li {
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 94%;
  font-weight: 500;

  color: gray;
  cursor: pointer;
}

.sigin-btn button {
  width: 100%;
  padding: 4px 0;
  font-size: 100%;
  font-weight: 600;
  background-color: #e1101a;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin: 33px 0;
}

.sigin-btn {
  padding-left: 10%;
  padding-right: 11%;
  line-height: 2;
  font-size: 110%;
  font-weight: 600;
  color: white;
  /* border-radius: 10px; */
  cursor: pointer;
  outline: none;
  border: none;
}

.sign6 {
  width: 100%;
  font-size: 108%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.sign6 span {
  text-decoration: none;
  /* text-align: center; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media (max-width: 768px) {
  .container {
    display: flex;
    /* Change to a block layout for smaller screens */
  }

  .sign1 {
    margin: 0 auto;
    max-width: 100%;
  }

  .social_media_text {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .container-fluid {
    padding-top: 0 !important;
  }
}
</style>
