<template>
  <NavBar />
  <div class="row">
    <div class="col-md-4 header">
      <img class="header_img" src="../../assets/cover2.png" alt="" />
      <div class="header_inner">
        <div class="parent_header_inner">
          <img class="user_profile" src="../../assets/01.jpeg" alt="" />
          <div class="mt-3">Hot & Fresh Pizza</div>
        </div>
        <div class="">
          <button class="btn btn-sm btn-success">
            Your referral code is:RestFree
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="user_balance">
        <img src="./../../assets/wallet.png" alt="" />
        <span>Availabel Blance</span>
        <span>$116.00</span>
      </div>
      <div class="user_details">
        <span>Personal Info</span>
        <span>Edit</span>
        <hr style="width: 100%" />
        <div>
          <p>About Us</p>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            pariatur ipsa
          </span>
        </div>
        <hr style="width: 100%" />
        <div>
          <p>Establishment Year</p>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
            pariatur ipsa
          </span>
        </div>
        <hr style="width: 100%" />

        <div>
          <p>Phone Number</p>
          <span> +91 1234567890 </span>
        </div>
        <hr style="width: 100%" />

        <div>
          <p>Email</p>
          <span> abc@info.com </span>
        </div>
        <hr style="width: 100%" />
        <div>
          <p>Address</p>
          <span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Accusantium, obcaecati
          </span>
        </div>
        <hr style="width: 100%" />
      </div>

      <div class="posts">
        <div class="posts_inner">
          <span>125 Post</span>
          <span>See all</span>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="./../../assets/01.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/02.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/03.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/04.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/05.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/06.jpg" width="30%" alt="" />
          </div>
        </div>
      </div>
      <div class="offers">
        <div class="offers_inner">
          <span>offers</span>
          <span>See all</span>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="./../../assets/01.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/02.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/03.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/04.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/05.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/06.jpg" width="30%" alt="" />
          </div>
        </div>
      </div>
      <div class="tagPost">
        <div class="tagPost_inner">
          <span>Tag Post</span>
          <span>See all</span>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="./../../assets/01.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/02.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/03.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/04.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/05.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="./../../assets/06.jpg" width="30%" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="post_nav_bar">
        <div class="post"
         @click="changeEffectPost"
          :class="changePost ?   'change-post-effect ' : 'change-post-effect-none '">
          <router-link
            to="/vendprofile/vendorpost"
            style="text-decoration: none; color: black"
          >
            Post
          </router-link>
        </div>
        <router-link
          to="/vendprofile/vendfollowers"
          style="text-decoration: none; color: black"
        >
          <div class="follower"
           @click="changeEffectHidden"
          :class="changefollower ?   'change-post-effect ' : 'change-post-effect-none '">follower</div>
        </router-link>

        <router-link
          to="/vendprofile/vendfollowing"
          style="text-decoration: none; color: black"
        >
          <div class="follower"
           @click="changeEffectReported"
          :class="changefollowing ?   'change-post-effect ' : 'change-post-effect-none '"
          >following</div>
        </router-link>

        <router-link
          to="/vendprofile/vendrequest"
          style="text-decoration: none; color: black"
        >
          <div class="request"
           @click="changeEffectRequest"
          :class="changeRequest ?   'change-post-effect ' : 'change-post-effect-none '">request</div>
        </router-link>
      </div>

      <div class="">
        <router-view></router-view>
      </div>
      <!-- <VendorList /> -->
    </div>
  </div>
</template>
<script>
// import VendorList from "./../../vendorprofilelist/VendorProList.vue";
// import MenuList from "@/components/menu/MenuList.vue"
// import Posts from "./posts.vue";
import NavBar from "./../../components/NavBar.vue";
export default {
  name: "InfluProfile",
  components: {
    NavBar,
    // Posts,
    // VendorList,
    // MenuList
  },
  data() {
    return {
      changePost: true,
      changefollower: false,
      changefollowing: false,
      changeRequest:false
    };
  },
  methods: {
    changeEffectPost() {
      this.changePost = !this.changePost;
      this.changefollower = false;
      this.changefollowing = false;
      this.changeRequest= false;
    },
    changeEffectHidden() {
      this.changefollower = !this.changefollower;
      this.changePost = false;
      this.changefollowing = false;
      this.changeRequest= false;
    },
    changeEffectReported() {
      this.changefollowing = !this.changefollowing;
      this.changePost = false;
      this.changefollower = false;
      this.changeRequest = false;
    },
    changeEffectRequest(){
      this.changeRequest =  !this.changeRequest;
      this.changePost = false;
      this.changefollower = false;
      this.changefollowing = false;



    }
  },
  
};
</script>
<style scoped>
.row {
  height: 0%;
}
.header_img {
  width: 100%;
}
.header {
  width: 100%;
  margin-top: 1rem;
  /* border:1px solid black; */
}
.user_profile {
  position: absolute;
  width: 6vw;
  max-width: 66%;
  height: 188%;
  border-radius: 62%;
  margin-top: -50%;
  border: 3px solid white;
}
.header_inner {
  display: flex;
  /* border: 1px solid black; */
  width: 100%;
  box-shadow: 0px 0px 4px #d4d4d4;
  padding: 0.5rem;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0.3rem;
  margin-bottom: 0.6rem;
}
.parent_header_inner {
  position: relative;
}
.home8 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  visibility: visible;
}
.post_nav_bar {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 7px #d4d4d4;
  padding: 0.5rem;
  border-radius: 0.3rem;
}
.user_balance {
  box-shadow: 0px 0px 6px #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.posts {
  margin-top: 0.5rem;
  box-shadow: 0px 0px 7px #d4d4d4;
  border-radius: 0.5rem;
}
.posts_inner {
  display: flex;
  padding: 0.3rem;
  justify-content: space-between;
  align-items: center;
}
.offers {
  margin-top: 0.5rem;
  box-shadow: 0px 0px 7px #d4d4d4;
  border-radius: 0.5rem;
}
.offers_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5;
}
.tagPost {
  margin-top: 0.5rem;
  box-shadow: 0px 0px 7px #d4d4d4;
  border-radius: 0.5rem;
}
.tagPost_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5;
}
/* .user_details {
  border-radius: 0.3rem;
  display: flex;
  margin-top: 0.6rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 6px #d4d4d4;
  padding: 0.6rem;
} */

.user_details {
  border-radius: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #d4d4d4;
  padding: 0.6rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 00.3rem;
}
.grid-item {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.grid-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.user_details > div {
  flex-basis: 100%;
  margin-top: 10px; /* Add some space between "Personal Info" and "About Us" */
}
.change-post-effect {
  color: #eb0e19;
  /* padding-bottom: 32%; */
  border-bottom: 0.3rem solid #eb0e19;
}
.change-post-effect-none {
  color: #b4b4b4;
}

@media screen and (max-width: 600px) {
  .user_profile {
    position: absolute;
    width: auto;
    max-width: 64%;
    height: 184%;
    border-radius: 62%;
    margin-top: -50%;
    margin-bottom: 10%;
    border: 3px solid white;
  }
}
@media screen and (max-width: 480px) {
  .user_profile {
    position: absolute;
    width: 30vw;
    height: 7vh;
    border-radius: 62%;
    margin-top: -37%;
    margin-bottom: 10%;
    border: 3px solid white;
  }
}
</style>