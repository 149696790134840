<template>
  <div class="animate__animated animate__fadeIn">
    <DummyNavbar class="Nav-bar mt-0" />
    <div class="container-fluid pt-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="reg1">
              <header>
                <div v-if="showAlert" class="alert">{{ alertMessage }}</div>
                <div v-else class="d-none"></div>
                <h1>Create Account</h1>
              </header>
              <h5>
                Join RedPrix today and elevate your foodie journey. Sign up now
                and start savoring the savings!
              </h5>

              <form style="margin-left: -1%; margin-top: 3%; margin-bottom: 1%">
                <div class="reg2">
                  <input type="text" v-model="full_name" required />
                  <span></span>
                  <label>Full Name</label>
                </div>
                <div class="reg3">
                  <input type="email" v-model="email" required />
                  <span></span>
                  <label>Enter Email Id</label>
                </div>

                <div class="reg4">
                  <select v-model="country_id" @change="getState()" required>
                    <option value disabled selected>Country</option>
                    <option
                      v-for="country in list"
                      :value="country.id"
                      v-bind:key="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>

                  <select v-model="state_id" @change="getCity()" required>
                    <option value disabled selected>State</option>
                    <option
                      v-for="item in state"
                      :value="item.id"
                      v-bind:key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <select v-model="city_id" required>
                    <option value disabled selected>City</option>
                    <option
                      v-for="item in city"
                      :value="item.id"
                      v-bind:key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>

                <div class="reg7">
                  <input type="text" v-model="set_password" required />
                  <span></span>
                  <label>Set Password</label>
                </div>
                <div class="reg8">
                  <input type="Password" v-model="confirm_password" required />
                  <span></span>
                  <label>Confirm Password</label>
                </div>

                <div class="reg9">
                  <input type="checkbox" v-model="agreed" />
                  <span>
                    <label>
                      Please Confirm that You agree to our policy & terms
                      <!-- <router-link to="/termsandcondition"></router-link> -->
                    </label>
                  </span>
                </div>

                <div class="reg10">
                  <!-- :disabled="!isValid" -->
                  <button
                    v-on:click="createAccount()"
                    type="button"
                    @click="navigate"
                    role="link"
                  >
                    <span v-if="!loadingSpinner">CREATE ACCOUNT</span>
                    <div v-else class="loading-spinner mx-auto">
                      <div class="spinner"></div>
                    </div>
                  </button>
                  <!-- <button type="submit" :disabled="!isFormValid">CREATE ACCOUNT</button> -->
                  <!-- disabled="!email || !password" -->
                </div>

                <div class="reg11">
                  <span class="me-2">Already on Redprix?</span>
                  <div class="reg12">
                    <router-link to="/login" custom v-slot="{ navigate }">
                      <span @click="navigate" role="link">Sign In</span>
                    </router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DummyNavbar from "./DummyUser/DummyNavbar.vue";
export default {
  name: "HelloWorld",

  data() {
    return {
      full_name: "",
      email: "",
      email_otp: "",
      set_password: "",
      confirm_password: "",
      country_id: 101,
      state_id: null,
      city_id: null,
      list: [],
      state: [],
      city: [],
      agreed: false,
      alertMessage: "",
      showAlert: false,
      loadingSpinner: false,
    };
  },
  components: {
    DummyNavbar,
  },
  // computed: {
  //   isValid() {
  //     return this.agreed;
  //   },
  // },

  methods: {
    // createAccount() {
    //   axios
    //     .post("customers/customers", {
    //       name: this.full_name,
    //       email: this.email,
    //       password: this.set_password,
    //       confirm_password: this.confirm_password,
    //       country_id: this.country_id,
    //       state_id: this.state_id,
    //       city_id: this.city_id,
    //     })
    //     .then((response) => {
    //       localStorage.setItem("email", this.email);

    //       // localStorage.setItem("name", this.full_name);
    //       // localStorage.setItem("password", this.set_password);
    //       //  this.tokenData = res.data.data.authorization;
    //       console.log(response.data.data);

    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.showAlert = true;
    //       this.alertMessage = error.response.data.message;
    //     });
    // },

    createAccount() {
      this.loadingSpinner = true;
      if (!this.agreed) {
        // If not agreed, show an error message or handle as needed
        this.showAlert = true;
        this.alertMessage = "You must agree to the terms.";
        this.loadingSpinner = false;
        return; // Exit the method
      }
      axios
        .post("customers/customers", {
          name: this.full_name,
          email: this.email,
          password: this.set_password,
          confirm_password: this.confirm_password,
          country_id: this.country_id,
          state_id: this.state_id,
          city_id: this.city_id,
        })
        .then((response) => {
          this.loadingSpinner = false;
          localStorage.setItem("email", this.email);
          localStorage.setItem("OTP", response.data.data.email_otp);

          // Redirect to the verification page
          this.$router.push("/Verification"); // Make sure to import Vue Router and have access to the router instance

          // console.log(response.data.data);
        })
        .catch((error) => {
          this.loadingSpinner = false;

          console.log(error);

          this.showAlert = true;
          this.alertMessage = error.response.data.message;
        });
    },

    getData() {
      axios.get("countries?is_light=true").then((result) => {
        this.list = result.data.data;
        if (this.country_id) {
          this.getState();
        }
      });
    },

    getState() {
      axios
        .post("states_of_country?is_light=1", {
          country_id: this.country_id,
        })
        .then((result) => {
          this.state = result.data.data;
          // console.log("States: ", this.state);
          if (this.state_id) {
            this.getCity();
          }
        });
    },
    getCity() {
      // console.log("State Id: ", this.state_id);
      axios
        .get(`cities_of_state?is_light=1`, {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.city = result.data.data;
          // console.log("City: ", this.city);
        });
    },

    /* otpVerify(){
        this.loading = true;
      const response = axios
        .post("https://uatapi.redprix.com/api/verify-email", {
          email: this.email,
          email_otp: this.email_otp,
        })
         .then(() => {
         
          this.email_otp = "";
          this.verySucc = "Email Verify Plz select continue!!!";
          localStorage.setItem("token", this.tokenData);
          this.$router.push("/home");
        })
         .catch((error) => {
          this.otpFail = error.response.data.message;
          this.email_otp = "";
        });
      console.log(response);*/
  },
  mounted() {
    this.getData();
    this.getState();
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
  // computed: {
  //   isFormValid() {
  //     return (
  //       this.full_name !== "" &&
  //       this.email !== "" &&
  //       this.country_id !== null &&
  //       this.state_id !== null &&
  //       this.city_id !== null &&
  //       this.set_password !== "" &&
  //       this.confirm_password !== "" &&
  //       this.set_password === this.confirm_password &&
  //       this.agreed
  //     );
  //   },
  // },
};
</script>
<style scoped>
.loading-spinner {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: auto;
  border: 6px solid #ffffff;
  border-radius: 50%;
  animation: loading-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #ffffff transparent transparent transparent;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container-fluid {
  background-image: url("../assets/wall.jpeg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.Nav-bar {
  position: relative;
  top: 0;
}

.col-lg-12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .row {
  height: 92vh;
} */
.sign {
  margin-top: 35.5%;
  margin-left: -34%;
}

.sign img {
  margin-left: 8%;
  margin-top: 11.5%;
}

.sign h5 {
  margin-left: 8.5%;
  width: 80%;
  margin-top: 3%;
  font-size: 117%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: gray;
  border-top: 1px solid;
  padding-top: 2%;
}

.reg1 {
  width: 100%;
  /* margin-top: 4.7%; */
  /* margin-left: 1%; */
  border-radius: 10px;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 2%;
}

.reg1 header {
  /* font-size: 10%; */
  color: red;
  text-align: left;
  /* line-height: 265%; */
  background: white;
  width: 96%;
  /* user-select: none; */
  /* padding-left: 20px; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-top: 2%;
  margin-left: 4%;
}

.reg1 h5 {
  margin-top: 0%;
  width: 91%;
  border-top: none;
  margin-left: 5%;
  font-size: 91%;
}

.reg2,
.reg7,
.reg8 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  width: 89%;
  margin-left: 5%;
  margin-top: 4%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.reg7 {
  margin-top: 3%;
}

.reg8 {
  margin-top: 3%;
}

.reg2 input,
.reg7 input,
.reg8 input {
  width: 82%;
  padding: 5px;
  height: 14px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 31px;
  padding-bottom: 3%;
}

.reg2 label,
.reg7 label,
.reg8 label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.reg2 span::before,
.reg7 span::before,
.reg8 span::before {
  content: "";
  position: absolute;
  top: 99%;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.reg2 input:focus ~ label,
.reg7 input:focus ~ label,
.reg8 input:focus ~ label,
.reg2 input:valid ~ label,
.reg7 input:valid ~ label,
.reg8 input:valid ~ label {
  top: 0%;
  color: #eb0e19;
}

.reg2 input:focus ~ span::before,
.reg7 input:focus ~ span::before,
.reg8 input:focus ~ span::before,
.reg2 input:valid ~ span::before,
.reg7 input:valid ~ span::before,
.reg8 input:valid ~ span::before {
  width: 100%;
}

.reg3 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  width: 89%;
  margin-left: 5%;
  margin-top: 2%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.reg3 input {
  width: 82%;
  padding: 5px;
  height: 14px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 31px;
  padding-bottom: 3%;
}

.reg3 label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.reg3 button {
  margin-left: -1.5%;
  /* position: fixed; */
  width: 19%;
  margin-top: 1.1%;
  line-height: 1.5;
  /* display: flex; */
  color: white;
  background: skyblue;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding-bottom: 1%;
}

.reg3 span::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.reg3 input:focus ~ label,
.reg3 input:valid ~ label {
  top: 0%;
  color: #eb0e19;
}

.reg3 input:focus ~ span::before,
.reg3 input:valid ~ span::before {
  width: 100%;
}

.reg4 {
  display: flex;
  align-items: center;
  align-items: center;
  gap: 4%;
  width: 92%;
  margin-left: 5%;
  margin-top: 5.4%;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.reg4 {
  display: flex;
  align-items: center;
  align-items: center;
  gap: 4%;
  width: 92%;
  /* margin-left: 5%; */
  /* margin-top: 11.4%; */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.reg4 select {
  border: none;
  background: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 45%;
  color: #adadad;
  font-weight: 500;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../assets/redaerrow.svg) no-repeat;
  background-position: right 10px top 50%;
  background-size: 8%;
}

.reg5 select {
  border: none;
  background: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 45%;
  color: #adadad;
  font-weight: 500;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../assets/redaerrow.svg) no-repeat;
  background-position: right 10px top 50%;
  background-size: 8%;
}

.reg6 select {
  border: none;
  background: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 46%;
  color: #adadad;
  font-weight: 500;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../assets/redaerrow.svg) no-repeat;
  background-position: right 10px top 50%;
  background-size: 8%;
}

.reg5 {
  margin-top: -5.3%;
  margin-left: 39%;
}

.reg6 {
  margin-left: 72%;
  margin-top: -5.3%;
}

.reg9 {
  margin-left: 6%;
  /* position: relative; */
  margin-top: 2.5%;
  color: gray;
  font-size: 90%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.reg9 label {
  margin-top: -0.2%;
  margin-left: 1%;
}

.reg9 input[type="checkbox"] {
  accent-color: red;
}

.reg10 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg10 button {
  margin-top: 5.5%;
  line-height: 1.6;
  width: 89%;
  background: red;
  border: none;
  color: white;
  border-radius: 10px;
  font-size: 101%;
  cursor: pointer;
  padding: 4px 0;
}

.reg11 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 108%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* .reg11 li {
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

/* .reg12 {
  margin-top: -9%;
  margin-left: -44%;
} */

.reg12 span {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: red;
  font-weight: 500;
  cursor: pointer;
  font-size: 108%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.alert {
  text-align: center;
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 0;
  --bs-alert-margin-bottom: 0;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  padding: 0rem;
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sign {
    margin-top: 35.5%;
    margin-left: 0%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
  }

  .sign h5 {
    margin-left: 8.5%;
    width: 83%;
    margin-top: 3%;
    font-size: 117%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: gray;
    border-top: 1px solid;
    padding-top: 2%;
  }

  .reg1 {
    width: 100%;
    margin-top: 2.7%;
    margin-left: 5%;
    margin-bottom: 1%;
    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4 {
    display: flex;
    align-items: center;
    align-items: center;
    gap: 4%;
    width: 92%;
    /* margin-left: 5%; */
    /* margin-top: 11.4%; */
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -6%;
    margin-left: 39%;
  }

  .reg6 {
    margin-left: 72%;
    margin-top: -6%;
  }

  /* .reg11 {
    margin-left: 18%;
    width: 40%;
    margin-top: 0.1%;
    font-size: 92%;
  } */
  /* .reg12 {
    margin-top: -9%;
    margin-left: -44%;
  } */
  .reg12 span {
    font-size: 96%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }

  .sign {
    margin-top: 35.5%;
    margin-left: -54%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
  }

  .sign h5 {
    margin-left: 8.5%;
    width: 83%;
    margin-top: 3%;
    font-size: 117%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: gray;
    border-top: 1px solid;
    padding-top: 2%;
  }

  .reg1 {
    width: 100%;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4 {
    display: flex;
    align-items: center;
    align-items: center;
    gap: 4%;
    width: 92%;
    /* margin-left: 5%; */
    /* margin-top: 11.4%; */
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 84%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 84%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 84%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -5%;
    margin-left: 39%;
  }

  .reg6 {
    margin-left: 72%;
    margin-top: -4.3%;
  }

  .reg11 {
    margin-left: 23%;
    width: 50%;
    margin-top: 0.1%;
    font-size: 92%;
  }

  /* .reg12 {
    margin-top: -8%;
    margin-left: -49%;
  } */
  .reg12 button {
    font-size: 96%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    background-image: url("../assets/wall.jpeg");
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }

  .sign {
    margin-top: 0.5%;
    margin-left: 16%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
  }

  .sign h5 {
    width: 203%;
  }

  .reg1 {
    width: 100%;
    /* height: 95%; */
    /* margin-top: 3.7%; */
    /* margin-left: 11%; */
    margin: 1% auto;
    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4 {
    display: flex;
    align-items: center;
    gap: 4%;
    width: 92%;
    /* margin-left: 5%; */
    margin-top: 5.4%;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -5%;
    margin-left: 39%;
  }

  .reg6 {
    margin-left: 72%;
    margin-top: -5%;
  }

  .reg11 {
    margin-top: 0.1%;
    font-size: 92%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .reg12 {
    margin-top: -7%;
    margin-left: -53%;
  } */
  .reg12 span {
    font-size: 96%;
  }
}

@media screen and (min-width: 575px) and (max-width: 767.98px) {
  /* .container {
    height: 99vh;
  } */
  .container-fluid {
    background-image: url("../assets/wall.jpeg");
    background-size: cover;
    background-position: center;
    /* min-height: 100vh; */
  }

  .sign {
    margin-top: -7.5%;
    margin-left: -5%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
  }

  .sign h5 {
    width: 87%;
  }

  .reg1 {
    margin: 8% auto;
    width: 100%;
    max-width: 104%;

    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4 {
    display: flex;
    align-items: center;
    align-items: center;
    gap: 4%;
    width: 92%;
    /* margin-left: 5%; */
    /* margin-top: 11.4%; */
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -5.3%;
    margin-left: 39%;
  }

  .reg6 {
    margin-left: 72%;
    margin-top: -5.3%;
  }

  .reg11 {
    margin-left: 23%;
    width: 40%;
    margin-top: 0.1%;
    font-size: 92%;
  }

  .reg12 span {
    font-size: 96%;
  }
}

@media screen and (min-width: 474.98px) and (max-width: 575.98px) {
  .container-fluid {
    background-image: url("../assets/wall.jpeg");
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }

  .sign {
    margin-top: -7.5%;
    margin-left: -5%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
  }

  .sign h5 {
    width: 87%;
  }

  .reg1 {
    width: 96%;
    margin: 16% auto;
    border: 1px solid black;
    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4,
  .reg5,
  .reg6 {
    position: relative;
    /* margin: 20px 0; */
    width: 46%;
    margin-left: 5%;
    margin-top: 8.4%;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 46%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -5.3%;
    margin-left: 39%;
  }

  .reg6 {
    margin-left: 72%;
    margin-top: -5.3%;
  }

  .reg11 {
    margin-left: 23%;
    width: 40%;
    margin-top: 0.1%;
    font-size: 92%;
  }

  /* .reg12 {
    margin-top: -9%;
    margin-left: -30%;
  } */
  .reg12 span {
    font-size: 86%;
  }
}

@media screen and (min-width: 374.98px) and (max-width: 475px) {
  .container-fluid {
    background-image: url("../assets/wall.jpeg");
    background-size: cover;
    background-position: center;
    /* min-height: 100vh; */
  }

  .sign {
    margin-top: -7.5%;
    margin-left: -5%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
    height: 56%;
    width: 56%;
  }

  .sign h5 {
    width: 87%;
    font-size: 94%;
  }

  .reg1 {
    width: 96%;
    margin: 15% auto;
    border: 1px solid black;
    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4 {
    display: flex;
    align-items: center;
    align-items: center;
    gap: 4%;
    width: 92%;
    /* margin-left: 5%; */
    /* margin-top: 11.4%; */
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 80%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 72%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 72%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -8%;
    margin-left: 39%;
  }

  .reg6 {
    margin-left: 72%;
    margin-top: -8%;
  }

  .reg9 label {
    margin-top: -0.2%;
    margin-left: 1%;
    font-size: 82%;
  }

  .reg11 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 88%;
  }

  /* .reg12 {
    margin-top: -12%;
    margin-left: -61%;
  } */
  .reg12 span {
    width: 13%;
    font-size: 2.4vh;
  }
}

@media screen and (min-width: 319.98px) and (max-width: 375px) {
  .container-fluid {
    background-image: url("../assets/wall.jpeg");
    background-size: cover;
    background-position: center;
    /* min-height: 100vh; */
  }

  .sign {
    margin-top: -7.5%;
    margin-left: -5%;
  }

  .sign img {
    margin-left: 8%;
    margin-top: 11.5%;
    height: 56%;
    width: 56%;
  }

  .sign h5 {
    width: 87%;
    font-size: 77%;
  }

  .reg1 {
    width: 96%;
    /* height: 99%; */
    /* margin-top: 3.7%; */
    /* margin-left: 2%; */
    margin: 24% auto;
    border: 1px solid black;
    border-radius: 10px;
    align-items: center;
    background-color: #ffffff;
  }

  .reg1 header {
    /* font-size: 10%; */
    color: red;
    text-align: left;
    /* line-height: 265%; */
    background: white;
    width: 96%;
    /* user-select: none; */
    /* padding-left: 20px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 2%;
    margin-left: 4%;
  }

  .reg1 h5 {
    margin-top: 0%;
    width: 91%;
    border-top: none;
    margin-left: 5%;
    font-size: 91%;
  }

  .reg7 {
    margin-top: 3%;
  }

  .reg8 {
    margin-top: 3%;
  }

  .reg4,
  .reg5,
  .reg6 {
    position: relative;
    /* margin: 20px 0; */
    width: 24%;
    margin-left: 5%;
    margin-top: 5.4%;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 90%;
  }

  .reg4 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 120%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 100%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg6 select {
    border: none;
    background: none;
    border-bottom: 1px solid;
    outline: none;
    width: 100%;
    color: #adadad;
    font-weight: 500;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../assets/redaerrow.svg) no-repeat;
    background-position: right 10px top 50%;
    background-size: 12%;
  }

  .reg5 {
    margin-top: -7.8%;
    margin-left: 39%;
    font-size: 100%;
  }

  .reg6 {
    margin-top: -7.8%;
    margin-left: 72%;
    font-size: 100%;
  }

  .reg9 {
    font-size: 89%;
  }

  .reg9 label {
    margin-top: -0.2%;
    margin-left: 1%;
    font-size: 69%;
  }

  .reg11 {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 73%;
  }

  /* .reg12 {
    margin-top: -14%;
    margin-left: -31%;
  } */
  .reg12 span {
    width: 17%;
    font-size: 89%;
  }

  .alert {
    width: 70vmin;
    text-align: center;
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 0;
    --bs-alert-padding-y: 0;
    --bs-alert-margin-bottom: 0;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    padding: 0rem;
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
  }
}
</style>