import { createStore } from "vuex";
import axios from "axios";

export default createStore({
	state() {
		return {
			otp: "", // Initialize otp as an empty string
		};
	},
	mutations: {
		setOTP(state, otp) {
			state.otp = otp; // Mutate the state with the fetched OTP
		},
	},
	actions: {
		async getOTP({ commit }) {
			try {
				// Make a GET request using Axios to fetch the OTP
				const response = await axios.post("/forgotpasswordotp");
				if (response.status === 200) {
					const otp = response.data.data.otp; // Assuming the OTP is in the response data
					commit("setOTP", otp); // Commit the OTP mutation
					// console.log("from store.js", otp);
				} else {
					console.error("Failed to fetch OTP");
				}
			} catch (error) {
				console.error("Error fetching OTP:", error);
			}
		},
	},
	getters: {
		getOTP: (state) => state.otp, // Getter to access the OTP from the state
	},
});
