<template>
  <div class="container px-0">
    <div class="heading">
      <h3>Offer Request List</h3>
    </div>
    <div class="offer_req_list px-3">
      <table>
        <thead>
          <tr>
            <th><input type="checkbox" v-model="selectedItems" /></th>
            <th>Name</th>
            <th>Member</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in offerReq" :key="item.id">
            <td>
              <input
                type="checkbox"
                v-model="selectedItems"
                :value="item.name"
              />
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.member }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.time }}</td>
            <td>
              <button
                class="btn btn-primary btn-sm w-100"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1"
              >
                View
              </button>
            </td>
          </tr>
        </tbody>
        <div
          class="modal fade"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Approve & Book
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="table_img">
                  <img src="./../../assets/table.png" />
                </div>
                <div class="user_info">
                  <div v-for="item in data" :key="item.id">
                    <div class="user_details">
                      <span>Name</span>
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="user_details">
                      <span>Email</span>
                      <span> {{ item.email }}</span>
                    </div>
                    <div class="user_details">
                      <span>Date</span>
                      <span>{{ item.date }}</span>
                    </div>

                    <div class="user_details">
                      <span>Time</span>
                      <span>{{ item.time }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger w-100"
                  data-bs-dismiss="modal"
                  @click="AcceptOffer"
                >
                  Approve & Book
                </button>
              </div>
            </div>
          </div>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      // data: [
      //   {
      //     id: "1",
      //     name: "Mark",
      //     email: "info@gmail.com",
      //     member: "Otto",
      //     date: "@mdo",
      //     time: "12:00 PM",
      //   },
      //   {
      //     id: "2",
      //     name: "Jacob",
      //     email: "info@gmail.com",
      //     member: "Thornton",
      //     date: "@fat",
      //     time: "1:30 PM",
      //   },
      //   {
      //     id: "3",
      //     name: "Larry",
      //     email: "info@gmail.com",
      //     member: "The Bird",
      //     date: "@twitter",
      //     time: "3:45 PM",
      //   },
      // ],
      offerReq: [],
    };
  },
  methods: {
    offerrequest() {
      axios.get("vendors/all-offer-requests").then((res) => {
        // console.log(res.data.data);
        this.offerReq = res.data.data;
      });
    },
    AcceptOffer() {
      const id = localStorage.getItem("id");
      axios
        .post("vendors/offers/offer-request/" + id, {
          is_accepted: "1",
        })
        .then(() => {
          // console.log(res.data.data);
        });
    },
    RejectOffer(id) {
      axios
        .post("vendors/offers/offer-request/" + id, {
          is_accepted: "0",
        })
        .then(() => {
          // conosle.res.data.data;
        });
    },
  },
};
</script>

<style scoped>
.heading h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: grey;
  padding: 0.8rem 0.9rem;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1.5rem;
}
.container {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 10px;
}
.table_img {
  width: 100%;
  padding: 0rem 5rem 0rem;
}
table {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
}
th,
td {
  padding: 8px;
  text-align: left;
}
td {
  background-color: #ff9ba0;
}
.table-header {
  background-color: #f2f2f2;
  margin-bottom: 10px; /* Adjust the margin value as needed */
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  padding: 1rem;
}
.user_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

tr {
  display: flex;
  padding: 0.2rem;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-top: 3px solid #ccc;
  border-bottom: 3px solid #ccc;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
</style>
