<template>
  <!-- <h1>VendorDetails</h1> -->
  <div class="content">
    <div class="influ">
      <!-- <header>Details of Influencer</header> -->

      <form class="px-2">
        <div class="d-flex align-items-center justify-content-between">
          <div class="first">
            <input type="text" v-model="firstname" required />
            <span></span>
            <label>First name</label>
          </div>
          <div class="second">
            <input type="text" v-model="lastname" required />
            <span></span>
            <label>Last name</label>
          </div>
        </div>
        <div class="third">
          <input type="text" v-model="email" required />
          <span></span>
          <label>Email id</label>
        </div>
        <div class="third">
          <input type="text" v-model="address" required />
          <span></span>
          <label>Address</label>
        </div>

        <div class="thirdt">
          <input type="text" v-model="mobile_no" required />
          <span></span>
          <label>Mobile Number</label>
        </div>

        <div class="text-s d-flex align-items-center justify-content-between">
          <select
            id="country"
            class="s1"
            v-model="country_id"
            @change="getState()"
            required
          >
            <option value class="j">Select Country</option>
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>

          <select
            id="state"
            class="s2"
            v-model="state_id"
            @change="getCity()"
            required
          >
            <option class="j" value>Select State</option>

            <option
              class="j"
              v-for="state in states"
              :key="state.id"
              :value="state.id"
            >
              {{ state.name }}
            </option>
          </select>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <select id="state" class="s3" v-model="city_id" required>
            <option class="j" value>Select City</option>

            <option
              class="j"
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
            >
              {{ city.name }}
            </option>
          </select>
          <div class="fourth">
            <input type="text" required />
            <span></span>
            <label>Area Pin Number</label>
          </div>
        </div>

        <div class="fifth">
          <input type="text" required v-model="links" />
          <span></span>
          <label>Link of Your Social Media Account or Website</label>
        </div>
        <!-- <div class=""> -->
        <div class="discription">
          <textarea
            class="px-2 py-1"
            placeholder="Description"
            name
            id
            v-model="description"
          ></textarea>
        </div>

        <div class="seventh">
          <!-- @click="openPopup()" -->
          <button class="submit" id="submit" @click="createVendor">Next</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "VendorDetails",
  data() {
    return {
      userdetails: [],
      firstname: "",
      lastname: "",
      email: "",
      address: "",
      mobile_no: "",
      country_id: "101",
      city_id: "",
      state_id: "",
      states: "",
      cities: "",
      countries: "",
      links: "",
      description: "",
    };
  },
  mounted() {
    // this.CreateVendor()
    this.getData();
    this.getState();
  },
  methods: {
    createVendor() {
      const formData = new FormData();
      formData.append("name", this.firstname + " " + this.lastname);
      formData.append("email", this.email);
      formData.append("address", this.address);
      formData.append("mobile", this.mobile_no);
      formData.append("country_id", this.country_id);
      formData.append("state_id", this.state_id);
      formData.append("city_id", this.city_id);
      formData.append("links", this.links);
      formData.append("desc", this.description);

      console.log("Vendor Data: ");
      formData.forEach((key, value) => {
        console.log("Key: ", key, " Value: ", value);
      });

      this.$router.push("/menulist/becomevendor/mandatoryfield");
    },
    getData() {
      axios
        .get("countries?is_light=1")
        .then((result) => {
          this.countries = result.data.data;
          // console.log("conutries", this.countries);
          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getState() {
      axios
        .get("states?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    OpenFile() {
      const fileInput = document.getElementById("file");
      fileInput.click();
    },
    getCity() {
      axios
        .get("cities_of_state?is_light=1", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // CreateVendor(){
    //   axios.post("vendors/vendors").then(res =>{
    //     console.log(res.data.data)
    //   }).catch(error =>{
    //     console.error(error.res.data.message)
    //   })
    // }
  },
};
</script>
<style scoped>
.influ {
  width: 100%;
  height: 40.5rem;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px #d4d4d4;
  margin-bottom: 3%;
  overflow-y: auto;
}

.influ::-webkit-scrollbar {
  display: none;
}

.influ header {
  font-size: 22px;
  /* color: white; */
  text-align: left;
  line-height: 265%;
  background: white;
  width: 100%;

  /* user-select: none; */
  padding-left: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-top: 0%;
  margin-left: 0;
  border-bottom: 1 px solid black;
  border-bottom: 1px solid;
}

.influ form {
  margin-top: 2%;
}

.first,
.second {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  width: 48%;
}
.first input,
.second input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.first label,
.second label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.second {
}
.third,
.fourth,
.thirdt,
.fifth {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 100%;
}
.fifthp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 100%;
}
.third input,
.fourth input,
.thirdt input,
.fifth input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.third label,
.fourth label,
.thirdt label,
.fifth label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.fourth {
  width: 48%;
  margin-top: 3.6%;
}
.fourth label {
  left: 5.5%;
}
.fifth label {
  left: 5.5%;
}
.thirdt label {
  left: 2%;
}
.text-s {
  margin-top: 4%;
}

select {
  width: 48%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1,
.s3 {
  width: 48%;
  margin-top: 20px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
}
.s3 {
  margin-top: 5%;
}

.s2 {
  padding: 2px;
  margin-top: 20px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
}
.sixth button {
  margin-left: -54%;
  margin-top: 3%;
  width: 13%;
  line-height: 1.8;
  background: cornflowerblue;
  border: none;
  cursor: pointer;
}

.seventh button {
  width: 100%;
  line-height: 2;
  background: #eb0e19;
  color: white;
  font-size: 122%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 4%;
  margin-bottom: 4%;
}
.discription {
  resize: none;
  margin-top: 4%;
  margin-bottom: 3%;
  color: lightgray;
  width: 100%;
  font-weight: 500;
  height: 5rem;
  border: 1px solid grey;
  border-radius: 6px;
}

.discription textarea {
  width: 100%;
  height: 5rem;
  outline: none;
}

.modal2 {
  background: #00000021;
  margin-top: -89%;
  width: 215.5%;
  margin-left: -81%;
  height: 107.7%;
  visibility: hidden;
}
.open-popup3 {
  visibility: visible;
}
.popup2 {
  width: 58%;
  height: 76%;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* padding: 0 30px 30px; */
  color: #333;
}
.popup2 header {
  margin-left: 0%;
  width: 100%;
  background: #eb0e19;
  border: none;
  /* height: 13%; */
  /* text-align: center; */
  line-height: 3.5;
  color: white;
}

.first span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.first input:focus ~ label,
.first input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.second span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.second input:focus ~ label,
.second input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.third span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.third input:focus ~ label,
.third input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.thirdt span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.thirdt input:focus ~ label,
.thirdt input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.fourth span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.fourth input:focus ~ label,
.fourth input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.fifth span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.fifth input:focus ~ label,
.fifth input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.firstp,
.secondp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: -5%;
  width: 65%;
  margin-left: -59%;
}
.firstp input,
.secondp input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.firstp label,
.secondp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.secondp {
  margin-left: 19%;
  margin-top: -7.7%;
}
.thirdp,
.fourthp {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 6%;
  width: 65%;
  margin-left: -59%;
  padding: 0%;
}
.thirdp input,
.fourthp input {
  width: 82%;

  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.thirdp label,
.fourthp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.fourthp {
  margin-left: 19%;
  margin-top: -11.5%;
}
.fifthp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 5%;
  width: 143%;
  margin-left: -59%;
}
.fifthp input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.fifthp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.desp {
  margin-left: -60%;
  width: 153%;
  margin-top: 12%;
}
.popup2 button {
  margin-left: -194%;
  margin-top: 0%;
  border: none;
  background: red;
  width: 35%;
  line-height: 2.1;
  border-radius: 6px;
  color: white;
  font-size: 118%;
  cursor: pointer;
}

.pop button {
  margin-left: 93%;
  display: flex;
  width: 3%;
  /* line-height: 1; */
  margin-top: -7.7%;
  position: fixed;
  background: none;
  border: none;
  cursor: pointer;
}

.modal3 {
  background: #00000021;
  margin-top: -62%;
  width: 207.2%;
  margin-left: -71%;
  height: 120%;
  visibility: hidden;
}
.popup3 {
  width: 27%;
  height: 52%;
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  top: 45%;
  left: 61%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
}
.open-popup4 {
  visibility: visible;
  transform: scale(1);
}
.popup3 img {
  width: 100px;
  margin-top: -50px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.popup3 h2 {
  font-size: 38px;
  font-weight: 500;
  margin: 30px 0 10px;
}
.popup3 button {
  width: 80%;
  margin-top: 17%;
  margin-left: 1%;
  padding: 10px 0;
  background: #6fd649;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 4%;
  width: 82%;
}

.text input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.form-2 {
  margin-left: -55%;
  margin-top: 10%;
}

form .text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: -10px;
  width: 88%;
}

.text-v input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

select {
  width: 40%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1 {
  margin-top: 20px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
  width: 48%;
}

.s2 {
  width: 48%;
  padding: 2px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
}
.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}
.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-left: -54%;
  margin-top: 9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 136%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 11%;
  width: 136%;
  margin-left: -53.5%;
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>