<template>
  <div style="display: flex; justify-content: center; align-items: center">
    <button type="button" class="btn-sm">
      <i
        class="fa-regular fa-thumbs-up"
        color="none"
        style="font-size: 1.5rem; color: black"
      ></i>
      <!-- <img v-if="!isLike" src="../assets/like-t.svg" width="20" height="20" /> -->
      <!-- <img v-else src="../assets/like.png" width="20" height="20" /> -->
    </button>
    <h6
      style="color: black; cursor: pointer;font-size: 1rem; margin-bottom: 0; margin-top: 0.2rem"
    >
      {{ likesCount }} Like
    </h6>
  </div>
</template>

<script>
export default {
  name: "user-like",
  props: {
    postId: String,
    likes_count: String,
  },
  data() {
    return {
      // liked: false,
      likesCount: this.likes_count,
      isLike: this.is_liked,
    };
  },
  mounted() {
  },

  computed: {
  },

  methods: {
  },
};
</script>

<style scoped>
.btn-sm {
  display: flex;
  background: none;
  border: none;
  color: #eb0e19;
  /* Default color for the button */
}

.liked {
  color: #eb0e19;
  /* Color when the post is liked */
}

.modal-body {
  padding: 0;
}

.search_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  padding: 13px 13px;
}

.search_bar input {
  border: 1px solid black;
  outline: none;
  width: 100%;
  border-radius: 0.2rem;
}

.user_like {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0px 13px;
}
</style>
