<template>
  <div class="custom-signupImage w-100">
    <!-- About Infinity Brains Section  -->

    <AboutInfinityBrainsVue />

    <div
      class="ml-md-3 d-flex flex-column justify-content-center align-items-center my-5"
    >
      <h1 class="fw-bold"><span class="text-danger">who</span> we are ?</h1>
      <h4 class="fw-semibold text-center">
        First Global Social Media Platform For Food Lovers
      </h4>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/happyManEating.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div
            class="ml-md-3 d-flex flex-column justify-content-center align-items-center"
          >
            <p class="fw-light fs-4">
              Redprix transforms the dining experience by integrating technology with the culinary arts, providing a unique platform for food enthusiasts and explorers. As a cutting-edge app, Redprix reshapes how people interact with food, harnessing the power of social sharing, discounts, and convenience.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center flex-row-reverse">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/restronent.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              At its core, Redprix is a paradise for food lovers, offering a dynamic platform to display gastronomic wonders through an innovative concept—foodfies. Users can snap and share their culinary adventures, known as "foodfies," diving into a community where sharing experiences is both encouraged and rewarded. The appeal goes beyond sharing; it's the key to exclusive deals and discounts on every delicious upload.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/happyWomenWithFood.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              This groundbreaking approach transforms the way individuals
              interact with their dining experiences. Beyond the joy of sharing,
              users relish in the excitement of redeeming offers and unlocking
              discounts at various partner restaurants.Redprix crafts a
              delightful synergy between food, technology, and savings,
              elevating the dining journey for its users.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center flex-row-reverse">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/foodsInTable.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              One of Redprix's key features is its ability to streamline the
              dining process. No longer bound by queues or last-minute
              uncertainties, users can effortlessly book tables in advance
              through the app, ensuring a seamless dining experience. This
              convenience is a game-changer allowing users to focus on savoring
              their meals rather than worrying about wait times.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/friendsEatingFood.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              The app's functionalities extend beyond mere food exploration.
              Redprix serves as an all-encompassing guide, assisting users in
              discovering not only the finest dining establishments but also a
              diverse array of vendors. Whether seeking breakfast, lunch,\
               or
              dinner options, the app's intuitive search filters effortlessly
              navigate users to healthy dining spots and trending restaurants
              within their vicinity.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center flex-row-reverse">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/profisionalManager.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              Moreover, Redprix embodies the spirit of community, fostering
              connections among food aficionados. It cultivates a space where
              users engage, share recommendations, and discover hidden culinary
              gems. The platform thrives on user-generated content, creating an
              interactive ecosystem that celebrates diversity in tastes and
              preferences.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5 w-75">
      <div class="row g-5 align-items-center">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/AdultCouple.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              The user experience on Redprix is designed with utmost care and
              user-friendliness. Its interface boasts simplicity without
              compromising functionality, ensuring that users can effortlessly
              navigate the app to explore, share, and redeem offers without any
              hassle. The seamless integration of social elements and practical
              features makes Redprix a must-have companion for every food
              enthusiast.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid w-75" style="margin-top: 5rem">
      <div class="row g-5 align-items-center flex-row-reverse">
        <div class="col-md-6 bg-body rounded">
          <img
            src="../assets/signup-assets/bestCouple.png"
            class="img-fluid custom-img-design"
            alt="ManEatingImage"
          />
        </div>
        <div class="col-md-6">
          <div class="ml-md-3">
            <p class="fw-light fs-4">
              In summary, Redprix is not merely an app; it's a culinary
              adventure waiting to unfold. It merges the pleasures of dining
              with the power of technology, offering a holistic experience that
              celebrates food, community, and savings. With its innovative
              approach to sharing, discovering, and enjoying food, Redprix
              stands as a beacon for those seeking culinary delight and
              convenience in a digital era.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100">
      <img
        src="../assets/signup-assets/Scroll Group 105.png"
        alt="scrollImage"
        class="img-fluid"
      />
    </div>
  </div>
</template>

<script>
import AboutInfinityBrainsVue from "./AboutInfinityBrains.vue";

export default {
  name: "SignupSecond",
  components: {
    AboutInfinityBrainsVue,
  },
};
</script>

<style scoped>
.custom-signupImage {
  background-image: url("../assets/signup-assets/194.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: initial;
}
</style>
