<template>
  <!-- The main create post component -->
  <div class="create-post">
    <header style="margin-top: 0.8rem; margin-bottom: 0.8rem">
      Create post
    </header>
    <button
      class="create_post_btn"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#exampleModas"
      style="
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 23px;
      "
    >
      <img
        v-if="userImage"
        :src="userImage"
        width="35"
        height="32"
        style="border-radius: 50%"
      />
      <img
        v-else
        src="../assets/cover.png"
        width="35"
        height="32"
        style="border-radius: 50%"
      />
      <h4
        style="
          font-size: 1.2rem;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          text-align: start;
          color: gray;
          cursor: pointer;
          padding: 0.5rem;
        "
      >
        What's on your mind {{ item.name }} ?....
      </h4>
    </button>

    <!-- Modal for creating a post -->
    <div
      class="modal fade"
      id="exampleModas"
      tabindex="-1"
      aria-labelledby="exampleModasLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              style="width: 750%; color: #fff"
              class="modal-title"
              id="exampleModasLabel"
            >
              Create Post
            </h5>
            <button
              type="button"
              class="btn btn-close text-white"
              data-bs-dismiss="modal"
              style="
                border-radius: 1rem;
                background-color: #ccc;
                width: 25%;
                opacity: 0.5;
              "
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Content for creating a post, including text input, image upload, and tag people -->
            <form>
              <div class="user-image-in-modal">
                <img
                  v-if="userImage"
                  :src="userImage"
                  width="35"
                  height="35"
                  style="border-radius: 50%"
                />
                <img
                  v-else
                  src="../assets/cover.png"
                  width="35"
                  height="35"
                  style="border-radius: 50%"
                />
                <h6 style="margin: 0">{{ item.name }}</h6>
              </div>
              <!-- Title input -->
              <div>
                <h6 style="color: #d5d5d5; position: relative; top: 23px">
                  Title
                </h6>
                <div class="text">
                  <input id="title" type="text" v-model="title" required />
                  <span></span>
                  <label>Title</label>
                </div>
              </div>
              <!-- Description input -->
              <div class="mpop">
                <input
                  class="description"
                  id="description"
                  type="text"
                  v-model="description"
                  placeholder="What's on your mind?"
                />
              </div>
              <!-- Uploaded images -->
              <div class="uploaded">
                <!-- Input for selecting images -->
                <label for="file" class="add-photo">
                  <input
                    type="file"
                    id="file"
                    multiple
                    ref="fileInput"
                    @change="handleFileChange"
                    accept="image/*"
                    style="display: none"
                  />
                  <div
                    class="add-photo-icon"
                    v-if="!previewImage"
                    :class="{
                      'level-preview': showLevel && uploadedImages.length > 0,
                    }"
                  >
                    <img
                      class="upload-img"
                      src="../assets/gallary.svg"
                      :class="{
                        'level-preview-img':
                          showLevel && uploadedImages.length > 0,
                      }"
                    />
                    <span
                      class="add-photo-text"
                      style="font-size: x-large"
                      :class="{
                        'level-preview-textsize':
                          showLevel && uploadedImages.length > 0,
                      }"
                      >Add Photo</span
                    >
                    <span
                      class="add-photo-text"
                      style="font-size: smaller"
                      v-if="!showLevel"
                      >or drag & drop</span
                    >
                  </div>
                </label>
                <!-- Display uploaded images -->
                <div
                  class="image-previews"
                  :class="{ scrollable: uploadedImages.length > 3 }"
                >
                  <div
                    class="image-row"
                    v-for="(image, index) in chunkedImages"
                    :key="index"
                  >
                    <div
                      class="image-preview"
                      v-for="(img, imgIndex) in image"
                      :key="imgIndex"
                    >
                      <button
                        @click="removeImage(img.id)"
                        class="remove-button"
                      >
                        <i class="fas fa-times" style="color: red"></i>
                      </button>
                      <img
                        :src="img.url"
                        style="width: 100%; padding: 0.5rem; height: 10rem"
                        alt="Image Preview"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Component for tagging people -->
              <div class="tag_checkin_btn">
                <!-- <TagModal :user="user" :selected-users="selectedUsers" @user-selection="handleUserSelection" /> -->
                <TagModal
                  :user="item"
                  :selected-users="localSelectedUsers"
                  @user-selection="handleUserSelection"
                />
              </div>
              <!-- Button to post the content -->
              <button
                class="post_button btn btn-danger"
                type="button"
                @click="fileUpload()"
                data-bs-dismiss="modal"
              >
                Post
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Other options like adding photos, hashtags, and location -->

    <div class="home5">
      <div
        class="h1 my-auto"
        data-bs-toggle="modal"
        data-bs-target="#exampleModas"
      >
        <img src="../assets/gallary.svg" />
        <p class="mt-2 ms-1">Photo</p>
      </div>
      <div
        class="h1 my-auto"
        data-bs-toggle="modal"
        data-bs-target="#exampleModas"
      >
        <img src="../assets/hashtag.svg" class="mt-1" />
        <p class="mt-1 ms-2">Add Hashtags</p>
      </div>
      <div
        class="h1 my-auto"
        data-bs-toggle="modal"
        data-bs-target="#exampleModas"
      >
        <img src="../assets/location-pin-1.jpg" />
        <p class="mt-1 ms-2">Location</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TagModal from "./Modal/TagModal.vue";

export default {
  props: {
    selectedUsers: Array,
  },
  name: "createPost",
  components: {
    TagModal,
  },
  data() {
    return {
      selectedFile: null,
      item: {
        name: "",
      },
      title: "",
      description: "",
      previewImage: "",
      // defaultUserImage: "../assets/cover.png",
      userImage: "",
      uploadedImages: [],
      nextImageId: 1,
      showLevel: false,
      localSelectedUsers: [], // Create a local copy of selectedUsers
    };
  },
  created() {
    this.getdata();
  },

  computed: {
    chunkedImages() {
      const chunkSize = 3;
      return this.uploadedImages.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },
  },

  methods: {
    getdata() {
      axios
        .get("customers/my-profile", {
          headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
        })
        .then((response) => {
          this.item = response.data.data;
          this.item1 = response.data.data.email;
          // Set the user image based on the user's profile data
          if (response.data.data.profile_photo) {
            this.userImage = response.data.data.profile_photo;
          }
          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token");
          }
        });
    },

    handleFileChange(event) {
      const files = event.target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.uploadedImages.push({
              id: this.nextImageId,
              url: e.target.result,
            });
            this.nextImageId++; // Increment the ID for the next image
            this.showLevel = true; // Apply the class after adding an image
          };
          reader.readAsDataURL(files[i]);
        }
      }
      this.selectedFile = files[0];
      console.log("Image File: ", this.selectedFile);
    },

    removeImage(imageId) {
      const index = this.uploadedImages.findIndex(
        (image) => image.id === imageId
      );
      if (index !== -1) {
        this.uploadedImages.splice(index, 1);
        if (this.uploadedImages.length === 0) {
          this.showLevel = false; // Remove the class if there are no images left
        }
      }
    },

    handleUserSelection(userId) {
      // Check if the userId is already in localSelectedUsers
      const index = this.localSelectedUsers.indexOf(userId);
      // console.log("this is index", index);
      if (index === -1) {
        // If not, push it to the localSelectedUsers array
        this.localSelectedUsers.push(userId);
      } else {
        // If it's already in the array, remove it from localSelectedUsers
        this.localSelectedUsers.splice(index, 1);
      }
    },

    fileUpload() {
      let formData = new FormData();
      formData.append("images[]", this.selectedFile);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append(
        "mentions",
        JSON.stringify({ user_ids: this.localSelectedUsers })
      ); // Use localSelectedUsers
      // ...
      console.log("Upload Function Called!!!");
      axios
        .post("customers/posts", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            DeviceID: "1111",
            versionName: "1.0.0",
            DeviceType: "0",
            responseType: "arraybuffer",
          },
        })
        .then((response) => {
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
/* Global Styles */
.create-post {
  margin-top: 0.8rem;
  padding: 0% 3% 0%;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  cursor: pointer;
}

.create_post_btn {
  width: 100%;
  height: 63px;
  border: none;
  line-height: 3;
  border-radius: 6px;
  background-color: whitesmoke;
}

.post_button {
  background: #eb0e19;
  margin-top: 12px;
  cursor: pointer;
  width: 100%;
}

.mpop {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #adadad;
  border-radius: 0.6rem;
  padding: 1%;
}

.description {
  width: 82%;
  height: 11vh;
  outline: none;
  margin-bottom: 3%;
  border-radius: 0.3rem;
}

/* Add Photo Button Styles */
.add-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.add-photo-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.upload-img {
  background-color: #ecebeb;
  border: 1px solid #ecebeb;
  border-radius: 3rem;
  height: 4rem;
}

.add-photo-text {
  font-weight: 700;
  color: #999999;
}

/* Input Styles */
.user-image-in-modal {
  display: flex;
  align-items: center;
}

.text {
  position: relative;
  border-bottom: 1px solid black;
  margin-top: 5%;
  width: 100%;
}

.text input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #302e2e;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

/* Modal Styles */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.8rem;
  outline: 0;
  margin-top: 8rem;
}

.modal-header {
  background-color: red;
}

/* Uploaded Images Styles */
.uploaded {
  border: 4px dashed #ccc;
  width: 100%;
  display: flex;
  height: 13rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 4%;
  position: relative;
}

.image-row {
  display: flex;
}

.image-preview {
  display: flex;
  position: relative;
  /* Relative positioning for nested elements */
  width: 50%;
  /* Display three images in a row */
  justify-content: center;
  align-items: center;
}

.image-previews.scrollable {
  max-height: 150px;
  /* Adjust the max-height as needed */
  overflow-y: auto;
}

.remove-button {
  color: red;
  font-weight: 600;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 13px;
  /* Move the cross icon to the top right corner of the image */
}

.level-preview {
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  z-index: 2;
  flex-direction: row;
  height: 2rem;
  width: 6rem;
}

.level-preview-img {
  width: 27%;
  height: 2rem;
  background: transparent;
  border: none;
}

.level-preview-textsize {
  font-size: smaller !important;
}

/* Home Section Styles */
.home5 {
  max-width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-size: 4vw;
  width: 100%;
}

.h1 {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.h1 p {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0;
}

.h1 img {
  height: 33px;
}

/* Additional Styles */
.mpop5 img {
  width: 3.8%;
  margin-top: -10%;
  position: absolute;
  margin-left: 86%;
  cursor: pointer;
}

.checkin_btn {
  width: 48%;
  padding: 2.5% 8% 1%;
  display: flex;
  border: 1px solid black;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
}

@media (max-width: 380px) {
  .h1 p {
    font-size: 0.5em;
  }

  .h1 img {
    height: 23px;
  }
  .h1 {
    padding: 0 0;
  }
}
</style>