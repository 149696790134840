<template>
  <div class="container">
    <div class="heading">
      <h3 class="fs-5">Request</h3>
      <div class="search_input">
        <input type="search" placeholder="Search" />
      </div>
    </div>
    <div class="followrs">
      <div>
        <div v-if="followrequest.length === 0">
          <p class="text-center" style="margin: 2% auto">
            You have no requests yet.
          </p>
        </div>
      </div>
      <div class="grid-container">
        <div
          class="grid-item"
          v-for="request in followrequest"
          :key="request.id"
        >
          <div v-if="request.length === 0">
            <p class="text-center" style="margin: 2% auto">
              You have no friend requests
            </p>
          </div>
          <div class="follower_profile">
            <img :src="request.profile_photo" v-if="profile_photo === ''" />
            <img src="./../assets/01.jpeg" alt />
            <div class="follower_details">
              <p>{{ request.first_name }}</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button
                class="btn btn-danger mx-1 btn-sm"
                @click="AcceptReq(request.id)"
              >
                Accept
              </button>
              <button
                @click="DeclineReq(request.id)"
                class="btn btn-danger btn-sm"
                style="background-color: #ccc; border: none"
              >
                Decline
              </button>
            </div>
          </div>
        </div>

        <!--  <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger btn-sm mx-1">Accept</button>
              <button
                class="btn btn-danger btn-sm"
                style="background-color: #ccc; border: none"
              >
                Decline
              </button>
            </div>
          </div>
        </div>-->
        <!-- <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger btn-sm mx-1">Accept</button>
              <button
                class="btn btn-danger btn-sm"
                style="background-color: #ccc; border: none"
              >
                Decline
              </button>
            </div>
          </div>
        </div>-->
        <!-- <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger btn-sm mx-1">Accept</button>
              <button
                class="btn btn-danger btn-sm"
                style="background-color: #ccc; border: none"
              >
                Decline
              </button>
            </div>
          </div>
        </div>-->
        <!-- <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger btn-sm mx-1">Accept</button>
              <button
                class="btn btn-danger btn-sm"
                style="background-color: #ccc; border: none"
              >
                Decline
              </button>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "vendrequest",
  data() {
    return {
      followrequest: [],
    };
  },
  mounted() {
    this.MyfollowReq();
  },
  methods: {
    MyfollowReq() {
      axios.get("customers/follows/my-follow-requests").then((res) => {
        this.followrequest = res.data.data;
        // console.log(this.request);
        // console.log(res.data.data);
      });
    },
    AcceptReq(id) {
      axios
        .post("customers/follows/action-type", {
          follower_id: id,
          action_type: "1",
        })
        .then((resp) => {
          console.log(resp.datadata);
        })
        .catch((error) => {
          console.log(error.resp.data.data);
          // alert(error.resp.data.data);
        });
    },
    DeclineReq(id) {
      axios
        .post("customers/follows/action-type", {
          follower_id: id,
          action_type: "2",
        })
        .then(() => {
          // console.log(resp.data.data);
        })
        .catch((error) => {
          console.log(error.resp.data.data);
          // alert(error.resp.data.data);
        });
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.google.com/specimen/Roboto?query=roboto");
.container {
  box-shadow: 0px 0px 6px #d4d4d4;
  border: 1px solid #ccc;
  padding: 0.3rem;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
  width: 100%;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading h3 {
  border-bottom: 1px solid black;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  /* grid-template-columns: repeat(auto-fill, minmax(22rem, 5fr)); */
  grid-gap: 1rem;

  /*display: grid;
  grid-template-columns: repeat( autofit, minmax(10rem, 1fr) );
  grid-gap: 10px; */
}
.grid-item {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
}
.grid-container > .grid-item {
  font-size: 30px;
  padding: 0.5em;
  text-align: center;
}
.follower_profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.follower_details p {
  margin-bottom: -1rem;
  font-size: 0.9rem;
}
.follower_details span {
  font-size: 0.9rem;
}
.Follow_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #ccc; */
  /* border: none; */
}
/* .Follow_btn button {
  /* margin-left: -0.5%; 
} */
.search_input input {
  border-radius: 0.5rem;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.2rem 1.2rem;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

/* @media (min-width: 540px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
} */
</style>