<template>
  <div class="col-md-12">
    <div class="container">
      <div class="post-nav">
        <router-link
          to="/userviewpost/mypostnav/mypost"
          style="color: #000; text-decoration: none"
        >
          <!-- @click="changeEffectPost" -->
          <div
            :class="{
              'change-post-effect':
                activeLink === '/userviewpost/mypostnav/mypost',
            }"
            @click="setActiveLink('/userviewpost/mypostnav/mypost')"
          >
            <!-- :class="
          changePost ? 'change-post-effect ' : 'change-post-effect-none '
        " -->
            My Post
          </div>
        </router-link>

        <router-link
          v-if="UserType == 2"
          to="/userviewpost/mypost/myvendoroffer"
          style="color: #000; text-decoration: none"
        >
          <!-- @click="changeEffectOffers" -->
          <div
            :class="{
              'change-post-effect':
                activeLink === '/userviewpost/mypost/myvendoroffer',
            }"
            @click="setActiveLink('/userviewpost/mypost/myvendoroffer')"
          >
            <!-- :class="
            changeOffers ? 'change-post-effect ' : 'change-post-effect-none '
          " -->
            My offers
          </div>
        </router-link>

        <router-link
          to="/userviewpost/mypost/mytags"
          style="color: #000; text-decoration: none"
        >
          <!-- @click="changeEffectTags"
        :class="
          changeTags ? 'change-post-effect ' : 'change-post-effect-none '
        " -->
          <div
            :class="{
              'change-post-effect':
                activeLink === '/userviewpost/mypost/mytags',
            }"
            @click="setActiveLink('/userviewpost/mypost/mytags')"
          >
            Tag Post
          </div>
        </router-link>
      </div>
      <router-view class="animate__animated animate__fadeIn"></router-view>
      <!-- <div class="grid-container">
        
        <div
          class="grid-item"
          v-for="post in MyPost"
          :key="post.id"
          @click="openModal(post.images[0].image)"
        >
          <div class="post_img">
            <img :src="post.images[0].image" alt="img" class="post_img" />
          </div>
        </div>
      </div>-->

      <!-- Modal HTML -->
      <!-- <div id="myModal" class="modal" @click="closeModal">
        <div class="Modal-content" @click="closeModal">
  
          <div class="single_post">
            <img id="modalImage" :src="modalImageSrc" alt="Modal Image" />
            <div class="user_details">
              <img class="user_profile_img" src="../../assets/cover.png" />
              <span style="    margin-left: 3rem; margin-right: 10rem;">Rajesh Prajapati</span>
              <span style="display: flex;  justify-content: flex-end; align-items: baseline;">
                <div class="dropdown">
                  <button class="dropdown-trigger" id="dropdown-trigger" @click="opendropdown">
                    <img src="../../assets/dots.svg" style="padding:3%" @click="opendropdown" alt />
                  </button>
                  <div
                    class="dropdown-content"
                    id="dropdown-content"
                    style="
                     position: absolute;
                    background: rgb(255, 255, 255);
                    border: 1px solid rgb(170, 170, 170);
                    border-radius: 0.5rem;
                    width: 102px;
                    right: 10%;"
                  >
                    <a href="#">Edit</a>
                    <a href="#">Delete</a>
                    <a href="#">Hide</a>
                    <a href="#">Copy Link</a>
                  </div>
                </div>
              </span>
              <div class="User_tags">
                <span>Raj hoto studio is the first global community platform</span>
                <ul style="list-style-type:none">
                  <li>#Restrant</li>
                  <li>#Jobs</li>
                  <li>#chef</li>
                </ul>
                <div class="user_action">
                  <img src="../../assets/like-thumbs.png" />
                  <span>12 like</span>
                  <img src="../../assets/comment.png" />
                  <span>12 comments</span>

                  <img src="../../assets/share.png" />
                  <span>12 share</span>
                </div>
                <div class="user_comments">
                  <img src="../../assets/cover.png" alt />
                  <div class="comments">
                    <div class="user_name">
                      <span>Sonal Rana</span>
                      <img src="../../assets/dots.svg" />
                    </div>
                    <p>hello</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "MyPost",
  data() {
    return {
      MyPost: [],
      modalImageSrc: "",
      showPoup: false,
      changePost: false,
      changeTags: false,
      changeOffers: false,
      UserType: "",
      activeLink: "",
    };
  },
  mounted() {
    this.getdata();
    this.activeLink = this.$route.path;
  },
  methods: {
    setActiveLink(link) {
      this.activeLink = link; // Update activeLink when a link is clicked
    },
    opendropdown() {
      // alert("hello");
      const showButton = document.getElementById("dropdown-trigger");
      const hiddenDiv = document.getElementById("dropdown-content");

      showButton.addEventListener("click", () => {
        hiddenDiv.classList.toggle("dropdown-content");
      });
    },

    myPost() {
      axios.get("customers/my-posts").then((res) => {
        // console.log(res.data.data);
        this.MyPost = res.data.data;
        // console.log("MyPost", this.MyPost);
      });
    },
    getdata() {
      axios
        .get("customers/my-profile", {
          headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
        })
        .then((response) => {
          this.item = response.data.data;
          this.UserType = response.data.data.user_type;
          // console.log("User Type: ", this.UserType);
          // localStorage.setItem("User_type")
          // alert(this.UserType)
          // console.log("userType",this.UserType)
          this.item1 = response.data.data.email;

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        });
    },
    openModal(imageSrc) {
      this.showModal = true;
      this.modalImageSrc = imageSrc;
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
    },
    closeModal(event) {
      if (event.target.id === "myModal") {
        const modal = document.getElementById("myModal");
        modal.style.display = "none";
      }
    },
    changeEffectPost() {
      this.changePost = !this.changePost;
      this.changeTags = false;
      this.changeOffers = false;
    },
    changeEffectTags() {
      this.changeTags = !this.changeTags;
      this.changePost = false;
      this.changeOffers = false;
    },
    changeEffectOffers() {
      this.changeOffers = !this.changeOffers;
      this.changePost = false;
      this.changeTags = false;
    },
  },
};
</script>

<style scoped>
.row {
  height: 7.5vh;
  width: 75%;
  margin-left: 12%;
}
.container {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 0px 8px #ccc;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  gap: 10px;
}

.grid-item {
  margin-top: 0.5rem;
  /* border: 1px solid #ccc; */
  padding: 1px;
  text-align: center;
  width: 50%;
}
.post_img {
  border-radius: 0.4rem;
  width: 126%;
  height: 100px;
}

/* The Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Content */
.Modal-content {
  margin: 3rem auto;
  display: block;
  width: 57%;
  max-width: 80%;
  /* max-height: 80%; */
  /* height: 70%; */
  background: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

/* Close Button */
.close {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #ccc;
  text-decoration: none;
  cursor: pointer;
}
#modalImage {
  width: 40%;
}
.single_post {
  display: flex;
  justify-content: space-between;
}
.user_details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* .user_details span{
      margin-left: -3rem;
} */
.user_profile_img {
  width: 20%;
  height: 20%;
  border-radius: 50%;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #fff;
  border: 1px solid #aaa;
}

.dropdown.active .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}
#dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

#dropdown-content a:hover {
  background: #f0f0f0;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.User_tags {
  margin-top: 6rem;
  margin-left: -26rem;
}
.user_action {
  padding: 0.2rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
}
.user_action img {
  width: 5%;
}
.user_comments {
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.user_comments img {
  width: 10%;
}
.comments {
  width: 100%;
  background-color: #ccc;
  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 0.4rem;
}
.user_name {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.3rem;
}
.user_name img {
  width: 5%;
}
.post-nav {
  gap: 20px;
  border-bottom: 2px solid #ccc;
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  width: 101%;
}
.change-post-effect {
  color: #eb0e19;
  /* padding-bottom: 32%; */
  border-bottom: 0.3rem solid #eb0e19;
}
.change-post-effect-none {
  color: #b4b4b4;
}
</style>