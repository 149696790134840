<template>
  <div>
    <form style="margin-top: 0%">
      <div class="text">
        <input type="text" v-model="business_name" required />
        <span></span>
        <label>Business Name</label>
      </div>
      <div class="text">
        <input type="text" v-model="business_email" required />
        <span></span>
        <label>Business Email</label>
      </div>
      <div class="text-s" style="margin-left: 0%">
        <select
          id="business_type"
          class="s2"
          style="margin-left: 0%"
          v-model="business_id"
          @change="GetBusniessType()"
          required
        >
          <option class="j" value>Type of Busniess</option>

          <option
            class="j"
            v-for="business in BusinessList"
            :key="business.id"
            :value="business.id"
          >
            {{ business.business_name }}
          </option>
        </select>
      </div>
      <div class="text pt-0 d-flex align-items-center justify-content-between">
        <input
          type="file"
          id="proof-input"
          @change="handleFileChange"
          required
          style="opacity: 0; cursor: pointer"
        />
        <span></span>
        <label for="proof-input">Upload Proof</label>
        <button
          class="btn"
          style="background: #eb0e19; color: white; padding: 0.2rem"
          type="button"
          @click="OpenFile1()"
        >
          Browse
        </button>
      </div>
      <div class="text">
        <input type="text" v-model="gst_no" required />
        <span></span>
        <label>GST IN</label>
      </div>

      <div class="text-v">
        <input type="text" required />
        <span></span>
        <label>Busniess Website</label>
      </div>

      <div class="text-v">
        <input type="text" v-model="business_mobile" required />
        <span></span>
        <label>Busniess Contact</label>
      </div>

      <div
        class="text-v"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <input
          id="file"
          type="file"
          @change="handleFileChange1"
          required
          style="opacity: 0; cursor: pointer"
        />
        <span></span>
        <label>Upload Logo</label>
        <button
          class="btn"
          style="background: #eb0e19; color: white; padding: 0.2rem"
          type="button"
          @click="OpenFile()"
        >
          Browse
        </button>
      </div>

      <div
        class="text-v"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <input
          type="file"
          @change="uploadBusniessimage"
          required
          id="fileupload"
          style="opacity: 0; cursor: pointer"
        />
        <span></span>
        <label>Upload Busniess place Image</label>
        <button
          class="btn"
          style="background: #eb0e19; color: white; padding: 0.2rem"
          type="button"
          @click="OpenFile1()"
        >
          Browse
        </button>
      </div>

      <div class="text-s" style="margin-left: 0%">
        <select
          id="country"
          class="s1"
          style="margin-left: 0%"
          v-model="country_id"
          @change="getState()"
          required
        >
          <option value class="j">Select Country</option>
          <option
            v-for="country in countries"
            :key="country.id"
            :value="country.id"
          >
            {{ country.name }}
          </option>
        </select>

        <select
          id="state"
          class="s2"
          style="margin-left: 0%"
          v-model="state_id"
          @change="getCity()"
          required
        >
          <option class="j" value>Select State</option>

          <option
            class="j"
            v-for="state in states"
            :key="state.id"
            :value="state.id"
          >
            {{ state.name }}
          </option>
        </select>

        <select
          id="state"
          class="s2"
          style="margin-left: 0%"
          v-model="city_id"
          @change="getCity()"
          required
        >
          <option class="j" value>Select City</option>

          <option
            class="j"
            v-for="city in cities"
            :key="city.id"
            :value="city.id"
          >
            {{ city.name }}
          </option>
        </select>
      </div>

      <button
        type="button"
        class="btn-1"
        style="margin-left: 0%"
        @click="GetUserDetails"
      >
        Next
      </button>
      <!-- </router-link> -->
    </form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "MandatoryField",
  data() {
    return {
      selectedFile: null,
      business_logo: null,
      Busniessimage: null,
      business_name: "",
      business_email: "",
      business_address: "",
      business_id: "",
      city_id: "",
      state_id: "",
      country_id: "101",
      // business_logo: "",
      opening_hours: "",
      business_mobile: "",
      gst_no: "",
      countries: [],
      states: [],
      cities: [],
      BusinessList: [],
    };
  },
  mounted() {
    this.GetBusniessType();
    // this.GetUserDetails();
    //  this.getCity();
    this.getData();
    this.getState();
  },
  methods: {
    handleFileChange1(event) {
      const file = event.target.files[0];
      this.business_logo = file;
      // console.log(this.business_logo);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      // console.log(this.selectedFile);
    },
    uploadBusniessimage(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      if (file) {
        this.selectedFile.textContent = file.name;
      } else {
        this.selectedFile.textContent = "";
      }
      // console.log(this.Busniessimage);
    },

    OpenFile() {
      const fileInput = document.getElementById("file");
      fileInput.click();
    },
    OpenFile1() {
      const fileInput = document.getElementById("fileupload");
      fileInput.click();
    },

    getData() {
      axios
        .get("countries?is_light=1")
        .then((result) => {
          this.countries = result.data.data;
          // console.log("conutries", this.countries);
          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getState() {
      axios
        .get("states?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCity() {
      axios
        .get("cities_of_state?is_light=1", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          // this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    GetBusniessType() {
      axios
        .get("admin/business")
        .then((response) => {
          this.BusinessList = response.data.data;
          // consle.log("business_id", this.business_id);
          // console.log("BusinessList", this.BusinessList);
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    GetUserDetails() {
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("business_logo", this.business_logo);
      formData.append("business_name", this.business_name);
      formData.append("business_email", this.business_email);
      formData.append("business_address", this.business_address);
      formData.append("business_mobile", this.business_mobile);
      formData.append("gst_no", this.gst_no);

      formData.append("city_id", this.city_id);
      formData.append("country_id", this.country_id);
      formData.append("state_id", this.state_id);
      formData.append("business_id", this.business_id);

      axios
        .post("vendors/vendors", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // console.log("Response of Vendor: ", response);
          alert("Vendor Created Succesfully!!");
          this.$router.push("/menulist/becomevendor/uploadoffers");
          // console.log("File uploaded successfully!", response.data.data);
          // Handle server response or do something after successful upload
        })
        .catch((error) => {
          console.error(error.response.data.message);
          // Handle error if the upload fails
        });
    },
  },
};
</script>
<style  scoped>
form {
  width: 100%;
  height: 40.5rem;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px #d4d4d4;
  margin-bottom: 3%;
  overflow-y: auto;
  padding: 0.2rem 1.3rem;
}

form::-webkit-scrollbar {
  display: none;
}
form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 10px 0;
  width: 100%;
}

.text input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 17px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}
form {
  /* margin-left: 40%;  */
}
/* .form-2 {
   margin-left: -55%;
   margin-top: 10%; 
} */
input[type="file"]::-webkit-file-upload-button {
  padding-left: 0rem;
  visibility: hidden;
}

form .text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 10px 0;
  width: 100%;
}

.text-v input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 17px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

form .text-s {
  /* margin-left: -254px; */
}

select {
  width: 40%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1,
.s3 {
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
  width: 100%;
  margin: 10px 0;
}

.s2 {
  padding: 2px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 100%;
  margin: 10px 0;
}
.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}
.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-left: -54%;
  margin-top: 9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 136%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 11%;
  width: 100%;
  /* margin-left: -53.5%; */
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>