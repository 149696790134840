<template>
  <div class="animate__animated animate__fadeIn">
    <navbar />

    <div class="row">
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="noti1 px-1">
          <div
            class="noti2 py-3 d-flex align-items-center justify-content-evenly"
          >
            <h3 class="">Notification setting</h3>
            <router-link
              class="w-25 text-right"
              to="/setting/Changepassword"
              style="text-decoration: none; color: gray"
            >
              <img src="../assets/setting.jpg" width="20" height="20" />
            </router-link>
          </div>
          <div></div>

          <div class="noti3 text-justify">
            RedPrix is the ultimate social media platform for food lovers,
            offering the best discounts and deals on restaurants near your
            location. With RedPrix, you can discover new and delicious eats
            while saving big on your dining experiences.
          </div>
        </div>

        <div class="noti4">
          <img src="../assets/bur3.png" />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="noti5">
          <h3>Notification</h3>

          <div v-if="notis.length > 0">
            <div
              class="noti6 position-relative"
              v-for="noti in notis"
              :key="noti.id"
            >
              <img :src="noti.data.offer_image" width="45" height="40" />
              <h4>
                {{ noti.data.notification_action }}
                {{ noti.data.notification_text }} {{ noti.data.offer_text }}...
                valid date : {{ moment(noti.data.valid_till).fromNow() }}
              </h4>
              <h6>{{ moment(noti.created_at).fromNow() }}</h6>
              <div class="noti7">
                <!-- v-on:click="open()" -->
                <img
                  src="../assets/vdot.svg"
                  @click="toggleToolTip(noti.id)"
                  width="10"
                  height="15"
                />
              </div>
              <div class="menu-tooltip" v-if="tooltipStates[noti.id]">
                <ul>
                  <router-link
                    to=""
                    @click="removeNotification(noti.id)"
                    class="text-decoration-none text-black"
                  >
                    <li>Remove Notification</li>
                  </router-link>
                  <hr />
                  <router-link
                    to=""
                    @click="muteNotification(noti.id)"
                    class="text-decoration-none text-black"
                  >
                    <li>Mute</li>
                  </router-link>
                  <hr />
                  <router-link
                    to=""
                    @click="offNotification(noti.id)"
                    class="text-decoration-none text-black"
                  >
                    <li>Turn Off</li>
                  </router-link>
                </ul>
              </div>
              <div
                class="overlay"
                v-if="tooltipStates[noti.id]"
                @click="closeToolTip(noti.id)"
              ></div>
            </div>
          </div>
          <!-- <div></div> -->
          <div v-else class="text-center my-2 text-muted">
            You didn't receive any notification yet.
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="noti10 mb-2">
          <img src="../assets/ice.png" width="200" height="220" />
        </div>
        <div class="noti11">
          <img
            class="ms-5 my-3"
            src="../assets/rp1.png"
            width="100"
            height="40"
          />
          <h5 class="text-justify">
            At RedPrix, we believe that dining experiences should be memorable,
            budget-friendly, and stress-free. That's why we've designed a
            user-friendly platform that makes booking tables and finding the
            perfect restaurant for any occasion a breeze.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import navbar from "./nav.vue";
export default {
  name: "notiFication",
  components: {
    navbar,
  },
  data() {
    return {
      notis: [],
      tooltipStates: {},
      moment: moment,
    };
  },
  methods: {
    removeNotification(id) {
      // console.log("Notification Id: ", id);
      axios.post(`customers/notifications/delete/${id}`).then(() => {
        // console.log("Notification deleted!!");
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      });
    },
    muteNotification(id) {
      console.log("Mute Notification!!", id);
    },
    offNotification(id) {
      console.log("Turn Off Notification!!", id);
    },
    toggleToolTip(noti_id) {
      // Toggle the tooltip state for the clicked notification
      this.tooltipStates[noti_id] = !this.tooltipStates[noti_id];
    },
    closeToolTip(noti_id) {
      // Close the tooltip when clicking outside
      this.tooltipStates[noti_id] = false;
    },
    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },

    getNoti() {
      axios.get("customers/notifications", {}).then((response) => {
        // console.log(response);
        this.notis = response.data.data;
        if (localStorage.getItem("token")) {
          axios.defaults.headers.common["Authorization"] =
            " Bearer " + localStorage.getItem("token");
        }
      });
    },
    open() {
      let sub = document.getElementById("sub");
      sub.classList.toggle("sub-all");
    },
    logoutUser() {
      axios.get("logout", {}).then(() => {
        localStorage.clear();
        console.clear();
        this.$router.push("/");
      });
    },
  },
  mounted() {
    this.getNoti();
  },
};
</script>
<style scoped>
.menu-tooltip {
  width: 30%;
  position: absolute;
  right: 0;
  /* top: 0; */
  background-color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 0.5rem;
  z-index: 1;
}

.menu-tooltip ul {
  font-size: 0.9rem;
  padding: 0.6rem;
  text-align: left;
  text-decoration: none;
  list-style-type: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  /* Adjust z-index as needed */
}

.row {
  width: 75%;
  margin: 0 auto;
}
.nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
}
.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}
.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}
.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}
.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}

.content {
  max-width: 760px;
  margin: auto;
}
.notification {
  margin-top: 3%;
  margin-left: -19.8%;
  width: 138.8%;
}
.noti1 {
  width: auto;
  background: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 5px #ccc;
  height: 50vh;
  margin-left: auto;
}

.noti2 {
  width: 100%;
  border-bottom: 1px solid lightgray;
  /* padding-bottom: 0%; */
}
.noti2 h3 {
  width: 60%;
  margin-bottom: 0;
  font-size: 95%;
}
.noti2 img {
  cursor: pointer;
}
.noti3 {
  width: 89%;
  padding-top: 0%;
  margin-left: 6%;
  margin-top: 5%;
  font-size: 77%;
  color: black;
  font-weight: 500;
  opacity: 0.9;
}
.noti4 {
  width: auto;
  margin-top: 3%;
  margin-left: auto;
}
.noti4 img {
  width: 100%;
}
.noti5 {
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding-bottom: 4%; */
  border: 1px solid lightgray;
  box-shadow: 0px 0px 5px #ccc;
}

.noti5 h3 {
  width: 100%;
  font-size: 115%;
  margin-top: 2%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 3%;
  padding-top: -2%;
  margin-left: 0%;
  padding-left: 3%;
}
.noti6 {
  margin-top: 2%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 6%;
  width: 100%;
  margin-left: 0%;
  margin-bottom: 4%;
}

.noti6 img {
  border-radius: 50%;
  margin-left: 3%;
}
.noti6 h4 {
  width: 56%;
  margin-top: -7%;
  margin-left: 13%;
  font-size: 80%;
  font-weight: 100;
}
.noti6 h6 {
  width: 15%;
  margin-left: 13%;
  margin-top: 1%;
  font-size: 64%;
  font-weight: 100;
  color: gray;
}
.noti7 {
  width: 3%;
  margin-left: 94%;
  margin-top: -10%;
}

.noti7 img {
  cursor: pointer;
}
.noti8,
.noti9 {
  margin-left: 70%;
  width: 11%;
  margin-top: -3%;
}
.noti9 {
  margin-left: 82%;
  margin-top: -4.2%;
}
.noti8 button {
  width: 96%;
  background: #eb0e19;
  color: white;
  border: none;
  line-height: 1.5;
  border-radius: 6px;
  font-size: 77%;
}
.noti9 button {
  width: 96%;
  background: #eb0e19;
  color: white;
  border: none;
  line-height: 1.5;
  border-radius: 6px;
  font-size: 77%;
}
.noti11 {
  width: 80%;
}

.noti11 h5 {
  margin-top: 2%;
  font-weight: 100;
  color: black;
  font-size: 74%;
  font-weight: 500;
  opacity: 0.9;
}
.sub {
  visibility: hidden;
}
.sub-all {
  visibility: visible;
}
</style>