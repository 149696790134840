<template>
  <div style="display: flex; justify-content: center; align-items: center">
    <button @click="toggleLike()" type="button" class="btn-sm">
      <i
        class="fa-regular fa-thumbs-up"
        color="none"
        v-if="!isLike"
        style="font-size: 1.5rem; color: black"
      ></i>
      <i
        class="fa-regular fa-thumbs-up animate__animated animate__heartBeat"
        v-else
        style="font-size: 1.5rem; font-weight: 600; color: #eb0e19"
      ></i>
      <!-- <img v-if="!isLike" src="../assets/like-t.svg" width="20" height="20" /> -->
      <!-- <img v-else src="../assets/like.png" width="20" height="20" /> -->
    </button>
    <h6
      @click="likeList"
      data-bs-toggle="modal"
      data-bs-target="#exampleModav"
      style="
        color: black;
        cursor: pointer;
        font-size: 1rem;
        margin-bottom: 0;
        margin-top: 0.2rem;
      "
    >
      {{ likesCount }} Like
    </h6>

    <div
      class="modal fade"
      id="exampleModav"
      tabindex="-1"
      aria-labelledby="exampleModavLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content" style="margin-top: 27%">
          <div class="modal-header" style="background: #eb0e19">
            <h5 class="modal-title" id="exampleModavLabel" style="color: white">
              Likes
              <img
                src="../assets/like-thumb.png"
                alt="like-thumb-image"
                width="35"
                height="35"
                style="position: relative; top: -7px; margin: 0 6px"
              />
              {{ this.likesCount }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="background-color: white; border-radius: 4rem"
            ></button>
          </div>
          <div class="modal-body" @click.stop>
            <div class="search_bar">
              <i
                class="fa fa-search"
                style="position: absolute; left: 23px; color: #a59595"
              ></i>
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search"
                v-model="searchTerm"
                @input="showUsers"
                style="padding: 7px 34px"
              />
            </div>
            <div>
              <hr />
            </div>
            <div
              class="user_like"
              v-for="like in filteredLikes"
              :key="like.id"
              @click.stop
            >
              <div v-if="like.user">
                <img
                  v-if="like.user.profile_photo"
                  :src="like.user.profile_photo"
                  height="50"
                  width="50"
                  :style="{ 'border-radius': '50%' }"
                />
                <img
                  v-else
                  src="../assets/cover.png"
                  alt="Default Image"
                  height="50"
                  width="50"
                  :style="{ 'border-radius': '50%' }"
                />
                <span style="padding: 0 13px">{{ like.user.name }}</span>
              </div>
              <div class="like-users-btn" v-if="like.user">
                <button
                  v-if="!like.isFollowing"
                  type="button"
                  class="btn btn-danger"
                  style="background-color: #eb0e19"
                  @click="follow(like)"
                >
                  Follow
                </button>
                <button
                  v-else-if="like.isFollowing"
                  type="button"
                  class="btn btn-danger"
                  style="background-color: #eb0e19"
                  @click="unfollow(like)"
                >
                  Unfollow
                </button>
              </div>
              <hr style="width: 100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "user-like",
  props: {
    postId: String,
    likes_count: String,
    is_liked: Boolean,
  },
  data() {
    return {
      // liked: false,
      likesCount: this.likes_count,
      isLike: this.is_liked,
      likes: [],
      showlikes: [],
      name: "",
      // isFollowing: false,
      searchTerm: "", // Add a search term property
    };
  },
  mounted() {
    this.likeList();
  },

  computed: {
    filteredLikes() {
      const searchTerm = this.searchTerm.toLowerCase();
      return this.showlikes.filter((like) => {
        return like.user && like.user.name.toLowerCase().includes(searchTerm);
      });
    },
  },

  methods: {
    toggleLike() {
      const action = this.isLike ? "dislike" : "like";
      axios
        .post(`customers/likes/toggle-like`, {
          post_id: this.postId,
          action: action,
        })
        .then((response) => {
          if (response.status === 200) {
            this.isLike = !this.isLike;
            this.likesCount = response.data.data.likes_count;
          }
        })
        .catch((error) => console.log(error));
    },

    getlike() {
      axios
        .post(`customers/likes/toggle-like`, {
          post_id: this.postId,
        })
        .then((response) => {
          if (response.data.data.action === "like") {
            this.isLike = true;
            this.likesCount++;
          } else if (response.data.data.action === "dislike") {
            this.isLike = false;
            this.likesCount--;
          }

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token");
          }
        })
        .catch((error) => console.log(error));
    },

    likeList() {
      axios
        .post(`customers/posts/likes`, { post_id: this.postId })
        .then((response) => {
          const responseData = response.data.data;
          if (responseData && responseData.likes) {
            const likesData = responseData.likes.data;
            // console.log('Likes Data:', likesData); // Add this line for debugging
            this.showlikes = likesData;
            this.likesCount = responseData.likes_count; // Set the correct likes count
          } else {
            console.error("Invalid response structure:", response);
          }
        })
        .catch((error) => {
          console.error(error.response.data.message);
        });
    },

    follow(user) {
      axios
        .post("customers/follows/follow", {
          followee_id: user.user_id,
        })
        .then(() => {
          user.isFollowing = true;
        });
    },

    unfollow(user) {
      axios
        .post("customers/follows/unfollow", {
          followee_id: user.user_id,
        })
        .then(() => {
          user.isFollowing = false;
        });
    },
  },
};
</script>

<style scoped>
.btn-sm {
  display: flex;
  background: none;
  border: none;
  /* Default color for the button */
}

.liked {
  color: red;
  /* Color when the post is liked */
}

.modal-body {
  padding: 0;
}

.search_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  padding: 13px 13px;
}

.search_bar input {
  border: 1px solid black;
  outline: none;
  width: 100%;
  border-radius: 0.2rem;
}

.user_like {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0px 13px;
}
</style>
