<template>
  <div class="w-100 position-relative bottom-0 py-2 footer-div">
    <!-- <h3 class="text-white fs-5 text-center my-3">Footer Of GarbaJockey</h3> -->
  </div>
</template>
  
  <script>
export default {
  name: "Dummy_Footer",
};
</script>
  
<style scoped>
.footer-div {
  margin-top: 8rem;
  background-color: #ff1b2e;
  height: 50px;
}
</style>