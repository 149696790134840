<template>
  <div class="dropdown me-2">
    <button
      class="dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="background: none; border: none"
    >
      <!-- <img src="../assets/dots.svg" width="18" height="18" /> -->
      <i
        class="fa-solid fa-ellipsis"
        style="font-size: 1.2rem; color: black"
      ></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li style="cursor: pointer" @click="hidePost()">
        <a class="dropdown-item">Hide</a>
      </li>
      <li style="cursor: pointer">
        <a class="dropdown-item">Copy Link</a>
      </li>
      <li
        style="cursor: pointer"
        @click="reportPost()"
        data-bs-toggle="modal"
        data-bs-target="#exampleModat"
      >
        <a class="dropdown-item">Report Post</a>
      </li>
    </ul>
  </div>

  <div
    class="modal fadet"
    id="exampleModat"
    tabindex="-1"
    aria-labelledby="exampleModatLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Report Post</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">this post is reported successfully</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "list",
  props: {
    postId: String,
  },
  methods: {
    reportPost() {
      // console.log(this.postId);
      axios
        .post(`customers/posts/reports/${this.postId}`, {
          post_id: this.postId,
          is_reported: 1,
        })
        .then((response) => {
          response.data.data;

          // console.log(response);

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
          // Pass postId to likeList method
        })
        .catch((error) => console.log(error));
    },
    hidePost() {
      let home8 = document.getElementById("home8");
      home8.classList.add("cus-all");
    },
  },
};
</script>
<style scoped>
.dropdown-toggle::after {
  display: none;
}
</style>
