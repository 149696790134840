<template>
  <div
    class="home8 animate__animated animate__zoomIn"
    id="home8"
    style="mamountergin-bottom: 37%"
    v-for="post in posts"
    :key="post.id"
  >
    <div class="user_details">
      <!-- {{post}} -->
      <img
        src="./../assets/pro2.jpg"
        controls
        style="width: 2.7rem; height: 2.3rem; border-radius: 50%"
        v-if="post.user.profile_photo == ''"
      />

      <img
        :src="post.user.profile_photo"
        v-else
        controls
        style="width: 2.7rem; height: 2.3rem; border-radius: 50%"
      />

      <div class="w-100 ms-1 d-flex flex-column">
        <div class="mt-1">
          <h6 class="mb-0 ms-2" style="font-size: 1.1rem">
            {{ post.user.name }} {{ post.images[0].post_id }}
            <!-- {{ post.user.name }} -->
          </h6>
        </div>
        <!-- <div class="d-flex align-items-center ms-2" style="margin-top: 0rem">
          <i
            class="fa-regular fa-clock"
            style="font-size: 0.6rem; margin-top: 0.1rem; color: black"
          ></i>
          <h6
            class="ms-1"
            style="margin-bottom: 0; margin-top: 0rem; font-size: 0.8rem"
          >
            {{ moment(post.images[0].created_at).fromNow() }}
          </h6>
        </div> -->
      </div>
      <div class="list_btn ms-auto">
        <list :postId="post.id" />
      </div>
    </div>
    <!-- <div class="h2">
      <div class="h3">
        <img src="../assets/clock.png" width="10" height="10" />
        <h6 style="font-size: 40%; margin-left: 2%; margin-top: -0.8%">
          {{ moment(post.images[0].created_at).fromNow() }}
        </h6>
      </div>
    </div> -->

    <div class="home10 ms-2">
      <h5 style="font-size: 80%; color: gray">{{ post.description }}</h5>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <img :src="post.images[0].image" style="width: 90%; height: 280px" />
    </div>
    <div class="home11" style="border-bottom: 1px solid lightgray">
      <div class="ho1 d-flex align-items-center justify-content-evenly w-100">
        <userlike
          :postId="post.id"
          :post-id="post.id"
          :likes_count="post.likes_count"
          :is_liked="post.is_liked"
        />
        <usercomment :postId="post.id" :comments_count="post.comments_count" />
        <usershare :postId="post.id" :shares_count="post.shares_count" />
      </div>
    </div>
    <div class="home12">
      <img
        src="../assets/pro2.png"
        width="30"
        height="30"
        style="border-radius: 50%; margin-left: 1%"
      />
      <!-- v-for="comments in ShowComments" :key="comments.id" -->
      <p v-for="post in posts" :key="post.id"></p>
      <div v-once>
        <p v-show="showComments(post.id)"></p>
      </div>
      <div class="home13">
        <button
          @click="toggleDown()"
          style="margin-left: 95%; border: none; background: none"
        >
          <img src="../assets/dots.svg" width="15" height="15" />
        </button>
        <h6
          style="
            margin-left: 70%;
            font-size: 70%;
            margin-top: -3.5%;
            margin-left: 85%;
          "
        >
          4m...
        </h6>
        <h4
          style="
            font-size: 90%;
            padding-left: 1%;
            padding-top: 1%;
            margin-top: -6%;
          "
        ></h4>
        <div v-for="comments in ShowComments" :key="comments.id">
          <h6 style="font-size: 60%; margin-top: -1.5%; padding-left: 1%">
            {{ comments.name }}
          </h6>
          <h6
            style="
              font-size: 55%;
              margin-top: -1%;
              padding-left: 1%;
              padding-bottom: 1%;
            "
          >
            {{ comments.comment }}
          </h6>
        </div>
      </div>
      <div
        class="togg4"
        style="
          width: 6%;
          border: 1px solid;
          margin-left: 26%;
          position: absolute;
          background: white;
          border-radius: 10px;
          margin-top: 4%;
        "
      >
        <div>
          <h6
            style="
              border-bottom: 1px solid;
              /* width: 83%; */
              margin-left: 0%;
              padding-top: 4%;
              padding-bottom: 9%;
              padding-left: 4%;
              font-size: 70%;
            "
          >
            Edit
          </h6>
        </div>
        <div>
          <h6
            style="
              /* width: 83%; */
              margin-left: 0%;
              font-size: 70%;
              padding-left: 4%;
            "
          >
            Delete
          </h6>
        </div>
      </div>

      <!--      
        <h5
          style="  font-size: 70%;border-bottom: 1px solid; width: 25%; cursor: pointer;margin-top: 2%; margin-left: 2%;"
      >View more comments</h5>-->
    </div>
  </div>
  <div class="home8" v-if="posts.length === 0">
    <p class="text-center" style="margin: 2% auto">
      You had not uploaded any post yet
    </p>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import userlike from "./user-like.vue";
import usercomment from "./user-comment.vue";
import usershare from "./user-share.vue";
import list from "./list.vue";
export default {
  name: "VendorPost",
  components: { userlike, usercomment, usershare, list },

  data() {
    return {
      ShowComments: [],
      moment: moment,
      posts: [],
    };
  },
  // props:{
  //     postId:String
  // },
  mounted() {
    this.getpost();
  },
  methods: {
    async getpost() {
      await axios
        .get("customers/my-posts", {
          headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
        })
        .then((response) => {
          // localStorage.setItem("id",response.data.data[0-15].images[0-15].post_id)
          // this.posts = response.data.data;
          // console.log(this.posts)
          // localStorage.setItem("Post_id", response.data.data)
          // const hidePostid = localStorage.getItem("hidePostId");
          this.posts = response.data.data;
          // console.log(response.data.data);
          // .filter((item)=> item.status === "1");
          // this.posts.forEach((item)=>{
          //   if(item.id == )
          // })
          //  this.users =  response.data.data
          //  console.log( response.data.data[0].id)
          // console.log(this.posts[0].images[0].image);
          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        });
    },
    showComments(id) {
      // alert()
      axios
        .post("customers/posts/comments", {
          post_id: id,
        })
        .then((response) => {
          this.ShowComments = response.data.data.comments;
          // console.log(this.ShowComments, "showComments");
        });
    },
    toggleMenu() {
      let togg = document.querySelector(".togg");
      togg.classList.toggle("newlist");
    },

    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },
    toggleDown() {
      let togg4 = document.querySelector(".togg4");
      togg4.classList.toggle("newlist4");
    },
    toggleSecond() {
      let togg5 = document.querySelector(".togg5");
      togg5.classList.toggle("newlist5");
    },
    postClick() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.add("postpop");
    },
    closePost() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.remove("postpop");
    },
    tagPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.add("tagpop");
    },
    returnPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.remove("tagpop");
    },
  },
};
</script>
<style scoped>
.home8 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  /* width: 56vmax; */
  width: 100%;
}

/*post css */

.nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
}
.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}
.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}
.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}
.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}
.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist3 {
  transform: scale(1);
}
.togg4 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist4 {
  transform: scale(1);
}
.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist5 {
  transform: scale(1);
}
.home {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 1%;
}
.home button {
  margin-left: 21%;
  margin-top: 3%;
  width: 57%;
  border: none;
  line-height: 2.2;
  font-size: 88%;
  border-radius: 6px;
  background: dodgerblue;
  color: white;
}
/* .home2 {
  margin-top: 29%;
  width: 71%;
  height: 40vh;
} */
.home2 img {
  width: 100%;
  padding: 1.5%;
}
.home3 {
  margin-left: 0%;
  width: 53%;
  height: 5vh;
  padding: 0rem 1rem 0rem;
}

.home6 {
  margin-top: 2%;
  margin-left: -2%;
}

.home8 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  visibility: visible;
}

.cus-all {
  visibility: hidden;
}

.togg {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist {
  transform: scale(1);
}
.newlist1 {
  transform: scale(1);
}
.home15 {
  margin-top: 2%;
}
.h2 {
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: space-between;
}
.h3,
.h4 {
  margin-left: 2rem;
  display: flex;
  width: 22%;
  justify-content: center;
  align-items: baseline;
  margin-top: -1rem;
}
.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}
.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 7%;
  padding-left: 1%;
}

.ho1 {
  display: flex;
  margin-left: 1%;
  gap: 3%;
  margin-top: 2%;
  padding: 0% 0.5% 0%;
}
.home12 {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 5%;
  padding: 1%;
}
.home13 {
  margin-top: -2%;
  width: 85%;
  /* margin-left: 8%; */
  background: rgb(243, 241, 241);
  border-radius: 6px;
}

.home16 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0% 1% 0%;
  /* margin-left: 25%; */
  border: 1px solid lightgray;
  margin-top: 1.5%;

  border-radius: 6px;
  margin-bottom: 26%;
  background-color: #fff;
  padding: 0% 3% 0%;
  flex-wrap: wrap;
}
.home17 {
  width: 27%;
  border: 1px solid;
  margin: 3%;
  margin-left: -1%;
  padding: 1%;
}
.home17 img {
  width: 100%;
  padding: 0.2rem;
}
.home17 button {
  margin: 1%;
  font-size: 80%;
  line-height: 1.8;
  width: 100%;
  background: #eb0e19;
  border: none;
  color: white;
  border-radius: 6px;
  font-size: 74%;
}

.home19,
.home21 {
  width: 154%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
}
.home20 {
  border-top: 1px solid;
  margin-top: 7%;
}
.pform1 {
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 0%;
  width: 91%;
  margin-left: 0%;
}

.pform1 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.pform1 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}
.pform1 span::before {
  content: "";
  position: absolute;
  top: 14.2%;
  left: 3.3%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform1 input:focus ~ label,
.pform1 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.pform1 input:focus ~ span::before,
.pform1 input:valid ~ span::before {
  width: 85.3%;
}
.pform2,
.pform3,
.pform4,
.pform5,
.pform6,
.pform7,
.pform8,
.pform9 {
  /* position: relative; */
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 91%;
  margin-left: 0%;
}

.pform2 input,
.pform3 input,
.pform4 input,
.pform5 input,
.pform6 input,
.pform7 input,
.pform8 input,
.pform9 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.pform2 label,
.pform3 label,
.pform4 label,
.pform5 label,
.pform6 label,
.pform7 label,
.pform8 label,
.pform9 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}
.pform2 span::before {
  content: "";
  position: absolute;
  top: 28.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform2 input:focus ~ label,
.pform2 input:valid ~ label {
  top: 55px;
  color: #eb0e19;
}

.pform2 input:focus ~ span::before,
.pform2 input:valid ~ span::before {
  width: 85.3%;
}
.pform3 span::before {
  content: "";
  position: absolute;
  top: 32.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform3 input:focus ~ label,
.pform3 input:valid ~ label {
  top: 22%;
  color: #eb0e19;
}

.pform3 input:focus ~ span::before,
.pform3 input:valid ~ span::before {
  width: 85.3%;
}
.pform4 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform4 input:focus ~ label,
.pform4 input:valid ~ label {
  top: 33%;
  color: #eb0e19;
}

.pform4 input:focus ~ span::before,
.pform4 input:valid ~ span::before {
  width: 85.3%;
}
.pform5 span::before {
  content: "";
  position: absolute;
  top: 42.7%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform5 input:focus ~ label,
.pform5 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform5 input:focus ~ span::before,
.pform5 input:valid ~ span::before {
  width: 38%;
}
.pform6 span::before {
  content: "";
  position: absolute;
  top: 42.5%;
  left: 50.5%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform6 input:focus ~ label,
.pform6 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform6 input:focus ~ span::before,
.pform6 input:valid ~ span::before {
  width: 38%;
}

.pform8 span::before {
  content: "";
  position: absolute;
  top: 77.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform8 input:focus ~ label,
.pform8 input:valid ~ label {
  top: 69%;
  color: #eb0e19;
}

.pform8 input:focus ~ span::before,
.pform8 input:valid ~ span::before {
  width: 85.3%;
}

.modal2 {
  position: absolute;
  background: #0e0e0e8c;
  width: 102.1%;
  height: 307.5%;
  margin-left: -28%;
  visibility: hidden;
  margin-top: -15%;
  margin-left: -34%;
}
.postpop {
  visibility: visible;
}
.modal-pop {
  width: 38%;
  height: 23%;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modal-pop h3 {
  background: #eb0e19;
  margin-top: -12%;
  height: 13%;
  width: 111.5%;
  margin-left: 0.55;
  text-align: left;
  margin-left: -5.6%;
  padding-left: 3%;
  padding-top: 5%;
  color: white;
  font-size: 129%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.modal-pop img {
  margin-left: -91%;
  margin-top: 4%;
  border-radius: 50%;
}
.modal-pop h4 {
  width: 42%;
  margin-left: 12%;
  text-align: left;
  margin-top: -9%;
  font-size: 144%;
}
.modal-pop h5 {
  text-align: left;
  width: 26%;
  margin-left: 12.2%;
  margin-top: 0.5%;
  font-size: 82%;
  font-weight: 100;
}
.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 90%;
  margin-left: 4%;
}

.text input {
  width: 82%;
  padding: -27px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
  margin-left: -17%;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}
.mpop {
  text-align: left;
  width: 90%;
  margin-left: 4%;
}
.mpop input {
  width: 99%;
  height: 68px;
  border-radius: 10px;
  border: 1px solid;
  outline: none;
}
.mpop1 {
  margin-left: 3%;
  margin-top: 5%;
  border: 2px dashed;
  height: 31%;
  width: 92%;
  border-radius: 10px;
}
.mpop3 {
  margin-top: -7%;
}
.mpop2 button,
.mpop3 button {
  margin-left: -54%;
  margin-top: 3%;
  width: 36%;
  background: white;
  border: 1px solid;
  border-radius: 6px;
  /* height: 43%; */
  cursor: pointer;
}
.mpop3 button {
  margin-top: -3.5%;
  margin-left: 49%;
}
.mpop2 button img,
.mpop3 button img {
  margin-left: -60%;
  margin-top: -3%;
}
.mpop2 button h4,
.mpop3 button h4 {
  width: 24%;
  /* margin-top: -11%; */
  margin-top: 1.7%;
  position: absolute;
  margin-left: 10%;
}
.mpop4 {
  margin-top: 3%;
}
.mpop4 button {
  width: 93%;
  line-height: 1.8;
  border-radius: 6px;
  font-size: 113%;
  border: none;
  background: #eb0e19;
  color: white;
  cursor: pointer;
}
.mpop5 {
  width: 9%;
}
.mpop5 img {
  width: 3.8%;
  margin-top: -10%;
  position: absolute;
  margin-left: 86%;
  cursor: pointer;
}
.mpop1 button input[type="file"] {
  position: absolute;
  transform: scale(2);

  width: 21px;
  margin-left: -18px;
  margin-top: 8px;
  cursor: pointer;
}
.add_comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
}
.add_comments_btn {
  background-color: #fff;
  color: black;
  padding: 1.5%;
  width: 20%;
  border-radius: 0.3rem;
}
.add_comments_input {
  width: 74%;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.exhome {
  display: flex;
  gap: 3%;
  margin-left: 1%;
  margin-top: -1%;
}
.user_details {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 2%;
}
</style>
