<template>
  <div class="dropdown">
    <button
      type="button"
      class="btn-sm dropdown-toggle me-3"
      id="dropdownMenuButton1"
      style="display: flex; /* width: 100%; */ background: none; border: none"
    >
      <!-- <img src="../assets/share.png" width="20" height="20" class="me-2" /> -->
      <i
        class="fa-solid fa-share-from-square me-2 my-auto"
        style="font-size: 1.2rem; color: black"
      ></i>
      <h6
        style="color: black; cursor: pointer;font-size: 1rem;margin-bottom: 0rem; margin-top: 0.1rem"
        
      >
        {{ this.shares_count }} Share
      </h6>
    </button>
  </div>
</template>
<script>
export default {
  name: "user-share",
  props: {
    postId: String,
    shares_count: String,
  },
};
</script>
<style scoped>
.dropdown-toggle::after {
  display: none;
}
</style>
