<template>
  <!-- The main create post component -->
  <div class="create-post">
    <header style="margin-top: 0.8rem; margin-bottom: 0.8rem">
      Create post
    </header>
    <button
      class="create_post_btn"
      type="button"
      @click="$router.push({ name: 'Signin' })"
      style="
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 23px;
      "
    >
      <img
        v-if="userImage"
        :src="userImage"
        width="35"
        height="32"
        style="border-radius: 50%"
      />
      <img
        v-else
        src="./assets/cover.png"
        width="35"
        height="32"
        style="border-radius: 50%"
      />
      <h4
        style="
          font-size: 1.2rem;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          text-align: start;
          color: gray;
          cursor: pointer;
          padding: 0.5rem;
        "
      >
        What's on your mind {{ item.name }} ?....
      </h4>
    </button>

    <!-- Other options like adding photos, hashtags, and location -->

    <div class="home5">
      <div class="h1 my-auto" @click="$router.push({ name: 'Signin' })">
        <img src="./assets/gallary.svg" />
        <p class="mt-2 ms-1">Photo</p>
      </div>
      <div class="h1 my-auto" @click="$router.push({ name: 'Signin' })">
        <img src="./assets/hashtag.svg" />
        <p class="mt-1 ms-2">Add Hashtags</p>
      </div>
      <div class="h1 my-auto" @click="$router.push({ name: 'Signin' })">
        <img src="./assets/location-pin-1.jpg" />
        <p class="mt-1 ms-2">Location</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    selectedUsers: Array,
  },
  name: "createPost",
  components: {},
  data() {
    return {
      selectedFile: null,
      item: {
        name: "",
      },
      // defaultUserImage: "../assets/cover.png",
      userImage: "",
      // apiUrl: "http://uatserver.redprix.com/public/api/",
      // apiUrl: "https://api.redprix.com/api/",
    };
  },
  created() {
    this.getdata();
  },

  computed: {},

  methods: {
    getdata() {
      axios
        .get(`customers/my-profile`, {
          headers: {
            DeviceID: "11111",
            versionName: "1.0.0",
            DeviceType: "0",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          this.item = response.data.data;
          this.item1 = response.data.data.email;
          // Set the user image based on the user's profile data
          if (response.data.data.profile_photo) {
            this.userImage = response.data.data.profile_photo;
          }
        });
    },
  },
};
</script>

<style scoped>
/* Global Styles */
.create-post {
  margin-top: 0.8rem;
  padding: 0% 3% 0%;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px #ccc;
}

.create_post_btn {
  width: 100%;
  height: 63px;
  border: none;
  line-height: 3;
  border-radius: 6px;
  background-color: whitesmoke;
}

/* Home Section Styles */
.home5 {
  max-width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-size: 4vw;
  width: 100%;
}

.h1 {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.h1 p {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0;
}

.h1 img {
  height: 33px;
}

@media (max-width: 380px) {
  .h1 p {
    font-size: 0.5em;
  }

  .h1 img {
    height: 23px;
  }
  .h1 {
    padding: 0 0;
  }
}
</style>