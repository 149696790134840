<template>
  <div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>This is the modal content.</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-primary" id="openModalBtn">
      Open Modal
    </button>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  name: "ModelPopup",
  // methods: {
  //   openmodel() {
  //     $(document).ready(function () {
  //       // When the first modal is shown, open the second modal
  //       $("#firstModal").on("shown.bs.modal", function () {
  //         $("#secondModal").modal("show");
  //       });
  //     });
  //   },
  // },
  mounted() {
    const openModalBtn = document.getElementById("openModalBtn");
    const myModal = new bootstrap.Modal(document.getElementById("myModal"));

    openModalBtn.addEventListener("click", () => {
      myModal.show();
    });
  },
};
</script>