
<template>
  <div class="container">
    <div class="heading">
      <h3 class="fs-5">Followers</h3>
      <div class="search_input">
        <input type="search" placeholder="Search" />
      </div>
    </div>
    <div class="followrs">
      <div>
        <div v-if="followers.length === 0">
          <p class="text-center" style="margin: 2% auto">
            You have no followers yet.
          </p>
        </div>
      </div>
      <div
        class="grid-container"
        v-for="follower in followers"
        :key="follower.id"
      >
        <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/01.jpeg" alt />
            <div class="follower_details">
              <p>{{ follower.first_name }}</p>
              <span>{{ follower.city.name }}</span>
            </div>
            <div class="Follow_btn">
              <button
                class="btn btn-danger"
                @click="RemoveFollower(follower.id)"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger">Remove</button>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger">Remove</button>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger">Remove</button>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger">Remove</button>
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="follower_profile">
            <img src="./../assets/man.jpg" alt="" />
            <div class="follower_details">
              <p>John doe</p>
              <span>ererrt</span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger">Remove</button>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Followers",
  data() {
    return {
      followers: [],
    };
  },
  mounted() {
    this.MyFollowers();
  },
  methods: {
    MyFollowers() {
      axios.get("customers/follows/my-followers").then((res) => {
        this.followers = res.data.data;
        // console.log(res.data.data);
      });
    },
    RemoveFollower(id) {
      axios
        .post("customers/follows/action-type", {
          follower_id: id,
          action_type: "2",
        })
        .then(() => {
          // console.log(resp.data.data);
        });
    },
  },
};
</script>
<style scoped>
/* *{
    font-size: 0.9rem;
} */
@import url("https://fonts.google.com/specimen/Roboto?query=roboto");
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading h3 {
  border-bottom: 1px solid black;
}
.container {
  box-shadow: 0px 0px 6px #d4d4d4;
  border: 1px solid #ccc;
  padding: 0.3rem;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
  width: 100%;
}
.search_input input {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  padding: 0.2rem 1.2rem;
}
.grid-container {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 1rem; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.grid-item {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
}
.grid-container > .grid-item {
  font-size: 30px;
  padding: 0.5em;
  text-align: center;
}
.follower_profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.follower_details p {
  margin-bottom: -1rem;
  font-size: 0.9rem;
}
.follower_details span {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>