<template>
  <div class="nav">
    <div class="container-fluid">
      <div class="row">
        <div>
          <img
            src="../assets/mainlogo.png"
            height="40"
            style="margin-top: 0.5%; margin-left: 2%"
          />
        </div>
        <div
          class="searchbar"
          style="margin-left: 9%; margin-top: -3.3%; width: 31%"
        >
          <input type="search" placeholder="Search People pages groups..." />
          <button type="submit">Search</button>
        </div>

        <div class="bar">
          <div class="b1" style="text-align: center">
            <img src="../assets/hand-holding.jpg" width="20" height="25" />
            <h5 style="font-size: 95%; font-weight: 100">Foodfie</h5>
          </div>
          <div class="b2" style="text-align: center; margin-left: 3%">
            <!-- <router-link to="/Dinein" style="text-decoration: none; color: gray">
              <img src="../assets/dine.jpg" width="20" height="25" />
              <img src="../assets/Group5006.png" width="20" height="25">
              
              <h5 style="font-size: 95%; font-weight: 100">Dine-in</h5>
            </router-link>-->

            <router-link
              to="/Dinein"
              style="text-decoration: none; color: gray"
              exact
              active-class="active-link"
            >
              <img src="../assets/dine.jpg" width="20" height="25" />
              <img src="../assets/Group5006.png" width="20" height="25" />
              <h5 style="font-size: 95%; font-weight: 100">Dine-in</h5>
            </router-link>
          </div>
          <div class="b3" style="text-align: center; margin-left: 4.5%">
            <router-link
              to="/notification"
              style="text-decoration: none; color: gray"
            >
              <img src="../assets/notifications.jpg" width="20" height="25" />
              <h5 style="font-size: 95%; font-weight: 100">Notification</h5>
            </router-link>
          </div>
          <div class="b4" style="text-align: center; margin-left: 6.5%">
            <router-link
              to="/setting"
              style="text-decoration: none; color: gray"
            >
              <img src="../assets/setting.jpg" width="20" height="25" />
              <h5 style="font-size: 95%; font-weight: 100">Setting</h5>
            </router-link>
          </div>
          <div
            @click="toggleUp()"
            class="b4"
            style="text-align: center; margin-left: 1.5%"
          >
            <img
              src="../assets/hand-holding.jpg"
              width="20"
              height="25"
              style="margin-left: 20%"
            />
            <h5 style="font-size: 95%; font-weight: 100">Me</h5>
            <img
              src="../assets/arrow.jpg"
              width="10"
              height="8"
              style="margin-top: -105%; margin-left: 65%"
            />
          </div>

          <div
            class="togg3"
            style="
              width: 7%;
              border: 1px solid;
              margin-left: 26%;
              position: absolute;
              background: white;
              border-radius: 10px;
              margin-top: 4%;
            "
          >
            <div>
              <h6
                style="
                  border-bottom: 1px solid;
                  /* width: 83%; */
                  margin-left: 0%;
                  padding-top: 4%;
                  padding-bottom: 3%;
                  padding-left: 4%;
                "
              >
                My Profile
              </h6>
            </div>
            <div>
              <router-link
                to="/MenuList/editprofile"
                style="text-decoration: none; color: gray"
              >
                <h6
                  style="
                    border-bottom: 1px solid;
                    /* width: 83%; */
                    margin-left: 0%;
                    padding-top: 4%;
                    padding-bottom: 3%;
                    padding-left: 4%;
                  "
                >
                  Edit Profile
                </h6>
              </router-link>
            </div>
            <div @click="logoutUser()">
              <h6
                style="
                  /* border-bottom: 1px solid; */
                  margin-left: 0%;
                  /* padding-top: 4%; */
                  padding-left: 4%;
                  cursor: pointer;
                "
              >
                Sign Out
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "NavBar",
  methods: {
    methods: {
      shouldShowPost(post) {
        return !this.hiddenPostIds.includes(post.id);
      },

      createPost() {
        const white = document.querySelector(".white");

        const file = document.querySelector("#file");

        file.addEventListener("change", function () {
          const choosedFile = this.files[0];
          if (choosedFile) {
            const reader = new FileReader();

            reader.addEventListener("load", function () {
              white.setAttribute("src", reader.result);
            });

            reader.readAsDataURL(choosedFile);
          }
        });
      },
      onUpload() {
        const formData = new FormData();
        formData.append("file", this.file);
        axios
          .post("customers/posts", formData)
          .then((response) => {
            // console.log("File uploaded:", response.data);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      },

      AMountChangeFunction() {
        let planname = document.getElementById("plannem").value;

        axios
          .get("admin/subscriptionPlan?search=" + planname, {})
          .then((res) => {
            localStorage.setItem("sub_id", res.data.data[0].id);
            localStorage.setItem("coupon_code", res.data.data[0].coupon);

            this.amount = res.data.data[0].amount;
            this.coupon = res.data.data[0].coupon;

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          })

          .catch((error) => {
            console.log(error);
          });
      },

      couponVerify() {
        axios
          .get("admin/subscriptionPlan", {})
          .then((res) => {
            this.coponerr = "";
            this.couponSuMsg = "Coupon Apply successfully";
            this.amount = 1;
            this.plans = res.data.data;
            // console.log("here is mypla", this.plans);
            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          })
          // this.calculate=this.amount_payPrint/100*90

          //    var remov= parseFloat(this.calculate).toFixed(0)
          //    this.amount_payPrint= remov+'.00'

          .catch((error) => {
            console.log(error);
          });
      },

      toggleMenu() {
        let togg = document.querySelector(".togg");
        togg.classList.toggle("newlist");
      },

      toggleUp() {
        let togg3 = document.querySelector(".togg3");
        togg3.classList.toggle("newlist3");
      },
      toggleDown() {
        let togg4 = document.querySelector(".togg4");
        togg4.classList.toggle("newlist4");
      },
      toggleSecond() {
        let togg5 = document.querySelector(".togg5");
        togg5.classList.toggle("newlist5");
      },
      postClick() {
        let modal2 = document.getElementById("modal2");
        modal2.classList.add("postpop");
      },
      closePost() {
        let modal2 = document.getElementById("modal2");
        modal2.classList.remove("postpop");
      },
      tagPost() {
        let modal3 = document.getElementById("modal3");
        modal3.classList.add("tagpop");
      },
      returnPost() {
        let modal3 = document.getElementById("modal3");
        modal3.classList.remove("tagpop");
      },

      // hvpo(postId){

      //   let home8 = document.getElementById("home8");
      //         home8.classList.add("cus-all");
      //         this.hidePost(postId);
      //       //   const postids= JSON.parse(localStorage.getItem("hidePostId")) || [];
      //       //   postids.push(postId);
      //       // localStorage.setItem("hidePostId", JSON.stringify(postids));
      // },

      getdata() {
        axios
          .get("customers/my-profile", {
            headers: {
              DeviceID: "11111",
              versionName: "1.0.0",
              DeviceType: "0",
            },
          })
          .then((response) => {
            this.item = response.data.data;
            this.item1 = response.data.data.email;

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          });
      },

      getpost() {
        this.loading = true;
        axios
          .get(`customers/posts?page=${this.page}&per_page=${this.perPage}`, {
            headers: {
              DeviceID: "11111",
              versionName: "1.0.0",
              DeviceType: "0",
            },
          })
          .then((response) => {
            this.posts = [...this.posts, ...response.data.data];
            this.page++;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      handleScroll() {
        if (
          window.innerHeight + window.scrollY >=
          document.documentElement.offsetHeight
        ) {
          this.getpost(); // Load more data when user scrolls to the bottom
        }
      },

      // getpost() {
      //   axios
      //     .get("customers/posts", {
      //       headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
      //     })
      //     .then((response) => {
      //       // localStorage.setItem("id",response.data.data[0-15].images[0-15].post_id)
      //       // this.posts = response.data.data;
      //       // console.log(this.posts)
      //       // localStorage.setItem("Post_id", response.data.data)
      //       const hidePostid = localStorage.getItem("hidePostId");
      //       this.posts = response.data.data;
      //       console.log(response.data.data);
      //       // .filter((item)=> item.status === "1");
      //       // this.posts.forEach((item)=>{
      //       //   if(item.id == )
      //       // })
      //       //  this.users =  response.data.data
      //       //  console.log( response.data.data[0].id)
      //       // console.log(this.posts[0].images[0].image);
      //       if (localStorage.getItem("token")) {
      //         axios.defaults.headers.common["Authorization"] =
      //           " Bearer " + localStorage.getItem("token");
      //       }
      //     });
      // },
      // hidePost(postId){
      //   axios
      //         .post(`customers/posts/hides/${postId}`, {
      //            is_hide : "1"
      //           })
      //         .then((response) => {
      //           // localStorage.getItem("id")
      //           // response.data.status
      //           //   console.log(response.data.status)
      //         })
      //         .catch((error) => console.log(error));
      // },
      logoutUser() {
        axios.get("logout", {}).then(() => {
          localStorage.clear();
          console.clear();
          this.$router.push("/");
        });
      },
    },
  },
};
</script>
<style scoped>
.nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
}
.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}
.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}
.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}
.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}
.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist3 {
  transform: scale(1);
}
.togg4 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist4 {
  transform: scale(1);
}
.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist5 {
  transform: scale(1);
}
.home {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 1%;
}
.home button {
  margin-left: 21%;
  margin-top: 3%;
  width: 57%;
  border: none;
  line-height: 2.2;
  font-size: 88%;
  border-radius: 6px;
  background: dodgerblue;
  color: white;
}
/* .home2 {
  margin-top: 29%;
  width: 71%;
  height: 40vh;
} */
.home2 img {
  width: 100%;
  padding: 1.5%;
}
.home3 {
  margin-left: 0%;
  width: 53%;
  height: 5vh;
  padding: 0rem 1rem 0rem;
}
.elite_ac_btn {
  width: 213%;
  line-height: 2.5;
  border: none;
  background: #eb0e19;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  margin-left: -1rem;
}

.home6 {
  margin-top: 2%;
  margin-left: -2%;
}
.home7 {
  border-top: 1px solid black;
  width: 87%;
  margin-left: 15%;
  margin-top: -3%;
}
.home8,
.home15 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  visibility: visible;
}

.cus-all {
  visibility: hidden;
}

.togg {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.newlist {
  transform: scale(1);
}
.newlist1 {
  transform: scale(1);
}
.home15 {
  margin-top: 2%;
}
.h2 {
  display: flex;
  width: 85%;
  margin-left: 10%;
  margin-top: -0.5%;
}
.h3,
.h4 {
  display: flex;
  width: 30%;
}
.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}
.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 7%;
  padding-left: 1%;
}

.ho1 {
  display: flex;
  margin-left: 1%;
  gap: 16%;
  margin-top: 2%;
  padding: 0% 0.5% 0%;
}
.home12 {
  margin-top: 5%;
}
.home13 {
  width: 85%;
  /* margin-left: 8%; */
  background: rgb(243, 241, 241);
  border-radius: 6px;
}

.home16 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0% 1% 0%;
  /* margin-left: 25%; */
  border: 1px solid lightgray;
  margin-top: 1.5%;

  border-radius: 6px;
  margin-bottom: 26%;
  background-color: #fff;
  padding: 0% 3% 0%;
  flex-wrap: wrap;
}
.home17 {
  width: 27%;
  border: 1px solid;
  margin: 3%;
  margin-left: -1%;
  padding: 1%;
}
.home17 img {
  width: 100%;
  padding: 0.2rem;
}
.home17 button {
  margin: 1%;
  font-size: 80%;
  line-height: 1.8;
  width: 100%;
  background: #eb0e19;
  border: none;
  color: white;
  border-radius: 6px;
  font-size: 74%;
}

.home19,
.home21 {
  width: 154%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
}
.home20 {
  border-top: 1px solid;
  margin-top: 7%;
}
.pform1 {
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 0%;
  width: 91%;
  margin-left: 0%;
}

.pform1 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.pform1 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}
.pform1 span::before {
  content: "";
  position: absolute;
  top: 14.2%;
  left: 3.3%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform1 input:focus ~ label,
.pform1 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.pform1 input:focus ~ span::before,
.pform1 input:valid ~ span::before {
  width: 85.3%;
}
.pform2,
.pform3,
.pform4,
.pform5,
.pform6,
.pform7,
.pform8,
.pform9 {
  /* position: relative; */
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 91%;
  margin-left: 0%;
}

.pform2 input,
.pform3 input,
.pform4 input,
.pform5 input,
.pform6 input,
.pform7 input,
.pform8 input,
.pform9 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.pform2 label,
.pform3 label,
.pform4 label,
.pform5 label,
.pform6 label,
.pform7 label,
.pform8 label,
.pform9 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}
.pform2 span::before {
  content: "";
  position: absolute;
  top: 28.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform2 input:focus ~ label,
.pform2 input:valid ~ label {
  top: 55px;
  color: #eb0e19;
}

.pform2 input:focus ~ span::before,
.pform2 input:valid ~ span::before {
  width: 85.3%;
}
.pform3 span::before {
  content: "";
  position: absolute;
  top: 32.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform3 input:focus ~ label,
.pform3 input:valid ~ label {
  top: 22%;
  color: #eb0e19;
}

.pform3 input:focus ~ span::before,
.pform3 input:valid ~ span::before {
  width: 85.3%;
}
.pform4 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform4 input:focus ~ label,
.pform4 input:valid ~ label {
  top: 33%;
  color: #eb0e19;
}

.pform4 input:focus ~ span::before,
.pform4 input:valid ~ span::before {
  width: 85.3%;
}
.pform5 span::before {
  content: "";
  position: absolute;
  top: 42.7%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform5 input:focus ~ label,
.pform5 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform5 input:focus ~ span::before,
.pform5 input:valid ~ span::before {
  width: 38%;
}
.pform6 span::before {
  content: "";
  position: absolute;
  top: 42.5%;
  left: 50.5%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform6 input:focus ~ label,
.pform6 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform6 input:focus ~ span::before,
.pform6 input:valid ~ span::before {
  width: 38%;
}

.pform8 span::before {
  content: "";
  position: absolute;
  top: 77.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform8 input:focus ~ label,
.pform8 input:valid ~ label {
  top: 69%;
  color: #eb0e19;
}

.pform8 input:focus ~ span::before,
.pform8 input:valid ~ span::before {
  width: 85.3%;
}

.modal2 {
  position: absolute;
  background: #0e0e0e8c;
  width: 102.1%;
  height: 307.5%;
  margin-left: -28%;
  visibility: hidden;
  margin-top: -15%;
  margin-left: -34%;
}
.postpop {
  visibility: visible;
}
.modal-pop {
  width: 38%;
  height: 23%;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modal-pop h3 {
  background: #eb0e19;
  margin-top: -12%;
  height: 13%;
  width: 111.5%;
  margin-left: 0.55;
  text-align: left;
  margin-left: -5.6%;
  padding-left: 3%;
  padding-top: 5%;
  color: white;
  font-size: 129%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.modal-pop img {
  margin-left: -91%;
  margin-top: 4%;
  border-radius: 50%;
}
.modal-pop h4 {
  width: 42%;
  margin-left: 12%;
  text-align: left;
  margin-top: -9%;
  font-size: 144%;
}
.modal-pop h5 {
  text-align: left;
  width: 26%;
  margin-left: 12.2%;
  margin-top: 0.5%;
  font-size: 82%;
  font-weight: 100;
}
.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 90%;
  margin-left: 4%;
}

.text input {
  width: 82%;
  padding: -27px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
  margin-left: -17%;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}
.mpop {
  text-align: left;
  width: 90%;
  margin-left: 4%;
}
.mpop input {
  width: 99%;
  height: 68px;
  border-radius: 10px;
  border: 1px solid;
  outline: none;
}
.mpop1 {
  margin-left: 3%;
  margin-top: 5%;
  border: 2px dashed;
  height: 31%;
  width: 92%;
  border-radius: 10px;
}
.mpop3 {
  margin-top: -7%;
}
.mpop2 button,
.mpop3 button {
  margin-left: -54%;
  margin-top: 3%;
  width: 36%;
  background: white;
  border: 1px solid;
  border-radius: 6px;
  /* height: 43%; */
  cursor: pointer;
}
.mpop3 button {
  margin-top: -3.5%;
  margin-left: 49%;
}
.mpop2 button img,
.mpop3 button img {
  margin-left: -60%;
  margin-top: -3%;
}
.mpop2 button h4,
.mpop3 button h4 {
  width: 24%;
  /* margin-top: -11%; */
  margin-top: 1.7%;
  position: absolute;
  margin-left: 10%;
}
.mpop4 {
  margin-top: 3%;
}
.mpop4 button {
  width: 93%;
  line-height: 1.8;
  border-radius: 6px;
  font-size: 113%;
  border: none;
  background: #eb0e19;
  color: white;
  cursor: pointer;
}
.mpop5 {
  width: 9%;
}
.mpop5 img {
  width: 3.8%;
  margin-top: -10%;
  position: absolute;
  margin-left: 86%;
  cursor: pointer;
}
.mpop1 button input[type="file"] {
  position: absolute;
  transform: scale(2);

  width: 21px;
  margin-left: -18px;
  margin-top: 8px;
  cursor: pointer;
}
.add_comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
}
.add_comments_btn {
  background-color: #fff;
  color: black;
  padding: 1.5%;
  width: 20%;
  border-radius: 0.3rem;
}
.add_comments_input {
  width: 74%;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.exhome {
  display: flex;
  gap: 3%;
  margin-left: 1%;
  margin-top: -1%;
}
</style>