<template>
  <div class="main-div">
    <!-- <div class="d-flex align-items-center justify-content-center">
      <img
        class="img-fluid w-75"
        src="../../assets/comming_soon_img.jpg"
        alt="Comming Soon Image"
      />
    </div> -->
    <div class="card mb-3 p-0">
      <div class="card-body p-0">
        <div class="heading text-subheading grey-color font-600">
          <h3>Become Atmanirbhar</h3>
        </div>
        <div class="mt-3">
          <form
            class="form-control border-0"
            style="padding: 10px 20px 10px 20px"
          >
            <label for="cp" class="cp text-desc font-400 mb-1">Full Name</label>
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Business Name</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1">Email Id</label>
            <div class="input-group mb-3">
              <input type="email" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Permanent Address</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Business Address</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <!-- <label for="cp" class="cp text-desc font-400">Business Contact</label>
                  <div class="input-group mb-3 border rounded-3 border-dark">
                      <input type="text" class="form-control" style="border: none;">
                  </div> -->

            <div class="row input-group mb-1">
              <div class="col">
                <label for="cp" class="cp text-desc font-400 mb-1">State</label>
                <div class="input-group mb-3">
                  <select
                    name="b_type"
                    id="b_type"
                    class="form-control"
                    style="
                      padding: 5px 5px 5px 5px;
                      width: -webkit-fill-available;
                    "
                  >
                    <option value="Select Your Business Type">
                      Select State
                    </option>
                    <!-- <option value="Restaurants">Restaurants</option>
                <option value="Hotel">Hotel</option>
                <option value="Salon">Salon</option>
                <option value="Grocery">Grocery</option>
                <option value="Mall">Mall</option>
                <option value="Multiplex">Multiplex</option>
                <option value="Gym">Gym</option>
                <option value="Dairy">Dairy</option> -->
                  </select>
                </div>
              </div>
              <div class="col">
                <label for="cp" class="cp text-desc font-400 mb-1">City</label>
                <div class="input-group mb-3">
                  <select
                    name="b_type"
                    id="b_type"
                    class="form-control"
                    style="
                      padding: 5px 5px 5px 5px;
                      width: -webkit-fill-available;
                    "
                  >
                    <option value="Select Your Business Type">
                      Select City
                    </option>
                    <!-- <option value="Restaurants">Restaurants</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Salon">Salon</option>
                  <option value="Grocery">Grocery</option>
                  <option value="Mall">Mall</option>
                  <option value="Multiplex">Multiplex</option>
                  <option value="Gym">Gym</option>
                  <option value="Dairy">Dairy</option> -->
                  </select>
                </div>
              </div>
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Current Occupation</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Net Annual Income in INR</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Education (Please Specify)</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Total Year of Experience</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Why are you willing to join Redprix?</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Why should Redprix support you with subsidy</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Pan Number</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="text" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Upload Pancard</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="file" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Aadhar Number</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="number" class="form-control" min="12" max="12" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Upload Aadharcard</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="file" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >GST Number</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="number" class="form-control" />
            </div>
            <label for="cp" class="cp text-desc font-400 mb-1"
              >Upload GST Certificate</label
            >
            <br />
            <div class="input-group mb-3">
              <input type="file" class="form-control" />
            </div>
            <input type="checkbox" class="mb-3" required /> I declare that my
            annual income is less than Rs 3 LPA
            <button
              class="btn frm-btn text-white w-100 text-center mt-1"
              style="background: red"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BecomeAtmanirbhar",
};
</script>

<style scoped>
.main-div {
  width: 100%;
  height: 40.5rem;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 10px;
  overflow-y: auto;
}

.main-div::-webkit-scrollbar {
  display: none;
}

.heading h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: grey;
  padding: 0.8rem 0.9rem;
  border-bottom: 2px solid #ccc;
}

.form-control,
.input-group {
  box-shadow: none !important;
}

input:focus {
  border: 0.08rem solid #eb0e19;
}

select:focus {
  border: 0.08rem solid #eb0e19;
}

.form-control input[type="file"] {
  color: red;
}
</style>