<template>
  <div class="custom-animation">
    <navbar />

    <div class="row mt-4">
      <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 right-column">
        <div class="home mb-4">
          <div>
            <img
              src="../assets/cover2.jpg"
              style="
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 100%;
                height: 6rem;
              "
            />
          </div>

          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: -2.5rem;
            "
          >
            <img
              src="../assets/pro2.jpg"
              style="
                width: 5.5rem;
                height: 5.1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                object-fit: cover;
              "
              v-if="item.profile_photo == '' || item.profile_photo == null"
            />
            <img
              :src="item.profile_photo"
              v-else
              controls
              style="
                width: 5.5rem;
                height: 5.1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                object-fit: cover;
              "
            />
          </div>

          <h4 style="text-align: center; margin-top: -1rem">{{ item.name }}</h4>
          <h6
            style="
              text-align: center;
              font-size: 90%;
              font-weight: 500;
              color: gray;
            "
          >
            {{ item.email }}
          </h6>
          <!-- <router-link :to="getRouterLink()">
          <button @click="getRouterLink" type="button">View Profile</button>
        </router-link>-->

          <div class="mb-4">
            <router-link to="/profile">
              <button @click="getUserTypes" role="link" type="button">
                View Profile
              </button>
            </router-link>
          </div>
          <h5
            style="
              font-size: 80%;
              margin-top: 5%;
              border-top: 2px solid lightgray;
              padding: 0.5rem 0.6rem;
              color: black;
              font-weight: 500;
              text-align: justify;
              opacity: 0.9;
            "
          >
            RedPrix is the ultimate social media platform for food lovers,
            offering the best discounts and deals on restaurants near your
            location. With RedPrix, you can discover new and delicious eats
            while saving big on your dining experiences.
          </h5>
        </div>
        <!-- <div class="home2 mt-3 mb-3">
          <img src="../assets/bur.jpg" />
        </div> -->
        <div>
          <RaiseTicket />
        </div>

        <LeftAdBanner />
      </div>

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mid-column">
        <div class="home3">
          <button
            :style="{
              background: isSubscribed ? '#fc5863 !important' : '#eb0e19',
            }"
            class="elite_ac_btn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            :disabled="isSubscribed"
          >
            {{
              isSubscribed ? "YOU ARE ELITE MEMBER" : "MAKE THIS ACCOUNT ELITE"
            }}
          </button>
        </div>

        <!-- Modal ---------------------------------------------------------------------------------------------------------------------------------------------------------->
        <div
          v-for="plan in plans"
          :key="plan.id"
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog d-flex align-items-center">
            <div class="mo position-relative overflow-hidden">
              <div
                class="d-flex h-100 align-items-center justify-content-center position-relative"
              >
                <img src="../assets/hburger.png" style="width: 100%" />
              </div>
              <div class="h-100 position-absolute top-0 px-3 pt-5" style="">
                <h4 class="text-center fs-3 text-white">Elite Account</h4>
                <h6 class="text-center fs-6 text-white mt-4" style="">
                  "Join RedPrix Elite and Get Exclusive Restaurant Discounts
                  Today!
                  <br />Do you love food and want to save money while dining
                  out? Then RedPrix Elite is for you. As an Elite member, you'll
                  have access to the best discounts and offers from top
                  restaurants and eateries from all around India. Don't miss out
                  on the chance to savor the savings. With RedPrix Elite, you
                  can take advantage of these each exclusive offers once a
                  month, giving you a fresh set of discounts every time. From
                  fine dining to quick bites, enjoy delicious food at a fraction
                  of the cost. Fine dining, quick bites, and everything in
                  between - RedPrix Elite has got you covered. Upgrade your
                  foodie experience and join RedPrix Elite now. Sign up today
                  and start saving on your favorite restaurants!"
                </h6>
              </div>
            </div>
            <div class="modal-content mx-auto">
              <div class="modal-header" style="height: 7vh">
                <h4 style="margin-top: 2%; font-size: 110%">
                  Sign up as an Elite Account
                </h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body ps-4">
                <!--  @submit.prevent="createPayment()" -->
                <form class="w-100">
                  <div class="pform1">
                    <input type="text" v-model="firstname" required />

                    <label>First Name</label>
                  </div>
                  <div class="pform2">
                    <input type="text" required />

                    <label>Last Name</label>
                  </div>

                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="pform5" style="width: 48%">
                      <input type="text" required />

                      <label>GST No.</label>
                    </div>
                    <div class="pform6" style="width: 48%">
                      <input type="text" required />

                      <label style="left: 56%">Mobile No.</label>
                    </div>
                  </div>
                  <div class="reg6">
                    <select
                      id="plannem"
                      @change="AMountChangeFunction()"
                      required
                      style="
                        width: 100%;
                        margin-top: 9%;
                        border: none;
                        outline: none;
                        border-bottom: 1px solid black;
                      "
                      v-model="selectedOption"
                    >
                      <option value="" disabled selected>
                        Subscription plan for elite account
                      </option>
                      <option
                        v-for="plan in plans"
                        :value="plan.name"
                        v-bind:key="plan.id"
                      >
                        {{
                          plan.name == "1 Year" && plan.id == "4"
                            ? plan.name + " - For Students"
                            : plan.name
                        }}
                      </option>
                    </select>
                  </div>
                  <div class="pform8" style="margin-top: 6%">
                    <h6 class="mb-0" style="font-size: 76%; color: gray">
                      I have promo code to use for this
                    </h6>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="width: 80%; margin-bottom: 1%"
                      >
                        <!-- <span class="mb-0 w-25">Code :- </span> -->
                        <input
                          type="text"
                          class="pt-2 pb-2 px-2"
                          placeholder="Code :- Enter your promo code"
                          v-model="coupon"
                          @input="getInputUpdatedValue"
                          :readonly="plan_id != '1'"
                        />
                      </div>

                      <button
                        type="button"
                        @click.prevent="couponVerify()"
                        style="
                          font-size: 79%;
                          width: 16%;
                          border: none;
                          line-height: 1.8;
                          background: dodgerblue;
                          border-radius: 6px;
                          color: white;
                        "
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div
                    id="emailHelp"
                    class="input-text text-success"
                    v-if="couponSuMsg"
                  >
                    {{ couponSuMsg }}
                  </div>
                  <div
                    id="emailHelp"
                    class="input-text text-danger"
                    v-if="coponerr"
                  >
                    {{ coponerr }}
                  </div>
                  <div class="pform9" style="margin-top: 6%">
                    <h6 style="font-size: 80%; color: gray">Payable Amount</h6>
                    <p class="mb-1">₹ {{ this.amount }}</p>
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <input
                      type="checkbox"
                      id="checkmark"
                      style="margin-top: 0.5%"
                    />
                    <label
                      style="font-size: 80%; margin-top: 0.5%; margin-left: 1%"
                      for="checkmark"
                      >Please check your input for checking checkbox</label
                    >
                  </div>
                </form>
              </div>
              <div
                class="modal-footer mb-2"
                style="border: none; margin-left: 0%; justify-content: left"
              >
                <button
                  @click="signInButtonPressed()"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style="width: 100%; background: #eb0e19; color: white"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- modal over for elite ////////////////////////////////////////////////////////////////////// -->

        <form
          id="paymentForm"
          method="post"
          action="https://secure.payu.in/_payment"
        >
          <input type="hidden" v-model="mkey" name="key" size="64" />
          <input type="hidden" v-model="txnid" name="txnid" size="64" />
          <input type="hidden" v-model="amount" name="amount" size="64" />
          <input
            type="hidden"
            v-model="productinfo"
            name="productinfo"
            size="64"
          />
          <input type="hidden" v-model="firstname" name="firstname" size="64" />
          <input type="hidden" v-model="email" name="email" size="64" />
          <input type="hidden" v-model="phone" name="phone" size="64" />
          <input type="hidden" v-model="surl" name="surl" />
          <input type="hidden" v-model="furl" name="furl" />
          <input type="hidden" id="hash" v-model="hash" name="hash" size="64" />
        </form>

        <!-- pay u money//////////////////////////////////////////////////// -->

        <!-- pay u money//////////////////////////////////////////////////// -->

        <createpost />

        <div
          class="home8 px-2 pb-3 animate__animated animate__zoomIn"
          id="home8"
          v-for="post in posts"
          :key="post.id"
        >
          <div class="user_details">
            <div
              class="d-flex align-items-center justify-content-center overflow-hidden"
              style="width: 3rem; height: 2.5rem; border-radius: 50%"
            >
              <img
                src="./../assets/pro2.jpg"
                controls
                class="img-fluid"
                style="width: inherit; height: inherit; object-fit: fill"
                v-if="
                  post.user.profile_photo == '' ||
                  post.user.profile_photo == null
                "
              />

              <img
                :src="post.user.profile_photo"
                v-else
                controls
                class="img-fluid"
                style="width: inherit; height: inherit; object-fit: fill"
              />
            </div>
            <div class="w-100 ms-1 d-flex flex-column">
              <div class="mt-1">
                <!-- <h6>{{ post.user.name }} {{ post.images[0].post_id }}</h6> -->
                <h6 class="mb-0 ms-2" style="font-size: 1.1rem">
                  {{ post.user.name }}
                </h6>
              </div>
              <!-- <div
                class="h3 d-flex align-items-center ms-1"
                style="margin-top: 0.1rem"
              >                <i
                  class="fa-regular fa-clock"
                  style="font-size: 0.6rem; color: black; margin-top: 0.1rem"
                ></i>
                <h6
                  style="
                    margin-bottom: 0;
                    margin-top: 0.1rem;
                    font-size: 0.8rem;
                  "
                >
                  {{ moment(post.created_at).fromNow() }}
                </h6>
              </div> -->
            </div>
            <div class="list_btn ms-auto">
              <list :postId="post.id" />
            </div>
          </div>

          <!-- Post creation timestamp -->
          <!-- <div class="h2">
          <div class="h3">
            <i
              class="fa-regular fa-clock"
              style="font-size: 0.9rem; color: black"
            ></i>
            <h6 style="margin-bottom: 0">
              {{ moment(post.created_at).fromNow() }}
            </h6>
          </div>
        </div> -->

          <!-- Post description -->
          <div class="home10 ps-0">
            <h5 style="font-size: 1rem; color: #4c4c4c; margin-left: 0.7rem">
              {{ post.description }}
            </h5>
          </div>

          <!-- Post image -->
          <!-- <div v-if="post.images && post.images.length > 0"> -->
          <!-- :src="post.images[0].image" -->
          <!-- :src="post.images[0].image ? post.images[0].image : defaultImage" -->
          <!-- <img
            :src="post.images[0].image ? post.images[0].image : defaultImage"
            style="width: 100%; aspect-ratio: 1.2"
          /> -->

          <!-- <img
            v-for="postimg of post.images"
            :key="postimg.id"
            :src="postimg.image"
            alt="post-image"
            style="width: 100%; aspect-ratio: 1.2"
          /> -->
          <!-- </div> -->

          <div
            v-if="post.images && post.images.length > 0"
            class="w-100 post-img-div"
          >
            <div
              v-if="post.images.length > 1"
              class="d-flex overflow-x-auto scroll-div"
            >
              <div v-for="postimg in post.images" :key="postimg.id" class="p-2">
                <div class="object-fit img-1-div">
                  <img
                    :src="postimg.image ? postimg.image : defaultPostImage"
                    alt="Post Image"
                    class=""
                  />
                </div>
              </div>
            </div>

            <div v-else class="p-2 img-2-div rounded-3">
              <img
                :src="
                  post.images[0].image ? post.images[0].image : defaultPostImage
                "
                alt="Post Image"
                class=""
              />
            </div>
          </div>

          <!-- User interactions (likes, comments, shares) -->
          <!-- <div class="home11" style="border-bottom: 1px solid lightgray">
          <div class="ho1">
            <userlike :postId="post.id" :post-id="post.id" :likes_count="post.likes_count" :is_liked="post.is_liked" />
            <usercomment :postId="post.id" :comments_count="post.comments_count" />
            <usershare :postId="post.id" :shares_count="post.shares_count" />
          </div>
        </div> -->

          <div class="home11" style="border-bottom: 1px solid lightgray">
            <div class="ho1 my-auto">
              <userlike
                :postId="post.id"
                :post-id="post.id"
                :likes_count="post.likes_count"
                :is_liked="post.is_liked == '0' ? false : true"
              />
              <usercomment
                :postId="post.id"
                :comments_count="post.comments_count"
              />
              <usershare :postId="post.id" :shares_count="post.shares_count" />
            </div>

            <!-- <div>
            <span class="like-count">
              <div class="square">{{ post.likes_count }}</div> Likes
            </span>
            <span class="comment-count">
              <div class="square">{{ post.comments_count }}</div> Comments
            </span>
            <span class="share-count">
              <div class="square">{{ post.shares_count }}</div> Shares
            </span>
          </div> -->
          </div>

          <!-- Comments section -->
          <div class="home12">
            <div class="home13">
              <!-- <div v-for="comment in ShowComments[post.id]" :key="comment.id" class="user_comments"> -->
              <div
                v-for="comment in ShowComments[post.id]"
                :key="comment.id"
                class="user_comments"
              >
                <!-- <img
                src="../assets/cover.png"
                alt
                v-if="
                  comment.user.profile_photo == '' ||
                  comment.user.profile_photo == null
                "
                style="width: 3rem; height: 2.8rem; border-radius: 50%"
              />
              <img
                :src="
                  comment.user && comment.user.profile_photo
                    ? comment.user.profile_photo
                    : '../assets/cover.png'
                "
                v-else
                style="width: 3rem; height: 2.8rem; border-radius: 50%"
              /> -->
                <img
                  :src="
                    comment.user && comment.user.profile_photo != ''
                      ? comment.user.profile_photo
                      : require('../assets/cover.png')
                  "
                  style="width: 3rem; height: 2.8rem; border-radius: 50%"
                />
                <div class="comments">
                  {{
                    comment.user && comment.user.name != ""
                      ? comment.user.name
                      : "Rahul Sharma"
                  }}
                  <br />
                  {{ comment.comment }}
                </div>
              </div>
            </div>

            <div
              class="togg4"
              style="
                width: 6%;
                border: 1px solid;
                margin-left: 26%;
                position: absolute;
                background: white;
                border-radius: 10px;
                margin-top: -4%;
              "
            >
              <div>
                <h6
                  style="
                    border-bottom: 1px solid;
                    /* width: 83%; */
                    margin-left: 0%;
                    padding-top: 4%;
                    padding-bottom: 9%;
                    padding-left: 4%;
                    font-size: 70%;
                  "
                >
                  My Profile
                </h6>
              </div>
              <div>
                <h6
                  style="
                    /* width: 83%; */
                    margin-left: 0%;
                    font-size: 70%;
                    padding-left: 4%;
                  "
                >
                  Setting
                </h6>
              </div>
            </div>

            <!-- "Add Comments" section -->
            <div class="add_comments" :key="post.id">
              <router-link
                :to="'/viewmorecomments/' + post.id"
                class="mb-3"
                style="text-decoration: none; color: black; width: 100%"
              >
                <!-- @click="viewMoreComments(post.id)" -->
                <!-- <h5 class="addComments-heading">View more comments {{ post.id }}</h5> -->
                <h5 class="addComments-heading">
                  <span
                    style="
                      transition: box-shadow 0.3s, transform 0.3s;
                      border-bottom: 1px solid #707070;
                    "
                    >View more comments</span
                  >
                </h5>
              </router-link>
              <!-- <input class="add_comments_input" type="text" v-model="post.comments" placeholder="Add Comments" /> -->
              <input
                class="add_comments_input"
                v-model="post.newComment"
                placeholder="Add Comments"
              />
              <!-- <button class="add_comments_btn text-dark" @click="AddComments(post.id, post.comments)">Post</button> -->
              <button
                class="add_comments_btn text-dark"
                :class="{ 'animate__animated animate__tada': iconClicked }"
                @click="AddComments(post.id, post.newComment)"
              >
                Post
              </button>
            </div>
          </div>
        </div>

        <div class="home16 py-3 px-3">
          <header class="ms-1">Reccomnded offers for you</header>
          <router-link to="/alloffers" custom v-slot="{ navigate }">
            <span
              @click="navigate"
              role="link"
              class="me-1"
              style="order: none; background: none"
              >View all</span
            >
          </router-link>
          <div class="exhome mx-auto mt-2">
            <div class="exhome-hom17">
              <div class="home17">
                <img src="../assets/poster2.jpg" />
                <h6>Coffe King</h6>
                <router-link to="/alloffers">
                  <button>Reedem Now</button>
                </router-link>
              </div>
              <div class="home17">
                <img src="../assets/poster2.jpg" />
                <h6>Coffe King</h6>
                <router-link to="/alloffers">
                  <button>Reedem Now</button>
                </router-link>
              </div>
              <div class="home17">
                <img src="../assets/poster2.jpg" />
                <h6>Coffe King</h6>
                <router-link to="/alloffers">
                  <button>Reedem Now</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="loading-spinner mx-auto mt-2 mb-5">
          <div class="spinner"></div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 left-column">
        <div class="home20">
          <friend-request />
        </div>

        <div class="home21">
          <friend-suggestion />
        </div>

        <div
          style="
            width: 100%;
            margin-top: 16%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 100%;
            "
          >
            <img src="../assets/ice.jpg" class="mb-1" width="129" />

            <img
              src="../assets/RP_1.jpg"
              class="mb-1"
              width="150"
              height="60"
              style="margin-top: 8%"
            />
          </div>

          <p
            class="mx-1"
            style="
              font-size: 80%;
              color: black;
              margin-top: 2%;
              text-align: justify;
              font-weight: 500;
              opacity: 0.9;
            "
          >
            At RedPrix, we believe that dining experiences should be memorable,
            budget-friendly, and stress-free. That's why we've designed a
            user-friendly platform that makes booking tables and finding the
            perfect restaurant for any occasion a breeze.
          </p>

          <div class="mx-1 my-3 w-100">
            <router-link to="/maincontactUs">
              <button
                type="button"
                class="btn text-white w-100"
                style="background-color: #eb0e19"
              >
                Contact-Us
              </button>
            </router-link>
          </div>
        </div>
        <RighttAdBanner />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import userlike from "./user-like.vue";
import usercomment from "./user-comment.vue";
import usershare from "./user-share.vue";
// import post from "./post.vue";
import createpost from "./createpost.vue";
import list from "./list.vue";
import navbar from "./nav.vue";
import FriendRequest from "./Modal/FriendRequest.vue";
import FriendSuggestion from "./Modal/FriendSuggestion.vue";
import RaiseTicket from "./RaiseTicket.vue";
// import { ref } from "vue";

import LeftAdBanner from "./LeftAdBanner.vue";
import RighttAdBanner from "./RightAdBanner.vue";

export default {
  name: "homePage",
  components: {
    userlike,
    usercomment,
    usershare,
    // post,
    RaiseTicket,
    createpost,
    list,
    navbar,
    FriendRequest,
    FriendSuggestion,
    LeftAdBanner,
    RighttAdBanner,
  },
  props: {
    userId: String,
  },
  data() {
    return {
      id: "",
      posts: [],
      item: [],
      item1: [
        {
          name: "email",
        },
      ],
      rate: "1",
      coupon: "",
      amount: "",
      couponSuMsg: "",
      coponerr: "",
      imageSrc: "",
      selectedOption: "",
      n3: [],
      moment: moment,
      plans: [],
      data: [],
      UserType: [],
      myoffers: [],
      productinfo: "yearPlan1",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      txnid: this.makeid(),
      firstname: "",
      email: "",
      phone: "",
      surl: window.location.origin + "/success",
      furl: window.location.origin + "/fail",
      service_provider: "payu_paisa",
      imageUrl: [],
      title: "",
      description: "",
      images: [],
      fileList: "",
      url: [],
      file: "",
      selectedFile: null,
      hiddenPostIds: JSON.parse(localStorage.getItem("hiddenPosts")) || [],
      comments: "",
      ShowComments: [],
      commentsToShow: 2,
      page: 1,
      perPage: 10,
      loading: false,
      postComments: {},
      commentDisplayed: false,
      defaultImage: require("@/assets/cover.png"),
      isLoadingMorePosts: false,
      endLoad: false,
      isSubscribed: false,
      newPromoCode: "",
      couponCodeText: "",
      amountText: "",
      plan_id: "",
      iconClicked: false,
    };
  },

  computed: {
    // filteredComments() {
    //     return (postId) => {
    //         return this.posts.find((post) => post.id === postId) ? post.comments || []
    //     };
    // },
  },

  methods: {
    getInputUpdatedValue(event) {
      this.couponSuMsg = "";
      // this.amount = 0;
      this.newPromoCode = event.target.value;
    },
    shouldShowPost(post) {
      return !this.hiddenPostIds.includes(post.id);
    },

    createPost() {
      const white = document.querySelector(".white");

      const file = document.querySelector("#file");

      file.addEventListener("change", function () {
        const choosedFile = this.files[0];
        if (choosedFile) {
          const reader = new FileReader();

          reader.addEventListener("load", function () {
            white.setAttribute("src", reader.result);
          });

          reader.readAsDataURL(choosedFile);
        }
      });
    },

    onUpload() {
      const formData = new FormData();
      formData.append("file", this.file);
      axios
        .post("customers/posts", formData)
        .then(() => {
          // console.log("File uploaded:", response.data);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    },

    AMountChangeFunction() {
      let planname = document.getElementById("plannem").value;

      this.couponSuMsg = "";

      axios
        .get("admin/subscriptionPlan?search=" + planname, {})
        .then((res) => {
          localStorage.setItem("sub_id", res.data.data[0].id);
          localStorage.setItem("coupon_code", res.data.data[0].coupon);

          this.newPromoCode = "";

          this.plan_id = res.data.data[0].id;

          this.amount = res.data.data[0].amount;
          this.amountText = res.data.data[0].amount;
          this.coupon = res.data.data[0].coupon;
          this.couponCodeText = res.data.data[0].coupon;

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        })

        .catch((error) => {
          console.log(error);
        });
    },

    couponVerify() {
      this.couponSuMsg = "";

      // console.log("New Promo Code: ", this.newPromoCode);

      if (this.plan_id == "1") {
        axios
          .get(`customers/apply-coupon/1`, {
            params: {
              coupon_code:
                this.newPromoCode && this.newPromoCode != this.couponCodeText
                  ? this.newPromoCode
                  : this.couponCodeText,
            },
          })
          .then((res) => {
            // console.log("Coupon Verify response: ", res);
            this.coponerr = "";
            this.couponSuMsg =
              "Coupon Apply successfully, You get " +
              res.data.data.discount_amount +
              " off from " +
              res.data.data.subscription_plans_amount +
              ".";
            this.amount = res.data.data.amount_pay;

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          })
          .catch((error) => {
            console.log(error);
            this.coponerr = "Invalid Coupon Code / Promo Code";
            setTimeout(() => {
              this.coponerr = "";
            }, 2000);
          });
      } else {
        axios
          .get(`customers/apply-coupon/${this.plan_id}`, {
            params: {
              coupon_code: this.couponCodeText,
            },
          })
          .then((res) => {
            // console.log("Coupon Verify response: ", res);
            this.coponerr = "";
            this.couponSuMsg =
              "Coupon Apply successfully, You get " +
              res.data.data.discount_amount +
              " off from " +
              res.data.data.subscription_plans_amount +
              ".";
            this.amount = res.data.data.amount_pay;

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // this.calculate=this.amount_payPrint/100*90

    //    var remov= parseFloat(this.calculate).toFixed(0)
    //    this.amount_payPrint= remov+'.00'

    getUserTypes() {
      if (this.UserType === 1) {
        alert(this.UserType);
        this.$router.push("/userProfile");
      } else if (this.UserType === 2) {
        alert(this.UserType);
        this.$.router.push("/vendprofile");
      } else if (this.UserType === 3) {
        alert(this.UserType);

        this.$.router.push("/influprofile");
      }
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      // console.log(text);
      localStorage.setItem("text", text);
      return text;
    },

    signInButtonPressed() {
      if (this.newPromoCode && this.newPromoCode != this.couponCodeText) {
        // console.log("Subscription With New Promo Code !!!");
        // console.log("User Id: ", this.item.id);
        // console.log("New Promo Code: ", this.newPromoCode);

        axios
          .post(`customers/subscribe`, {
            subscription_plan_id: localStorage.getItem("sub_id"),
            coupon_code: this.newPromoCode,
          })
          .then((response) => {
            response.data.data;

            console.log("Subscription Response: ", response);
            localStorage.setItem("UsingVendorCode", 1);
            this.$router.push("/success");

            // localStorage.setItem(
            //   "Subscription_Response",
            //   JSON.stringify(response.data)
            // );

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }

            // this.$router.push("/success");
          })
          .catch((error) => console.log(error));
      } else {
        var data =
          this.mkey +
          "|" +
          this.txnid +
          "|" +
          this.amount +
          "|" +
          this.productinfo +
          "|" +
          this.firstname +
          "|" +
          this.email +
          "|||||||||||";
        var sha512 = require("js-sha512");

        var salt = "3oFxUMtWG2";
        var hash = sha512(data + salt);

        // console.log(hash);
        // console.log(data);
        document.getElementById("hash").value = hash;

        document.getElementById("paymentForm").submit();

        // axios
        //   .post(`customers/subscribe`, {
        //     subscription_plan_id: localStorage.getItem("sub_id"),
        //     coupon_code: localStorage.getItem("coupon_code"),
        //   })
        //   .then((response) => {
        //     // response.data.data;

        //     console.log(response);

        //     if (localStorage.getItem("token")) {
        //       axios.defaults.headers.common["Authorization"] =
        //         " Bearer " + localStorage.getItem("token");
        //     }
        //     // Pass postId to likeList
        //     // this.$router.push("/success");
        //   })
        //   .catch((error) => console.log(error));
      }
    },

    toggleMenu() {
      let togg = document.querySelector(".togg");
      togg.classList.toggle("newlist");
    },

    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },

    toggleDown() {
      let togg4 = document.querySelector(".togg4");
      togg4.classList.toggle("newlist4");
    },

    toggleSecond() {
      let togg5 = document.querySelector(".togg5");
      togg5.classList.toggle("newlist5");
    },

    postClick() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.add("postpop");
    },

    closePost() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.remove("postpop");
    },

    tagPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.add("tagpop");
    },

    returnPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.remove("tagpop");
    },

    getdata() {
      axios
        .get("customers/my-profile", {
          headers: {
            DeviceID: "11111",
            versionName: "1.0.0",
            DeviceType: "0",
          },
        })
        .then((response) => {
          this.item = response.data.data;
          this.UserType = response.data.data.user_type;
          // alert(this.UserType)
          // console.log("userType",this.UserType)
          this.item1 = response.data.data.email;

          if (this.item.is_subscribed == "0") {
            this.isSubscribed = false;
          } else {
            this.isSubscribed = true;
          }

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        });
    },

    offerReedem() {
      axios.post("customers/offers/redeem-offer/").then(() => {
        // console.log(res.data.data);
      });
    },

    showoffer() {
      axios.post("vendors/my-offer").then((res) => {
        this.myoffers = res.data.data;
        // console.log(res.data.data);
      });
    },

    async getpost() {
      try {
        const response = await axios.get("customers/posts", {
          headers: {
            DeviceID: "11111",
            versionName: "1.0.0",
            DeviceType: "0",
          },
        });

        // console.log("Post Response: ", response);

        if (response.data && response.data.data) {
          this.posts = response.data.data;
          // console.log("Post: ", this.posts);
          this.ShowComments = {};
          for (const post of this.posts) {
            const postId = post.id;
            // console.log("Post Array: ", this.posts);
            // await this.getCommentsForPost(post.id);
            await this.getCommentsForPost(postId);
          }
          // console.log("Comments Section: ", this.ShowComments);
        } else {
          console.error("No post data found in the response.");
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },

    async loadMorePosts() {
      // console.log("Load More Function Called!!!");

      try {
        this.isLoadingMorePosts = true;

        const page = Math.ceil(this.posts.length / 15) + 1;
        // console.log("Page Number: ", page);
        const pageSize = 15;

        const response = await axios.get("customers/posts", {
          headers: {
            DeviceID: "11111",
            versionName: "1.0.0",
            DeviceType: "0",
          },
          params: {
            page: page,
            per_page: pageSize,
          },
        });

        // console.log("More Posts Response: ", response);

        const currentPosts = response.data.data;

        for (const currentPost of currentPosts) {
          const postId = currentPost.id;
          // console.log("Post Array: ", this.posts);
          // await this.getCommentsForPost(post.id);
          await this.getCommentsForPost(postId);
        }
        // console.log("Updated Comments Section: ", this.ShowComments);

        if (currentPosts.length > 0) {
          this.posts = [...this.posts, ...currentPosts];
          if (this.posts.length >= response.data.total) {
            // console.log("All Posts Fetched Successfully!!");
            this.endLoad = true;
          }
        } else {
          this.isLoadingMorePosts = false;
          this.endLoad = true;
        }

        // console.log("More Posts: ", this.posts, " Length: ", this.posts.length);
      } catch (error) {
        console.error("Error fetching more posts:", error);
      } finally {
        this.isLoadingMorePosts = false;
      }
    },

    handleScroll() {
      // Clear the previous timer
      clearTimeout(this.scrollTimer);

      // Set a new timer to execute after a delay
      this.scrollTimer = setTimeout(() => {
        if (
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 200
        ) {
          if (this.endLoad === false) {
            this.loadMorePosts();
            // console.log("Load More Calling!!!");
          }
        }
      }, 1000); // Adjust the debounce delay as needed
    },

    // async getCommentsForPost(postId) {
    //   try {
    //     const response = await axios.post("customers/posts/comments", {
    //       post_id: postId,
    //     });

    //     if (
    //       response.data &&
    //       response.data.data &&
    //       response.data.data.comments
    //     ) {
    //       // Update the ShowComments property with the fetched comments
    //       // this.ShowComments = response.data.data.comments;
    //       this.$data.ShowComments = response.data.data.comments;
    //       // alert(this.$data.ShowComments[0].comment);
    //     } else {
    //       console.log("No comment data found in the response.");
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    async getCommentsForPost(postId) {
      try {
        const response = await axios.post("customers/posts/comments", {
          post_id: postId,
        });

        if (
          response.data &&
          response.data.data &&
          response.data.data.comments
        ) {
          // Update the ShowComments property with the fetched comments
          // this.ShowComments = response.data.data.comments || [];
          this.ShowComments[postId] =
            response.data.data.comments.slice(-2) || [];
          // this.$set(this.ShowComments, postId, response.data.data.comments);
          // console.log("Comments Section: ", this.ShowComments);
        } else {
          console.log("No comment data found in the response.");
        }
      } catch (error) {
        console.error(error);
      }
    },

    viewMoreComments(postId) {
      const post = this.posts.find((post) => post.id === postId);
      if (post) {
        post.commentsToShow += 2;
        // console.log("More Comments: ", this.ShowComments[postId]);
        if (!this.ShowComments[postId]) {
          // console.log("Getting More Comments !!");
          this.getCommentsForPost(postId, post.commentsToShow);
        }
      }
    },

    // AddComments(id) {
    //   axios
    //     .post("customers/comments", {
    //       post_id: id,
    //       comment: this.comments,
    //     })
    //     .then((response) => {
    //       // console.log(id)
    //       console.log("comment added", response.data.data);
    //       this.comments = "";
    //     });
    // },

    AddComments(postId, comments) {
      this.iconClicked = !this.iconClicked;
      axios
        .post("customers/comments", {
          post_id: postId,
          comment: comments, // Use the comments specific to the post
        })
        .then(() => {
          const post = this.posts.find((p) => p.id === postId);
          if (post) {
            post.newComment = ""; // Reset the input field for the specific post
          }
          // After adding a comment, refresh the comments for the post
          this.getCommentsForPost(postId);
        });
    },

    logoutUser() {
      axios.get("logout", {}).then(() => {
        localStorage.clear();
        console.clear();
        this.$router.push("/");
      });
    },

    couponApply() {
      axios
        .get("admin/subscriptionPlan", {})
        .then((res) => {
          this.plans = res.data.data;
          // console.log("Plans Response: ", res);

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        })
        // this.calculate=this.amount_payPrint/100*90
        //    var remov= parseFloat(this.calculate).toFixed(0)
        //    this.amount_payPrint= remov+'.00'
        .catch((error) => {
          console.log(error);
        });
    },
  },

  async created() {
    // Fetch posts and comments when the component is created
    // console.log("Created Starts !!");
    await this.getpost();
    // console.log("Created Ends !!");
  },

  mounted() {
    this.getdata();
    this.couponApply();
    this.makeid();
    this.getpost();
    window.addEventListener("scroll", this.handleScroll);

    // console.log(this.$router.params, "");
    //  this.getData2();

    // window.addEventListener('scroll', this.handleScroll);
    this.hash =
      this.mkey +
      "|" +
      this.txnid +
      "|" +
      this.amount +
      "|" +
      this.productInfo +
      "|" +
      this.firstName +
      "|" +
      this.email +
      "|" +
      this.id +
      "||||||||||";
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.inactive-class {
  background: #bf3e3ef4 !important;
}

.modal-dialog {
  max-width: 60%;
  height: 100vh;
  margin: 0 auto;
}

.modal::-webkit-scrollbar,
.modal-dialog::-webkit-scrollbar {
  display: none !important;
}

.mo {
  width: 50%;
  height: 33rem;
}

.modal-content {
  width: 50%;
  height: 33rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media only screen and (min-width: 2080px) {
  .mo {
    height: 40rem;
  }

  .modal-content {
    height: 40rem;
  }
}

@media only screen and (max-width: 2079px) {
  .mo {
    height: 38rem;
  }

  .modal-content {
    height: 38rem;
  }
}

@media only screen and (max-width: 1460px) {
  .mo {
    height: 37rem;
  }

  .modal-content {
    height: 37rem;
  }
}

@media only screen and (max-width: 1280px) {
  .modal-dialog {
    max-width: 65%;
  }

  .mo {
    height: 35rem;
  }

  .modal-content {
    height: 35rem;
  }
}

@media only screen and (max-width: 1024px) {
  .modal-dialog {
    max-width: 75%;
  }

  .mo {
    height: 35rem;
  }

  .modal-content {
    height: 35rem;
  }
}

@media only screen and (max-width: 992px) {
  .modal-dialog {
    max-width: 90%;
    height: 100vh;
    justify-content: center;
  }

  .mo {
    display: none;
  }

  .modal-content {
    width: 60%;
    height: auto;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .modal-dialog {
    max-width: 95%;
  }

  .modal-content {
    width: 70%;
  }
}

@media only screen and (max-width: 577px) {
  .modal-content {
    width: 90%;
  }
}

.loading-spinner {
  position: relative;
  width: 25px;
  height: 25px;
}

.spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: auto;
  border: 6px solid #eb0e19;
  border-radius: 50%;
  animation: loading-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #eb0e19 transparent transparent transparent;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.scroll-div {
  width: 98%;
  height: 32rem;
}

.scroll-div::-webkit-scrollbar {
  display: none;
}

.img-1-div img {
  width: 31.3rem;
  height: 30rem;
}

.img-2-div {
  width: 100%;
  height: 32rem;
  overflow: hidden;
}

.img-2-div img {
  width: -webkit-fill-available;
  height: 30rem;
}

@media only screen and (max-width: 1180px) {
  .img-2-div img {
    width: 100%;
  }
}

.row {
  width: 75%;
  margin: 0 auto;
}

@media (max-width: 530px) {
  .row {
    margin: 0 auto;
  }
}

@media (min-width: 381px) and (max-width: 580px) {
  .row {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .row {
    width: 100%;
  }

  .mid-column {
    padding: 0;
    margin: 0;
  }
}

/* .nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
} */
.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}

.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}

.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}

.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}

.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist3 {
  transform: scale(1);
}

.togg4 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist4 {
  transform: scale(1);
}

.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist5 {
  transform: scale(1);
}

.home {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 1%;
}

.home button {
  margin-left: 21%;
  margin-top: 3%;
  width: 57%;
  border: none;
  line-height: 2.2;
  font-size: 88%;
  border-radius: 6px;
  background: #ed1823;
  color: white;
}

/* .home2 {
  margin-top: 29%;
  width: 71%;
  height: 40vh;
} */
.home2 img {
  width: 100%;
  padding: 1.5%;
}

.home3 {
  /* margin: auto; */
  width: 100%;
  /* height: 5vh; */
  /* padding: 0rem 1rem 0rem; */
}

.elite_ac_btn {
  width: 100%;
  line-height: 2.5;
  border: none;
  background: #eb0e19 !important;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  /* margin-left: -1rem; */
}

.home6 {
  margin-top: 2%;
  margin-left: -2%;
}

.home7 {
  border-top: 1px solid black;
  width: 87%;
  margin-left: 15%;
  margin-top: -3%;
}

.home8,
.home15 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #ccc;
  /* visibility: visible; */
}

.cus-all {
  visibility: hidden;
}

.togg {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist {
  transform: scale(1);
}

.newlist1 {
  transform: scale(1);
}

.home15 {
  margin-top: 2%;
}

.h2 {
  display: flex;
  /* width: 100%; */
  margin-left: 3.7rem;
  margin-top: 0;
  margin-bottom: 0;
}

.h3,
.h4 {
  display: flex;
  width: 50%;
  margin-top: -1.5rem;
  align-items: center;
  /* padding-left: 20; */
  gap: 2%;
  margin-bottom: 0;
}

.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}

.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 12px;
  padding-left: 23px;
}

.ho1 {
  display: flex;
  /* margin-left: 1%; */
  /* margin-top: 2%; */
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.6rem;
  min-width: 50px;
  border-top: 1px solid #ccc;
}

.like-count,
.comment-count,
.share-count {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* .square {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  line-height: 20px;
  margin-right: 5px;
  border-radius: 4px;
} */

.ho1 h6 {
  font-size: 6vw;
}

.home12 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home13 {
  width: 100%;
  /* margin-left: 8%; */
  /* background: rgb(243, 241, 241); */
  border-radius: 6px;
}

.home16 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 2rem 3rem;
  /* margin-left: 25%; */
  border: 1px solid lightgray;
  margin-top: 1.5%;
  border-radius: 6px;
  margin-bottom: 4%;
  background-color: #fff;
  flex-wrap: wrap;
  box-shadow: 0px 0px 13px 0px #ccc;
}

.exhome {
  display: flex;
  justify-content: center;
  gap: 6px;
  width: 100%;
}

.exhome-hom17 {
  display: flex;
  justify-content: space-evenly;
  gap: 0.8%;
}

.home17 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0.8rem 0.2rem;
  padding: 0.4rem 0.4rem;
  border-radius: 0.4rem;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .home17 {
    margin: 0;
  }
}

.home17 img {
  width: 100%;
  margin-bottom: 0.6rem;
}

.home17 button {
  margin: 5px 0;
  line-height: 1.8;
  width: 100%;
  background: #eb0e19;
  border: none;
  color: white;
  border-radius: 0.5rem;
  font-size: 1em;
}

/* .home19_user_detials {
  display: flex;
  align-items: center;
  gap: 2%;
} */

/* @media screen and (min-width: 768px) and (max-width: 1200px) {

  .home21_user_detials {
    flex-direction: column;
    justify-content: center;
  }
} */

@media (max-width: 1080px) {
  .left-column {
    display: none;
  }

  .right-column {
    display: none;
  }

  .mid-column {
    width: 100%;
  }
}

/* @media (min-width:1080px) {} */

/* .home19_user_detials img {
  border-radius: 50%;
} */

/* .home21_user_detials {
  display: flex;
  align-items: center;
  gap: 2%;
}

.home21_user_detials img {
  border-radius: 50%;
} */

.user_names {
  display: block;
}

/* .home19 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
  padding: 0.5rem;
} */

/* .home21 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
  padding: 0.5rem;
} */

/* .home20 {
  border-top: 1px solid;
  margin-top: 23px;
} */

.pform1 {
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 0%;
  width: 100%;
  margin-left: 0%;
}

.pform1 input {
  width: 90%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.pform1 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  left: 8%;
  margin-top: 3%;
}

.pform1 span::before {
  content: "";
  position: absolute;
  top: 14.2%;
  left: 3.3%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform1 input:focus ~ label,
.pform1 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.pform1 input:focus ~ span::before,
.pform1 input:valid ~ span::before {
  width: 85.3%;
}

.pform2,
.pform3,
.pform4,
.pform5,
.pform6,
.pform7,
.pform8,
.pform9 {
  /* position: relative; */
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 100%;
  margin-left: 0%;
}

.pform2 input,
.pform3 input,
.pform4 input,
.pform5 input,
.pform6 input,
.pform7 input,
.pform8 input,
.pform9 input {
  width: 90%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.pform2 label,
.pform3 label,
.pform4 label,
.pform5 label,
.pform6 label,
.pform7 label,
.pform8 label,
.pform9 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  left: 8%;
  margin-top: 3%;
}

.pform2 span::before {
  content: "";
  position: absolute;
  top: 28.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform2 input:focus ~ label,
.pform2 input:valid ~ label {
  top: 53px;
  color: #eb0e19;
}

.pform2 input:focus ~ span::before,
.pform2 input:valid ~ span::before {
  width: 85.3%;
}

.pform3 span::before {
  content: "";
  position: absolute;
  top: 32.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform3 input:focus ~ label,
.pform3 input:valid ~ label {
  top: 22%;
  color: #eb0e19;
}

.pform3 input:focus ~ span::before,
.pform3 input:valid ~ span::before {
  width: 85.3%;
}

.pform4 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform4 input:focus ~ label,
.pform4 input:valid ~ label {
  top: 33%;
  color: #eb0e19;
}

.pform4 input:focus ~ span::before,
.pform4 input:valid ~ span::before {
  width: 85.3%;
}

.pform5 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform5 input:focus ~ label,
.pform5 input:valid ~ label {
  top: 25%;
  color: #eb0e19;
}

.pform5 input:focus ~ span::before,
.pform5 input:valid ~ span::before {
  width: 38%;
}

.pform6 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 50.5%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform6 input:focus ~ label,
.pform6 input:valid ~ label {
  top: 25%;
  color: #eb0e19;
}

.pform6 input:focus ~ span::before,
.pform6 input:valid ~ span::before {
  width: 38%;
}

.pform8 span::before {
  content: "";
  position: absolute;
  top: 77.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform8 input:focus ~ label,
.pform8 input:valid ~ label {
  top: 69%;
  color: #eb0e19;
}

.pform8 input:focus ~ span::before,
.pform8 input:valid ~ span::before {
  width: 85.3%;
}

.modal2 {
  position: absolute;
  background: #0e0e0e8c;
  width: 102.1%;
  height: 307.5%;
  margin-left: -28%;
  visibility: hidden;
  margin-top: -15%;
  margin-left: -34%;
}

.postpop {
  visibility: visible;
}

.modal-pop {
  width: 38%;
  height: 23%;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-pop h3 {
  background: #eb0e19;
  margin-top: -12%;
  height: 13%;
  width: 111.5%;
  margin-left: 0.55;
  text-align: left;
  margin-left: -5.6%;
  padding-left: 3%;
  padding-top: 5%;
  color: white;
  font-size: 129%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-pop img {
  margin-left: -91%;
  margin-top: 4%;
  border-radius: 50%;
}

.modal-pop h4 {
  width: 42%;
  margin-left: 12%;
  text-align: left;
  margin-top: -9%;
  font-size: 144%;
}

.modal-pop h5 {
  text-align: left;
  width: 26%;
  margin-left: 12.2%;
  margin-top: 0.5%;
  font-size: 82%;
  font-weight: 100;
}

.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 90%;
  margin-left: 4%;
}

.text input {
  width: 82%;
  padding: -27px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
  margin-left: -17%;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.mpop {
  text-align: left;
  width: 90%;
  margin-left: 4%;
}

.mpop input {
  width: 99%;
  height: 68px;
  border-radius: 10px;
  border: 1px solid;
  outline: none;
}

.mpop1 {
  margin-left: 3%;
  margin-top: 5%;
  border: 2px dashed;
  height: 31%;
  width: 92%;
  border-radius: 10px;
}

.mpop3 {
  margin-top: -7%;
}

.mpop2 button,
.mpop3 button {
  margin-left: -54%;
  margin-top: 3%;
  width: 36%;
  background: white;
  border: 1px solid;
  border-radius: 6px;
  /* height: 43%; */
  cursor: pointer;
}

.mpop3 button {
  margin-top: -3.5%;
  margin-left: 49%;
}

.mpop2 button img,
.mpop3 button img {
  margin-left: -60%;
  margin-top: -3%;
}

.mpop2 button h4,
.mpop3 button h4 {
  width: 24%;
  /* margin-top: -11%; */
  margin-top: 1.7%;
  position: absolute;
  margin-left: 10%;
}

.mpop4 {
  margin-top: 3%;
}

.mpop4 button {
  width: 93%;
  line-height: 1.8;
  border-radius: 6px;
  font-size: 113%;
  border: none;
  background: #eb0e19;
  color: white;
  cursor: pointer;
}

.mpop5 {
  width: 9%;
}

.mpop5 img {
  width: 3.8%;
  margin-top: -10%;
  position: absolute;
  margin-left: 86%;
  cursor: pointer;
}

.mpop1 button input[type="file"] {
  position: absolute;
  transform: scale(2);

  width: 21px;
  margin-left: -18px;
  margin-top: 8px;
  cursor: pointer;
}

.add_comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  flex-wrap: wrap;
}

.addComments-heading {
  font-size: 0.9rem;
  /* border-bottom: 1px solid #707070; */
  width: 100%;
  cursor: pointer;
  color: #707070;
  display: inline;
}

.addComments-heading:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  color: red;
}

.add_comments input {
  border: 1px solid black;
  border-radius: 0.5rem;
}

.add_comments_btn {
  border: 1px solid black;
  outline: 0;
  background-color: #fff;
  color: black;
  padding: 1.5%;
  width: 20%;
  border-radius: 0.3rem;
}

.add_comments_input {
  width: 74%;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.user_action_btn button {
  margin-top: 8%;
  font-size: 87%;
  width: 40%;
  margin-left: 4%;
  color: white;
  background: #eb0e19;
  border: none;
  line-height: 1.8;
  border-radius: 6px;
}

.user_details {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 1.2rem 0.6rem;
}

.user_comments {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  /* border-radius: 50%; */
}

.user_comments img {
  width: 10%;
  padding: 1%;
}

.comments {
  display: block;
  background-color: rgb(228, 228, 228);
  padding: 2%;
  width: 100%;
  margin: 1%;
  border-radius: 0.5rem;
}
</style>
