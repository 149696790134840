<template>
  <div>
    <div class="home20">
      <FriendRequest />
    </div>

    <div class="home21">
      <FriendSuggestion />
    </div>

    <div
      style="
        width: 100%;
        margin-top: 16%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <div
        style="
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          width: 100%;
        "
      >
        <!-- <img src="./assets/ice.jpg" class="mb-1" width="129" /> -->

        <img
          src="./assets/RP_1.jpg"
          width="150"
          height="60"
          style="margin-top: 8%"
        />
      </div>

      <p
        class="ms-1"
        style="
          font-size: 80%;
          color: black;
          margin-top: 2%;
          text-align: justify;
          font-weight: 500;
          opacity: 0.9;
        "
      >
        At RedPrix, we believe that dining experiences should be memorable,
        budget-friendly, and stress-free. That's why we've designed a
        user-friendly platform that makes booking tables and finding the perfect
        restaurant for any occasion a breeze.
      </p>

      <div class="mx-1 my-3 w-100">
        <router-link to="/login">
          <button
            type="button"
            class="btn text-white w-100"
            style="background-color: #eb0e19"
          >
            Contact-Us
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FriendRequest from "./FriendRequest.vue";
import FriendSuggestion from "./FriendSuggestion.vue";
export default {
  name: "DummyRightside",
  components: {
    FriendRequest,
    FriendSuggestion,
  },
};
</script>

<style scoped>
</style>