<template>
  <!-- <h1>hello</h1> -->
  <form style="margin-top: 0%">
    <div class="text">
      <input type="text" v-model="offer_name" required />
      <span></span>
      <label>OfferTitle</label>
    </div>
    <div
      class="text-v"
      style="
        display: flex;
        justifiy-content: space-between;
        align-items: center;
        align-items: center;
      "
    >
      <input
        id="file"
        type="file"
        @change="handleFileChange"
        style="opacity: 0"
        required
      />
      <span></span>
      <label>Upload offers Image</label>
      <button
        class="btn"
        style="background: #eb0e19; color: white; padding: 0.2rem"
        @click="OpenFile"
      >
        Browser
      </button>
    </div>

    <div
      class="text-v"
      style="
        border: 1px solid grey;
        padding: 0.3rem 0.3rem;
        margin-bottom: 0;
        margin-top: 1.6rem;
        border-radius: 6px;
        font-weight: 500;
      "
    >
      <textarea
        name
        id
        cols="46"
        rows="8"
        v-model="about_offer"
        placeholder="About Offer..."
      ></textarea>
      <!-- <span></span> -->
    </div>
    <div class="About_Offer_Person">
      <div class="text" style="width: 40%">
        <input type="text" v-model="ended_at" required />
        <span></span>
        <label>Offer(7Days)</label>
      </div>

      <div
        class="text"
        style="
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <input type="text" v-model="no_of_person" required />

        <span></span>
        <label>Person</label>
        <div style="display: flex">
          <span @click="increment">
            <img
              src="../../assets/a2.png"
              width="20"
              style="transform: rotate(90deg)"
              alt
            />
          </span>
          <p>{{ no_of_person }}</p>

          <span @click="decrement">
            <img
              src="../../assets/arrow.jpg"
              width="20"
              alt
              style="transform: rotate(-88deg)"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="text">
      <input type="text" required />
      <span></span>
      <label>Address</label>
    </div>

    <button
      class="btn-1"
      type="button"
      style="margin-left: 0%"
      @click="UploadOffer"
    >
      Submit
    </button>
  </form>
</template>

<script>
import axios from "axios";
export default {
  name: "UploadOffers",
  data() {
    return {
      offer_name: "",
      no_of_person: 0,
      ended_at: "",
      about_offer: "",
      // count: 0,
      selectedFile: null,
    };
  },
  methods: {
    decrement() {
      this.no_of_person++;
    },
    increment() {
      this.no_of_person--;
    },
    OpenFile() {
      const fileInput = document.getElementById("file");
      fileInput.click();
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      // this.imageUrl = URL.createObjectURL(this.selectedFile);
    },
    UploadOffer() {
      const formData = new FormData();

      formData.append("offer_name", this.offer_name);
      formData.append("no_of_person", this.no_of_person);
      formData.append("offer_image", this.selectedFile);
      formData.append("ended_at", this.ended_at);
      formData.append("about_offer", this.about_offer);
      axios.post("vendors/offers", formData).then(() => {
        // console.log(res);
        console.log("Offer Created Successfully!!");
        this.$router.push("/setting/Changepassword");
      });
    },
  },
};
</script>
<style scoped>
.About_Offer_Person {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
form {
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.6rem 1.3rem;
  border-radius: 3px;
  box-shadow: 2px 4px 5px #d4d4d4;
}
form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 4%;
  width: 100%;
}
input[type="file"]::-webkit-file-upload-button {
  padding-left: -5rem;
  visibility: hidden;
}

.text input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

/* .form-2 {
   margin-left: -55%;
   margin-top: 10%; 
} */

form .text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: -10px;
  width: 100%;
}

.text-v input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 17px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

form .text-s {
  margin-left: -254px;
  margin-top: 25px;
}

select {
  width: 40%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1 {
  margin-top: 20px;
  margin-left: 10%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
  width: 38%;
}

.s2 {
  margin-left: 61px;
  padding: 2px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
}
.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}
.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-left: -54%;
  margin-top: 9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 136%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 11%;
  width: 100%;
  /* margin-left: -53.5%; */
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
textarea {
  width: 100%;
  height: 5rem;
  resize: none;
  outline: none;
}
</style>