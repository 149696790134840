<template>
  <div class="filter_btn">
    <!--       <i class="fa fa-sliders" aria-hidden="true"></i> -->
    <!-- Button trigger modal -->
    <span data-bs-toggle="modal" data-bs-target="#filterModal">
      <img src="./../../assets/filter.png" width="20" />
    </span>

    <!-- Modal -->
    <div
      class="modal fade"
      id="filterModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Filter</h5>
            <button type="button" data-bs-dismiss="modal">save and Review</button>
          </div>
          <div class="modal-body">
            <div class="search_input">
              <input type="search" v-model="city" @input="searchCities" id="search" />
              <button class="btn btn-primary btn-sm" @click="searchCities">Done</button>
            </div>
            <div class="filter_checkbox">
              <input
                type="checkbox"
                id="selectAll"
                v-model="selectAllCities"
                @change="toggleSelectAll"
              />
                &nbsp;
              <label for="selectAll">All</label>
            </div>

            <!-- Checkbox list for cities -->
            <div class="filter_checkbox">
              <div v-for="city in cities" :key="city.id">
                <input
                  type="checkbox"
                  :id="`city-${city.id}`"
                  :value="city.id"
                  v-model="selectedCities"
                />
                &nbsp;
                <label :for="`city-${city.id}`">{{ city.name }}</label>
                <br />
              </div>
            </div>
            <!-- <hr /> -->
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "filteroffers",
  data() {
    return {
      city: "",
      cities: [],
      selectedCities: [],
      selectAllCities: false,
    };
  },
  mounted() {
    this.searchCities();
  },
  methods: {
    searchCities() {
      axios
        .get(`cities?search=${this.city}`)
        .then((res) => {
          this.cities = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleSelectAll() {
      if (this.selectAllCities) {
        // If "Select All" is checked, select all cities
        this.selectedCities = this.cities.map((city) => city.id);
      } else {
        // If "Select All" is unchecked, deselect all cities
        this.selectedCities = [];
      }
    },
  },
};
</script>
<style scoped>
.modal-header {
  background-color: red;
  color: #fff;
}
.search_input {
  width: 100%;
  /* padding-left:10%;
    padding-right:10%; */
  display: flex;
  justify-content: space-between;
  gap: 2%;
  align-items: center;
}
.search_input input {
  width: 100%;
  border: 1px solid black;
  outline: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 72%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.modal-body {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
</style>
