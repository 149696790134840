<template>
  <div class="main-div">
    <div class="heading">
      <h3>Help & Support</h3>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <img
        class="img-fluid"
        src="../../assets/help_img_1.jpg"
        style="width: 20rem"
        alt="Comming Soon Image"
      />
    </div>
    <div class="mt-4 w-100">
      <div class="d-flex align-items-center justify-content-center">
        <input
          type="text"
          class="form-control"
          placeholder="How can we help you ?"
          style="width: 60%"
        />
        <button
          type="button"
          class="btn text-white ms-2 rounded-3"
          style="width: 25%; background-color: #eb0e19"
        >
          Ask Us
        </button>
      </div>
      <div class="mt-4 ms-1 me-1">
        <div class="px-5">
          <h3 class="fs-4 mb-1" style="color: #eb0e19">
            Ask your queries on call :
          </h3>
          <hr class="my-0" />
          <span class="fs-5 mt-2 ms-1">+91 91041 98942</span>
        </div>
      </div>
      <div class="mt-4 ms-1 me-1">
        <div class="px-5">
          <h3 class="fs-4 mb-1" style="color: #eb0e19">Come to us :</h3>
          <hr class="my-0" />
          <span class="fs-5 mt-2 ms-1"
            >604, Luxuria Business Hub, Nr. VR Mall,</span
          ><br />
          <span class="fs-5 mt-2 ms-1"> Vesu, Surat, Gujarat 395007</span>
        </div>
      </div>
      <div class="mt-4 ms-1 me-1">
        <div class="px-5">
          <button
            type="button"
            class="btn text-white w-100 rounded-3"
            style="background-color: #eb0e19"
            @click="this.$router.push('/setting/contactus')"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "HelpAndSupport",
};
</script>
  
  <style scoped>
.main-div {
  width: 100%;
  height: 40.5rem;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 10px;
  overflow-y: auto;
}

.main-div::-webkit-scrollbar {
  display: none;
}

.heading h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: grey;
  padding: 0.8rem 0.9rem;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1.5rem;
}

.form-control {
  box-shadow: none !important;
}

input:focus {
  border: 0.08rem solid #eb0e19;
}
</style>