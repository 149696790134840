<template>
  <div class="animate__animated animate__fadeIn">
    <DummyNavBar />

    <div class="container-xl w-100 custom-container row-div">
      <div class="row custom-res-row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-3 left-side px-2">
          <DummyLeftSide />
          <LeftAdBanner />
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 px-2 custom-res-col">
          <DummyCenterCreatePost />
          <DummyCenterPost />
        </div>
        <div class="col-12 col-sm-12 col-md-2 col-lg-3 px-2 -col">
          <DummyRightSide />
          <RightAdBanner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DummyNavBar from "./DummyNavbar.vue";
import DummyLeftSide from "./DummyLeftside.vue";
import DummyRightSide from "./DummyRightside.vue";
// import DummyCenterCreatePost from "./DummyCenterCreatePost.vue";
import DummyCenterPost from "./DummyCenterPost.vue";
import LeftAdBanner from "./LeftAdBanner.vue";
// import InfiniteLoading from "vue-infinite-loading";
import RightAdBanner from "./RightAdBanner.vue";

export default {
  name: "DummyHome",
  data() {
    return {
      token: "",
    };
  },

  components: {
    DummyNavBar,
    DummyLeftSide,
    DummyRightSide,
    // DummyCenterCreatePost,
    DummyCenterPost,
    // InfiniteLoading,
    RightAdBanner,
    LeftAdBanner,
  },

  created() {
    this.lodData();
  },

  methods: {
    lodData() {
      this.token = localStorage.getItem("authToken");
      //   console.log("this is home page show token", this.token);
    },
    logout() {
      localStorage.removeItem("authToken");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
* {
  font-family: Montserrat, sans-serif;
}

.row-div {
  margin-top: 7rem !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin: 0;
    max-width: 100%;
  }

  .row-div {
    margin-top: 7rem !important;
  }
}

@media (max-width: 1399px) {
  .row-div {
    margin-top: 7rem !important;
  }
}

@media (max-width: 992px) {
  .col-lg-3 {
    display: none;
  }

  .col-lg-6 {
    width: 100%;
  }
  .row-div {
    margin-top: 7rem !important;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .row-div {
    margin-top: 7rem !important;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100% !important;
  }
  .row-div {
    margin-top: 7rem !important;
  }
}
</style>
