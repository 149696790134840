<template>
  <div class="home">
    <div>
      <img
        src="./assets/cover2.jpg"
        style="
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          width: 100%;
          height: 6rem;
        "
      />
    </div>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -2.5rem;
      "
    >
      <img
        src="./assets/pro2.jpg"
        style="
          width: 30%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          mix-blend-mode: darken;
        "
        v-if="item.profile_photo == ''"
      />
      <img
        :src="item.profile_photo"
        v-else
        controls
        style="
          width: 30%;
          height: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 1.3rem;
          mix-blend-mode: darken;
        "
      />
    </div>

    <h4 style="text-align: center; margin-top: -1rem">{{ item.name }}</h4>
    <h6
      style="text-align: center; font-size: 90%; font-weight: 500; color: gray"
    >
      {{ item.email }}
    </h6>
    <!-- <router-link :to="getRouterLink()">
      <button @click="getRouterLink" type="button">View Profile</button>
    </router-link>-->

    <div class="mb-4">
      <router-link to="/login">
        <button role="link" type="button">View Profile</button>
      </router-link>
    </div>
    <h5
      style="
        font-size: 80%;
        margin-top: 5%;
        border-top: 2px solid lightgray;
        padding: 0.5rem 0.6rem;
        color: black;
        font-weight: 500;
        text-align: justify;
        opacity: 0.9;
      "
    >
      RedPrix is the ultimate social media platform for food lovers, offering
      the best discounts and deals on restaurants near your location. With
      RedPrix, you can discover new and delicious eats while saving big on your
      dining experiences.
    </h5>
  </div>
  <!-- <div class="home2 mt-3">
    <img src="./assets/bur.jpg" />
  </div> -->
</template>

<script>
import axios from "axios";

export default {
  name: "LeftSideComponent",

  data() {
    return {
      item: [],
      // apiUrl: "http://uatserver.redprix.com/public/api/",
      // apiUrl: "https://api.redprix.com/api/",
    };
  },
  components: {},
  methods: {
    getdata() {
      axios
        .get(`customers/my-profile`, {
          headers: {
            DeviceID: "11111",
            versionName: "1.0.0",
            DeviceType: "0",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          // console.log("Response: ", response)
          this.item = response.data.data;
          this.UserType = response.data.data.user_type;
          // alert(this.UserType)
          // console.log("userType",this.UserType)
          this.item1 = response.data.data.email;
        });
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>

<style scoped>
.home {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 1%;
}

.home button {
  margin-left: 21%;
  margin-top: 3%;
  width: 57%;
  border: none;
  line-height: 2.2;
  font-size: 88%;
  border-radius: 6px;
  background: #ed1823;
  color: white;
}

.home2 img {
  width: 100%;
  padding: 1.5%;
}
</style>
