<template>
  <div class="animate__animated animate__fadeIn">
    <navbar />
    <div class="content row">
      <div class="col-md-5">
        <div class="menu">
          <div class="menu1" style="width: 100%">
            <h3>Setting</h3>
          </div>

          <div class="menu2">
            <router-link
              to="/setting/Changepassword"
              style="color: #000; text-decoration: none"
            >
              <div
                class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                style="cursor: pointer"
              >
                <h4 class="my-auto">Change Password</h4>
                <h5 class="my-auto">
                  <i class="fa-solid fa-chevron-right"></i>
                </h5>
              </div>
            </router-link>

            <div v-if="userType == 2" class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/vendorAccount"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">My Vendor Account</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
            <div v-if="userType == 3" class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/influencerAccount"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">My Influencer Account</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
            <div class="toggle_menu px-3 py-2">
              <h4 class="w-75">Notification</h4>

              <div class="w-25">
                <div class="toggle">
                  <div
                    class="toggle-button"
                    v-on:click="animatedToggle()"
                  ></div>
                </div>
              </div>
            </div>

            <!-- <div class="menu3" style="cursor: pointer">
            <router-link
              style="text-decoration: none; color: white"
              to="/setting/permission"
            >
              <div
                class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                style="cursor: pointer"
              >
                <h4 class="my-auto">Permission</h4>
                <h5 class="my-auto">></h5>
              </div>
            </router-link>
          </div> -->
            <div class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/privacypolicy"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">Privacy Policy</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
            <div class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/termsandcondition"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">Terms And Condition</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
            <div class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/aboutus"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">About Us</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
            <div class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/contactus"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">Contact Us</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
            <div class="menu3" style="cursor: pointer">
              <router-link
                style="text-decoration: none; color: white"
                to="/setting/faqs"
              >
                <div
                  class="menu3 d-flex align-items-center justify-content-between px-3 py-2"
                  style="cursor: pointer"
                >
                  <h4 class="my-auto">FAQ's</h4>
                  <h5 class="my-auto">
                    <i class="fa-solid fa-chevron-right"></i>
                  </h5>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <router-view class="animate__animated animate__fadeIn"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./nav.vue";
import axios from "axios";
export default {
  name: "settIng's",
  components: {
    navbar,
  },
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      old_password: "",
      new_password: "",
      confirm_password: "",
      userType: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    getdata() {
      axios
        .get("customers/my-profile", {
          headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
        })
        .then((response) => {
          // this.item = response.data.data;
          this.userType = response.data.data.user_type;
          // alert(this.UserType)
          // console.log("userType", this.UserType);
          // this.item1 = response.data.data.email;

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        });
    },
    // openEye(){
    //     let eyeicon = document.getElementById("eyeicon")
    //     let password = document.getElementById("eyeicon")

    //     eyeicon.onclick= function(){
    //         if(password.type == "password"){
    //             password.type = "text"
    //         }else{
    //             password.type = "password"
    //         }
    //     }

    // },
    toggleshow() {
      this.showPassword = !this.showPassword;
    },
    toggleshow2() {
      this.showPassword2 = !this.showPassword2;
    },
    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },
    changePassword() {
      axios
        .post("change-password", {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_password: this.confirm_password,
        })
        .then(() => {
          // console.log(response.data.data);
        })
        .catch((error) => console.log(error));
    },

    logoutUser() {
      axios.get("logout", {}).then(() => {
        localStorage.clear();
        console.clear();
        this.$router.push("/");
      });
    },
    animatedToggle() {
      let toggle = document.querySelector(".toggle");

      toggle.classList.toggle("active");
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .col-md-5 {
    margin-bottom: 3rem;
  }
}
.row {
  width: 75%;
  margin: 2rem auto;
}

.change1 {
  background: #eb0e19;
  height: 9%;
  /* background: #eb0e19; */
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  /* line-height: 4; */
  margin-top: 0%;
  background: white;
  height: 9%;
  border-bottom: 1px solid lightgray;
}

.change1 h3 {
  padding-left: 3%;
  padding-top: 2%;
  font-size: 127%;
  font-weight: 600;
  color: gray;
}

.change2 {
  width: 50%;
  margin-left: 34%;
  margin-top: 6%;
}

.change3 {
  width: 81%;
  margin-left: 10%;
  margin-top: 3%;
}

.change3 h3 {
  text-align: center;
  font-size: 102%;
  font-weight: 100;
  color: gray;
}

.change4 {
  margin-top: 0%;
}

.change5 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 3%;
  width: 84%;
  margin-left: 6%;
}

.change5 img {
  margin-left: 9%;
  cursor: pointer;
}

.change5 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.change5 label {
  position: absolute;
  top: 42%;
  left: 17px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.change5 span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.change5 input:focus ~ label,
.change5 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.change5 input:focus ~ span::before,
.change5 input:valid ~ span::before {
  width: 100%;
}

.change6 {
  width: 84%;
  margin-top: 10%;
  margin-left: 6%;
}

.change6 button {
  margin-left: 0%;
  width: 104%;
  line-height: 2.5;
  background: #eb0e19;
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: -1%;
  cursor: pointer;
}

.toggle {
  position: absolute;
  width: 2.5%;
  height: 2.5vh;
  background: #ff9ba0;
  border-radius: 30px;
  transition: 0.3s;
  margin-left: 2rem;
  margin-top: -0.6rem;
}

.toggle .toggle-button {
  position: absolute;
  top: 0;
  left: 0%;
  width: 52%;
  height: 2.5vh;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.toggle.active {
  background: #eb0e19;
}

.toggle.active .toggle-button {
  left: 48%;
}

.nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
}

.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}

.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}

.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}

.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}

.select_boxes {
  display: flex;
}

.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist3 {
  transform: scale(1);
}

.main {
  display: flex;
  list-style: none;
  padding: 1rem;
  margin-left: -28%;
  width: 100%;
}

.menu {
  width: 100%;
  /* border: 1px solid; */
  border-radius: 10px;
  box-shadow: 0px 0px 8px #ccc;
}

.menu1 {
  /* height: 8%; */
  background: #eb0e19;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100.1%;
}

.menu1 h3 {
  padding: 5%;
  font-size: 150%;
  color: white;
  font-size: 150%;
  color: white;
}

.menu2 {
  display: flex;
  flex-direction: column;
}

.menu3 {
  background: #e5a0ac21;
  margin-bottom: 3%;
}

.menu3 h4 {
  font-weight: 600;
  color: #0000007a;
  font-size: 116%;
}

.menu3 h5 {
  font-size: 124%;
  font-weight: 100;
  color: #0000007a;
}

.menu3 i {
  font-size: 0.9rem;
  margin-right: 10px;
}

.menu3:hover,
.menu3 h4:hover,
.menu3 h5:hover {
  background: pink;
  color: white;
}

.toggle_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e5a0ac21;
  margin-bottom: 3%;
  height: 6vh;
}

.toggle_menu:hover,
.toggle_menu h4:hover,
.toggle_menu h5:hover {
  background: pink;
  color: white;
}

.toggle_menu h5 {
  font-weight: 100;
  color: #0000007a;
}

.toggle_menu h4 {
  font-weight: 600;
  color: #0000007a;
  font-size: 116%;
}

.profile {
  margin-left: 18.5%;
  /* border: 1px solid black; */
  width: 100%;
  border-radius: 1%;
  background-color: white;
  box-shadow: 0px 0px 4px #d4d4d4;
}

.profile header {
  font-size: 22px;
  color: black;
  text-align: left;
  line-height: 2.6;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 20px;
  user-select: none;
  border-bottom: 1px solid black;
  margin-top: 0px;
}

.upload {
  width: 171px;
  position: relative;
  height: 200px;
  margin-top: 4px;
  margin-left: 40px;
  top: 4rem;
  left: -2rem;
}

.imgp {
  width: 100%;
  border-radius: 50%;
  border: 6px solid #eaeaea;
}

.imgp1 {
  width: 40px;
  background: skyblue;
  border-radius: 50%;
  margin-top: -36%;
  margin-right: -23%;
}

.upload .choose {
  position: absolute;
  top: -27%;
  right: 0;
  height: 40px;
  line-height: 33px;
  /* overflow: hidden; */
  text-align: center;
  cursor: pointer;
}

.upload .choose input[type="file"] {
  position: absolute;
  transform: scale(2);
  opacity: 0;
  width: 21px;
  margin-left: 4px;
  margin-top: -31px;
  cursor: pointer;
}

form {
  margin-left: 40%;
  margin-top: -33%;
}

form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 82%;
  margin-left: 8%;
}

.text input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.form-2 {
  margin-left: -55%;
  margin-top: 10%;
}

form .text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: -10px;
  width: 88%;
}

.text-v input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

form .text-s {
  margin-left: -184px;
  /* margin-top: 25px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 123%;
}

select {
  width: 53%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s2,
.s3 {
  margin-top: 28px;
  margin-left: -9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
  /* width: 100%; */
  left: 16%;
  top: -26%;

  left: 16%;
}

/*.s2 {
   margin-left: 61px; 
  padding: 2px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 100%;
}*/

#dob {
  background: transparent;
  height: 50%;
  position: absolute;
  top: 38%;
  left: -5%;
  /* border: 8px solid black; */
  width: 100%;
  cursor: pointer;
}

.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-left: -54%;
  margin-top: 9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 136%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 11%;
  width: 136%;
  margin-left: -53.5%;
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>
