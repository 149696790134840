<template>
  <div class="dropdown">
    <button
      class="dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      @click="$router.push({name: 'Signin'})"
      style="background: none; border: none"
    >
      <!-- <img src="../assets/dots.svg" width="18" height="18" /> -->
      <i
        class="fa-solid fa-ellipsis"
        style="font-size: 1.2rem; color: black"
      ></i>
    </button>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "list",
  methods: {
  },
};
</script>
<style scoped>
.dropdown-toggle::after {
  display: none;
}
</style>
