<template>
  <button type="button" class="btn-sm ms-1" @click="$router.push({name: 'Signin'})">
    <!-- <img src="../assets/comment.png" width="20" height="20" /> -->
    <!-- <i class="fa-solid fa-comment" style="font-size: 1.5rem; color: black"></i> -->
    <i
      class="fa-regular fa-comment"
      style="font-size: 1.5rem; color: black"
    ></i>
    <h6 style="color: black; cursor: pointer;font-size: 1rem;margin-bottom: 0rem; margin-top: 0.2rem">{{ comments_count }} Comments</h6>
  </button>
</template>

<script>

export default {
  name: "user-comment",
  props: {
    postId: String,
    comments_count: String,
  },
  data() {
    return {
      
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.btn-sm {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  gap: 8px;
}

.btn-sm p {
  font-size: 0.9rem;
}

/* Add custom CSS to style the modal */
.is-active {
  display: block;
}
</style>
