
<template>
  <div class="nav">
    <div class="container-fluid">
      <div class="row">
        <div>
          <img
            src="../assets/mainlogo.png"
            height="40"
            style="margin-top: 0.5%; margin-left: 2%"
          />
        </div>
        <div
          class="searchbar"
          style="margin-left: 9%; margin-top: -3.3%; width: 31%"
        >
          <input type="search" placeholder="Search People pages groups..." />
          <button type="submit">Search</button>
        </div>

        <div class="bar">
          <div class="b1" style="text-align: center">
            <img src="../assets/hand-holding.jpg" width="20" height="25" />
            <h5 style="font-size: 95%; font-weight: 100">Foodfie</h5>
          </div>
          <div class="b2" style="text-align: center; margin-left: 3%">
            <router-link
              to="/Dinein"
              style="text-decoration: none; color: gray"
            >
              <img src="../assets/dine.jpg" width="20" height="25" />
              <h5 style="font-size: 95%; font-weight: 100">Dine-in</h5>
            </router-link>
          </div>
          <div class="b3" style="text-align: center; margin-left: 4.5%">
            <router-link
              to="/notification"
              style="text-decoration: none; color: gray"
            >
              <img src="../assets/notifications.jpg" width="20" height="25" />
              <h5 style="font-size: 95%; font-weight: 100">Notification</h5>
            </router-link>
          </div>
          <div class="b4" style="text-align: center; margin-left: 6.5%">
            <router-link
              to="/setting"
              style="text-decoration: none; color: gray"
            >
              <img src="../assets/setting.jpg" width="20" height="25" />
              <h5 style="font-size: 95%; font-weight: 100">Setting</h5>
            </router-link>
          </div>
          <div
            @click="toggleUp()"
            class="b4"
            style="text-align: center; margin-left: 1.5%"
          >
            <img
              src="../assets/hand-holding.jpg"
              width="20"
              height="25"
              style="margin-left: 20%"
            />
            <h5 style="font-size: 95%; font-weight: 100">Me</h5>
            <img
              src="../assets/arrow.jpg"
              width="10"
              height="8"
              style="margin-top: -105%; margin-left: 65%"
            />
          </div>

          <div
            class="togg3"
            style="
              width: 7%;
              border: 1px solid;
              margin-left: 26%;
              position: absolute;
              background: white;
              border-radius: 10px;
              margin-top: 4%;
            "
          >
            <div>
              <h6
                style="
                  border-bottom: 1px solid;
                  /* width: 83%; */
                  margin-left: 0%;
                  padding-top: 4%;
                  padding-bottom: 3%;
                  padding-left: 4%;
                "
              >
                My Profile
              </h6>
            </div>
            <div>
              <router-link
                to="/editprofile"
                style="text-decoration: none; color: gray"
              >
                <h6
                  style="
                    border-bottom: 1px solid;
                    /* width: 83%; */
                    margin-left: 0%;
                    padding-top: 4%;
                    padding-bottom: 3%;
                    padding-left: 4%;
                  "
                >
                  Edit Profile
                </h6>
              </router-link>
            </div>
            <div @click="logoutUser()">
              <h6
                style="
                  /* border-bottom: 1px solid; */
                  margin-left: 0%;
                  /* padding-top: 4%; */
                  padding-left: 4%;
                  cursor: pointer;
                "
              >
                Sign Out
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home15">
    <img
      src="../assets/man.jpg"
      width="45"
      height="45"
      style="margin-top: 3%; border-radius: 50%"
    />
    <h6 style="margin-top: -8%; margin-left: 10%">Rakesh Prajapati</h6>
    <div class="h2">
      <div class="h3">
        <img src="../assets/clock.png" width="10" height="10" />
        <h6 style="font-size: 40%; margin-left: 2%; margin-top: -0.8%">
          30 min
        </h6>
      </div>
      <div class="h3" style="margin-left: -18%">
        <img src="../assets/location-pin.png" width="10" height="10" />
        <h6 style="font-size: 40%; margin-left: 2%; margin-top: -0.8%">
          Surat city
        </h6>
      </div>
    </div>
    <div class="home9">
      <button @click="toggleShow()">
        <img src="../assets/dots.svg" width="20" height="10" />
      </button>
    </div>
    <div
      class="togg2"
      style="
        width: 7%;
        border: 1px solid;
        margin-left: 26%;
        position: absolute;
        background: white;
        border-radius: 10px;
      "
    >
      <div>
        <h6
          style="
            border-bottom: 1px solid;
            /* width: 83%; */
            margin-left: 0%;
            padding-top: 4%;
            padding-bottom: 3%;
            padding-left: 4%;
          "
        >
          Edit
        </h6>
      </div>
      <div>
        <h6
          style="
            border-bottom: 1px solid;
            /* width: 83%; */
            margin-left: 0%;
            padding-top: 4%;
            padding-bottom: 3%;
            padding-left: 4%;
          "
        >
          Hide
        </h6>
      </div>
      <div>
        <h6
          style="
            /* border-bottom: 1px solid; */
            margin-left: 0%;
            /* padding-top: 4%; */
            padding-left: 4%;
          "
        >
          Copy Link
        </h6>
      </div>
    </div>

    <div class="home10">
      <h5 style="font-size: 80%; color: gray">fssdfs ferfdsaf</h5>
    </div>
    <div>
      <img src="../assets/burger.jpg" width="546" height="400" />
    </div>

    <div class="home11">
      <div class="ho1">
        <button>
          <img src="../assets/like.png" width="25" height="25" />
          <h6 style="font-size: 95%; margin-top: 5%; margin-left: 7%">
            125 Likes
          </h6>
        </button>
      </div>
      <div class="ho1">
        <button>
          <img src="../assets/comment.png" width="25" height="25" />
          <h6 style="font-size: 95%; margin-top: 5%; margin-left: 7%">
            125 Comments
          </h6>
        </button>
      </div>
      <div class="ho1">
        <button>
          <img src="../assets/share.png" width="25" height="25" />
          <h6 style="font-size: 95%; margin-top: 5%; margin-left: 7%">
            125 Share
          </h6>
        </button>
      </div>
    </div>
    <div class="home12">
      <img
        src="../assets/man.jpg"
        width="30"
        height="30"
        style="border-radius: 50%"
      />
      <div class="home13">
        <button
          @click="toggleSecond()"
          style="margin-left: 95%; border: none; background: none"
        >
          <img src="../assets/dots.svg" width="15" height="15" />
        </button>
        <h6
          style="
            margin-left: 70%;
            font-size: 70%;
            margin-top: -3.5%;
            margin-left: 85%;
          "
        >
          4m...
        </h6>
        <h4
          style="
            font-size: 90%;
            padding-left: 1%;
            padding-top: 1%;
            margin-top: -6%;
          "
        >
          Sonal Randeria
        </h4>
        <h6 style="font-size: 60%; margin-top: -1.5%; padding-left: 1%">
          Digital marketing||Social media
        </h6>
        <h6
          style="
            font-size: 55%;
            margin-top: -1%;
            padding-left: 1%;
            padding-bottom: 1%;
          "
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum
          molestias deserunt alias numquam nobis, laudantium, id sed voluptatem
          reiciendis magni libero totam! Eius, id libero? Lorem ipsum, dolor sit
          amet consectetur adipisicing elit.
        </h6>
      </div>
      <h5
        style="
          font-size: 70%;
          border-bottom: 1px solid;
          width: 21%;
          cursor: pointer;
        "
      >
        View more comments
      </h5>
    </div>
    <div
      class="togg5"
      style="
        width: 6%;
        border: 1px solid;
        margin-left: 26%;
        position: absolute;
        background: white;
        border-radius: 10px;
        margin-top: -5%;
      "
    >
      <div>
        <h6
          style="
            border-bottom: 1px solid;
            /* width: 83%; */
            margin-left: 0%;
            padding-top: 4%;
            padding-bottom: 9%;
            padding-left: 4%;
            font-size: 70%;
          "
        >
          My Profile
        </h6>
      </div>
      <div>
        <h6
          style="
            /* width: 83%; */
            margin-left: 0%;
            font-size: 70%;

            padding-left: 4%;
          "
        >
          Setting
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "commentShow",
  methods: {
    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },
    toggleDown() {
      let togg4 = document.querySelector(".togg4");
      togg4.classList.toggle("newlist4");
    },
    toggleSecond() {
      let togg5 = document.querySelector(".togg5");
      togg5.classList.toggle("newlist5");
    },
    toggleShow() {
      let togg2 = document.querySelector(".togg2");
      togg2.classList.toggle("newlist1");
    },
    logoutUser() {
      axios.get("logout", {}).then(() => {
        localStorage.clear();
        console.clear();
        this.$router.push("/");
      });
    },
  },
};
</script>

<style scoped>
.home15 {
  width: 36%;
  margin-left: 32%;
  margin-top: 2%;
  border: 1px solid lightgray;
  height: 92vh;
  border-radius: 6px;
  visibility: visible;
}

.cus-all {
  visibility: hidden;
}

.togg {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist {
  transform: scale(1);
}

.newlist1 {
  transform: scale(1);
}

.h2 {
  display: flex;
  width: 85%;
  margin-left: 10%;
  margin-top: -0.5%;
}

.h3,
.h4 {
  display: flex;
  width: 30%;
}

.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}

.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 7%;
  padding-left: 1%;
}

.home11 {
  display: flex;
  gap: 9%;
  border-bottom: 1.8px solid lightgray;
}

.ho1 button {
  width: 120%;
  display: flex;
  background: none;
  border: none;
}

.ho1 {
  width: 24%;
  margin-top: 1%;
  margin-left: 3%;
}

.home12 {
  margin-top: 5%;
}

.home13 {
  margin-top: -6%;
  width: 85%;
  margin-left: 8%;
  background: rgb(243, 241, 241);
  border-radius: 6px;
}

.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist5 {
  transform: scale(1);
}

.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist1 {
  transform: scale(1);
}
</style>