<template>
  <nav class="navbar">
    <div class="logo">
      <img
        src="../assets/mainlogo.png"
        style="width: 3rem; height: 3rem; cursor: pointer"
        @click="$router.push('/home')"
      />
      <div class="searchbar">
        <input
          type="search"
          class="px-4"
          v-model="userInput"
          @input="globalSearch"
          placeholder="Search People pages groups..."
        />
        <div class="search_list" v-if="userInput != ''">
          <div v-if="loadingFlag" class="bg-white mt-2">
            <div class="text-center no-data-message" style="font-size: 16px">
              Loading...
            </div>
          </div>
          <div
            class="search_list_header"
            v-if="userInput != '' && !loadingFlag"
          >
            <div
              @click="activeTab = 'all'"
              :class="{ active: activeTab === 'all' }"
            >
              All
              <span
                class="underline"
                :class="{ inactive: activeTab !== 'all' }"
              ></span>
            </div>
            <div
              @click="activeTab = 'people'"
              :class="{ active: activeTab === 'people' }"
            >
              People
              <span
                class="underline"
                :class="{ inactive: activeTab !== 'people' }"
              ></span>
            </div>
            <div
              @click="activeTab = 'vendor'"
              :class="{ active: activeTab === 'vendor' }"
            >
              Vendor
              <span
                class="underline"
                :class="{ inactive: activeTab !== 'vendor' }"
              ></span>
            </div>
            <div
              @click="activeTab = 'influencer'"
              :class="{ active: activeTab === 'influencer' }"
            >
              Influencer
              <span
                class="underline"
                :class="{ inactive: activeTab !== 'influencer' }"
              ></span>
            </div>
            <div
              @click="activeTab = 'post'"
              :class="{ active: activeTab === 'post' }"
            >
              Post
              <span
                class="underline"
                :class="{ inactive: activeTab !== 'post' }"
              ></span>
            </div>
          </div>

          <div class="content" v-if="userInput != '' && !loadingFlag">
            <div v-if="activeTab === 'all'" class="tab-content">
              <div class="people_div">
                <div>
                  <div class="people_header">
                    <p>people</p>
                    <p
                      style="color: #ccc; font-size: 0.9rem"
                      @click="showAllPeople = !showAllPeople"
                    >
                      See All
                    </p>
                  </div>
                  <div v-if="filteredResults.length > 0">
                    <div
                      class="people_details"
                      v-for="result in limitedResults"
                      :key="result.id"
                    >
                      <img
                        src="./../assets/cover.png"
                        v-if="result.profile_photo == ''"
                        class="people-details-img"
                      />
                      <img
                        :src="result.profile_photo"
                        v-else
                        class="people-details-img"
                      />
                      <span class="span-tag-margin">{{ result.name }}</span>
                      <button
                        v-if="!isFollowing"
                        class="btn btn-danger"
                        @click="followAction(result.id, searchResults)"
                      >
                        Follow
                      </button>
                      <button
                        v-else
                        class="btn btn-danger"
                        @click="unfollowAction(result.id, searchResults)"
                      >
                        Unfollow
                      </button>
                      <br />
                    </div>
                  </div>
                  <div v-else>
                    <p>No users found.</p>
                  </div>
                  <hr />
                </div>
              </div>

              <!-- vendor_div -->
              <div class="Vendor_div">
                <div class="Vendor_header">
                  <p>Vendor</p>
                  <p
                    style="color: #ccc; font-size: 0.9rem"
                    @click="showAllVendor = !showAllVendor"
                  >
                    See All
                  </p>
                </div>
                <div v-if="VendorData.length > 0">
                  <div
                    class="Vendor_details"
                    v-for="vendor in limitedVendors"
                    :key="vendor.id"
                  >
                    <!-- {{vendor.user.name}} -->
                    <img
                      src="./../assets/cover.png"
                      v-if="vendor.profile_photo == ''"
                      class="people-details-img"
                    />
                    <img
                      :src="vendor.profile_photo"
                      v-else
                      class="people-details-img"
                    />
                    <span class="span-tag-margin">{{ vendor.user.name }}</span>
                    <!-- <span style="margin: 0 auto; margin-left: 2.5rem;">{{ result.country.name }}</span> -->
                    <button
                      v-if="!isFollowing"
                      class="btn btn-danger"
                      @click="followAction(vendor.user_id, VendorData)"
                    >
                      Follow
                    </button>
                    <!-- Show "unfollow" button if following -->
                    <button
                      v-else
                      class="btn btn-danger"
                      @click="unfollowAction(vendor.user_id, VendorData)"
                    >
                      Unfollow
                    </button>
                  </div>
                </div>
                <div v-else>
                  <p>No vendors found.</p>
                </div>
                <hr />
              </div>

              <!-- Influencer_div -->

              <div class="Influencer_div">
                <div class="Influencer_header">
                  <p>Influencer</p>
                  <p
                    style="color: #ccc; font-size: 0.9rem"
                    @click="showAllInfluencer = !showAllInfluencer"
                  >
                    See All
                  </p>
                </div>
                <div
                  class="Influencer_details"
                  v-for="influencer in limitedInfluencers"
                  :key="influencer.id"
                >
                  <img
                    src="./../assets/cover.png"
                    v-if="influencer.user.profile_photo == ''"
                    class="people-details-img"
                  />
                  <img
                    :src="influencer.user.profile_photo"
                    v-else
                    class="people-details-img"
                  />
                  <span class="span-tag-margin">{{
                    influencer.user.name
                  }}</span>
                  <!-- <span style="margin: 0 auto; margin-left: 2.5rem;">{{ result.country.name }}</span> -->
                  <button
                    v-if="!isFollowing"
                    class="btn btn-danger"
                    @click="followAction(influencer.user_id, InfluencerData)"
                  >
                    Follow
                  </button>
                  <!-- Show "unfollow" button if following -->
                  <button
                    v-else
                    class="btn btn-danger"
                    @click="unfollowAction(influencer.user_id, InfluencerData)"
                  >
                    Unfollow
                  </button>
                </div>
                <hr />
              </div>

              <!-- Post_div -->
              <div class="Post_div">
                <div class="Post_header">
                  <p>Post</p>
                  <p
                    style="color: #ccc; font-size: 0.9rem"
                    @click="showAllPost = !showAllPost"
                  >
                    See All
                  </p>
                </div>
                <!-- <div class="Post_details" v-for="post in PostData" :key="post.id"></div> -->
                <div
                  class="home8"
                  id="home8"
                  v-for="post in limitedPosts"
                  :key="post.id"
                >
                  <div class="user_details">
                    <!-- {{post}} -->
                    <img
                      src="./../assets/cover.png"
                      style="width: 3rem; height: 2.5rem; border-radius: 50%"
                      v-if="post.user.profile_photo === ''"
                    />
                    <img
                      :src="post.user.profile_photo"
                      style="width: 3rem; height: 2.5rem; border-radius: 50%"
                      v-else
                    />
                    <div class="w-100 ms-1 d-flex flex-column">
                      <div class="mt-1">
                        <!-- <h6>{{ post.user.name }} {{ post.images[0].post_id }}</h6> -->
                        <h6 class="mb-0 ms-2" style="font-size: 1.1rem">
                          {{ post.user.name }} {{ post.images[0].post_id }}
                        </h6>
                      </div>
                      <div
                        class="h3 d-flex align-items-center ms-1"
                        style="margin-top: 0.1rem"
                      >
                        <!-- <img src="../assets/clock.png" width="10" height="10" /> -->
                        <i
                          class="fa-regular fa-clock"
                          style="
                            font-size: 0.6rem;
                            color: black;
                            margin-top: 0.2rem;
                          "
                        ></i>
                        <h6
                          class="ms-1"
                          style="
                            margin-bottom: 0;
                            margin-top: 0.1rem;
                            font-size: 0.8rem;
                          "
                        >
                          {{ moment(post.created_at).fromNow() }}
                        </h6>
                      </div>
                    </div>

                    <!-- <div style="width: 100%">
                      <h6>{{ post.user.name }} {{ post.images[0].post_id }}</h6>
                    </div> -->
                    <list :postId="post.id" />
                  </div>
                  <!-- <div class="h2">
                    <div class="h3">
                      <img src="../assets/clock.png" width="10" height="10" />
                      <h6
                        style="
                          font-size: 40%;
                          margin-left: 2%;
                          margin-top: -0.8%;
                        "
                      >
                        {{ moment(post.images[0].created_at).fromNow() }}
                      </h6>
                    </div>
                  </div> -->

                  <div class="home10">
                    <h5 style="font-size: 80%; color: gray">
                      {{ post.description }}
                    </h5>
                  </div>
                  <div>
                    <img
                      :src="post.images[0].image"
                      style="width: 100%; height: 280px"
                    />
                  </div>
                  <div
                    class="home11"
                    style="border-bottom: 1px solid lightgray"
                  >
                    <div class="ho1">
                      <userlike
                        :postId="post.id"
                        :post-id="post.id"
                        :likes_count="post.likes_count"
                        :is_liked="post.is_liked"
                      />
                      <usercomment
                        :postId="post.id"
                        :comments_count="post.comments_count"
                      />
                      <usershare
                        :postId="post.id"
                        :shares_count="post.shares_count"
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>

            <!-- Tab_Content -->
            <div v-if="activeTab === 'people'" class="tab-content">
              <div class="people_div">
                <div>
                  <div class="people_header">
                    <p>people</p>
                    <p
                      style="color: #ccc; font-size: 0.9rem"
                      @click="showAllPeople = !showAllPeople"
                    >
                      See All
                    </p>
                  </div>
                  <div v-if="filteredResults.length > 0">
                    <div
                      class="people_details"
                      v-for="result in limitedResults"
                      :key="result.id"
                    >
                      <img
                        src="./../assets/cover.png"
                        v-if="result.profile_photo == ''"
                        class="people-details-img"
                      />
                      <img
                        :src="result.profile_photo"
                        v-else
                        class="people-details-img"
                      />
                      <span class="span-tag-margin">{{ result.name }}</span>
                      <button
                        v-if="!isFollowing"
                        class="btn btn-danger"
                        @click="followAction(result.id, searchResults)"
                      >
                        Follow
                      </button>
                      <button
                        v-else
                        class="btn btn-danger"
                        @click="unfollowAction(result.id, searchResults)"
                      >
                        Unfollow
                      </button>
                      <br />
                    </div>
                  </div>
                  <div v-else>
                    <p>No users found.</p>
                  </div>
                  <hr />
                </div>
              </div>
            </div>

            <div v-if="activeTab === 'vendor'" class="tab-content">
              <div class="Vendor_div">
                <div class="Vendor_header">
                  <p>Vendor</p>
                  <p
                    style="color: #ccc; font-size: 0.9rem"
                    @click="showAllVendor = !showAllVendor"
                  >
                    See All
                  </p>
                </div>
                <div v-if="VendorData.length > 0">
                  <div
                    class="Vendor_details"
                    v-for="vendor in limitedVendors"
                    :key="vendor.id"
                  >
                    <!-- {{vendor.user.name}} -->
                    <img
                      src="./../assets/cover.png"
                      v-if="vendor.profile_photo == ''"
                      class="people-details-img"
                    />
                    <img
                      :src="vendor.profile_photo"
                      v-else
                      class="people-details-img"
                    />
                    <span class="span-tag-margin">{{ vendor.user.name }}</span>
                    <!-- <span style="margin: 0 auto; margin-left: 2.5rem;">{{ result.country.name }}</span> -->
                    <button
                      v-if="!isFollowing"
                      class="btn btn-danger"
                      @click="followAction(vendor.user_id, VendorData)"
                    >
                      Follow
                    </button>
                    <!-- Show "unfollow" button if following -->
                    <button
                      v-else
                      class="btn btn-danger"
                      @click="unfollowAction(vendor.user_id, VendorData)"
                    >
                      Unfollow
                    </button>
                  </div>
                </div>
                <div v-else>
                  <p>No vendors found.</p>
                </div>
                <hr />
              </div>
            </div>

            <div v-if="activeTab === 'influencer'" class="tab-content">
              <div class="Influencer_div">
                <div class="Influencer_header">
                  <p>Influencer</p>
                  <p
                    style="color: #ccc; font-size: 0.9rem"
                    @click="showAllInfluencer = !showAllInfluencer"
                  >
                    See All
                  </p>
                </div>
                <div
                  class="Influencer_details"
                  v-for="influencer in limitedInfluencers"
                  :key="influencer.id"
                >
                  <img
                    src="./../assets/cover.png"
                    v-if="influencer.user.profile_photo == ''"
                    class="people-details-img"
                  />
                  <img
                    :src="influencer.user.profile_photo"
                    v-else
                    class="people-details-img"
                  />
                  <span class="span-tag-margin">{{
                    influencer.user.name
                  }}</span>
                  <!-- <span style="margin: 0 auto; margin-left: 2.5rem;">{{ result.country.name }}</span> -->
                  <button
                    v-if="!isFollowing"
                    class="btn btn-danger"
                    @click="followAction(influencer.user_id, InfluencerData)"
                  >
                    Follow
                  </button>
                  <!-- Show "unfollow" button if following -->
                  <button
                    v-else
                    class="btn btn-danger"
                    @click="unfollowAction(influencer.user_id, InfluencerData)"
                  >
                    Unfollow
                  </button>
                </div>
                <hr />
              </div>
            </div>

            <div v-if="activeTab === 'post'" class="tab-content">
              <div class="Post_div">
                <div class="Post_header">
                  <p>Post</p>
                  <p
                    style="color: #ccc; font-size: 0.9rem"
                    @click="showAllPost = !showAllPost"
                  >
                    See All
                  </p>
                </div>
                <!-- <div class="Post_details" v-for="post in PostData" :key="post.id"></div> -->
                <div
                  class="home8"
                  id="home8"
                  style="mamountergin-bottom: 37%"
                  v-for="post in limitedPosts"
                  :key="post.id"
                >
                  <div class="user_details">
                    <!-- {{post}} -->
                    <img
                      src="./../assets/cover.png"
                      style="width: 3rem; height: 2.5rem; border-radius: 50%"
                      v-if="post.user.profile_photo === ''"
                    />
                    <img
                      :src="post.user.profile_photo"
                      style="width: 3rem; height: 2.5rem; border-radius: 50%"
                      v-else
                    />
                    <div class="w-100 ms-1 d-flex flex-column">
                      <div class="mt-1">
                        <!-- <h6>{{ post.user.name }} {{ post.images[0].post_id }}</h6> -->
                        <h6 class="mb-0 ms-2" style="font-size: 1.1rem">
                          {{ post.user.name }} {{ post.images[0].post_id }}
                        </h6>
                      </div>
                      <div
                        class="h3 d-flex align-items-center ms-1"
                        style="margin-top: 0.1rem"
                      >
                        <!-- <img src="../assets/clock.png" width="10" height="10" /> -->
                        <i
                          class="fa-regular fa-clock"
                          style="
                            font-size: 0.6rem;
                            color: black;
                            margin-top: 0.2rem;
                          "
                        ></i>
                        <h6
                          class="ms-1"
                          style="
                            margin-bottom: 0;
                            margin-top: 0.1rem;
                            font-size: 0.8rem;
                          "
                        >
                          {{ moment(post.created_at).fromNow() }}
                        </h6>
                      </div>
                    </div>

                    <!-- <div style="width: 100%">
                      <h6>{{ post.user.name }} {{ post.images[0].post_id }}</h6>
                    </div> -->
                    <list :postId="post.id" />
                  </div>
                  <!-- <div class="h2">
                    <div class="h3">
                      <img src="../assets/clock.png" width="10" height="10" />
                      <h6
                        style="
                          font-size: 40%;
                          margin-left: 2%;
                          margin-top: -0.8%;
                        "
                      >
                        {{ moment(post.images[0].created_at).fromNow() }}
                      </h6>
                    </div>
                  </div> -->

                  <div class="home10">
                    <h5 style="font-size: 80%; color: gray">
                      {{ post.description }}
                    </h5>
                  </div>
                  <div>
                    <img
                      :src="post.images[0].image"
                      style="width: 100%; height: 280px"
                    />
                  </div>
                  <div
                    class="home11"
                    style="border-bottom: 1px solid lightgray"
                  >
                    <div class="ho1">
                      <userlike
                        :postId="post.id"
                        :post-id="post.id"
                        :likes_count="post.likes_count"
                        :is_liked="post.is_liked"
                      />
                      <usercomment
                        :postId="post.id"
                        :comments_count="post.comments_count"
                      />
                      <usershare
                        :postId="post.id"
                        :shares_count="post.shares_count"
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <button class="search_btn" type="buton" @click="globalSearch"></button>
        <!-- <button class="search_btn" type="buton" v-if="userResults.length > 3" @click="showAllResults = !showAllResults">
          {{ showAllResults ? 'See Less' : 'See All' }}
        </button> -->
      </div>
    </div>

    <div class="profile_top_nav" @click="toggleToolTip">
      <img
        v-if="userProfile.profile_photo"
        :src="userProfile.profile_photo"
        alt="Image"
        style="width: 2.5rem; height: 2.5rem; border-radius: 50%"
      />
      <img
        v-else
        src="../assets/man.jpg"
        style="width: 2.5rem; height: 2.5rem; border-radius: 50%"
      />
      <div class="menu-tooltip" v-if="istooltipOpen">
        <ul>
          <router-link
            to="/profile/post"
            style="text-decoration: none; color: #000; font-size: 0.7rem"
          >
            <li>My Account</li>
          </router-link>
          <hr />
          <router-link
            to="/menuList/editprofile"
            style="text-decoration: none; color: #000; font-size: 0.7rem"
          >
            <li>Edit</li>
          </router-link>
          <hr />
          <router-link
            to="/"
            style="text-decoration: none; color: #000; font-size: 0.7rem"
          >
            <li>Logout</li>
          </router-link>
        </ul>
      </div>
    </div>

    <ul class="nav-links" :style="{ bottom: slideDownPosition }">
      <li>
        <router-link
          to="/home"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <div class="nav_link_img">
            <img
              v-if="$route.path === '/home'"
              class="foodfie_logo"
              src="./../assets/hand-holding.jpg"
              width="21"
              height="22"
            />
            <img
              v-if="$route.path !== '/home'"
              src="../assets/Group5008.png"
              width="21"
              height="22"
            />
          </div>
          <div class="link-name">FoodFie</div>
        </router-link>
      </li>
      <li>
        <router-link
          to="/Dinein"
          exact-active-class="active-link"
          active-class="inactive-link"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <img
            v-if="$route.path !== '/Dinein'"
            src="../assets/dine.jpg"
            width="24"
            height="22"
          />
          <img
            v-if="$route.path === '/Dinein'"
            src="../assets/dinein_2.png"
            width="24"
            height="22"
          />
          <div class="link-name">Dine in</div>
        </router-link>
      </li>
      <li>
        <router-link
          to="/notification"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <img
            v-if="$route.path !== '/notification'"
            src="../assets/notifications.jpg"
            width="19"
            height="22"
          />
          <img
            v-if="$route.path === '/notification'"
            src="../assets/notifications_1.png"
            width="19"
            height="22"
          />
          <div class="link-name">Notification</div>
        </router-link>
      </li>
      <li>
        <router-link
          to="/IBTalks"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <img
            v-if="$route.path !== '/IBTalks'"
            src="../assets/microphone-342.svg"
            width="20"
            height="22"
          />
          <img
            v-if="$route.path === '/IBTalks'"
            src="../assets/microphone-342_selected.svg"
            width="20"
            height="22"
          />
          <div class="link-name">IB Talks</div>
        </router-link>
      </li>
      <li>
        <router-link
          to="/AllBlogs"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <img
            v-if="
              $route.path !== '/AllBlogs' && !$route.path.startsWith('/blogs')
            "
            src="../assets/blogger.svg"
            width="19"
            height="22"
          />
          <img
            v-if="
              $route.path === '/AllBlogs' || $route.path.startsWith('/blogs')
            "
            src="../assets/blogger_selected.svg"
            width="19"
            height="22"
          />
          <div class="link-name">Blogs</div>
        </router-link>
      </li>
      <li>
        <router-link
          to="/tutorialPage"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <img
            v-if="$route.path !== '/tutorialPage'"
            src="../assets/tutorial_icon_unselected.svg"
            width="25"
            height="22"
          />
          <img
            v-if="$route.path === '/tutorialPage'"
            src="../assets/tutorial_icon_selected.svg"
            width="25"
            height="22"
          />
          <div class="link-name">Tutorials</div>
        </router-link>
      </li>
      <li>
        <router-link
          to="/setting/Changepassword"
          style="text-decoration: none; color: gray"
          class="nav_link"
        >
          <img
            v-if="$route.path.startsWith('/setting')"
            src="../assets/settings_1.png"
            width="22"
            height="22"
          />
          <img v-else src="../assets/setting.jpg" width="22" height="22" />
          <div class="link-name">Setting</div>
        </router-link>
      </li>
      <li id="me-li-item">
        <a href="#" class="nav_link_profile" @click="toggleToolTip">
          <img
            :src="
              userProfile.profile_photo
                ? userProfile.profile_photo
                : require('../assets/cover.png')
            "
            style="
              width: 1.5rem;
              height: 1.4rem;
              object-fit: cover;
              border-radius: 50%;
            "
          />
          <div>
            <span>Me</span>
            <img src="./../assets/arrow.jpg" width="10" />
          </div>

          <div class="menu-tooltip" v-if="istooltipOpen">
            <ul>
              <router-link to="/profile/post">
                <li>My Account</li>
              </router-link>
              <hr />
              <router-link to="/menuList/editprofile">
                <li>Edit</li>
              </router-link>
              <hr />
              <router-link to="/" @click="logout">
                <li>Logout</li>
              </router-link>
            </ul>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";
import moment from "moment";
import userlike from "./user-like.vue";
import usercomment from "./user-comment.vue";
import usershare from "./user-share.vue";
import list from "./list.vue";

export default {
  name: "NavBar",
  components: { userlike, usercomment, usershare, list },
  data() {
    return {
      isMenuOpen: false,
      istooltipOpen: false,
      isNavVisible: false,
      slideDownPosition: "100%",
      prevScrollY: -1,
      userInput: "",
      searchResults: [],
      PeopleData: "",
      PostData: "",
      VendorData: "",
      InfluencerData: "",
      activeTab: "all",
      isFollowing: false,
      ShowComments: [],
      moment: moment,
      posts: [],
      showAllPeople: false,
      showAllVendor: false,
      showAllInfluencer: false,
      showAllPost: false,
      followingUsers: [],
      loadingFlag: false,

      userProfile: {},
    };
  },

  computed: {
    // Computed property to determine whether the user is following
    isFollowingStatus(userId) {
      // return this.isFollowing;
      return this.followingUsers.includes(userId);
    },
    filteredResults() {
      if (this.activeTab === "all" && this.userInput) {
        return this.searchResults.filter((result) =>
          result.name.toLowerCase().includes(this.userInput.toLowerCase())
        );
      } else if (this.activeTab === "people" && this.userInput) {
        return this.searchResults.filter((result) =>
          result.name.toLowerCase().includes(this.userInput.toLowerCase())
        );
      } else {
        return this.searchResults;
      }
    },

    limitedResults() {
      if (this.activeTab === "all" && !this.showAllPeople) {
        return this.filteredResults.slice(0, 2);
      } else if (this.activeTab === "people" && !this.showAllPeople) {
        return this.filteredResults.slice(0, 2);
      } else {
        return this.filteredResults;
      }
    },

    limitedVendors() {
      if (this.activeTab === "all" && !this.showAllVendor) {
        return this.VendorData.slice(0, 2);
      } else if (this.activeTab === "vendor" && !this.showAllVendor) {
        return this.VendorData.slice(0, 2);
      } else {
        return this.VendorData;
      }
    },

    limitedInfluencers() {
      if (this.activeTab === "all" && !this.showAllInfluencer) {
        return this.InfluencerData.slice(0, 2);
      } else if (this.activeTab === "influencer" && !this.showAllInfluencer) {
        return this.InfluencerData.slice(0, 2);
      } else {
        return this.InfluencerData;
      }
    },

    limitedPosts() {
      if (this.activeTab === "all" && !this.showAllPost) {
        return this.PostData.slice(0, 2);
      } else if (this.activeTab === "post" && !this.showAllPost) {
        return this.PostData.slice(0, 2);
      } else {
        return this.PostData;
      }
    },
  },

  watch: {
    activeTab: function (newValue) {
      if ((newValue !== "people" && newValue !== "all") || this.showAllPeople) {
        this.showAllPeople = false;
      }
      if ((newValue !== "vendor" && newValue !== "all") || this.showAllVendor) {
        this.showAllVendor = false;
      }

      if (
        (newValue !== "influencer" && newValue !== "all") ||
        this.showAllInfluencer
      ) {
        this.showAllInfluencer = false;
      }

      if ((newValue !== "post" && newValue !== "all") || this.showAllPost) {
        this.showAllPost = false;
      }
    },
    showAllPeople: function (newValue) {
      if (newValue && this.activeTab !== "people" && this.activeTab !== "all") {
        this.showAllPeople = false;
      }
    },
    showAllVendor: function (newValue) {
      if (newValue && this.activeTab !== "vendor" && this.activeTab !== "all") {
        this.showAllPeople = false;
      }
    },
    showAllInfluencer: function (newValue) {
      if (
        newValue &&
        this.activeTab !== "influencer" &&
        this.activeTab !== "all"
      ) {
        this.showAllPeople = false;
      }
    },
    showAllPost: function (newValue) {
      if (newValue && this.activeTab !== "post" && this.activeTab !== "all") {
        this.showAllPeople = false;
      }
    },
  },

  mounted() {
    this.getUserProfile();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    getUserProfile() {
      axios.get("customers/my-profile").then((res) => {
        // console.log(res.data.data);
        this.userProfile = res.data.data;
        // localStorage.setItem("id", res.data.data.business_category_id);

        // console.log("User Data: ", this.userProfile);
      });
    },
    // toggleMenu() {
    //   this.isMenuOpen = !this.isMenuOpen;
    // },
    toggleToolTip() {
      this.istooltipOpen = !this.istooltipOpen;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      let togg = document.querySelector(".togg");
      togg.classList.toggle("newlist");
    },

    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },
    toggleDown() {
      let togg4 = document.querySelector(".togg4");
      togg4.classList.toggle("newlist4");
    },
    toggleSecond() {
      let togg5 = document.querySelector(".togg5");
      togg5.classList.toggle("newlist5");
    },
    postClick() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.add("postpop");
    },
    closePost() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.remove("postpop");
    },
    tagPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.add("tagpop");
    },
    returnPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.remove("tagpop");
    },

    handleScroll() {
      const currentScrollY = window.scrollY;
      // console.log("Current Scroll Y:", currentScrollY);
      if (currentScrollY > this.prevScrollY) {
        // Scrolling down
        this.slideDownPosition = "100%"; // Slide down
      } else {
        // Scrolling up
        this.slideDownPosition = "-2%"; // Slide up
      }

      this.prevScrollY = currentScrollY;
    },

    globalSearch() {
      this.loadingFlag = true;
      axios
        .post(`global-search?search=${this.userInput}`)
        .then((res) => {
          // console.log(res.data.data);
          this.loadingFlag = false;
          this.searchResults = res.data.data.users;
          this.PeopleData = res.data.data.users;
          this.VendorData = res.data.data.vendors;
          this.PostData = res.data.data.posts;
          this.InfluencerData = res.data.data.influencers;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    followAction(id, tabData) {
      // Make an API call to follow the item
      axios
        .post("customers/follows/follow", {
          followee_id: id,
          // You can add any additional data required for the API call
        })
        .then((res) => {
          // Handle the response if needed
          alert(res);
          // console.log("Follow action called with id:", id, "and tabData:", tabData);

          // Set isFollowing to true after a successful follow
          this.isFollowing = true;
          this.followingUsers.push(id);

          // Update the isFollowing status for all users in the tabData
          tabData.forEach((user) => {
            user.isFollowing = true;
          });
        })
        .catch((error) => {
          // Handle API call errors
          console.error("Error following:", error);
        });
    },
    unfollowAction(id, tabData) {
      // Make an API call to unfollow the item
      axios
        .post("customers/follows/unfollow", {
          followee_id: id,
          // You can add any additional data required for the API call
        })
        .then((res) => {
          // Handle the response if needed
          alert(res);
          // console.log("Unfollow action called with id:", id, "and tabData:", tabData);

          // Set isFollowing to false after a successful unfollow
          const index = this.followingUsers.indexOf(id);
          if (index !== -1) {
            this.followingUsers.splice(index, 1);
            this.isFollowing = false;
          }

          // Update the isFollowing status for all users in the tabData
          tabData.forEach((user) => {
            user.isFollowing = false;
          });
        })
        .catch((error) => {
          // Handle API call errors
          console.error("Error unfollowing:", error);
        });
    },

    // Add methods and data for other tabs as needed

    // Add methods and data for other tabs as needed
    logout() {
      localStorage.clear();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  color: #333;
  border-bottom: 1px solid hotpink;
  margin-bottom: 0.9rem;
  padding: 0rem;
  /* position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 1; */
}

.logo {
  display: flex;
  font-size: 1.5rem;
  gap: 0.9rem;
}

.menu-toggle {
  /* display: none; */
  flex-direction: column;
  cursor: pointer;
}

.profile_top_nav {
  display: none;
}

/* .icon-bar {
  width: 25px;
  height: 3px;
  background-color: white;
} */

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 1rem;
  text-align: center;
  align-items: center;
}

.nav-links li a {
  text-decoration: none;
  color: #333;
}

/* .nav-links li a:hover {
  text-decoration: underline;
} */

.nav_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.nav_link_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-name {
  margin-top: auto;
}
.searchbar {
  display: flex;
}

.search_list {
  display: block;
  position: absolute;
  margin: 0;
  top: 83%;
  box-shadow: 0px 0px 5px #ccc;
  padding: 1%;
  z-index: 2;
  background: #fff;
  width: 50%;
  border-radius: 0.5rem;
}

@media (min-width: 1159px) {
  .search_list {
    width: 35%;
  }
}

.search_list_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-align: -webkit-center;
}

/* people_div */
/* .people_div {
  width: 133%;
} */
.people_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.people_details,
.Influencer_details,
.Post_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
}

.people-details-img {
  border-radius: 3rem;
  height: 53px;
  width: 53px;
}

.Vendor_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
}

.span-tag-margin {
  margin: 0 auto;
  margin-left: 2.5rem;
}

/* .underline {
  width: 108%;
  height: 6px;
  background-color: #ccc;
  display: block;
  border-radius: 5rem;
} */
.inactive {
  width: 100%;
  height: 6px;
  background-color: #ccc;
  display: block;
  border-radius: 5rem;
}

.active {
  color: red;
  /* Text color when active */
  border: 1px solid red;
  /* Border when active */
  padding: 1%;
  background-color: #fff;
  border-radius: 0.5rem;
}

.people_header p {
  margin-bottom: 0;
  padding: 1%;
}

.Vendor_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Vendor_header p {
  margin-bottom: 0;
  padding: 1%;
}

.Influencer_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Influencer_header p {
  margin-bottom: 0;
  padding: 1%;
}

.Post_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Post_header p {
  margin-bottom: 0;
  padding: 1%;
}

.searchbar input {
  /* height: 4.7vh; */
  /* width: 240px; */
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
  width: 21vmax;
}

.searchbar .search_btn {
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
}

.menu-tooltip {
  position: absolute;
  left: 80%;
  /* top: 0; */
  background-color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 0.5rem;
  z-index: 1;
}

.menu-tooltip ul {
  padding: 1rem;
  text-align: left;
  text-decoration: none;
  list-style-type: none;
}

.tab-content {
  cursor: pointer;
}

.home8 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  /* width: 56vmax; */
  max-width: 130%;
  width: 100%;
}

.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist3 {
  transform: scale(1);
}

.togg4 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist4 {
  transform: scale(1);
}

.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist5 {
  transform: scale(1);
}

.user_details {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 2%;
}

.h3,
.h4 {
  margin-left: 2rem;
  display: flex;
  width: 22%;
  justify-content: center;
  align-items: baseline;
  margin-top: -1rem;
}

.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}

.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 2%;
  margin-bottom: 1%;
  padding-left: 1%;
}

.ho1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1%;
  gap: 3%;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 0rem 0.6rem;
}

.home16 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0% 1% 0%;
  /* margin-left: 25%; */
  border: 1px solid lightgray;
  margin-top: 1.5%;

  border-radius: 6px;
  margin-bottom: 26%;
  background-color: #fff;
  padding: 0% 3% 0%;
  flex-wrap: wrap;
}

.home17 {
  width: 27%;
  border: 1px solid;
  margin: 3%;
  margin-left: -1%;
  padding: 1%;
}

.home17 img {
  width: 100%;
  padding: 0.2rem;
}

.home17 button {
  margin: 1%;
  font-size: 80%;
  line-height: 1.8;
  width: 100%;
  background: #eb0e19;
  border: none;
  color: white;
  border-radius: 6px;
  font-size: 74%;
}

.home19,
.home21 {
  width: 154%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
}

.home20 {
  border-top: 1px solid;
  margin-top: 7%;
}

@media only screen and (max-width: 992px) {
  .navbar {
    justify-content: space-evenly;
  }

  .logo {
    width: 25%;
  }

  .searchbar input {
    width: 100%;
  }

  .nav-links {
    width: fit-content;
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 794px) {
  nav ul {
    padding-left: 0rem;
  }
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }
  .menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  /* .nav-links {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 2%;
    z-index: 1;
    box-shadow: 0px 0px 7px #ccc;
    justify-content: space-around;
    align-items: center;
    overflow-x: auto;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-top: 1px solid hotpink;
  } */
  .nav-links {
    position: fixed;
    bottom: -1%;
    /* left: -3%; */
    background-color: #fff;
    width: 100%;
    padding-top: 2%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1.5rem;
    margin: 0 0.1rem;
    z-index: 1;
    box-shadow: 0px 0px 7px #ccc;
    justify-content: space-between;
    align-items: center;
    /* overflow-x: auto; */
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    white-space: nowrap;
    border-top: 2px solid hotpink;
    gap: 0;
    /* transition: 1.3s ease-in-out; */
  }

  .nav-links li {
    width: 23%;
  }

  .nav-links.active {
    display: flex;
  }

  /* .show {
    bottom: -7%;
  } */
  /* .nav-links li {
    margin-bottom: 0.5rem;
  } */
  .searchbar {
    display: none;
  }

  .menu-tooltip {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 0.5rem;
    z-index: 1;
    width: 20%;
    font-size: 46%;
    top: 82%;
    left: 76%;
  }

  .menu-tooltip ul {
    padding: 0.6rem;
    text-align: left;
    margin-bottom: 0;
  }

  .nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    /* overflow: hidden; */
  }

  /* .nav_link img {
    width: 40%;
    aspect-ratio: 3/2;
    object-fit: contain;
  } */
  .nav_link_profile {
    display: none;
    /* overflow: hidden; */
  }

  #me-li-item {
    display: none;
  }

  .logo {
    width: auto;
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1rem;
  }

  .profile_top_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .nav-links {
    padding-bottom: 2rem;
  }

  .link-name {
    display: none;
  }

  .menu-tooltip {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 0.5rem;
    z-index: 1;
    width: 30%;
    font-size: 46%;
    top: 82%;
    left: 65%;
  }

  .menu-tooltip ul {
    padding: 0.6rem;
    text-align: left;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 320px) {
  .menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .nav-links {
    position: fixed;
    bottom: -3%;
    /* left: -5%; */
    background-color: #fff;
    /*width: 100%;
    padding: 2% 5% 1% 4%;*/
    width: 100%;
    padding-top: 2%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;
    z-index: 1;
    box-shadow: 0px 0px 7px #ccc;
    justify-content: space-between;
    align-items: center;
    /* overflow-x: auto; */
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    white-space: nowrap;
    border-top: 2px solid hotpink;
    gap: 1%;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    width: 50%;
  }

  .searchbar {
    display: none;
  }

  .menu-tooltip {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 0.5rem;
    z-index: 1;
    width: 17%;
    font-size: 46%;
    top: 82%;
    left: 79%;
  }

  .menu-tooltip ul {
    padding: 0.3rem;
    text-align: left;
    margin-bottom: 0;
  }

  .nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: fit-content;
    /* overflow: hidden; */
  }

  /* .nav_link img {
    width: 40%;
    aspect-ratio: 3/2;
    object-fit: contain;
  } */
  .nav_link_profile {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    /* overflow: hidden; */
  }

  .logo {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 2.1rem;
  }

  .profile_top_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>