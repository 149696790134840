<template>
  <div class="home19">
    <header class="ms-2">Follow Request</header>
    <hr />
    <div class="home19_user_detials" v-if="userData.length > 0">
      <img
        :src="userData.profile_photo || defaultImage"
        width="35"
        height="35"
      />
      <div class="user_names">
        <!-- <h6>{{ userData[userData.length - 1].first_name }}</h6> -->
        <h6>
          {{ userData[0].first_name ? userData[0].first_name : "Random User" }}
        </h6>
        <h6>mutual friends</h6>
      </div>
    </div>

    <div
      class="user_action_btn mt-4 d-flex align-items-center justify-content-evenly"
      v-if="userData.length > 0"
    >
      <button @click="$router.push({ name: 'Signin' })">Confirm</button>
      <button
        @click="$router.push({ name: 'Signin' })"
        style="background-color: #ccc"
      >
        Decline
      </button>
    </div>

    <div class="d-flex align-items-center justify-content-center my-2">
      <h3 class="my-auto text-muted" style="font-size: 0.9rem">
        No Follow Request Foud
      </h3>
    </div>

    <div>
      <hr />
    </div>

    <div class="tag_checkin_btn">
      <div class="d-flex align-items-center" v-if="followRequests.length > 0">
        <img
          v-for="request in followRequests.slice(0, 2)"
          :key="request.id"
          :src="request.profile_photo || defaultImage"
          width="35"
          height="35"
          style="border-radius: 50%"
        />
        <div
          v-if="followRequests.length > 2"
          class="more-users-count"
          style="color: #eb0e19"
        >
          +{{ followRequests.length - 2 }}
        </div>
        <div
          v-if="followRequests.length == 1"
          class="more-users-count fs-6"
          style="color: #eb0e19"
        >
          +1
        </div>
      </div>
      <button type="button" class="btn" style="padding: 0 0">
        <span @click="$router.push({ name: 'Signin' })"
          >View Friend's Requests</span
        >
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      defaultImage: require("@/assets/cover.png"),
      followRequests: [],
      userData: [],
      // apiUrl: "http://uatserver.redprix.com/public/api/",
      // apiUrl: "https://api.redprix.com/api/",
    };
  },

  created() {
    this.loadUserData();
    this.loadFollowRequests();
  },

  methods: {
    loadUserData() {
      axios
        .get(`customers/follows/my-follow-requests`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.userData = response.data.data || [];
            // console.log("userData:", this.userData); // Debug statement
          } else {
            console.error("Invalid response structure for user data.");
          }
        })
        .catch((error) => {
          console.error("Error loading user data:", error);
        });
    },

    loadFollowRequests() {
      axios
        .get(`customers/follows/my-follow-requests`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          if (response.data && Array.isArray(response.data.data)) {
            this.followRequests = response.data.data;
            this.followRequests.forEach((request) => {
              request.showConfirm = true;
              request.isProcessing = false; // Initialize isProcessing
            });
          } else {
            console.error("Invalid response structure.");
          }
        })
        .catch((error) => {
          console.error("Error loading follow requests:", error);
        });
    },
  },
};
</script>

<style scoped>
.home19 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
  padding: 0.5rem;
}

.home19_user_detials {
  display: flex;
  align-items: center;
  gap: 2%;
}

.home19_user_detials img {
  border-radius: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .home19_user_detials {
    flex-direction: column;
    justify-content: center;
  }
}

.user_action_btn button {
  font-size: 87%;
  width: 40%;
  color: white;
  background: #eb0e19;
  border: none;
  line-height: 1.8;
  border-radius: 6px;
}

.tag_checkin_btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 13px;
}

.tag_btn {
  width: 50%;
  padding: 0.4rem 1.5rem 4px;
  display: flex;
  border: 1px solid black;
  align-items: center;
  margin-right: 23px;
  background-color: #ffff;
  border-radius: 0.5rem;
  justify-content: space-around;
  cursor: pointer;
}

.search_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  padding: 13px 13px;
}

.search_bar input {
  border: 1px solid black;
  outline: none;
  width: 100%;
  border-radius: 0.2rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px 0px;
}

.checkin_btn {
  width: 50%;
  padding: 0.7rem 1.5rem 4px;
  display: flex;
  border: 1px solid black;
  justify-content: space-around;
  align-items: center;
  background-color: #ffff;
  border-radius: 0.5rem;
}

.modal-overlay {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  overflow-x: auto;
}

.modal-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  /* padding: 20px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-width: 100%;
  width: 26%;
  text-align: center;
  border-radius: 0.5rem;
  margin: 0 auto;
  overflow-y: auto;
  /* Add this line to enable vertical scrolling */
}

.modal-header {
  background-color: red;
  color: #fff;
}

.tag_users {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0px 13px;
}

/* .tag-users-info {
    display: flex;
    width: 100%;
    align-items: center;
} */

/* .tag-users-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
} */

.user_name {
  display: block;
  text-align: left;
}

.Modal-body {
  width: 100%;
  padding: 0.5rem;
  /* height: 40vmax; */
}
</style>
