<template>
  <div class="custom-animation">
    <navbar />
    <div class="contianer">
      <div class="grid-container">
        <div class="grid-item" v-for="offer in offers" :key="offer.id">
          <div class="promated_offers">
            <button class="promated_btn">PROMOTED</button>
            <div
              class="d-flex align-item-center justify-content-center img-div"
            >
              <img
                src="./../assets/nooffers.png"
                v-if="offer.offer_image == ''"
              />
              <img
                :src="offer.offer_image"
                data-bs-toggle="modal"
                :data-bs-target="'#OffersModal' + offer.id"
                v-else
              />
            </div>
          </div>
          <div
            class="modal fade"
            :id="'OffersModal' + offer.id"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>-->
                <div class="modal-body">
                  <!-- {{offer.offer_image}} -->
                  <div class="offers_details_header">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <img :src="offer.offer_image" />
                      <img
                        src="./../assets/nooffers.png"
                        alt
                        v-if="offers.offers_img === ''"
                      />
                    </div>
                    <p>Offers Details</p>
                    <hr />
                  </div>
                  <div class="dpop">
                    <!-- <img src="../assets/logo2.png" width="550" height="200" /> -->
                    <!-- <img :src="offer.offer_image" />
                  <h2>Offer Details</h2>-->

                    <div class="dpop1">
                      <h6>Business name</h6>
                      <span>:</span>
                      <h6>{{ offer.vendor.business_name }}</h6>
                    </div>
                    <div class="dpop2">
                      <h6>About Offer</h6>
                      <span>:</span>
                      <h6>{{ offer.about_offer }}</h6>
                      <h6 v-if="offer.about_offer == ''">no content</h6>
                    </div>
                    <div class="dpop1">
                      <h6>Validity</h6>
                      <span>:</span>
                      <h6>For 7 Days only</h6>
                    </div>
                    <div class="dpop1">
                      <h6>No. of Person</h6>
                      <span>:</span>
                      <h6>{{ offer.no_of_person }}</h6>
                    </div>
                    <div class="dpop2">
                      <h6>Address</h6>
                      <span>:</span>
                      <h6>{{ offer.vendor.business_address }}</h6>
                    </div>
                    <div class="dpop1">
                      <h6>Other</h6>
                      <span>:</span>
                      <h6>50% off on any Sizzler</h6>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    data-bs-dismiss="modal"
                    @click="openPopup(offer.id)"
                  >
                    Book Table
                  </button>
                  <!--   data-bs-dismiss="modal" -->
                </div>
              </div>
            </div>
          </div>

          <div class="offer_details">
            <div class="offer_details_offer_name">
              <h5 style="color: black">{{ offer.vendor.business_name }}</h5>
              <div class="star">
                <h6
                  class="mb-0"
                  style="font-size: 1rem; margin-left: 11%; color: white"
                >
                  4.5
                </h6>
                <img
                  src="../assets/star.svg"
                  style="width: 1.1rem; height: 1rem; margin-top: 0.1rem"
                />
              </div>
            </div>

            <span class="w-75 ms-2 fs-5 fw-500 px-1">{{
              offer.vendor.business_address
            }}</span>
            <div class="offer_Details">
              <h5 class="mb-0">OFFER:</h5>
              <span class="ms-2 mb-0">{{ offer.offer_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- offers show Modal  -->
        <div v-if="showModal" class="Modal">
          <div class="Modal-content">
            <div class="Modal-header">
              <img src="./../assets/mainlogo.png" width="50" />
              <h1 style="width: 92%">RedPrix</h1>
              <span class="close" @click="showModal = false">&times;</span>
            </div>
            <div
              style="
                display: block;
                display: flex;
                padding-left: 7%;
                justify-content: space-between;
                border-bottom: 2px solid #c10111;
                color: red;
              "
            >
              <span>
                504, luxuira Business,vesu,
                <br />surat-395009
              </span>
              <div class="review_btn">
                <!-- <button class="btn btn-success">Write Review</button> -->
                <button
                  class="btn btn-success"
                  v-if="!textboxes.length"
                  @click="addTextBox"
                >
                  Write Review
                </button>
                <button
                  class="btn btn-danger"
                  v-else
                  @click="removeTextBox(index)"
                >
                  Remove
                </button>
                <!-- <writereview /> -->
              </div>
            </div>
            <div class="Modal-body">
              <div class="user_review">
                <img src="./../assets/cover.png" width="50" alt />
                <span>Rozy joe</span>
                <span>rating</span>
              </div>
              <div class="reviews">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nesciunt aut neque inventore molestias praesentium adipisci
                  perferendis magnam reprehenderit sit! Quasi at velit cum
                  voluptate ipsum placeat provident temporibus dolorem! Animi.
                </p>
              </div>
              <div
                class="text-box"
                v-for="(textBox, index) in textboxes"
                :key="index"
              >
                <input
                  type="text"
                  v-model="textBox.text"
                  placeholder="Write your review here"
                />
              </div>
            </div>
            <div class="Modal-footer">
              <button
                class="btn btn-light"
                style="background-color: #ccc"
                @click="closeModal"
              >
                Cancel
              </button>
              <button class="btn btn-danger" @click="closeAndOpenModal">
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="date_time_Modal">
        <div v-if="showdateTimeModale" class="Modal">
          <div class="dateTimeModal-content">
            <div class="dateTime-Modal-header">
              <h5>Select Date & Time</h5>
              <i
                class="fa fa-times"
                aria-hidden="true"
                @click="closeNewModal"
                style="
                  background-color: rgb(204, 204, 204, 0.1);
                  border-radius: 50%;
                  color: rgb(255, 255, 255);
                  padding: 1%;
                  margin: 1%;
                "
              ></i>
            </div>
            <div class="dateTime-Modal-body">
              <div class="dateTime-Modal-body-image">
                <img src="./../assets/table.png" alt />
              </div>
              <div class="datetimeform">
                <!-- <BookTableForm /> -->
                <div class="date">
                  <label>Select Date</label>
                  <div class="select_date">
                    <input type="date" v-model="date" />
                  </div>

                  <label>Select Time</label>
                  <div class="time-picker">
                    <input v-model="selectedTime" type="time" />
                    <button
                      @click="toggleAMPM('AM')"
                      :class="{ active: ampm === 'AM' }"
                    >
                      AM
                    </button>
                    <button
                      @click="toggleAMPM('PM')"
                      :class="{ active: ampm === 'PM' }"
                    >
                      PM
                    </button>
                  </div>
                  <div class="select_member">
                    <label>No of Memebers</label>
                    <input type="text" v-model="no_of_person" />
                  </div>
                </div>
              </div>
            </div>

            <div class="dateTime-Modal-footer">
              <!--  v-for="offer in offers" :key="offer.id" -->
              <button
                class="btn btn-danger btn-sm w-100"
                @click="BookTable(), closeAndOpenModal()"
              >
                BOOK TABLE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="confirm_bookingModal">
        <div v-if="showCofirmBookingModal" class="Modal">
          <div class="dateTimeModal-content">
            <div class="dateTime-Modal-header">
              <h5>Booking Confirm</h5>
              <i
                class="fa fa-times"
                aria-hidden="true"
                @click="closeNewModal"
                style="
                  background-color: rgb(204, 204, 204, 0.1);
                  border-radius: 50%;
                  color: rgb(255, 255, 255);
                  padding: 1%;
                  margin: 1%;
                "
              ></i>
            </div>
            <div class="dateTime-Modal-body">
              <div class="dateTime-Modal-body-image">
                <img src="./../assets/table.png" alt />
              </div>
              <div class="confirm_booking_Modal_UserDetails">
                <!-- <div
                  class="UserDetails_show"
                  v-for="user in showuserdetails"
                  :key="user.id"
                >
                  <p>name</p>
                  <p>{{ user }}</p>
                </div> -->
                <div class="UserDetails_show">
                  <p style="margin-left: 70px">Customer Name:</p>
                  <p>
                    {{
                      showuserdetails.customer_name
                        ? showuserdetails.customer_name
                        : "Customer Name"
                    }}
                  </p>
                </div>
                <div class="UserDetails_show">
                  <p style="margin-left: 70px">Email-Id:</p>
                  <p>
                    {{
                      showuserdetails.email
                        ? showuserdetails.email
                        : "customer@gmail.com"
                    }}
                  </p>
                </div>
                <div class="UserDetails_show">
                  <p style="margin-left: 70px">Mobile No:</p>
                  <p>
                    {{
                      showuserdetails.mobile
                        ? showuserdetails.mobile
                        : "9638527415"
                    }}
                  </p>
                </div>
                <div class="UserDetails_show">
                  <p style="margin-left: 70px">Date:</p>
                  <p>
                    {{
                      showuserdetails.date ? showuserdetails.date : "2024-05-23"
                    }}
                  </p>
                </div>
                <div class="UserDetails_show">
                  <p style="margin-left: 70px">Time:</p>
                  <p>
                    {{
                      showuserdetails.time
                        ? showuserdetails.time + " " + ampm
                        : "03:45 PM"
                    }}
                  </p>
                </div>
                <div class="UserDetails_show">
                  <p style="margin-left: 70px">No of Persons:</p>
                  <p>
                    {{
                      showuserdetails.no_of_person
                        ? showuserdetails.no_of_person
                        : "5"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="dateTime-Modal-footer">
              <button
                class="btn btn-danger btn-sm w-100"
                @click="closebookingModal"
              >
                BOOK & Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="Reserved-Table">
        <div class="Modal" v-if="showReservedModal">
          <div class="dateTimeModal-content">
            <div class="Reserved-Table-Modal-header">
              <img
                class="Reserved-Table-Modal-header-img"
                src="./../assets/last.png"
              />
            </div>
            <div class="Reserved-Table-Modal-body">
              <p>Thankyou..!</p>
              <p>Your Table is reseverd</p>
            </div>
            <div class="dateTime-Modal-footer">
              <button class="btn btn-danger btn-sm w-100" @click="closeModal">
                Go To Home Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import navbar from "./../components/nav.vue";
// import BookTableForm from "./Modal/TableReserved.vue";
export default {
  name: "AllOffers",
  components: {
    // writereview,
    // BookTableForm,
    navbar,
  },
  data() {
    return {
      offers: [],
      textboxes: [],
      showModal: false,
      showTextBox: false,
      showdateTimeModale: false,
      showCofirmBookingModal: false,
      showPicker: false,
      ampm: "AM",
      showReservedModal: false,
      showuserdetails: [],
      selectedTime: "",
      date: "",
      no_of_person: "",
      selectedOfferId: "",
      item: [],
    };
  },

  mounted() {
    this.GetOffer();
    this.getUserProfile();
  },
  methods: {
    // GetOffer() {
    //   axios.get("vendors/offers").then((res) => {
    //     this.offers = res.data.data;
    //     console.log(res.data.data);
    //     console.log("offers", this.offers);
    //   });
    // },
    openPopup(id) {
      this.showdateTimeModale = true;
      this.selectedOfferId = id;
      //   this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.showReservedModal = false;
    },
    addTextBox() {
      // Add a new text box to the array when the button is clicked
      this.textboxes.push({});
    },
    removeTextBox(index) {
      this.textboxes.splice(index, 1);
    },
    closeAndOpenModal() {
      this.showModal = false; // Close the current modal
      this.showdateTimeModale = false; // Open the new modal
      this.showCofirmBookingModal = true;
      // this.showReservedModal = true;
    },
    // Function to close the new modal
    closeNewModal() {
      this.showdateTimeModale = false;
      this.showCofirmBookingModal = false;
      // this.showReservedModal = true;
      // this.showCofirmBookingModal = false;
      // this.showReservedModal = false;
    },

    closebookingModal() {
      // alert("hello");
      this.showCofirmBookingModal = false;
      this.showReservedModal = true;
    },

    showTimePicker() {
      this.showPicker = true;
    },
    toggleAMPM(newAMPM) {
      this.ampm = newAMPM;
    },
    // BookTable() {
    //   axios
    //     .post("customers/offers/redeem-offer/", {
    //       date: this.date,
    //       time: this.time,
    //       no_of_person: this.no_of_person,
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //     });
    // },

    GetOffer(offerId) {
      axios.get("vendors/offers?order_by=desc&sort=id").then((res) => {
        this.offers = res.data.data;
        // console.log(res.data.data);
        // console.log("offers", this.offers);

        // Store the selected offer ID
        this.selectedOfferId = offerId;
      });
    },
    BookTable() {
      if (this.selectedOfferId === null) {
        // Handle the case where no offer is selected
        console.error("No offer selected");
        return;
      }

      // console.log("Item Details: ", this.item);
      this.showuserdetails = {
        customer_name: this.item.name,
        email: this.item.email,
        mobile: this.item.mobile,
        date: this.date,
        time: this.selectedTime,
        no_of_person: this.no_of_person,
      };

      // console.log("User Details: ", this.showuserdetails);

      axios
        .post(`customers/offers/redeem-offer/${this.selectedOfferId}`, {
          // offer_id: this.selectedOfferId, // Use the selectedOfferId here
          date: this.date,
          time: this.time,
          no_of_person: this.no_of_person,
        })
        .then(() => {
          // console.log(res.data.data);
          // this.showuserdetails = res.data.data;
          // this.showuserdetails = {
          //   name: this.item.name,
          //   email: this.item.email,
          //   mobile: this.item.mobile,
          //   date: this.date,
          //   time: this.selectedTime,
          //   no_of_person: this.no_of_person,
          // };
          // console.log("User Details: ", this.showuserdetails);
        })
        .catch(() => {
          // console.log("Error in Offer: ", err);
        });
    },
    getUserProfile() {
      axios.get("customers/my-profile").then((res) => {
        // console.log(res.data.data);

        this.item = res.data.data;
        // console.log("Customer Response: ", this.item);
      });
    },
  },
};
</script>

<style scoped>
.img-div {
  width: 100%;
  height: 28rem;
}

.container {
  padding: 1%;
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 0.5rem;
}
.near_by_offers {
  display: flex;
  align-items: center;
  gap: 0.5%;
}
.near_by_offers h4 {
  width: 24%;
}
.center_border {
  border: 1px solid #ccc;
  margin-left: 2rem;
  width: 70%;
}

.grid-container {
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 0.5rem;
  padding: 2%;
  display: grid;
  /* grid-template-columns: repeat( auto-fill, minmax(32vw, 1fr));  Use vw for width */
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  gap: 2vh; /* Use vh for gap */
}

.grid-item {
  background-color: #ffffff;
  /* padding: 2vh; */
  /* text-align: center; */
  font-size: 1vw; /* Use vw for font size */
  width: 100%;
  border-radius: 1vh; /* Use vh for border-radius */
  box-shadow: 0px 0px 5px #ccc;
  gap: 2%;
  cursor: pointer;
  padding-bottom: 10px;
}

.grid-item img {
  width: 100%;
  /* object-fit: contain;/ */
  height: 28rem;
  object-fit: fill;
  border-radius: 1em;
}

.offers_details_header img {
  width: 95%;
  height: 18rem;
  object-fit: fill;
  border-radius: 1em;
}

.offers_details_header p {
  font-size: 1.1rem;
  margin-top: 5px;
  margin-left: 2px;
  margin-bottom: 0;
}

.offers_details_header hr {
  margin-top: 2px;
}

/* Additional styles for responsiveness */

.promated_btn {
  /* margin-left: -41%; */
  border-radius: 0.8rem;
  background-color: #eb0e19;
  border: none;
  color: #fff;
  padding: 0.3rem;
  position: absolute;
  top: 1.5rem;
  right: 1.3rem;
}
.star {
  background: #1ef11efa;
  width: 15%;
  position: relative;
  display: flex;
  border-radius: 10px;
  padding: 0.4rem 0.2rem;
  /* align-items: center; */
  justify-content: space-between;
}
.offer_details_offer_name {
  margin-top: 1.5rem;
  padding: 0.2rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offer_details_offer_name span {
  font-size: 0.5vw;
  color: #ccc;
}
.offer_Details {
  padding: 0.2rem 0.7rem;
  display: flex;
  /* justify-content: space-between; */
  align-items: baseline;
}
.offer_Details h5 {
  font-size: 1.2rem;
  color: red;
}
.offer_Details span {
  font-size: 0.95rem;
  color: black;
  margin-bottom: 1px;
}
.promated_offers {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0.5rem;
  position: relative;
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-modal-footer-bg);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-top: none;
}
.modal-footer button {
  color: #fff;
}

.dpop1,
.dpop2 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.dpop1 span,
.dpop2 span {
  width: 50%;
  text-align: left; /* Align text to the left */
  display: flex;
  justify-content: flex-start; /* Align ":" to the center */
  align-items: center;
}
/* .offers_details{
  display:flex;
  justify-content:space-between;
  align-items: center;
} */
/* Modal css */
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Modal-content {
  background-color: white;
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 50%;
  margin: 5% auto;
  padding: 1%;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  /* flex-direction: column; */
}
.Modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .Modal-content h1 {

} */

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.review_btn {
  padding: 1%;
}
.Modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2%;
}
.Modal-footer button {
  width: 95%;
  margin: 2%;
}
.user_review {
  padding: 1%;
  display: flex;
  align-items: center;
  gap: 1%;
}
.reviews {
  padding: 2%;
}
/* Modal css end */

/* date&timeModal css  */
.dateTimeModal-content {
  border-radius: 0.5rem;
  background-color: white;
  /* padding: 20px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 30%;
  margin: 5% auto;
}
.dateTime-Modal-header {
  display: flex;
  align-items: center;
  background-color: red;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  justify-content: space-between;
}
.dateTime-Modal-header h5 {
  color: #fff;
  padding: 3%;
}
.dateTime-Modal-body-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateTime-Modal-footer {
  padding: 5%;
  width: 79%;
  margin: 0 auto;
}
/* date&timeModal css end  */

.date {
  width: 100%;
  padding-left: 16%;
  padding-right: 16%;
}
.select_date {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
}
.select_date input {
  outline: none;
  width: 100%;
}

.time-picker {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
}
.time-picker input {
  outline: none;
  width: 100%;
}
.active {
  background-color: #007bff;
  color: #fff;
}
.select_member input {
  border-bottom: 1px solid black;
  outline: none;
  width: 100%;
}
.UserDetails_show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 5%;
}

.UserDetails_show p {
  width: 50%;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.Reserved-Table-Modal-header-img {
  margin: 3%;
  padding-left: 12%;
  padding-right: 9%;
  width: 97%;
}

.Reserved-Table-Modal-body p {
  text-align: center;
}
.filter_btn {
  display: flex;
  gap: 4%;
}
.filter_btn p {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .grid-container {
    font-size: 2.5vw;
    /* Example using vw for font size */
    grid-template-columns: repeat(1, minmax(25%, 1fr));
  }
  .img-div {
    width: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1201px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(25%, 1fr));
  }

  .img-div {
    width: 100%;
  }
}
</style>