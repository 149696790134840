<template>
  <div class="grid-container" v-if="vendoroffers.length > 0">
    <!-- <h1>hello</h1> -->
    <div
      class="grid-item shadow-md rounded-2"
      v-for="offer in vendoroffers"
      :key="offer.id"
    >
      <div class="d-flex align-items-center justify-content-center px-1 py-1">
        <img :src="offer.offer_image" class="post_img" style="" />
      </div>
      <div class="pt-1 pb-1 px-1" style="background-color: lightgray">
        <div class="">
          <span style="font-size: 1.2rem; font-weight: 500; color: red"
            >OFFER: </span
          ><br />
          <span style="font-size: 0.9rem">{{ offer.offer_name }}</span>
        </div>
        <div class="my-1">
          <span style="font-size: 0.9rem; font-weight: 500; color: gray"
            >Business Name:
          </span>
          <h4 class="fs-6">{{ offer.vendor.business_name }}</h4>
        </div>
        <p class="text-center text-muted mb-0" style="font-size: 0.8rem">
          {{ offer.about_offer }}
        </p>
      </div>
    </div>
  </div>
  <div class="w-100 py-3 text-center" v-else>
    <p class="mx-auto text-muted mb-0 mt-1">You didn't upload any offer yet.</p>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "vendoroffers",
  data() {
    return {
      vendoroffers: [],
    };
  },
  mounted() {
    this.getMyOffer();
  },
  methods: {
    getMyOffer() {
      // console.log("Showing Vendor Offers Function Called!!!");
      axios
        .get("vendors/my-offer")
        .then((resp) => {
          // console.log(resp.data.data);
          this.vendoroffers = resp.data.data;
          // console.log(this.vendoroffers)
        })
        .catch((error) => {
          console.log(error.resp.data.message);
        });
    },
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  /* gap: 10px; */
}

.grid-item {
  margin: 0.5rem auto;
  border: 1px solid #ccc;
  text-align: center;
  width: 90%;
}
.post_img {
  border-radius: 0.4rem;
  width: 100%;
  height: 12rem;
}
</style>