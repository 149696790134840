<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
.btn:focus {
  box-shadow: none;
}

.custom-animation {
  animation: fadeIn;
  animation-duration: 1.2s;
}
</style>



<style scoped>
@import url("https://fonts.google.com/specimen/Roboto?query=roboto");

* {
  margin: 0px;
  padding: 0;

  box-sizing: border-box;
  list-style: none;
}

body {
  font-family: sans-serif;
}

body::-webkit-scrollbar {
  background-color: transparent;
  width: 0.5rem;
}

body::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-color: #ccc;
  /* box-shadow: 5px 5px 20px red; */
}
</style>
