import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./axios.js";
import axios from "axios";
import store from "./../vuex/store";
// import Vuetify from "vuetify";
import { createVuetify } from "vuetify";
// import "vuetify/dist/vuetify.min.css";

import "vuetify/dist/vuetify.min.css";

import "animate.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";
const vuetify = createVuetify();

const app = createApp(App);
app.use(router);
app.mount("#app");
app.use(store);
app.use(vuetify);

// createApp(App).use(router).mount('#app')
axios.defaults.headers.common["Authorization"] =
	" Bearer " + localStorage.getItem("token");
