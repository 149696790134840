<template>
  <div class="privacy-policy py-1">
    <div class="privacy-policy-heading">
      <h1 class="fs-5 ps-3 pt-1 text-grey">Privacy Policy</h1>
    </div>
    <div class="policy-details">
      <h2>1. Introduction</h2>
      <p>
        Welcome to RedPrix, the ultimate social media app for food lovers! At
        RedPrix, we value your privacy and are committed to protecting your
        personal information. This Privacy Policy is designed to help you
        understand how we collect, use, disclose, and safeguard your data when
        you use our website and services. By using RedPrix, you consent to the
        practices described in this Privacy Policy.
      </p>

      <h2>2. Information We Collect</h2>
      <h3>2.1 User-Provided Information:</h3>
      <p>
        When you use RedPrix, we may collect the following types of information
        that you provide to us:
      </p>
      <ul>
        <li>
          Personal Information: This includes your name, email address, and
          profile picture.
        </li>
        <li>
          Location Information: We collect location data when you upload a
          selfie with your food to provide you with relevant restaurant
          discounts.
        </li>
      </ul>

      <h3>2.2 Automatically Collected Information:</h3>
      <p>
        In addition to the information you provide, we may also automatically
        collect certain information when you use RedPrix, including but not
        limited to:
      </p>
      <ul>
        <li>
          Device Information: We gather information about the type of device you
          use to access our platform, such as the device model, operating
          system, and unique device identifiers.
        </li>
        <li>
          Usage Data: We collect data related to your interactions with RedPrix,
          such as the pages you view, the actions you take, and the dates and
          times of your visits.
        </li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>
          <strong>3.1 Providing and Improving Our Services:</strong> We use your
          information to operate and maintain RedPrix, enhance our features, and
          develop new functionalities to improve your user experience.
        </li>
        <li>
          <strong>3.2 Personalization:</strong> We may use your data to
          personalize your experience on RedPrix, such as providing restaurant
          recommendations based on your location and preferences.
        </li>
        <li>
          <strong>3.3 Communication:</strong> We may use your email address to
          send you promotional offers, updates, and important information
          related to RedPrix. You can opt out of marketing communications at any
          time.
        </li>
        <li>
          <strong>3.4 Analytics:</strong> We analyze user behavior and
          preferences to gain insights into how our platform is used. This helps
          us make informed decisions and improve our services.
        </li>
        <li>
          <strong>3.5 Customer Support:</strong> Your information may be used to
          respond to your inquiries, resolve issues, and provide customer
          support.
        </li>
      </ul>

      <!-- Continue adding content for remaining sections -->

      <h2>4. Sharing Your Information</h2>
      <p>We may share your information in the following circumstances:</p>
      <ul>
        <li>
          <strong>4.1 With Your Consent:</strong> We may share your data with
          third parties if you give us your consent to do so.
        </li>
        <li>
          <strong>4.2 Legal Obligations:</strong> We may disclose your
          information to comply with applicable laws, regulations, or legal
          processes.
        </li>
        <li>
          <strong>4.3 Business Transactions:</strong> If RedPrix is involved in
          a merger, acquisition, or sale of assets, your information may be
          transferred as part of that transaction.
        </li>
        <li>
          <strong>4.4 Protection of Rights:</strong> We may share your data to
          protect our rights, privacy, safety, or the rights, privacy, or safety
          of others.
        </li>
      </ul>

      <!-- Add content for Section 5, 6, 7, 8, and 9 as needed -->

      <h2>5. Security</h2>
      <p>
        We take reasonable measures to protect your information, but no method
        of data transmission over the Internet or electronic storage is
        completely secure. While we strive to protect your data, we cannot
        guarantee its absolute security.
      </p>

      <h2>6. Your Choices</h2>
      <p>You have certain rights regarding your personal information:</p>
      <ul>
        <li>
          <strong>6.1 Account Information:</strong> You can update and correct
          your account information at any time.
        </li>
        <li>
          <strong>6.2 Marketing Communications:</strong> You can opt out of
          receiving promotional communications from us.
        </li>
        <li>
          <strong>6.3 Account Deletion:</strong> You may request the deletion of
          your RedPrix account and associated data, subject to our data
          retention policy.
        </li>
      </ul>

      <h2>7. Children's Privacy</h2>
      <p>
        RedPrix is not intended for children under the age of 13, and we do not
        knowingly collect personal information from children under 13. If you
        believe we have collected information from a child under 13, please
        contact us immediately.
      </p>

      <h2>8. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically to reflect changes in our
        practices and services. We will notify you of any significant changes by
        posting an updated policy on this page.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        privacy practices, please contact us at [Contact Information].
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy {
  width: 100%;
  height: 40.5rem;
  box-shadow: 0px 0px 5px #ccc;

  border-radius: 0.5rem;
  overflow: auto;
}

.privacy-policy::-webkit-scrollbar {
  display: none;
}
.privacy-policy-heading {
  width: 100%;
  border-bottom: 1px solid grey;
}
.policy-details {
  padding: 0.6rem 1.2rem;
}

.policy-details h1,
h2,
h3,
li,
p {
  text-align: justify;
}

.policy-details h2 {
  font-size: 1.4rem;
  color: grey;
}

.policy-details h3 {
  font-size: 1.2rem;
  color: grey;
}

.policy-details p,
li {
  font-size: 0.9rem;
  font-weight: 100;
  color: #0000008c;
}
</style>
