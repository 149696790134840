<template>
  <swiper :slides-per-view="5" :space-between="15" @swiper="onSwiper" @slideChange="onSlideChange">
    <swiper-slide>
      <img class="slider_img" src="./assets/Vijay.png" />

    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/gentssalon.png" />

    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/levis.png" />
    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/poster.png" />
    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/Vijay.png" />
    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/Vijay.png" />
    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/Vijay.png" />
    </swiper-slide>
    <swiper-slide>
      <img class="slider_img" src="./assets/Vijay.png" />
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

export default {
  name: "owlslider",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
    //   console.log(swiper);
    };
    const onSlideChange = () => {
    //   console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
<style scoped>
/* swiper {
  width: 100%;
} */
.slider_img{
    width:100%
}
/* .swiper-slide {
  width: 400px; 
} */
</style>