<template>
  <div class="content-div">
    <div class="data-div py-4 px-4 shadow-sm rounded border-0 bg-white">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <img
          style="height: auto; width: 10rem"
          class=""
          center
          src="../assets/fail_img.png"
          alt="Center image"
        />
      </div>
      <div class="content">
        <div v-if="errormas" class="alert alert-danger mt-2 mb-0" role="alert">
          {{ errormas }}
        </div>
        <h1 class="mt-2 mb-4">Opps ! Something Went Wrong</h1>
        <p class="px-5 mb-5">
          Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
          laying out print, graphic or web designs.
        </p>
        <a v-bind:href="'/home'" class="fs-5 fw-600"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-left mb-1 me-2"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            /></svg
          >Go to Home</a
        >

        <!-- <router-link to="/home">Go to Home</router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "fail",
  // data() {
  // return {
  //     errormas:'',
  //     coupon_code:'',
  //     errormasss:''
  // }},
  //   methods: {

  //   },

  //     mounted() {

  //         this.subscribe = JSON.parse(localStorage.getItem("copondetails"))
  //         if(this.subscribe){
  //            this.coupon_code=this.subscribe.applied_coupon

  //         }else{
  //            this.coupon_code=''

  //         }

  //       if(localStorage.getItem("hash")){

  //            axios.post("/subscription", { plan_id: "1", coupon_code: this.coupon_code })
  //            .then((result) => { if (result.data.status_code == 201) {
  //              axios.post('payment/'+result.data.data.id,{payment_status:'2'})
  //              .catch((error) => {
  //                        if(error.response.data.status_code == '422'){
  //                           localStorage.removeItem('hash');
  //                           localStorage.removeItem('copondetails');
  //                           this.$router.go(this.$router.currentRoute)
  //                        }})}})}

  //         if(localStorage.getItem('expireSession')){
  //                 setTimeout(() => {localStorage.removeItem('expireSession')}, 10000);
  //          }else if(!localStorage.getItem('expireSession')){
  //              this.$router.push("/home");
  //          }

  //  },
};
</script>


<style scoped>
.content-div {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-div {
  width: 50%;
  height: 70%;
}
.content {
  text-align: center;
}

.content h1 {
  font-size: 2rem;
  padding-top: 25px;
  color: #f01b1b;
}

.content a {
  width: 30%;
  margin-top: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  color: #f01b1b;
  border-radius: 0.6rem;
  padding: 0.4rem 0.9rem;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  background: #f01b1b;
  color: white;
}
</style>