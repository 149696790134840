<template>
  <div class="dropdown">
    <button
      type="button"
      class="btn-sm dropdown-toggle me-3"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="display: flex; /* width: 100%; */ background: none; border: none"
      @click="clickFunction"
    >
      <!-- <img src="../assets/share.png" width="20" height="20" class="me-2" /> -->
      <i
        class="fa-solid fa-share-from-square me-2 my-auto"
        style="font-size: 1.2rem; color: black"
        :class="{ 'animate__animated animate__tada': iconClicked }"
      ></i>
      <h6
        style="
          color: black;
          cursor: pointer;
          font-size: 1rem;
          margin-bottom: 0rem;
          margin-top: 0.1rem;
        "
        class="my-auto"
      >
        {{ this.shares_count }} Share
      </h6>
    </button>
    <ul
      class="dropdown-menu"
      aria-labelledby="dropdownMenuButton1"
      style="
        position: absolute;
        inset: 0px auto auto 27px;
        margin: 0px;
        transform: translate(-11px, 40px);
        min-width: 2rem;
      "
    >
      <li style="cursor: pointer">
        <a
          class="dropdown-item"
          href="https://www.facebook.com/"
          target="_blank"
          ><img src="../assets/fb.png" width="55" height="35"
        /></a>
      </li>
      <li style="cursor: pointer">
        <a
          class="dropdown-item"
          href="https://web.whatsapp.com/"
          target="_blank"
          ><img src="../assets/whatsapp.png" width="55" height="35"
        /></a>
      </li>
      <li style="cursor: pointer">
        <a class="dropdown-item" href="https://twitter.com/" target="_blank"
          ><img
            style="margin-left: 21%"
            src="../assets/twitter.png"
            width="30"
            height="30"
        /></a>
      </li>
      <li style="cursor: pointer">
        <a class="dropdown-item" href="https://in.linkedin.com/" target="_blank"
          ><img
            style="margin-left: 21%"
            src="../assets/link.png"
            width="30"
            height="30"
        /></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "user-share",
  props: {
    postId: String,
    shares_count: String,
  },
  data() {
    return {
      iconClicked: false,
    };
  },
  methods: {
    clickFunction() {
      this.iconClicked = !this.iconClicked;
    },
  },
};
</script>
<style scoped>
.dropdown-toggle::after {
  display: none;
}
</style>
