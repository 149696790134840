<template>
  <main class="custom-res-navbar">
    <!-- Navbar for larger screens -->
    <div class="container-xl d-none d-md-flex justify-content-center w-100">
      <nav
        class="d-flex w-100 justify-content-between px-3 px-lg-5 align-items-center fixed-top large-navbar"
        style="background: #e1101a; padding: 1.1rem 0rem; margin: 0"
      >
        <div class="row d-flex align-items-center" style="width: fit-content">
          <div class="col-sm-8 col-md-11 col-xl-4">
            <RouterLink to="/" class="text-decoration-none">
              <div class="d-flex align-items-center">
                <img
                  src="./assets/mainlogo.png"
                  alt="logo of RedPrix"
                  style="width: 3rem; height: 3rem; object-fit: contain"
                />
                <h3 class="fw-bold fs-3 ms-1 my-auto" style="color: white">
                  Redprix
                </h3>
              </div>
            </RouterLink>
          </div>

          <!-- <div class="col-sm-7 col-7 mx-5 my-auto" style="width: 70%">
            <h3 class="fw-bold fs-4 text-white mb-0 mt-1">
              First Global Social Media Platform For Food Lovers
            </h3>
          </div> -->
        </div>

        <ul class="d-flex my-auto">
          <li
            class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover"
            @click="logout"
          >
            <button
              class="text-decoration-none text-black fw-lighter"
              style="border: none; background: none"
              @click="logout"
            >
              Login
            </button>
          </li>

          <RouterLink
            to="/Register"
            class="text-decoration-none text-black fw-lighter"
          >
            <li
              class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover"
            >
              Sign-up
            </li>
          </RouterLink>

          <RouterLink
            to="/ib-talks"
            class="text-decoration-none text-black fw-lighter"
          >
            <li
              class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover"
            >
              IB-Talks
            </li>
          </RouterLink>
          <RouterLink
            to="/the-phoenix-tales"
            class="text-decoration-none text-black fw-lighter"
          >
            <li
              class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover"
            >
            The Phoenix Tales
            </li>
          </RouterLink>

          <RouterLink
            to="/all-blogs"
            class="text-decoration-none text-black fw-lighter"
          >
            <li
              class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover"
            >
              Blogs
            </li>
          </RouterLink>

          <RouterLink
            to="/Tutorial-Page"
            class="text-decoration-none text-black fw-lighter"
          >
            <li
              class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover"
            >
              Tutorials
            </li>
          </RouterLink>
          <a
      href="https://www.redprix.com/privacy_policy.html"
      class="text-decoration-none text-black fw-lighter"
    >
      <div class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover">
        Privacy Policy
      </div>
    </a>
    
    <a
      href="https://www.redprix.com/terms_and_condition.html"
      class="text-decoration-none text-black fw-lighter"
    >
      <div class="list-unstyled fs-5 px-3 py-2 mx-2 bg-white rounded custom-hover">
        Terms & Condition
      </div>
    </a>
        </ul>
      </nav>
    </div>

    <!-- Navbar for smaller screens -->
    <nav
      class="navbar navbar-expand-lg navbar-light d-md-none p-3 justify-content-between"
      style="background-color: #e1101a"
    >
      <div class="container">
        <RouterLink to="/" class="navbar-brand text-decoration-none">
          <div class="d-flex align-items-center">
            <img
              src="./assets/mainlogo.png"
              alt="logo of RedPrix"
              style="width: 3rem; height: 3rem; object-fit: contain"
            />
            <h3 class="fw-bold fs-3 ms-1 my-auto" style="color: white">
              Redprix
            </h3>
          </div>
        </RouterLink>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <!-- Use the SideNavigation component -->
      <DummySideNavBar
        v-if="isSideNavigationOpen"
        @closeSidebar="closeSidebar"
      />
    </nav>
  </main>
</template>

  
<script>
import DummySideNavBar from "./DummySidebar.vue";
export default {
  name: "DummyNavBar",
  data() {
    return {
      isSideNavigationOpen: false,
    };
  },

  created() {
    this.lodData();
  },

  methods: {
    lodData() {
      this.token = localStorage.getItem("authToken");
      //   console.log("this is home page show token", this.token);
    },

    logout() {
      localStorage.removeItem("authToken");
      this.$router.push("/login");
    },

    toggleNavbar() {
      this.isSideNavigationOpen = true;
    },

    closeSidebar() {
      this.isSideNavigationOpen = false;
    },
  },
  components: {
    DummySideNavBar,
  },
};
</script>

  
<style scoped>
* {
  font-family: Montserrat, sans-serif;
}

.custom-hover {
  transition: transform 0.3s ease;
  cursor: pointer;
}

.custom-hover:hover {
  transform: scale(1.06);
}

@media (max-width: 457px) {
  .custom-logo {
    width: 75% !important;
  }
}

@media (max-width: 767px) {
  .custom-res-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
}

@media screen and (max-width: 992px) {
}
</style>
