<template>
  <div class="animate__animated animate__fadeIn">
    <navbar />

    <div class="container" style="margin: 5rem auto">
      <div class="row">
        <div class="col-md-6" style="box-shadow: 0px 0px 9px -4px black">
          <h2 class="text-danger">Contact Information</h2>
          <p>
            <i class="bi bi-geo-alt text-danger"></i>
            <strong class="mx-2">Location:</strong>
            <span
              >604, Luxuria Business Hub, Nr. VR Mall, Vesu, Surat, Gujarat
              395007</span
            >
          </p>
          <p>
            <i class="bi bi-phone text-danger"></i>
            <strong class="mx-2">Contact Number:</strong>
            <span>+91 91041 98942</span>
          </p>
          <p>
            <i class="bi bi-envelope text-danger"></i>
            <strong class="mx-2">Email:</strong>
            <span>contact@infinitybrains.com</span>
          </p>
        </div>

        <div class="col-md-6" style="box-shadow: 0px 0px 9px -4px black">
          <h2 class="text-danger">Contact Form</h2>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label class="text-danger" for="name">Name:</label>
              <input
                v-model="formData.name"
                type="text"
                class="form-control"
                id="name"
                required
              />
            </div>
            <div class="form-group">
              <label class="text-danger" for="email">Email:</label>
              <input
                v-model="formData.email"
                type="email"
                class="form-control"
                id="email"
                required
              />
            </div>
            <div class="form-group">
              <label class="text-danger" for="phone">Phone Number:</label>
              <input
                v-model="formData.phone"
                type="tel"
                class="form-control"
                id="phone"
                required
              />
            </div>
            <div class="form-group">
              <label class="text-danger" for="message">Message:</label>
              <textarea
                v-model="formData.message"
                class="form-control"
                id="message"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit" class="btn btn-danger w-100">Submit</button>
          </form>
          <div v-if="confirmationMessage" class="mt-3 alert alert-success">
            {{ confirmationMessage }}
            <p>
              For more information, contact us at
              <strong>contact@infinitybrains.com</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import navbar from "./nav.vue";

export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      confirmationMessage: "",
    };
  },
  methods: {
    submitForm() {
      this.confirmationMessage =
        "Thank you for your message! We will get back to you soon.";
      this.formData = {
        name: "",
        email: "",
        phone: "",
        message: "",
      };
    },
  },
  components: { navbar },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .col-md-6 {
    margin-top: 2rem;
  }
}
</style>