<template>
  <div class="custom-animation">
    <navbar />
    <div class="container">
      <div class="row mb-3">
        <div class="col-lg-3 col-md-12 mt-sm-3 mt-md-3">
          <div>
            <img src="../assets/bur.png" style="width: 100%; height: 18rem" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 my-sm-4 my-md-5">
          <!-- create-post-modal -->
          <div class="createoffer">
            <div class="heading">
              <h4 class="ms-2 mb-1">Create Post/Create Offer</h4>
            </div>
            <div
              class="user_details mt-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <img
                v-if="profilePhoto != ''"
                :src="profilePhoto"
                alt
                width="36"
                height="32"
                style="border-radius: 50%"
              />
              <img
                v-else
                src="./../assets/cover.png"
                alt
                width="36"
                height="32"
                style="border-radius: 50%"
              />
              <span class="fs-5 fw-500"
                >What's in your mind {{ userName }} ?</span
              >
            </div>

            <div class="home5 mt-4">
              <div
                class="h1 d-flex align-items-center my-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src="../assets/gallary.svg" />
                <h4 class="mt-2 ms-1">Photos</h4>
              </div>
              <div
                class="h1 d-flex align-items-center my-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src="../assets/hashtag.svg" class="mt-1" />
                <h4 class="mt-1 ms-2">Hashtag</h4>
              </div>
              <div
                class="h1 d-flex align-items-center my-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src="../assets/location-pin-1.jpg" />
                <h4 class="mt-1 ms-2">Location</h4>
              </div>
            </div>
            <!-- Modal -->
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div
                    class="modal-header"
                    style="background-color: white; border-bottom: none"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <img
                        v-if="profilePhoto != ''"
                        :src="profilePhoto"
                        alt
                        width="38"
                        height="34"
                        style="border-radius: 50%"
                      />
                      <img
                        v-else
                        src="./../assets/cover.png"
                        alt
                        width="38"
                        height="34"
                        style="border-radius: 50%"
                      />
                    </div>
                    <button
                      type="button"
                      class="btn-close me-1"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <ul
                      class="nav nav-tabs w-75 mx-auto"
                      id="myTabs"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link active text-center px-4 py-2"
                          id="tab1-tab"
                          data-bs-toggle="tab"
                          href="#tab1"
                          role="tab"
                          aria-controls="tab1"
                          aria-selected="true"
                          >Create post</a
                        >
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link text-center px-4 py-2"
                          id="tab2-tab"
                          data-bs-toggle="tab"
                          href="#tab2"
                          role="tab"
                          aria-controls="tab2"
                          aria-selected="false"
                          >Create Offer</a
                        >
                      </li>
                    </ul>

                    <!-- tab-content -->
                    <div class="tab-content mx-auto mt-3" id="myTabsContent">
                      <div
                        class="tab-pane fade show active"
                        id="tab1"
                        role="tabpanel"
                        aria-labelledby="tab1-tab"
                      >
                        <div
                          :class="{
                            'toggleable-div': true,
                            active: activeTab === 'tab1Div1',
                          }"
                          id="tab1Div1"
                        >
                          <!-- <h4
                            style="
                              width: 50%;
                              margin-left: 12%;
                              margin-top: -7.5%;
                              font-size: 135%;
                            "
                          >
                            {{ item.name }}
                         </h4> -->
                          <!-- <h5
                            style="
                              width: 50%;
                              font-size: 100%;
                              margin-left: 12%;
                              color: gray;
                              margin-top: -0.5%;
                            "
                          ></h5> -->
                          <form style="">
                            <div
                              class="text"
                              style="margin-left: 5%; width: 86.5%"
                            >
                              <input
                                id="title"
                                type="text"
                                v-model="title"
                                required
                              />
                              <span></span>
                              <label>First name</label>
                            </div>
                            <div
                              class="mpop"
                              style="
                                margin-top: 7%;
                                margin-left: 4.8%;
                                width: 100%;
                              "
                            >
                              <input
                                class="description"
                                id="description"
                                type="text"
                                v-model="description"
                                placeholder="What's on your mind?"
                              />
                            </div>

                            <div class="uploaded">
                              <!-- Input for selecting images -->
                              <label for="file" class="add-photo">
                                <input
                                  type="file"
                                  id="file"
                                  multiple
                                  ref="fileInput"
                                  @change="handleFileChange"
                                  accept="image/*"
                                  style="display: none"
                                />
                                <div
                                  class="add-photo-icon"
                                  v-if="!previewImage"
                                  :class="{
                                    'level-preview':
                                      showLevel && uploadedImages.length > 0,
                                  }"
                                >
                                  <img
                                    class="upload-img"
                                    src="../assets/gallary.svg"
                                    :class="{
                                      'level-preview-img':
                                        showLevel && uploadedImages.length > 0,
                                    }"
                                  />
                                  <span
                                    class="add-photo-text"
                                    style="font-size: x-large"
                                    :class="{
                                      'level-preview-textsize':
                                        showLevel && uploadedImages.length > 0,
                                    }"
                                    >Add Photo</span
                                  >
                                  <span
                                    class="add-photo-text"
                                    style="font-size: smaller"
                                    v-if="!showLevel"
                                    >or drag & drop</span
                                  >
                                </div>
                              </label>
                              <!-- Display uploaded images -->
                              <div
                                class="image-previews"
                                :class="{
                                  scrollable: uploadedImages.length > 3,
                                }"
                              >
                                <div
                                  class="image-row"
                                  v-for="(image, index) in chunkedImages"
                                  :key="index"
                                >
                                  <div
                                    class="image-preview"
                                    v-for="(img, imgIndex) in image"
                                    :key="imgIndex"
                                  >
                                    <button
                                      @click="removeImage(img.id)"
                                      class="remove-button"
                                    >
                                      <i
                                        class="fas fa-times"
                                        style="color: red"
                                      ></i>
                                    </button>
                                    <img
                                      :src="img.url"
                                      style="
                                        width: 100%;
                                        padding: 0.5rem;
                                        height: 10rem;
                                      "
                                      alt="Image Preview"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>

                          <div class="tag_checkin_btn">
                            <!-- <TagModal :user="user" :selected-users="selectedUsers" @user-selection="handleUserSelection" /> -->
                            <TagModal
                              :user="item"
                              :selected-users="localSelectedUsers"
                              @user-selection="handleUserSelection"
                            />
                          </div>
                          <button
                            class="post_button btn btn-danger"
                            type="button"
                            @click="fileUpload()"
                            data-bs-dismiss="modal"
                          >
                            Post
                          </button>
                        </div>
                        <div
                          :class="{
                            'toggleable-div': true,
                            active: activeTab === 'tab1Div2',
                          }"
                          id="tab1Div2"
                        ></div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="tab2"
                        role="tabpanel"
                        aria-labelledby="tab2-tab"
                      >
                        <div
                          :class="{
                            'toggleable-div': true,
                            active: activeTab === 'tab2Div1',
                          }"
                          id="tab2Div1"
                        ></div>
                        <div
                          :class="{
                            'toggleable-div': true,
                            active: activeTab === 'tab2Div2',
                          }"
                          id="tab2Div2"
                        >
                          <form style="margin-top: 0%">
                            <div class="text">
                              <input
                                type="text"
                                v-model="offer_name"
                                required
                              />
                              <span></span>
                              <label>OfferTitle</label>
                            </div>
                            <div
                              class="text-v"
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                              "
                            >
                              <input id="file" type="file" required />
                              <span></span>
                              <label>Upload offers Image</label>
                              <button
                                class="btn btn-primary btn-sm"
                                @click="OpenFile"
                              >
                                Browse
                              </button>
                            </div>

                            <div class="" style="">
                              <textarea
                                name
                                id
                                cols="46"
                                rows="4"
                                v-model="about_offer"
                                placeholder="About Offer..."
                              ></textarea>
                              <span></span>
                            </div>
                            <div class="About_Offer_Person">
                              <div
                                class="text"
                                style="width: 45%; margin-top: 8px"
                              >
                                <input type="text" required />
                                <span></span>
                                <label>Offer(7Days)</label>
                              </div>

                              <div
                                class="text"
                                style="
                                  width: 45%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-around;
                                  margin-top: 8px;
                                "
                              >
                                <input
                                  type="text"
                                  v-model="no_of_person"
                                  required
                                />
                                <!--  -->
                                <span></span>
                                <label>Person</label>
                                <div
                                  style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 6px;
                                  "
                                >
                                  <span @click="increment">
                                    <img
                                      src="../assets/a2.png"
                                      width="20"
                                      style="transform: rotate(90deg)"
                                      alt
                                    />
                                  </span>
                                  <p>{{ no_of_person }}</p>

                                  <span @click="decrement">
                                    <img
                                      src="../assets/arrow.jpg"
                                      width="20"
                                      alt
                                      style="transform: rotate(-88deg)"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="text" style="margin-top: 0">
                              <input type="text" required />
                              <span></span>
                              <label>Address</label>
                            </div>

                            <button
                              class="btn-1"
                              type="button"
                              style="margin-left: 0%"
                              @click="UploadOffer()"
                            >
                              Submit
                            </button>

                            <div class="popup-demo">
                              <div
                                class="popup-overlay"
                                v-if="showModal"
                                @click="closePopup"
                              ></div>
                              <div class="popup" v-if="showModal">
                                <div class="popup-content">
                                  <img
                                    src="../assets/Asset19@3x.png"
                                    width="100"
                                  />
                                  <p>
                                    Want to promate offer
                                    <sup>..</sup>?
                                  </p>
                                  <span>
                                    Lorem ipsum dolor sit amet consectetur,
                                    <br />adipisicing elit.
                                  </span>
                                  <div class="promated-btn">
                                    <button
                                      class="btn btn-secondary"
                                      style="
                                        background-color: #ccc;
                                        border: none;
                                        width: 38%;
                                      "
                                      type="button"
                                      @click="closePopup"
                                    >
                                      Cancle
                                    </button>
                                    <button
                                      class="btn btn-danger"
                                      style="border: none; margin: 1.5rem"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ChoosePlan"
                                      @click="closePopup"
                                    >
                                      Promate Now
                                    </button>

                                    <!-- Modal -->
                                    <div
                                      class="modal fade"
                                      id="ChoosePlan"
                                      tabindex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <h5
                                              class="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Choose Plan
                                            </h5>
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div class="modal-body">
                                            <div class="ChoosePlan_img">
                                              <img
                                                src="../assets/uncle.png"
                                                width="100"
                                              />
                                            </div>
                                            <div class="ChoosePlan_from">
                                              <form style="margin-top: 0%">
                                                <div class="text">
                                                  <div
                                                    class="text1-s"
                                                    style="margin-left: 0%"
                                                  >
                                                    <select
                                                      id="country"
                                                      class="s1"
                                                      style="
                                                        margin-left: 0%;
                                                        width: 100%;
                                                      "
                                                      v-model="plan_id"
                                                      @change="
                                                        choosePromotionPlan
                                                      "
                                                      required
                                                    >
                                                      <option value class="j">
                                                        select PromotionPlan
                                                      </option>
                                                      <!--  @change="choosePromotionPlan(plan.id,plan.amount)" -->
                                                      <option
                                                        class="j"
                                                        v-for="plan in promotionplans"
                                                        :key="plan.id"
                                                        :value="plan.id"
                                                      >
                                                        {{ plan.name }}
                                                      </option>
                                                    </select>
                                                  </div>
                                                  <span></span>
                                                </div>
                                                <div class="text">
                                                  <input
                                                    type="text"
                                                    v-model="coupon"
                                                    required
                                                  />
                                                  <span></span>
                                                  <label
                                                    >Enter Coupon Code</label
                                                  >
                                                </div>

                                                <div class="text-v">
                                                  <input
                                                    type="text"
                                                    v-model="business_mobile"
                                                    required
                                                  />
                                                  <span></span>
                                                  <label
                                                    >Enter Referral Code</label
                                                  >
                                                </div>

                                                <div
                                                  class="text1-s"
                                                  style="margin-left: 0%"
                                                >
                                                  <select
                                                    id="country"
                                                    class="s1"
                                                    style="margin-left: 0%"
                                                    v-model="state_id"
                                                    @change="getState()"
                                                    required
                                                  >
                                                    <option value class="j">
                                                      Select Country
                                                    </option>
                                                    <option
                                                      class="j"
                                                      v-for="country in countries"
                                                      :key="country.id"
                                                      :value="country.id"
                                                    >
                                                      {{ country.name }}
                                                    </option>
                                                  </select>

                                                  <select
                                                    id="state"
                                                    class="s2"
                                                    style="margin-left: 0%"
                                                    v-model="state_id"
                                                    @change="getCity()"
                                                    required
                                                  >
                                                    <option class="j" value>
                                                      Select State
                                                    </option>

                                                    <option
                                                      class="j"
                                                      v-for="state in states"
                                                      :key="state.id"
                                                      :value="state.id"
                                                    >
                                                      {{ state.name }}
                                                    </option>
                                                  </select>

                                                  <select
                                                    id="state"
                                                    class="s3"
                                                    style="margin-left: 0%"
                                                    v-model="city_id"
                                                    required
                                                  >
                                                    <option class="j" value>
                                                      Select City
                                                    </option>

                                                    <option
                                                      class="j"
                                                      v-for="city in cities"
                                                      :key="city.id"
                                                      :value="city.id"
                                                    >
                                                      {{ city.name }}
                                                    </option>
                                                  </select>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                          <div class="modal-footer">
                                            <button
                                              type="button"
                                              class="btn btn-danger"
                                              data-bs-dismiss="modal"
                                              style="width: 100%"
                                              @click="PaymentOp()"
                                            >
                                              Create Payment
                                            </button>
                                          </div>
                                        </div>
                                        <!-- payment form  -->

                                        <form
                                          id="paymentForm"
                                          method="post"
                                          :action="payuUrl"
                                        >
                                          <input
                                            type="hidden"
                                            v-model="mkey"
                                            name="key"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="txnid"
                                            name="txnid"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="amount"
                                            name="amount"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="productinfo"
                                            name="productinfo"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="name"
                                            name="firstname"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="email"
                                            name="email"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="phone"
                                            name="phone"
                                            size="64"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="surl"
                                            name="surl"
                                          />
                                          <input
                                            type="hidden"
                                            v-model="furl"
                                            name="furl"
                                          />
                                          <input
                                            type="hidden"
                                            id="hash"
                                            v-model="hash"
                                            name="hash"
                                            size="64"
                                          />
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <!--tab content end  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 mt-sm-3 mt-md-3">
          <div>
            <img src="../assets/bk.png" style="width: 100%; height: 18rem" />
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="d8">
          <div class="tab-box">
            <swiper
              :slides-per-view="6"
              :space-between="0"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide>
                <button>
                  <img src="../assets/select-all.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">All</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/groceries-i.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Grocery</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/gym.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Gym</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/dairy-i.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Dairy</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/cup-i.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Bakery</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/cafe-i.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Cafe</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/spoon.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Restaurant</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/Icecream-i.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Ice-cream</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/salon.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Salon</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/koper-i.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Koper</h6>
                </button>
              </swiper-slide>

              <swiper-slide style="width: 15%">
                <button>
                  <img src="../assets/clothes.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">Clothes</h6>
                </button>
              </swiper-slide>

              <swiper-slide>
                <button>
                  <img src="../assets/select-all.svg" width="65" height="40" />
                  <h6 class="mx-auto mt-2">New</h6>
                </button>
              </swiper-slide>
            </swiper>
          </div>
        </div>

        <div class="Top_brands">
          <h4>Top brands you</h4>
          <!-- <hr class="center_border" /> -->
          <div class="center_border"></div>
        </div>
        <owlslider />
      </div>
      <div class="near_by_offers">
        <dineinoffers />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import navbar from "./../components/nav.vue";
import owlslider from "./../owlslider.vue";
import dineinoffers from "./../components/ShowOffers.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import TagModal from "./Modal/TagModal.vue";

// Import Swiper styles
import "swiper/css";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "dinein",
  components: {
    navbar,
    owlslider,
    dineinoffers,
    Swiper,
    SwiperSlide,
    TagModal,
  },
  data() {
    return {
      promotionplans: [],
      showModal: false,
      showModalPay: false,
      promotedoffers: [],
      selectedFile: null,
      title: "",
      description: "",
      no_of_person: "",
      time: "",
      date: "",
      offers: [],
      offer_name: "",
      offer_promotion_plan: "1",
      offer_promotion_code: "DH24200",
      // no_of_person: "",
      ended_at: "",
      about_offer: "",
      plan_id: "",
      city_id: "",
      state_id: "",
      country_id: "",
      cities: [],
      countries: [],
      userData: [],
      readAllplans: [],

      payuUrl: "https://secure.payu.in/_payment",
      subscription: [],
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      name: "",
      email: "",
      phone: "",
      productinfo: "redprix",
      // surl: window.location.origin + "/Payment_Success",
      // furl: window.location.origin + "/Payment_Success",
      hash: this.hash,
      amount: null,
      txnid: this.makeid(),
      // payment_status: "1",
      coupon: "",

      // email: "",
      password: "",
      // phone: "", // customer phone number

      token: "",
      surl: window.location.origin + "/success", // success url
      furl: window.location.origin + "/fail", // failure url
      service_provider: "payu_paisa",

      userName: "",
      profilePhoto: "",
      item: {
        name: "",
      },
      previewImage: "",
      // defaultUserImage: "../assets/cover.png",
      userImage: "",
      uploadedImages: [],
      nextImageId: 1,
      showLevel: false,
      localSelectedUsers: [], // Create a local copy of selectedUsers
    };
  },
  computed: {
    chunkedImages() {
      const chunkSize = 3;
      return this.uploadedImages.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },
  },

  methods: {
    handleFileChange(event) {
      const files = event.target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.uploadedImages.push({
              id: this.nextImageId,
              url: e.target.result,
            });
            this.nextImageId++; // Increment the ID for the next image
            this.showLevel = true; // Apply the class after adding an image
          };
          reader.readAsDataURL(files[i]);
        }
      }
      this.selectedFile = files[0];
      console.log("Image File: ", this.selectedFile);
    },
    removeImage(imageId) {
      const index = this.uploadedImages.findIndex(
        (image) => image.id === imageId
      );
      if (index !== -1) {
        this.uploadedImages.splice(index, 1);
        if (this.uploadedImages.length === 0) {
          this.showLevel = false; // Remove the class if there are no images left
        }
      }
    },

    handleUserSelection(userId) {
      // Check if the userId is already in localSelectedUsers
      const index = this.localSelectedUsers.indexOf(userId);
      // console.log("this is index", index);
      if (index === -1) {
        // If not, push it to the localSelectedUsers array
        this.localSelectedUsers.push(userId);
      } else {
        // If it's already in the array, remove it from localSelectedUsers
        this.localSelectedUsers.splice(index, 1);
      }
    },

    fileUpload() {
      let formData = new FormData();
      formData.append("images[]", this.selectedFile);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append(
        "mentions",
        JSON.stringify({ user_ids: this.localSelectedUsers })
      ); // Use localSelectedUsers
      // ...
      console.log("Upload Function Called!!!");
      axios
        .post("customers/posts", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            DeviceID: "1111",
            versionName: "1.0.0",
            DeviceType: "0",
            responseType: "arraybuffer",
          },
        })
        .then((response) => {
          console.log(response);
          // location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    UploadOffer() {
      if (this.item.user_type == "2" && this.item.user_type_text == "Vendor") {
        axios
          .post("vendors/offers", {
            offer_name: this.offer_name,
            no_of_person: this.no_of_person,
            ended_at: this.ended_at,
            about_offer: this.about_offer,
          })
          .then(() => {
            // console.log(res.data.data);
          });
        this.showPopup();
      } else {
        alert(
          "Your not eligible to upload offer.\nYou need to build your profile as Vendor first !!!"
        );
      }
    },
    getData() {
      axios
        .get("countries?is_light=1")
        .then((result) => {
          this.countries = result.data.data;
          // console.log("conutries", this.countries);
          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getState() {
      axios
        .get("states?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCity() {
      axios
        .get("cities_of_state?is_light=1", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          // this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    PaymentOp() {
      // alert(this.amount);
      var data =
        this.mkey +
        "|" +
        this.txnid +
        "|" +
        this.amount +
        "|" +
        this.productinfo +
        "|" +
        this.name +
        "|" +
        this.email +
        "|||||||||||";
      var sha512 = require("js-sha512");
      var salt = "3oFxUMtWG2";
      var hash = sha512(data + salt);
      // console.log(this.amount);

      // console.log(hash);
      // console.log(data);
      document.getElementById("hash").value = hash;
      localStorage.setItem("hash", hash);
      document.getElementById("paymentForm").submit();
    },
    showPopup() {
      this.showModal = true;
    },
    closePopup() {
      this.showModal = false;
    },
    getUserProfile() {
      axios.get("customers/my-profile").then((res) => {
        // console.log(res.data.data);

        this.item = res.data.data;
        // console.log("Customer Response: ", this.item);
        this.userName = res.data.data.name;
        this.profilePhoto = res.data.data.profile_photo;
        // localStorage.setItem("id", res.data.data.business_category_id);

        // console.log("User Data: ", this.userProfile);
      });
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>
<style scoped>
.createoffer {
  border: 1px solid #edecec;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #edecec;
  padding: 1.5rem;
  height: 100%;
  cursor: pointer;
}
.user_details {
  display: flex;
  align-items: center;
  border: 1px solid #edecec;
  background-color: #edecec;
  margin: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.8rem 1.1rem;
  cursor: pointer;
}
.user_details img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.dine {
  margin-top: 0%;
  margin-left: -24.5%;
  width: 147.2%;
}

.home5 {
  max-width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-size: 4vw;
  width: 100%;
}
.h1 {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.h1 h4 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0;
}

.h1 img {
  height: 42px;
}

@media (max-width: 380px) {
  .h1 p {
    font-size: 0.5em;
  }

  .h1 img {
    height: 23px;
  }
  .h1 {
    padding: 0 0;
  }
}

.d8 {
  margin-top: 5%;
  overflow-x: hidden;
}
.d8,
.tab-box {
  display: flex;
  gap: 2.5%;
  overflow-x: auto;
  width: 95%;
  margin: 1rem auto;
}
.tab-box,
.tab {
  white-space: nowrap;
  white-space: nowrap;
  gap: 2.8%;
}
.tab-box button {
  background: white;
  border: none;
  border-radius: 10px;
}

.tab-box button h6 {
  margin-top: 15%;
}
.d8 button img {
  margin-left: -3%;
  box-shadow: 0px 0px 7px #ccc;
  padding: 3%;
  border-radius: 0.5rem;
}
.i1 {
  margin-left: 1%;
}
.Top_brands {
  display: flex;
  align-items: center;

  margin-bottom: 1.6rem;
  width: 100%;
}
.Top_brands h4 {
  width: auto;
  margin-left: 1rem;
}
.center_border {
  border: 1px solid #ccc;
  margin-left: 2rem;
  width: 80%;
}
.swiper-slide-active {
  width: 0%;
}
/* .swiper,.swiper-container {
  /* margin-left: auto; 
  /* margin-right: auto; 
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
  width: 50%;
} */
.near_by_offers {
  /* border:1px solid black; */
}
.createoffer {
  border: 1px solid #edecec;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #edecec;
}
.user_details {
  display: flex;
  align-items: center;
  border: 1px solid #edecec;
  background-color: #edecec;
  margin: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
/*.user_details img {
  width: 100px;
  border-radius: 50%;
  padding: 0rem 1.3rem 0rem;
}*/

.dine {
  margin-top: 0%;
  margin-left: -24.5%;
  width: 147.2%;
}

.home5 {
  margin-top: 5%;
  display: flex;
}
form {
  width: 100%;
  padding: 0 1.5rem;
  margin-top: 1.2rem;
}
form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 4%;
  width: 100%;
}
input[type="file"]::-webkit-file-upload-button {
  padding-left: -5rem;
  visibility: hidden;
}

.text input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 40%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

/* .form-2 {
   margin-left: -55%;
   margin-top: 10%; 
} */

form .text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 10px;
  width: 100%;
}

.text-v input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 15%;
  left: 17px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

/*.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}*/

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

form .text1-s {
  margin-left: -254px;
  margin-top: 25px;
}

select {
  width: 40%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1,
.s3 {
  margin-top: 20px;
  margin-left: 10%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
  width: 31%;
  margin-right: -3rem;
}

.s2 {
  margin-left: 61px;
  padding: 2px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 32%;
  margin-right: 3rem;
}
.text1-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}
.text1-s select:focus ~ option,
.text1-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-left: -54%;
  margin-top: 9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 136%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 11%;
  width: 100%;
  /* margin-left: -53.5%; */
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
textarea {
  width: 100%;
  resize: none;
  border: 1px solid black;
  outline: none;
  border-radius: 0.5rem;
  margin-top: 8px;
  padding: 8px 12px;
}
.About_Offer_Person {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup-demo {
  text-align: center;
  /*padding: 20px;*/
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.popup-content {
  text-align: center;
}
.promated-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.description {
  width: 86.5%;
  height: auto;
  outline: none;
  border-radius: 0.3rem;
  padding: 0.1rem;
  border-bottom: 1px solid black;
}
.tag_checkin_btn {
  width: 96%;
  margin: 0 auto;
}
.tag_btn {
  width: 50%;
  padding: 1% 8% 1%;
  display: flex;
  border: 1px solid black;
  align-items: center;
  background-color: #ffff;
  border-radius: 0.5rem;
  justify-content: space-around;
}
.white {
  width: 100%;
  border-radius: 0%;
  border: none;
  padding: 0% 39% 0%;
}
#file {
  top: 54%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  cursor: pointer;
}

.checkin_btn {
  width: 48%;
  padding: 2.5% 8% 1%;
  display: flex;
  border: 1px solid black;
  justify-content: space-around;
  align-items: center;
  background-color: #ffff;
  border-radius: 0.5rem;
}

/* Add Photo Button Styles */
.add-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.add-photo-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.upload-img {
  background-color: #ecebeb;
  border: 1px solid #ecebeb;
  border-radius: 3rem;
  height: 4rem;
}

.add-photo-text {
  font-weight: 700;
  color: #999999;
}

/* Uploaded Images Styles */
.uploaded {
  border: 4px dashed #ccc;
  width: 96%;
  display: flex;
  height: 13rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  margin-top: 4%;
  margin-bottom: 4%;
  position: relative;
}

.image-row {
  display: flex;
}

.image-preview {
  display: flex;
  position: relative;
  /* Relative positioning for nested elements */
  width: 50%;
  /* Display three images in a row */
  justify-content: center;
  align-items: center;
}

.image-previews.scrollable {
  max-height: 150px;
  /* Adjust the max-height as needed */
  overflow-y: auto;
}

.remove-button {
  color: red;
  font-weight: 600;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 13px;
  /* Move the cross icon to the top right corner of the image */
}

.level-preview {
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  z-index: 2;
  flex-direction: row;
  height: 2rem;
  width: 6rem;
}

.level-preview-img {
  width: 27%;
  height: 2rem;
  background: transparent;
  border: none;
}

.level-preview-textsize {
  font-size: smaller !important;
}
.modal-footer {
  border-top: none;
  width: 100%;
}
.post_button {
  background: #eb0e19;
  margin-top: 5%;
  cursor: pointer;
  width: 100%;
}
.nav-tabs .nav-link {
  /* margin-bottom: -1px; */
  /* background: 0 0; */
  border: 1px solid red;
  border-radius: 0.5rem;

  color: black;
  width: 108%;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: red;
  border-color: var(--bs-nav-tabs-link-active-border-color);
  width: 108%;
  margin-bottom: 0.5rem;
}
.nav-tabs .nav-item {
  margin-right: 0rem; /* Adjust the desired gap between tabs */
}
/* #tab1-tab,
#tab2-tab {
  text-align: center;
  border-bottom: 3px solid black;
  border-radius: 0;
} */

#myTabs {
  display: flex;
  justify-content: space-evenly;
  border-bottom: none;
}
.modal-header {
  color: #fff;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background-color: #eb0e19;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
</style>