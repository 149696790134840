<template>
  <div class="permission-div">
    <div class="permission-heading">
      <h3 class="fs-5 ps-3 pt-2 pb-1 text-grey">Permission</h3>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-5">
      <img
        class="img-fluid w-75"
        src="../assets/comming_soon_img.jpg"
        alt="Comming Soon Image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionComp",
};
</script>

<style scoped>
.permission-div {
  width: 100%;
  height: 40.5rem;
  border-radius: 10px;
  background: white;
  margin-bottom: 1%;
  box-shadow: 0px 0px 5px #ccc;
  overflow-y: auto;
}

.permission-div::-webkit-scrollbar {
  display: none;
}

.permission-heading {
  width: 100%;
  border-bottom: 1px solid grey;
}
</style>