<template>
  <div class="vendor-acc-div">
    <div class="vendor-acc-heading">
      <h3 class="fs-5 ps-3 pt-2 pb-1 text-grey">Vendor Account</h3>
    </div>
    <!-- Vendor Form  -->
    <div class="d-flex align-items-center justify-content-center">
      <div class="profile">
        <div class="upload d-flex flex-column flex-lg-row align-items-center">
          <div class="choose mt-4 mt-lg-0 position-relative">
            <div class="">
              <img
                :src="imageUrl"
                v-if="imageUrl"
                alt="Uploaded Image"
                class="imgp"
              />
              <img class="imgp" v-else src="../assets/pro2.png" />
            </div>
            <!-- <div class="w-auto position-absolute bottom-0 end-0">
              <img class="imgp1" src="../assets/so22.png" />
              <input type="file" @change="handleFileChange" />
            </div> -->
          </div>

          <div class="first-form-div ps-1 pe-4">
            <div class="text">
              <input type="text" v-model="business_name" required readonly />
              <span></span>
              <label>Business Name</label>
            </div>

            <div class="text">
              <input type="email" v-model="business_email" required readonly />
              <span></span>
              <label>Business Email</label>
            </div>
            <div class="text">
              <input type="text" v-model="business_mobile" required readonly />
              <span></span>
              <label>Business Mobile No</label>
            </div>
          </div>
        </div>

        <div class="w-100 px-4 mt-3">
          <div class="form-2">
            <h6>Business Address</h6>
            <div class="text-v">
              <input type="text" v-model="business_address" required readonly />
              <span></span>
              <label>Address</label>
            </div>
          </div>

          <div class="form-2 mb-2 mt-1">
            <div class="text-v">
              <input type="text" v-model="country_id" required readonly />
              <span></span>
              <label>Country</label>
            </div>
          </div>

          <div class="select_boxes">
            <div class="text-s">
              <!-- <select
                id="state"
                class="s2"
                v-model="state_id"
                @change="getCity()"
                required
                disabled
              >
                <option class="j" value>Select State</option>

                <option
                  class="j"
                  v-for="state in states"
                  :key="state.id"
                  :value="state.id"
                >
                  {{ state.name }}
                </option>
              </select>

              <select
                id="state"
                class="s3"
                v-model="city_id"
                @change="getCity()"
                required
                disabled
              >
                <option class="j" value>Select City</option>

                <option
                  class="j"
                  v-for="city in cities"
                  :key="city.id"
                  :value="city.id"
                >
                  {{ city.name }}
                </option>
              </select> -->
              <div class="text my-2" style="width: 48%">
                <input type="text" v-model="state_id" required readonly />
                <span></span>
                <label>State</label>
              </div>
              <div class="text my-2" style="width: 48%">
                <input type="text" v-model="city_id" required readonly />
                <span></span>
                <label>City</label>
              </div>
            </div>
          </div>

          <!-- <div class="date">
            <label for="birth_date">Date of birth</label>
            <label for="birth_date" id="dob" v-on:click="Userdob"></label>
            <input type="date" id="birth_date" v-model="birth_date" />
          </div> -->

          <!-- <button class="btn-1" type="button" @click="updateVendorProfile()">
            Update
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  name: "PermissionComp",
  data() {
    return {
      imageUrl: null,
      vendorProfile: [],
      influencerProfile: [],
      selectedFile: null,
      country_id: 101,
      city_id: "",
      state_id: "",
      cities: [],
      states: [],
      countries: [],
      business_name: "",
      business_email: "",
      business_mobile: "",
      business_address: "",
    };
  },
  methods: {
    getData() {
      axios
        .get("countries?is_light=1")
        .then((result) => {
          this.countries = result.data.data;
          // console.log("conutries", this.countries);
          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getState() {
      axios
        .get("states?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          // this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCity() {
      axios
        .get("cities_of_state?is_light=1", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          // this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.selectedFile);
    },
    getVendorProfile() {
      axios.get("vendors/my-vendor-profile").then((res) => {
        // console.log("Vendor Profile Response: ", res);
        this.vendorProfile = res.data.data;

        //Assign Data of vendor
        this.imageUrl = this.vendorProfile.business_logo;
        this.business_name = this.vendorProfile.business_name;
        this.business_email = this.vendorProfile.business_email;
        this.business_mobile = this.vendorProfile.business_mobile;
        this.business_address = this.vendorProfile.business_address;
        this.country_id = this.vendorProfile.country.name;
        this.state_id = this.vendorProfile.state.name;
        this.city_id = this.vendorProfile.city.name;
      });
    },
    // updateVendorProfile() {
    //   const formData = new FormData();
    //   formData.append("business_name", this.business_name);
    //   formData.append("business_email", this.business_email);
    //   formData.append("business_address", this.business_address);
    //   formData.append("business_mobile", this.business_mobile);
    //   formData.append("business_logo", this.selectedFile);
    //   formData.append("state_id", this.state_id);
    //   formData.append("country_id", this.country_id);
    //   formData.append("city_id", this.city_id);
    //   formData.append("business_id", this.vendorProfile.business_category_id);

    //   // console.log("User Id: ", this.vendorProfile.id);
    //   // console.log("Form Data:");
    //   // formData.forEach((key, value) => {
    //   //   console.log(key, value);
    //   // });
    //   axios
    //     .post("vendors/vendors/update/" + this.vendorProfile.id, formData)
    //     .then(() => {
    //       // console.log(response);
    //       location.reload();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
  },
  mounted() {
    // this.getData();
    // this.getState();
    this.getVendorProfile();
  },
};
</script>
  
  <style scoped>
.vendor-acc-div {
  width: 100%;
  height: 36rem;
  border-radius: 10px;
  background: white;
  margin-bottom: 1%;
  box-shadow: 0px 0px 5px #ccc;
  overflow-y: auto;
}

.vendor-acc-div::-webkit-scrollbar {
  display: none;
}

.vendor-acc-heading {
  width: 100%;
  border-bottom: 1px solid grey;
}

.row {
  width: 100%;
}
.content {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 10px;
}

.profile {
  width: 100%;
  border-radius: 1%;
  background-color: white;
}

.profile header {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  color: grey;
  line-height: 2.6;
  padding: 0rem 1.2rem;
  user-select: none;
  border-bottom: 1px solid grey;
  margin-top: 0px;
}

.imgp {
  width: 10rem;
  height: 9rem;
  border-radius: 50%;
  border: 6px solid #eaeaea;
}

.imgp1 {
  width: 40px;
  background: skyblue;
  border-radius: 50%;
}

.choose {
  width: 35%;
  text-align: center;
  cursor: pointer;
}

.choose input[type="file"] {
  transform: scale(2);
  opacity: 0;
  width: 21px;
  cursor: pointer;
}

.first-form-div {
  width: 65%;
}

@media only screen and (max-width: 992px) {
  .first-form-div {
    width: 100%;
    padding: 0 1.5rem !important;
  }

  .imgp {
    margin-left: -1.6rem;
  }
}

.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 28px 0;
  width: 100%;
}

.text input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.form-2 {
  margin-top: 2%;
}

.text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 0;
  width: 100%;
}

.text-v input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

.text-s {
  /* margin-top: 25px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

select {
  width: 47%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s2,
.s3 {
  margin-top: 1rem;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
}

#dob {
  background: transparent;
  height: 50%;
  position: absolute;
  top: 38%;
  left: -5%;
  /* border: 8px solid black; */
  width: 100%;
  cursor: pointer;
}

.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-top: 1.6rem;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 100%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 4rem;
  width: 100%;
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>