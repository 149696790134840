<template>
  <div class="col-md-12">
    <router-view class="animate__animated animate__fadeIn"></router-view>
    <div class="grid-container" v-if="MyPost.length > 0">
      <div
        class="grid-item"
        v-for="post in MyPost"
        :key="post.id"
        @click="openModal(post)"
      >
        <div class="post_img">
          <img :src="post.images[0].image" alt="img" class="post_img" />
        </div>
      </div>

      <!-- Modal HTML -->
      <div id="myModal" class="modal" v-if="showpopup" @click="closeModal">
        <div class="modal-content">
          <div class="single_post">
            <div class="modalImage pt-5 mt-5">
              <img
                :src="selectedPost.images[0].image"
                id=""
                alt="img"
                class="img-fluid"
              />
            </div>

            <div class="user_details">
              <div class="d-flex align-items-center mx-2">
                <img
                  src="../../assets/cover.png"
                  alt
                  v-if="selectedPost.user.profile_photo == ''"
                />
                <img
                  :src="selectedPost.user.profile_photo"
                  v-else
                  controls
                  width="45"
                  height="45"
                />

                <div class="ms-2" style="width: 78%">
                  <h6 class="mb-0 fs-5 fw-bold">
                    {{ selectedPost.user.name }}
                  </h6>
                  <!-- {{ selectedPost.images[0].post_id }} -->
                </div>
              </div>
              <div class="list_btn">
                <list :postId="selectedPost.id" />
              </div>
              <div class="user_description">
                <h5 style="font-size: 80%; color: gray">
                  {{ selectedPost.description }}
                </h5>
              </div>
              <div class v-if="selectedPost.description == ''">
                <h5 style="font-size: 80%; color: gray">No description</h5>
              </div>
              <div class="mx-auto">
                <div class="user_action">
                  <userlike
                    :postId="selectedPost.id"
                    :likes_count="selectedPost.likes_count"
                    :is_liked="selectedPost.is_liked"
                  />
                  <usercomment
                    :postId="selectedPost.id"
                    :comments_count="selectedPost.comments_count"
                  />
                  <usershare
                    :postId="selectedPost.id"
                    :shares_count="selectedPost.shares_count"
                  />
                </div>

                <div class="comment_section">
                  <div v-for="comment in ShowComments" :key="comment.id">
                    <div
                      class="user_comments"
                      v-if="
                        comment.user.profile_photo || comment.comment.length > 0
                      "
                    >
                      <img
                        src="../../assets/cover.png"
                        alt
                        v-if="comment.user.profile_photo == ''"
                      />
                      <img :src="comment.user.profile_photo" />
                      <div class="comments">
                        <div
                          class="no-comments"
                          v-if="comment.comment.length == 0"
                        >
                          <p>No Comments</p>
                        </div>
                        {{ comment.user.name }}
                        <br />
                        {{ comment.comment }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p
                style="text-decoration: underline;margin:0; padding: 1%;"
              >load more comments</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 py-3 text-center" v-else>
      <p class="mx-auto text-muted mb-0 mt-1">
        You didn't upload anything yet.
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import userlike from "./../../components/user-like.vue";
import usercomment from "./../../components/user-comment.vue";
import usershare from "./../../components/user-share.vue";
import list from "./../../components/list.vue";
export default {
  name: "MyPost",
  components: {
    userlike,
    usercomment,
    usershare,
    list,
  },
  data() {
    return {
      MyPost: [],
      // modalImageSrc: "",
      showpopup: false,
      showModal: false,
      postId: "",
      id: "",
      ShowComments: null,
    };
  },
  // computed:{

  // },
  mounted() {
    this.myPost();
  },
  methods: {
    opendropdown() {
      // alert("hello");
      const showButton = document.getElementById("dropdown-trigger");
      const hiddenDiv = document.getElementById("dropdown-content");

      showButton.addEventListener("click", () => {
        hiddenDiv.classList.toggle("dropdown-content");
      });
    },

    async myPost() {
      await axios.get("customers/my-posts").then((res) => {
        // console.log(res.data.data);
        this.MyPost = res.data.data;
        // console.log("MyPost", this.MyPost);
      });
    },
    openModal(post) {
      this.showpopup = true;
      this.selectedPost = post;
      this.showComments(post.id);
      // console.log("selected post", this.selectedPost);
    },
    // closeModal(event) {
    //   if (event.target.id === "myModal") {
    //     const modal = document.getElementById("myModal");
    //     modal.style.display = "none";
    //   }
    // },

    closeModal(event) {
      if (event.target.id === "myModal") {
        this.showpopup = false;
        // this.selectedPost = null;
      }
    },
    showComments(postId) {
      axios
        .post("customers/posts/comments", {
          post_id: postId,
        })
        .then((response) => {
          this.ShowComments = response.data.data.comments;
          // console.log("comments", this.ShowComments);
        });
    },
  },
};
</script>

<style scoped>
.comment_section {
  height: 25rem;
  overflow-y: auto;
}

.row {
  height: 7.5vh;
  width: 75%;
  margin-left: 12%;
}
.container {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 0px 8px #ccc;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  gap: 10px;
}

.grid-item {
  margin: 0.5rem auto;
  /* border: 1px solid #ccc; */
  padding: 1px;
  text-align: center;
  width: 90%;
}
.post_img {
  border-radius: 0.4rem;
  width: 100%;
  height: 10rem;
}

/* The Modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  max-width: 100%; /* Adjust this value based on your preference */
  margin: 0 auto;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Content */
.modal-content {
  margin: 3rem auto;
  width: 75vw;
  max-width: 90vw;
  /* width: 57%; */
  /* max-width: 80%; */
  /* max-height: 80%; */
  /* height: 70%; */
  background: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

/* Close Button */
.close {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #ccc;
  text-decoration: none;
  cursor: pointer;
}
.modalImage {
  width: 48%;
  height: 100%;
}
.single_post {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.user_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
}
.user_details img {
  width: 3rem;
  height: 2.9rem;
  border-radius: 50%;
}
/* .user_details span{
      margin-left: -3rem;
} */
.user_profile_img {
  width: 20%;
  height: 20%;
  border-radius: 50%;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #fff;
  border: 1px solid #aaa;
}

.dropdown.active .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}
#dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

#dropdown-content a:hover {
  background: #f0f0f0;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
/* .User_tags {
  margin-top: 6rem;
  margin-left: -26rem;
} */
.user_action {
  padding: 0.2rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user_action img {
  width: 5%;
}
.user_comments {
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.user_comments img {
  width: 10%;
}
.comments {
  width: 100%;
  background-color: #ccc;
  padding: 0.2rem;
  margin: 0.3rem;
  border-radius: 0.4rem;
}
.user_name {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.3rem;
}
.user_name img {
  width: 5%;
}
.post-nav {
  gap: 20px;
  border-bottom: 2px solid #ccc;
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  width: 101%;
}
.user_description {
  width: 100%;
  margin-bottom: 10px;
  display: block;
  padding: 3%;
}

@media (max-width: 768px) {
  /* The Modal */
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    max-width: 100vw; /* Adjust this value based on your preference */
    width: 100vw;
    margin: 0 auto;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.5rem;
  }

  /* Modal Content */
  .modal-content {
    margin: 3rem auto;
    background: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    /* overflow: auto; */
    max-width: 100vw; /* Adjust this value based on your preference */
    width: 95vw;
  }
  #modalImage {
    width: 100%;
  }
  .single_post {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .user_action {
    padding: 0.5rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    width: 90vw;
    max-width: 100vw;
  }
  .user_details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding: 0.5rem;
  }
  .user_details img {
    width: 10%;
  }
}
</style>