<template>
  <div class="content">
    <div class="main">
      <div class="influ">
        <header>Details of Influencer</header>
        <form class="px-4">
          <div class="d-flex align-items-center justify-content-between">
            <div class="first">
              <input type="text" required />
              <span></span>
              <label>First name</label>
            </div>
            <div class="second">
              <input type="text" required />
              <span></span>
              <label>Last name</label>
            </div>
          </div>
          <div class="third">
            <input type="text" required />
            <span></span>
            <label>Email id</label>
          </div>
          <div class="third">
            <input type="text" required />
            <span></span>
            <label>Addreess</label>
          </div>

          <div class="thirdt">
            <input type="text" required />
            <span></span>
            <label>Mobile Number</label>
          </div>

          <div class="text-s d-flex align-items-center justify-content-between">
            <select
              id="country"
              class="s1"
              v-model="country_id"
              @change="getState()"
              required
            >
              <option value class="j">Select Country</option>
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>

            <select
              id="state"
              class="s2"
              v-model="state_id"
              @change="getCity()"
              required
            >
              <option class="j" value>Select State</option>

              <option
                class="j"
                v-for="state in states"
                :key="state.id"
                :value="state.id"
              >
                {{ state.name }}
              </option>
            </select>
          </div>

          <div class="d-flex align-items-center justify-content-between">
            <select
              id="state"
              class="s3"
              v-model="city_id"
              @change="getCity()"
              required
            >
              <option class="j" value>Select City</option>

              <option
                class="j"
                v-for="city in cities"
                :key="city.id"
                :value="city.id"
              >
                {{ city.name }}
              </option>
            </select>
            <div class="fourth">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
          </div>

          <div class="fifth">
            <input type="text" required />
            <span></span>
            <label id="fifth-1"
              >Link of Your Social Media Account or Website</label
            >
          </div>

          <div class="fifth">
            <input type="text" required v-model="social_media_link" />
            <span></span>
            <label id="fifth-2">Social Media Link</label>
          </div>

          <div class="fifth">
            <input type="text" required v-model="followers_count" />
            <span></span>
            <label id="fifth-3">Followers</label>
          </div>

          <div class="fifth">
            <input type="text" required v-model="platform" />
            <span></span>
            <label id="fifth-4">Plathform</label>
          </div>

          <!-- <div class="sixth">
            <button type="button" @click="addfileds" id="addButton">
              Add+
            </button>
          </div> -->

          <div class="seventh">
            <button
              type="button"
              class="submit"
              id="submit"
              @click="becomeinflu()"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div class="modal2" id="modal2">
        <!-- <div class="popup2">
          <header>
            Add Bank Details
            <div class="pop">
              <button @click="closePopup()">
                <img
                  class="imgpop mt-3"
                  src="../assets/cancel.png"
                  width="35"
                />
              </button>
            </div>
          </header>
          <form>
            <div class="firstp">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
            <div class="secondp">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
            <div class="thirdp">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
            <div class="fourthp">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
            <div class="thirdp">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
            <div class="fourthp">
              <input type="text" required />
              <span></span>
              <label>Area Pin Number</label>
            </div>
            <div class="fifthp">
              <input type="text" required />
              <span></span>
              <label>Email id</label>
            </div>
            <div class="desp">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Id natus
              deleniti illum veniam dignissimos qui blanditiis possimus, quam
              necessitatibus dolores soluta labore facilis. Sit aliquid facilis
              harum corporis, voluptatum commodi nam repudiandae, reiciendis
              soluta dolorem similique, quo saepe.
            </div>

            <button @click="openToggle()">Reedem Now</button>
          </form> -->

        <div class="modal3" id="modal3">
          <div class="popup3">
            <img src="../assets/checked.png" />
            <h2>Thank You</h2>
            <p>
              Thank You for applying this.Our Manager will call you back very
              shortly
            </p>
            <button id="close3" @click="closeToggle()">Ok</button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "joininflu",
  data() {
    return {
      country_id: "101",
      city_id: "",
      state_id: "",
      states: [],
      cities: [],
      countries: [],
      inputGroups: [],
      social_media_link: "",
      followers_count: "",
      platform: "",
    };
  },
  mounted() {
    this.getData();
    this.getState();
  },

  methods: {
    becomeinflu() {
      const formData = new FormData();

      const linkArray = this.social_media_link.split(" ");
      // console.log("Link Array: ", linkArray);
      const followersArray = this.followers_count.split(" ");
      // console.log("Followers Array: ", followersArray);
      const platformArray = this.platform.split(" ");
      // console.log("Platform Array: ", platformArray);

      formData.append("social_media_link", JSON.stringify(linkArray));
      formData.append("followers_count", JSON.stringify(followersArray));
      formData.append("platform", JSON.stringify(platformArray));

      // console.log("Influencer Data: ");
      // formData.forEach((value, key) => {
      //   console.log("Key: ", key, " Value: ", value);
      // });

      axios.post("influencer/influencer", formData).then(() => {
        // console.log("Response Of Influe: ", res);
        alert("Your Influencer Account Created!!");
        this.$router.push("/setting/Changepassword");
      });
    },
    openPopup() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.add("open-popup3");
    },
    closePopup() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.remove("open-popup3");
    },
    openToggle() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.add("open-popup4");
      let modal2 = document.getElementById("modal2");
      modal2.classList.remove("open-popup3");
    },
    closeToggle() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.remove("open-popup4");
    },
    getData() {
      axios
        .get("countries?is_light=1")
        .then((result) => {
          this.countries = result.data.data;
          // console.log("conutries", this.countries);
          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getState() {
      axios
        .get("states?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          // this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCity() {
      axios
        .get("cities_of_state?is_light=1", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          // this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // addfileds() {
    //   // eslint-disable-next-line no-debugger
    //   // debugger;

    //   const newInputGroup = [
    //     { index: this.inputGroups.length,inputIndex: 0, label: 'Social Media Link' },
    //     { index: this.inputGroups.length,inputIndex: 1, label: 'Followers Count' },
    //     { index: this.inputGroups.length,inputIndex: 2, label: 'Platform' },
    //   ];
    //   this.inputGroups.push(newInputGroup);
    // },
    //     const newIndex = this.inputFields.length;
    //     this.inputFields.push(newIndex);
  },
  // },
};
</script>
<style scoped>
form {
  width: 100%;
}

form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 82%;
  margin-left: 8%;
}

.text input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.content {
  margin: auto;
  height: 40.5rem;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 7px #ccc;
}

.content::-webkit-scrollbar {
  display: none;
}
.main {
  list-style: none;
  width: 100%;
}

.influ {
  width: 100%;
  /* margin-top: -84%; */
  border-radius: 10px;
  align-items: center;
  background-color: #ffffff;
}

.influ header {
  font-size: 1.5rem;
  text-align: left;
  line-height: 265%;
  background: white;
  width: 100%;
  font-weight: 500;
  color: grey;
  padding-left: 20px;
  border-bottom: 1px solid grey;
}

.influ form {
  margin-top: 2%;
}
#inputContainer input {
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 141%;
  margin-left: -55%;
  outline: none;
}
.input-field {
  width: 100%;
}

.first,
.second {
  position: relative;
  border-bottom: 1px solid black;
  width: 48%;
}
.first input,
.second input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.first label,
.second label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.third,
.fourth,
.thirdt,
.fifth {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 100%;
}
.fifthp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 100%;
}
.third input,
.fourth input,
.thirdt input,
.fifth input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.third label,
.fourth label,
.thirdt label,
.fifth label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.fourth {
  width: 48%;
}
.fourth label {
  left: 6.5%;
}
#fifth-1 {
  left: 5.5%;
}
#fifth-2 {
  left: 2.5%;
}
#fifth-3 {
  left: 2%;
}
#fifth-4 {
  left: 2%;
}
.thirdt label {
  left: 2%;
}
.text-s {
  margin-top: 4%;
}

select {
  width: 48%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1,
.s3 {
  width: 48%;

  margin-top: 20px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
}
.s3 {
  margin-top: 5%;
  margin-left: 0%;
}

.s2 {
  width: 48%;
  padding: 2px;
  margin-top: 20px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
}
.sixth button {
  margin-top: 3%;
  width: 100%;
  line-height: 1.8;
  background: cornflowerblue;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.3rem;
}

.seventh button {
  margin-bottom: 3%;
  margin-top: 5%;
  width: 100%;
  line-height: 2;
  background: #eb0e19;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.modal2 {
  background: #00000021;
  margin-top: -89%;
  width: 215.5%;
  margin-left: -81%;
  height: 107.7%;
  visibility: hidden;
}
.open-popup3 {
  visibility: visible;
}
.popup2 {
  width: 58%;
  height: 39vmax;
  background: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 77%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* padding: 0 30px 30px; */
  color: #333;
  box-shadow: 0px 0px 7px;
}
.popup2 header {
  margin-left: 0%;
  width: 100%;
  background: #eb0e19;
  border: none;
  /* height: 13%; */
  /* text-align: center; */
  line-height: 3.5;
  color: white;
}

.first span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.first input:focus ~ label,
.first input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.second span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.second input:focus ~ label,
.second input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.third span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.third input:focus ~ label,
.third input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.thirdt span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.thirdt input:focus ~ label,
.thirdt input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.fourth span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.fourth input:focus ~ label,
.fourth input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.fifth span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.fifth input:focus ~ label,
.fifth input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.firstp,
.secondp {
  position: relative;
  border-bottom: 1px solid black;
  margin-top: -16%;
  width: 65%;
  margin-left: -59%;
}
.firstp input,
.secondp input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.firstp label,
.secondp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.secondp {
  margin-left: 19%;
  margin-top: -7.7%;
}
.thirdp,
.fourthp {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 6%;
  width: 65%;
  margin-left: -59%;
  padding: 0%;
}
.thirdp input,
.fourthp input {
  width: 82%;

  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.thirdp label,
.fourthp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.fourthp {
  margin-left: 19%;
  margin-top: -11.5%;
}
.fifthp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 5%;
  width: 143%;
  margin-left: -59%;
}
.fifthp input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.fifthp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.desp {
  margin-left: -60%;
  width: 153%;
  margin-top: 12%;
}
.popup2 button {
  margin-left: -194%;
  margin-top: 0%;
  border: none;
  background: red;
  width: 35%;
  line-height: 2.1;
  border-radius: 6px;
  color: white;
  font-size: 118%;
  cursor: pointer;
}

.pop button {
  margin-left: 93%;
  display: flex;
  width: 3%;
  /* line-height: 1; */
  margin-top: -7.7%;
  position: fixed;
  background: none;
  border: none;
  cursor: pointer;
}

.modal3 {
  background: #00000021;
  margin-top: -62%;
  width: 207.2%;
  margin-left: -71%;
  height: 120%;
  visibility: hidden;
}
.popup3 {
  width: 27%;
  /* height: 52%; */
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  top: 45%;
  left: 61%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
}
.open-popup4 {
  visibility: visible;
  transform: scale(1);
}
.popup3 img {
  width: 43%;
  margin-top: 4%;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.popup3 h2 {
  font-size: 38px;
  font-weight: 500;
  margin: 30px 0 10px;
}
.popup3 button {
  width: 80%;
  margin-top: 17%;
  margin-left: 1%;
  padding: 10px 0;
  background: #6fd649;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
</style>