<template>
  <div class="container">
    <div class="heading">
      <h3 class="fs-5">Following</h3>
      <div class="search_input">
        <input type="search" placeholder="Search" />
      </div>
    </div>

    <div class="followers">
      <div>
        <div v-if="visibleFollowing.length === 0">
          <p class="text-center" style="margin: 2% auto">
            You are not following anyone yet.
          </p>
        </div>
      </div>
      <div class="grid-container">
        <div
          v-for="follows of visibleFollowing"
          :key="follows.id"
          class="grid-item"
        >
          <div class="follower_profile">
            <div>
              <img
                :src="follows.profile_photo || defaultImage"
                alt="usr-image"
              />
            </div>
            <div class="follower_details text-left w-100 mx-3">
              <p>{{ follows.first_name }} {{ follows.last_name }}</p>
              <span>{{ follows.city.name }} </span>
            </div>
            <div class="Follow_btn">
              <button class="btn btn-danger">unfollow</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-container mt-2">
        <button
          class="btn btn-danger w-100"
          v-if="following.length > visibleFollowing.length"
          @click="loadMore"
        >
          Load More
        </button>
        <button
          class="btn btn-danger w-100"
          v-if="visibleFollowing.length > 8"
          @click="seeLess"
        >
          See Less
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "vendFollowing",
  data() {
    return {
      following: [],
      visibleFollowing: [],
      followingItemShow: 8,
      defaultImage: require("@/assets/defaultPersonImage.jpg"),
    };
  },
  mounted() {
    this.myfollowing();
  },
  methods: {
    myfollowing() {
      axios.get("customers/follows/my-followings").then((res) => {
        this.following = res.data.data;
        this.updateDisplayedFollowings();
      });
    },

    updateDisplayedFollowings() {
      this.visibleFollowing = this.following.slice(0, this.followingItemShow);
      // console.log(this.visibleFollowing);
    },

    loadMore() {
      this.followingItemShow += 4;
      this.updateDisplayedFollowings();
    },

    seeLess() {
      this.followingItemShow = 8;
      this.updateDisplayedFollowings();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

/* General styles */
.heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading h3 {
  border-bottom: 1px solid black;
}

.search_input {
  padding: 0% 1% 0%;
}

.search_input input {
  width: 16vw;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  padding: 0.2rem 1.2rem;
}

.container {
  box-shadow: 0px 0px 6px #d4d4d4;
  border: 1px solid #ccc;
  padding: 0.3rem;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
  width: 100%;
}

/* Grid styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.grid-item {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.grid-container > .grid-item {
  font-size: 30px;
  padding: 0.5em;
  text-align: center;
}

/* Follower profile styles */
.follower_profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

/* Follower details styles */
.follower_details p {
  margin-bottom: -1rem;
  font-size: 0.9rem;
}

.follower_details span {
  font-size: 0.9rem;
}

/* Follow button styles */
.Follow_btn button {
  background-color: #ccc;
  border: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
</style>
