<template>
  <div class="container-fluid animate__animated animate__fadeIn">
    <div class="container">
      <div class="otp1">
        <header>
          <h1>Verification Code</h1>
        </header>
        <h5>Enter the verification code received in the mail I'd</h5>

        <div class="otp2">
          <!-- <input id="partitioned" type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');" maxlength="1" v-model="email_otp" autocomplete="off"  /> -->

          <input
            type="text"
            id="o1"
            class="text1"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
            placeholder
            maxlength="1"
            v-model="otpDigit1"
            v-on:click="focus()"
            required
          />
          <input
            type="text"
            id="o2"
            class="text2"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
            placeholder
            v-model="otpDigit2"
            maxlength="1"
            required
          />
          <input
            type="text"
            id="o3"
            class="text3"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
            placeholder
            v-model="otpDigit3"
            maxlength="1"
            required
          />
          <input
            type="text"
            id="o4"
            class="text4"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
            placeholder
            v-model="otpDigit4"
            maxlength="1"
            required
          />
          <input
            type="text"
            id="o5"
            class="text5"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
            placeholder
            v-model="otpDigit5"
            maxlength="1"
            required
          />
          <input
            type="text"
            id="o6"
            class="text5"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
            placeholder
            v-model="otpDigit6"
            maxlength="1"
            required
          />
        </div>

        <div class="otp3">
          <!-- <router-link to="/home" custom v-slot="{ navigate }"> -->
          <button
            v-on:click="otpVerify()"
            @click="navigate"
            role="link"
            type="confirm"
          >
            <span v-if="!loadingSpinner">Confirm</span>
            <div v-else class="loading-spinner mx-auto">
              <div class="spinner"></div>
            </div>
          </button>
          <!-- </router-link> -->
        </div>

        <div class="otp4">
          <span>Don't receive OTP?</span>
          <div class="otp5">
            <span @click="resend()">Resend</span>
            <br />
            <!-- enteredOTP:{{enteredOTP}} -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Helloworld",
  data() {
    return {
      email: "",
      email_otp: "",
      token: [],
      otpDigit1: "",
      otpDigit2: "",
      otpDigit3: "",
      otpDigit4: "",
      otpDigit5: "",
      otpDigit6: "",
      emailOTP: [],
      otp: {
        email: "",
        email_otp: "",
      },
      loadingSpinner: false,
    };
  },

  methods: {
    focus() {
      let otp2 = document.getElementsByClassName("otp2")[0];
      otp2.onkeyup = function (e) {
        let target = e.srcElement;
        let maxLength = parseInt(target.attributes["maxlength"].value, 10);
        let myLength = target.value.length;
        if (myLength >= maxLength) {
          let next = target;
          while ((next = next.nextElementSibling)) {
            if (next == null) break;
            if (next.tagName.toLowerCase() == "input") {
              next.focus();
              break;
            }
          }
        } else if (myLength === 0) {
          let previous = target;
          while ((previous = previous.previousElementSibling)) {
            if (previous == null) break;
            if (previous.tagName.toLowerCase() === "input") {
              previous.focus();
              break;
            }
          }
        }
      };
    },

    // async otpVerify() {
    //   const enteredOTP = `${this.otpDigit1}${this.otpDigit2}${this.otpDigit3}${this.otpDigit4}${this.otpDigit5}${this.otpDigit6}`;
    //   console.log("entred otp", enteredOTP);

    //   await axios
    //     .post("verify-email", {
    //       email_otp: enteredOTP,
    //       email: localStorage.getItem("email"),
    //     })

    //     .then((res) => {
    //       this.email = "";
    //       this.email_otp = "";
    //       this.userdetails = res.data.data;
    //       if (enteredOTP !=this.emailOTP) {
    //         // OTPs match, navigate to the login screen
    //         this.$router.push("/");
    //         // OTPs do not match, display an error message
    //         alert("Invalid OTP. Please try again.");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       alert(error.response.data.message);
    //     });
    // },
    // async resend() {
    //   var emaill = localStorage.getItem("email");
    //   const respodnse = await axios.post("resend-email-otp", { email: emaill });
    //   console.log(respodnse);
    // },

    async otpVerify() {
      this.loadingSpinner = true;
      const enteredOTP = `${this.otpDigit1}${this.otpDigit2}${this.otpDigit3}${this.otpDigit4}${this.otpDigit5}${this.otpDigit6}`;
      // console.log("entered otp", enteredOTP);

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post("verify-email", {
          email_otp: enteredOTP,
          email: localStorage.getItem("email"),
        });

        this.loadingSpinner = false;

        this.email = "";
        this.email_otp = "";
        this.emailOTP = localStorage.getItem("OTP");

        // console.log("enteredOTP:", enteredOTP);
        // console.log("this.emailOTP:", this.emailOTP);
        if (enteredOTP === this.emailOTP) {
          // OTPs match, navigate to the login screen
          this.$router.push("/login");
          localStorage.removeItem("OTP");
        } else {
          // OTPs do not match, display an error message
          alert("Invalid OTP. Please try again.");
        }
      } catch (error) {
        console.error(error);
        alert(error.response.data.message);
      }
    },

    async resend() {
      this.loadingSpinner = true;
      var emaill = localStorage.getItem("email");

      try {
        const response = await axios.post("resend-email-otp", {
          email: emaill,
        });
        // console.log(response);

        this.loadingSpinner = false;

        // Show an alert when the response is successful
        if (response.status == 200) {
          alert("OTP has been resent successfully!");
        } else {
          alert("Failed to resend OTP. Please try again later.");
        }
      } catch (error) {
        console.error("Error while resending OTP:", error);
        alert(error.response.data.message);
      }
    },
  },
};
</script>
<style scoped>
.col-lg-4 {
  width: 100%;
}

.loading-spinner {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: auto;
  border: 6px solid #ffffff;
  border-radius: 50%;
  animation: loading-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #ffffff transparent transparent transparent;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container-fluid {
  background: url("../assets/wall.jpeg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 12% 0% 0% 7%; */
  height: 100vh;
}

.row {
  margin: 0 auto;
}

.sign {
  margin-top: 48.5%;
  margin-left: -50%;
}

.sign img {
  margin-left: 8%;
  margin-top: 6.5%;
}

.sign h5 {
  margin-left: 8.5%;
  width: 80%;
  margin-top: 3%;
  font-size: 117%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: gray;
  border-top: 1px solid;
  padding-top: 2%;
}

.otp1 h1 {
  margin-top: 0%;
  border-top: none;
}

.otp1 {
  width: 50%;
  border: 1px solid black;
  border-radius: 10px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 7px #707070;
  margin: 0 auto;
  padding: 1.1rem 0;
}

@media screen and (max-width: 768px) {
  .otp1 {
    width: 90% !important;
  }
}

@media screen and (max-width: 1080px) {
  .otp1 {
    width: 70%;
  }
}

@media screen and (min-width: 1680px) {
  .otp1 {
    width: 48rem;
  }
}

.otp1 header {
  font-size: 22px;
  color: red;
  /* text-align: left; */
  /* line-height: 265%; */
  background: white;
  width: 96%;
  /* user-select: none; */
  padding-left: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-top: 3%;
  margin-left: 2%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.otp1 h5 {
  margin-top: 0%;
  width: 91%;
  border-top: none;
  margin-left: 7%;
  font-size: 91%;
}

.otp2 {
  /* margin-left: 60.5%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.otp2 input {
  width: 93%;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  font-size: 185%;
  text-align: center;
  margin: 0 4%;
  border-radius: none;
  border-radius: 0px;
}

.otp3 {
  padding-left: 5%;
  padding-right: 5%;
}

.otp3 button {
  width: 100%;
  line-height: 1.8;
  font-size: 1.2rem;
  background: red;
  border: none;
  outline: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  padding: 5px 0;
}

.otp4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* .otp4 li {
  list-style: none;
  margin-top: -1%;
  color: gray;
 
} */

/* .otp5 {
  margin-top: -8.8%;
  margin-left: -42%;
} */

.otp5 span {
  /* margin-left: 106%; */
  background: none;
  border: none;
  outline: none;
  color: red;
  font-weight: 500;
  cursor: pointer;
  font-size: 108%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input {
  line-height: 50px;
  font-size: 25px;
  align-items: center;
  margin: 10px 5px;

  transition: all 0.2s ease-in-out;
  border-radius: 3px;

  /*&:focus {
          border-color: purple;
          box-shadow: 0 0 5px purple inset;
        }
        
        &::selection {
          background: transparent;
        }*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#partitioned {
  padding-left: 12px;
  letter-spacing: 32px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 291px;
}
</style>