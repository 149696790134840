<template>
  <!-- <h1>Hello</h1> -->
  <div class="">
    <div class="vendor-details">
      <router-link
        to="/menulist/becomevendor/vendordetails"
        style="text-decoration: none; color: gray"
      >
        <!-- :class="
        changePost ? 'change-post-effect ' : 'change-post-effect-none '
      " -->
        <h5
          @click="changeEffect('vendordetails'), changeEffectPost()"
          :class="{ 'change-post-effect': isActive('vendordetails') }"
        >
          Details of vendor
        </h5>
      </router-link>
      <router-link
        to="/menulist/becomevendor/mandatoryfield"
        style="text-decoration: none; color: gray"
      >
        <!-- <h5
          @click="changeEffectHidden"
          :class="
            changehidden ? 'change-post-effect' : 'change-post-effect-none'
          "
        > -->
        <h5
          @click="changeEffect('mandatoryfield'), changeEffectHidden()"
          :class="{ 'change-post-effect': isActive('mandatoryfield') }"
        >
          Mandtory Filed
        </h5>
      </router-link>

      <router-link
        to="/menulist/becomevendor/uploadoffers"
        style="text-decoration: none; color: gray"
      >
        <!-- <h5
          @click="changeEffectReported"
          :class="
            changeReported ? 'change-post-effect' : 'change-post-effect-none'
          "
        > -->
        <h5
          @click="changeEffect('uploadoffers'), changeEffectReported()"
          :class="{ 'change-post-effect': isActive('uploadoffers') }"
        >
          Upload Offers
        </h5>
      </router-link>
    </div>
    <div class>
      <router-view class="animate__animated animate__fadeIn"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "BecomeVendor",
  data() {
    return {
      changePost: true,
      changehidden: false,
      activeLink: null,
      changeReported: false,
    };
  },
  methods: {
    changeEffectPost() {
      this.changePost = !this.changePost;
      this.changehidden = false;
      this.changeReported = false;
    },
    changeEffectHidden() {
      this.changehidden = !this.changehidden;
      this.changePost = false;
      this.changeReported = false;
    },
    changeEffectReported() {
      this.changeReported = !this.changeReported;
      this.changePost = false;
      this.changehidden = false;
    },
    changeEffect(link) {
      this.activeLink = link;
      // Save the active link to localStorage
      localStorage.setItem("activeLink", link);
    },
    isActive(link) {
      return this.activeLink === link;
    },
  },
  mounted() {
    // Retrieve the active link from localStorage on component mount
    this.activeLink = localStorage.getItem("activeLink") || "vendordetails";
  },
};
</script>
<style scoped>
.vendor-details {
  cursor: pointer;
  padding: 2% 2% 0%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0px 0px 2px #d4d4d4;
  border-bottom: none;
}
.change-post-effect-none {
  color: #b4b4b4;
}
.change-post-effect {
  color: #eb0e19;
  /* padding: 20px; */
  border-bottom: 0.1rem solid #eb0e19;
}
</style>