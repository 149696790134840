<template>
  <div class="main-div">
    <div class="heading px-2">
      <h3 class="mb-0 ms-2 text-grey">FAQS</h3>
    </div>
    <div class="px-3 mt-3">
      <div class="accordion mt-1" id="accordionExample">
        <div class="accordion-item mt-1">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed fs-5 fw-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              What is Redprix ?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body fs-6">
              <strong>RedPrix</strong> is the ultimate social media app for food
              lovers, offering the best discounts and deals on restaurants near
              your location. With RedPrix, you can discover new and delicious
              eats while saving big on your dining experiences.
            </div>
          </div>
        </div>
        <div class="accordion-item mt-2">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed fs-5 fw-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              How do I get started?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body fs-6">
              <strong>Here's how it works:</strong> Simply upload a selfie with
              your food, and our app will detect your location and give you the
              best discounts on restaurants near you. Book a table, fill in your
              details and enjoy your meal at a fraction of the cost.
            </div>
          </div>
        </div>
        <div class="accordion-item mt-2">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed fs-5 fw-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              How can I post photos and videos?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body fs-6">
              <strong>Create Posts: </strong> It is much simple as you thought,
              First redirect to home page and click on Create Post section, By
              clicking on one pop up will open and in this pop add you post
              title in Title after this, Add post/video description in below
              section, After this click on Add Photos and select whatever you
              want to post, In Last click on Tag People button and select more
              than one people and click on Post button and after complete this
              process your post/video will be uploaded.
            </div>
          </div>
        </div>
        <div class="accordion-item mt-2">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed fs-5 fw-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              How can I follow other users?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body fs-6">
              <strong>Accept Follow Request:</strong> It is simple process,
              First redirect to home page and you can see one Suggestions
              section at the right side of home page and in this you can see all
              new peoples who sends you follow requests and by clicking Confirm
              button you accept their follow request and by clicking on Decline
              you reject their request.
            </div>
          </div>
        </div>
        <div class="accordion-item mt-2">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed fs-5 fw-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              Who can join?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body fs-6">
              <strong>All Peoples can join Redprix.</strong> We allow to all
              people to join Redprix and specially for food lovers who loves to
              share their food stories and also here Vendors also can be part of
              our Redprix journey. We also welcome to Influencers at our place
              share their food experiences from different places.
            </div>
          </div>
        </div>
        <div class="accordion-item mt-2">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed fs-5 fw-semibold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Want to know more about Redprix ?
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body fs-6">
              <strong>At RedPrix,</strong> we believe that dining experiences
              should be memorable, budget-friendly, and stress-free. That's why
              we've designed a user-friendly platform that makes booking tables
              and finding the perfect restaurant for any occasion a breeze.
              <br />
              <strong>Join the RedPrix community today</strong> and start
              enjoying delicious eats while saving big. Whether you're looking
              for a romantic dinner for two, a fun night out with friends, or
              just a quick bite, RedPrix has got you covered. So, why wait?
              Download the RedPrix app now and start discovering the best
              restaurants in town!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqsComp",
};
</script>

<style scoped>
.main-div {
  width: 100%;
  height: 36rem;
  border-radius: 10px;
  background: white;
  padding: 0% 0% 1%;
  box-shadow: 0px 0px 5px #ccc;
  overflow-y: auto;
}

.main-div::-webkit-scrollbar {
  display: none;
}

.heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  /* line-height: 4; */
  margin-top: 0%;
  background: white;
  height: 7%;
  border-bottom: 1px solid grey;
}

.heading h3 {
  padding-left: 3%;
  padding-top: 2%;
  font-size: 127%;
  font-weight: 600;
  color: gray;
}

.main-div h3 {
  font-size: 1.2rem;
  color: grey;
}

.main-div h5 {
  font-size: 0.9rem;
  font-weight: 100;
  color: #0000008c;
}

.icon {
  cursor: pointer;
}

.accordion-item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.1rem solid grey;
}

.accordion-button {
  border: none;
  padding: 0.8rem 0.5rem;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: grey;
  background-color: rgb(240, 240, 240);
  box-shadow: none;
}

.accordion-button::after {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1.5rem;
}
</style>