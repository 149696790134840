<template>
  <div v-if="taggedpost.length > 0">
    <div class="grid-container" v-for="tagpost in taggedpost" :key="tagpost.id">
      <div class="grid-item">
        <!-- <img class="post_img" src="./../../assets/08.jpg" alt=""> -->
        <!-- {{tagpost}} -->
        <img :src="tagpost.images[0].image" class="post_img" />
      </div>
      <!-- <div class="grid-item">
          <img class="post_img" src="./../../assets/08.jpg" alt="">
      </div>-->
      <!-- <div class="grid-item">
          <img class="post_img" src="./../../assets/08.jpg" alt="">
      </div>
      <div class="grid-item">
          <img class="post_img" src="./../../assets/08.jpg" alt="">
      </div>
      <div class="grid-item">
          <img class="post_img" src="./../../assets/08.jpg" alt="">
      </div>
      <div class="grid-item">
          <img class="post_img" src="./../../assets/08.jpg" alt="">
      </div>  -->
    </div>
  </div>

  <div class="w-100 py-3 text-center" v-else>
    <p class="mx-auto text-muted mb-0 mt-1">
      You didn't get mentioned in any post yet.
    </p>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Mytags",
  data() {
    return {
      taggedpost: [],
    };
  },
  mounted() {
    this.getMentiondPost();
  },
  methods: {
    getMentiondPost() {
      axios.get("customers/my-mentioned-post").then((res) => {
        this.taggedpost = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  /* gap: 10px; */
}

.grid-item {
  margin-top: 0.5rem;
  /* border: 1px solid #ccc; */
  padding: 1px;
  text-align: center;
  width: 50%;
}
.post_img {
  border-radius: 0.4rem;
  width: 126%;
  height: 100px;
}
</style>