<template>
  <div class="custom-animation">
    <navbar />
    <div class="menulist-row row">
      <div class="menulist-col col-md-4">
        <div class="content">
          <div class="menu">
            <div class="menu1">
              <h3>Menu</h3>
            </div>
            <div class="menu2">
              <div class="menu3" style="cursor: pointer">
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/MenuList/editprofile"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Edit Profile</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>
              <div
                class="menu3"
                style="cursor: pointer"
                data-bs-toggle="modal"
                data-bs-target="#PaymentDetails"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Payment Details</h4>
                  <h5><i class="fa-solid fa-chevron-right"></i></h5>
                </div>
              </div>

              <div
                class="modal fade"
                id="PaymentDetails"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Transcations
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="available-balance">
                        <img src="../../assets/cover.png" width="50" alt />
                        <div class="balance-details">
                          <span class="balance-label">Available Balance</span>
                          <span class="balance-amount">&#8377;110</span>
                        </div>
                      </div>
                      <div class="recived-balance">
                        <img src="../../assets/cover.png" width="50" alt />
                        <p class="recived-balance-extra">rohan</p>
                        <div class="recived-balance-details">
                          <span class="recived-balance-label">Received</span>
                          <span class="recived-balance-amount">&#8377;110</span>
                        </div>
                      </div>

                      <!-- <div class="total_amount">
                    </div>-->
                    </div>
                    <div class="modal-footer">
                      <!--  RedeemRequest(),  -->
                      <button
                        type="button"
                        @click="showPopup()"
                        class="btn btn-danger w-100"
                        data-bs-dismiss="modal"
                      >
                        WithDraw
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="menu3" style="cursor: pointer">
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/MenuList/offereqlist"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Offer Request</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>

              <div
                v-if="this.UserType == 1"
                class="menu3"
                style="cursor: pointer"
              >
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/menulist/becomevendor/vendordetails"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Become a vendor</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>

              <div
                class="menu3"
                v-if="this.UserType == 1"
                style="cursor: pointer"
              >
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/menulist/joininflu"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Join Influencer</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>

              <div class="menu3" style="cursor: pointer">
                <!-- v-if="this.UserType == 2 || this.UserType !== 1" -->

                <router-link
                  style="text-decoration: none; color: white"
                  to="/menulist/becomeAtmanirbhar"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Become atmanirbhar</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>

              <div class="menu3" style="cursor: pointer">
                <!--  v-if="this.UserType == 2 || this.UserType !== 1"   -->

                <router-link
                  style="text-decoration: none; color: gray"
                  to="/menulist/becomeFrenchise"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Become Frenchise</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>

              <div class="menu3" style="cursor: pointer">
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/MenuList/Deleteaccount"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Delete Account</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
                <!-- Modal -->
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-md">
                    <div class="modal-content">
                      <div class>
                        <img src alt />
                      </div>
                      <div class="modal-body">
                        <div class="delete_account">
                          <img src="../../assets/Group4895.png" alt />
                          <p style="color: black; text-align: center">
                            Are you sure
                            <br />You want to Delete Account
                          </p>
                          <div
                            style="width: 100%; padding: 0rem 3rem 0rem 4rem"
                          >
                            <span style="color: black; text-align: center">
                              Delete this account will delete all of its
                              messages,
                              <br />Contacts and other data from your Device!
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                          style="
                            background-color: #ccc;
                            border: none;
                            width: 46%;
                          "
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          style="width: 50%"
                          @click="deleteUser"
                          data-bs-dismiss="modal"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="menu3" style="cursor: pointer">
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/menulist/helpAndSupport"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Help & Support</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>
              <div class="menu3" style="cursor: pointer">
                <router-link
                  style="text-decoration: none; color: gray"
                  to="/menulist/blockedAccount"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Block Account</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>
              <div class="menu3" style="cursor: pointer">
                <router-link style="text-decoration: none; color: gray" to="/">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h4>Log Out</h4>
                    <h5><i class="fa-solid fa-chevron-right"></i></h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <div class="popup-demo">
            <div
              class="popup-overlay"
              v-if="showModal"
              @click="closePopup"
            ></div>
            <div class="popup" v-if="showModal">
              <div class="popup-content">
                <div class="popup-header">
                  <p>Add Bank Details</p>
                  <div @click="closePopup">
                    <img class="close-btn" src="../../assets/cancel.png" />
                  </div>
                </div>
                <div class="amount_redeemation_form">
                  <form style="margin-top: 7%">
                    <div class="firstp">
                      <input
                        type="text"
                        v-model="account_holder_name"
                        required
                      />
                      <span></span>
                      <label>Account Holder Name</label>
                    </div>
                    <div class="secondp">
                      <input type="text" v-model="ifsc_code" required />
                      <span></span>
                      <label>IFSC Code</label>
                    </div>
                    <div class="thirdp">
                      <input type="text" v-model="branch_name" required />
                      <span></span>
                      <label>Bank Name</label>
                    </div>
                    <div class="fourthp">
                      <input type="text" v-model="account_number" required />
                      <span></span>
                      <label>Account Number</label>
                    </div>
                    <div class="thirdp">
                      <input type="text" v-model="redeem_amount" required />
                      <span></span>
                      <label>Redeem Amount</label>
                    </div>
                    <div class="fourthp">
                      <input type="text" v-model="remark" required />
                      <span></span>
                      <label>Remark</label>
                    </div>
                    <!-- <div class="fifthp">
                    <input type="text" required />
                    <span></span>
                    <label>Email id</label>
                  </div>-->
                    <div class="desp">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Id natus deleniti illum veniam dignissimos qui blanditiis
                      possimus, quam necessitatibus dolores soluta labore
                      facilis. Sit aliquid facilis harum corporis, voluptatum
                      commodi nam repudiandae, reiciendis soluta dolorem
                      similique, quo saepe.
                    </div>
                  </form>
                </div>
                <div class="promated-btn">
                  <button
                    class="btn btn-danger"
                    style="border: none; width: 38%"
                    type="button"
                    @click="xRedeemRequest()"
                  >
                    Redeem Now
                  </button>

                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="ChoosePlan"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Choose Plan
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <div class="ChoosePlan_img">
                            <img src="../../assets/uncle.png" width="100" />
                          </div>
                          <div class="ChoosePlan_from">
                            <form style="margin-top: 0%">
                              <div class="text">
                                <div class="text1-s" style="margin-left: 0%">
                                  <select
                                    id="country"
                                    class="s1"
                                    style="margin-left: 0%; width: 100%"
                                    v-model="plan_id"
                                    @change="choosePromotionPlan()"
                                    required
                                  >
                                    <option value class="j">
                                      select PromotionPlan
                                    </option>
                                    <option
                                      class="j"
                                      v-for="plan in promotionplan"
                                      :key="plan.id"
                                      :value="plan.id"
                                    >
                                      {{ plan.name }}
                                    </option>
                                  </select>
                                </div>
                                <span></span>
                              </div>
                              <div class="text">
                                <input type="text" v-model="coupon" required />
                                <span></span>
                                <label>Enter Coupon Code</label>
                              </div>

                              <div class="text-v">
                                <input
                                  type="text"
                                  v-model="business_mobile"
                                  required
                                />
                                <span></span>
                                <label>Enter Referral Code</label>
                              </div>

                              <div class="text1-s" style="margin-left: 0%">
                                <select
                                  id="country"
                                  class="s1"
                                  style="margin-left: 0%"
                                  v-model="plan_id"
                                  @change="getState()"
                                  required
                                >
                                  <option value class="j">
                                    Select Country
                                  </option>
                                  <option
                                    class="j"
                                    v-for="country in countries"
                                    :key="country.id"
                                    :value="country.id"
                                  >
                                    {{ country.name }}
                                  </option>
                                </select>

                                <select
                                  id="state"
                                  class="s2"
                                  style="margin-left: 0%"
                                  v-model="state_id"
                                  @change="getCity()"
                                  required
                                >
                                  <option class="j" value>Select State</option>

                                  <option
                                    class="j"
                                    v-for="state in states"
                                    :key="state.id"
                                    :value="state.id"
                                  >
                                    {{ state.name }}
                                  </option>
                                </select>

                                <select
                                  id="state"
                                  class="s3"
                                  style="margin-left: 0%"
                                  v-model="city_id"
                                  required
                                >
                                  <option class="j" value>Select City</option>

                                  <option
                                    class="j"
                                    v-for="city in cities"
                                    :key="city.id"
                                    :value="city.id"
                                  >
                                    {{ city }}
                                  </option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-danger"
                            data-bs-dismiss="modal"
                            style="width: 100%"
                            @click="PaymentOp()"
                          >
                            Create Payment
                          </button>
                        </div>
                      </div>
                      <!-- payment form  -->

                      <form id="paymentForm" method="post" :action="payuUrl">
                        <input
                          type="hidden"
                          v-model="mkey"
                          name="key"
                          size="64"
                        />
                        <input
                          type="hidden"
                          v-model="txnid"
                          name="txnid"
                          size="64"
                        />
                        <input
                          type="hidden"
                          v-model="amount"
                          name="amount"
                          size="64"
                        />
                        <input
                          type="hidden"
                          v-model="productinfo"
                          name="productinfo"
                          size="64"
                        />
                        <input
                          type="hidden"
                          v-model="name"
                          name="firstname"
                          size="64"
                        />
                        <input
                          type="hidden"
                          v-model="email"
                          name="email"
                          size="64"
                        />
                        <input
                          type="hidden"
                          v-model="phone"
                          name="phone"
                          size="64"
                        />
                        <input type="hidden" v-model="surl" name="surl" />
                        <input type="hidden" v-model="furl" name="furl" />
                        <input
                          type="hidden"
                          id="hash"
                          v-model="hash"
                          name="hash"
                          size="64"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="edit-profile-col col-md-8">
        <router-view class="animate__animated animate__fadeIn"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import navbar from "./../nav.vue";
// import NavBar from "@/NavBar.vue"
export default {
  name: "MenuList",
  components: {
    navbar,
  },
  data() {
    return {
      UserType: [],
      item: [],
      item1: [],
      account_holder_name: "",
      account_number: "",
      bank_name: "",
      ifsc_code: "",
      branch_name: "",
      redeem_amount: "",
      remark: "",
      showModal: false,
    };
  },
  methods: {
    getdata() {
      axios
        .get("customers/my-profile", {
          headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
        })
        .then((response) => {
          this.item = response.data.data;
          this.UserType = response.data.data.user_type;
          // alert(this.UserType)
          // console.log("userType", this.UserType);
          this.item1 = response.data.data.email;

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        });
    },

    deletevendor() {
      const id = localStorage.getItem("id");
      const token = localStorage.getItem("token");
      // console.log(token, "token");

      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      axios
        .delete("vendors/vendors/" + id)
        .then(() => {
          // console.log(res.data.data);
        })
        .catch((error) => {
          alert(error.res.data.message);
        });
    },
    deleteUser() {
      const id = localStorage.getItem("id");
      const token = localStorage.getItem("token");
      // console.log(token, "token");

      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      axios
        .delete(`customers/delete/${id}`)
        .then((res) => {
          console.log(res.data.data);
          localStorage.clear();
          this.$router.push("/login");
        })
        .catch((error) => {
          alert(error.res.data.message);
        });
    },

    RedeemRequest() {
      axios
        .post("customers/redeem-request", {
          account_holder_name: this.account_holder_name,
          account_number: this.account_number,
          bank_name: this.bank_name,
          ifsc_code: this.ifsc_code,
          branch_name: this.branch_name,
          redeem_amount: this.redeem_amount,
          remark: this.remark,
        })
        .then(() => {
          // console.log(res.data.data);
        });
    },
    showPopup() {
      this.showModal = true;
    },
    closePopup() {
      this.showModal = false;
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>

<style scoped>
.menu {
  /* max-height: 100vh; */
  border: 1px solid;
  border-radius: 10px;
  margin: 0;
}

.menulist-row {
  width: 75%;
  margin: 0.2rem auto;
}

.menulist-col {
}

.nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
}

.searchbar input {
  height: 4.7vh;
  width: 100%;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}

.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}

.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}

.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}

.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist3 {
  transform: scale(1);
}

.content {
  margin: 0;
  /* margin: auto; */
}

.main {
  display: flex;
  list-style: none;
}

.menu {
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
}

.menu1 {
  background: #eb0e19;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  padding: 13px 23px;
}

.menu1 h3 {
  font-size: 1.5em;
  color: white;
}

.menu2 {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.menu3 {
  background: #fff5f5;
  margin-bottom: 0.7rem;
  padding: 0.6rem 1.2rem;
}

.menu3 h4 {
  width: 100%;
  font-weight: 600;
  color: #0000007a;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.menu3 h5 {
  font-size: 1.3rem;
  font-weight: 400;
  color: #0000007a;
  margin-bottom: 0;
}

.menu3 i {
  font-size: 0.9rem;
  margin-right: 8px;
}

.menu3:hover,
.menu3 h4:hover,
.menu3 h5:hover {
  background: pink;
  color: white;
}

.profile {
  margin-left: 18.5%;
  /* border: 1px solid black; */
  width: 100%;
  border-radius: 1%;
  background-color: white;
  box-shadow: 0px 0px 4px #d4d4d4;
}

.profile header {
  font-size: 22px;
  color: black;
  text-align: left;
  line-height: 2.6;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 20px;
  user-select: none;
  border-bottom: 1px solid black;
  margin-top: 0px;
}

.upload {
  width: 171px;
  position: relative;
  height: 200px;
  margin-top: 4px;
  margin-left: 40px;
  top: 4rem;
  left: -2rem;
}

.imgp {
  border-radius: 50%;
  border: 6px solid #eaeaea;
}

.imgp1 {
  background: skyblue;

  border-radius: 50%;
}

.upload .choose {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 40px;
  line-height: 33px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.upload .choose input[type="file"] {
  position: absolute;
  transform: scale(2);
  opacity: 0;
  width: 21px;
  margin-left: -18px;
  margin-top: 8px;
  cursor: pointer;
}

form {
  margin-left: 40%;
  margin-top: -33%;
}

form .text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 4%;
  width: 82%;
}

.text input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.form-2 {
  margin-left: -55%;
  margin-top: 10%;
}

form .text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: -10px;
  width: 88%;
}

.text-v input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

form .text-s {
  margin-left: -254px;
  margin-top: 25px;
}

select {
  width: 40%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s1 {
  margin-top: 20px;
  margin-left: 10%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
  width: 38%;
}

.s2 {
  margin-left: 61px;
  padding: 2px;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
}

.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-left: -54%;
  margin-top: 9%;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 136%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 11%;
  width: 136%;
  margin-left: -53.5%;
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.delete_account img {
  margin-bottom: 3rem;
  width: 100%;
  padding: 0rem 8rem 0rem;
}

.modal-footer {
  border-top: none;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem 1rem;
  background-color: var(--bs-modal-footer-bg);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

/* .available-balance{
  display: flex;
  align-items: center;
} */
.available-balance {
  display: flex;
  align-items: center;
}

.balance-details {
  margin-left: 10px;
  /* Add some space between the image and text */
}

.balance-label {
  display: block;
}

.balance-amount {
  margin-top: 5px;
  /* Add space between text and amount */
}

.recived-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-top: 1rem;
  background-color: #f3f2f2;
}

.recived-balance-details {
  margin-left: 10px;
  /* Add some space between the image and text */
}

.recived-balance img {
  border-radius: 50%;
}

.recived-balance-details {
  margin-left: 10px;
  /* Add some space between the image and text */
}

.recived-balance-label {
  display: block;
  font-size: 0.7rem;
}

.recived-balance-amount {
  margin-top: 5px;
  font-weight: 800;
  font-size: 1.5rem;
}

.recived-balance-extra {
  font-size: 20px;
  margin-right: 14rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  background-color: red;
  color: #fff;
  border-bottom: var(--bs-modal-header-border-width) solid;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.popup-demo {
  text-align: center;
  padding: 20px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 64%;
  height: 91%;
}

.popup-content {
  text-align: center;
}

.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  width: 100%;
  background: red;
  padding: 1rem;
  color: white;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.promated-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.9rem;
  padding-left: 0.5rem;
  margin-top: 3rem;
}

.firstp,
.secondp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: -5%;
  width: 65%;
  margin-left: -59%;
}

.firstp input,
.secondp input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.firstp label,
.secondp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.secondp {
  margin-left: 19%;
  margin-top: -7.7%;
}

.thirdp,
.fourthp {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 6%;
  width: 65%;
  margin-left: -59%;
  padding: 0%;
}

.thirdp input,
.fourthp input {
  width: 82%;

  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.thirdp label,
.fourthp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.fourthp {
  margin-left: 19%;
  margin-top: -11.5%;
}

.fifthp {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 5%;
  width: 143%;
  margin-left: -59%;
}

.fifthp input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.fifthp label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.desp {
  margin-left: -60%;
  width: 153%;
  margin-top: 12%;
}

.popup2 button {
  margin-left: -194%;
  margin-top: 0%;
  border: none;
  background: red;
  width: 35%;
  line-height: 2.1;
  border-radius: 6px;
  color: white;
  font-size: 118%;
  cursor: pointer;
}

.close-btn {
  width: 100%;
  cursor: pointer;
}

/* Style for floating labels */
.amount_redeemation_form label {
  position: absolute;
  pointer-events: none;
  /* left: 15px;
  top: 10px; */
  transition: 0.2s ease all;
  color: #999;
}

/* Move the label upward when input is focused */
.amount_redeemation_form input:focus ~ label,
.amount_redeemation_form input:valid ~ label {
  top: -5px;
  font-size: 12px;
  transition: 1s;
  color: #c90101;
}

.amount_redeemation_form input:focus,
.amount_redeemation_form input:valid {
  transition: 2s;
  width: 100%;
}

.edit-profile-col {
}
</style>
