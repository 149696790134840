<template>
  <div class="container-fluid animate__animated animate__fadeIn">
    <div class="container">
      <form @submit.prevent="resetPassword">
        <header>
          <h1>Create New Password</h1>
          <p>Your new password must be different from the previous password.</p>
          <div
            v-if="showAlert"
            class="alert"
            :class="{ 'success-alert': success_pass }"
          >
            {{ alertMessage }}
          </div>
        </header>

        <div class="create1">
          <input
            type="password"
            v-model="newPassword"
            id="newPassword"
            required
          />
          <label for="newPassword">Enter new Password</label>
          <span></span>
        </div>

        <div class="create1">
          <input
            type="password"
            v-model="confirmPassword"
            id="confirmPassword"
            required
          />
          <label for="confirmPassword">Confirm Password</label>
          <span></span>
        </div>

        <div class="reset_btn">
          <button type="submit" :disabled="loading" @click="resetPassword">
            <span v-if="!loading">RESET</span>
            <div v-else class="loading-spinner mx-auto">
              <div class="spinner"></div>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateNewPass",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      loading: false,
      showAlert: false,
      alertMessage: "",
      token: "",
      u_id: null,
      email: "",
      success_pass: false,
    };
  },

  computed: {
    isFormValid() {
      return (
        this.newPassword === this.confirmPassword &&
        this.newPassword.length >= 8
      );
    },
  },

  mounted() {
    this.u_id = localStorage.getItem("u_id");
    // console.log("Url Data: ", this.u_id);
    if (localStorage.getItem("token")) {
      this.$router.push("/home");
    }
  },

  methods: {
    async resetPassword() {
      if (this.newPassword.length < 8) {
        this.loading = true;
        this.showAlert = true;
        this.alertMessage = "Password should be minimum 8 characters.";
        setTimeout(() => {
          this.loading = false;
          this.showAlert = false;
          this.alertMessage = "";
        }, 1000);
        return;
      }

      try {
        this.loading = true;
        const response = await axios.post(`forgotchangepassword/${this.u_id}`, {
          new_password: this.newPassword,
          confirm_password: this.confirmPassword,
        });
        // console.log("Response: ", response);
        if (response.data.status === true) {
          this.showAlert = true;
          this.success_pass = true;
          this.alertMessage = "Password Updated Successfully";
          this.newPassword = ""; // Clear the new password
          this.confirmPassword = ""; // Clear the confirmation password
          setTimeout(() => {
            this.showAlert = false;
            this.$router.push("/login");
          }, 2000);
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.showAlert = true;
          this.alertMessage = error.response.data.message; // Use the actual field or message from the server
        } else {
          this.showAlert = true;
          this.alertMessage = "An error occurred.";
        }
        setTimeout(() => {
          this.loading = false;
          this.showAlert = false;
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.loading-spinner {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: auto;
  border: 6px solid #ffffff;
  border-radius: 50%;
  animation: loading-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #ffffff transparent transparent transparent;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#password-form {
  max-width: 400px;
  width: 95%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  border-radius: 0.5rem;
  position: fixed;
  top: 14%;
}

.alert {
  text-align: center;
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 0;
  --bs-alert-margin-bottom: 0;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  padding: 0rem;
  margin-bottom: 0rem;
  color: var(--bs-alert-color);
  font-size: 1rem;
  color: red;
}

.success-alert {
  color: green;
}

header h1 {
  color: red;
  /* line-height: 265%; */
  width: 100%;
  font-size: 1.5rem;
  /* user-select: none; */
  padding: 2%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

header p {
  color: #414141;
  padding-left: 3%;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 116%; */
  background-image: url("../assets/wall.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
  min-height: 100vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 12px 13px #ccc;
  background-color: #fff;
  border-radius: 1.2rem;
  width: 45%;
  padding: 2rem 0;
}

@media screen and (max-width: 768px) {
  .container {
    width: 90% !important;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    width: 70%;
  }
}

@media screen and (min-width: 1680px) {
  .container {
    width: 45rem;
  }
}

.create1 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  /* width: 84%; */
  /* margin-left: 6%; */
  margin-top: 5%;
  /* font-weight: 500; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 50%;
  min-width: 100%;
  padding: 1%;
}

.create1 input {
  width: 82%;
  padding: 5px;
  height: 14px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 37px;
  padding-bottom: 3%;
}

.create1 label {
  position: absolute;
  top: 50%;
  left: 7%;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.create1 span::before {
  content: "";
  position: absolute;
  top: 98%;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.create1 input:focus ~ label,
.create1 input:valid ~ label {
  top: 0%;
  color: #eb0e19;
}

.create1 input:focus ~ span::before,
.create1 input:valid ~ span::before {
  width: 100%;
}

.reset_btn {
  margin-top: 2rem;
  margin-bottom: 3%;
  margin-left: auto;
  margin-right: auto;
}

.reset_btn button {
  width: 100%;
  background-color: red;
  color: #fff;
  border: none;
  outline: none;
  padding: 2%;
  border-radius: 0.5rem;
}
</style>