<template>
  <!-- <navbar /> -->
  <div class="change">
    <div class="change1">
      <h3>Change Password</h3>
    </div>

    <div class="change2">
      <img src="../../assets/lady.png" width="200" height="150" />
    </div>
    <div class="change3">
      <h3>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum, ipsa
        recusandae doloremque possimus earum dolorem magnam? Repellat saepe
        veniam in incidunt? Nostrum, libero? Ex, rerum!
      </h3>
    </div>

    <div class="change4">
      <div class="change5">
        <input v-model="old_password" type="text" required />
        <span></span>
        <label>Current Password</label>
      </div>
      <div class="change5">
        <input
          v-if="showPassword"
          type="text"
          class="input"
          v-model="new_password"
        />
        <input
          v-else
          type="password"
          id="psd"
          class="input"
          maxlength="20"
          v-model="new_password"
          required
        />
        <i
          style="font-size: 150%; float: right"
          @click="toggleshow()"
          class="fa"
          :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"
        ></i>
        <span></span>
        <label>New Password</label>
      </div>
      <div class="change5">
        <input
          v-if="showPassword2"
          type="text"
          class="input"
          v-model="confirm_password"
        />
        <input
          v-else
          type="password"
          id="psd1"
          class="input"
          maxlength="20"
          v-model="confirm_password"
          required
        />
        <i
          style="font-size: 150%; float: right"
          @click="toggleshow2()"
          class="fa"
          :class="{ 'fa-eye': showPassword2, 'fa-eye-slash': !showPassword2 }"
        ></i>

        <span></span>
        <label>Confirm Password</label>
      </div>
      <div class="change6">
        <button @click="changePassword()">RESET PASSWORD</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import navbar from "./../../components/nav.vue";
export default {
  name: "changePassword",
  components: {
    // navbar,
  },
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      old_password: "",
      new_password: "",
      confirm_password: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },

  methods: {
    // openEye(){
    //     let eyeicon = document.getElementById("eyeicon")
    //     let password = document.getElementById("eyeicon")

    //     eyeicon.onclick= function(){
    //         if(password.type == "password"){
    //             password.type = "text"
    //         }else{
    //             password.type = "password"
    //         }
    //     }

    // },
    toggleshow() {
      this.showPassword = !this.showPassword;
    },
    toggleshow2() {
      this.showPassword2 = !this.showPassword2;
    },
    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },
    changePassword() {
      axios
        .post("change-password", {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_password: this.confirm_password,
        })
        .then(() => {
          // console.log(response.data.data);
        })
        .catch((error) => console.log(error));
    },

    logoutUser() {
      axios.get("logout", {}).then(() => {
        localStorage.clear();
        console.clear();
        this.$router.push("/");
      });
    },
    animatedToggle() {
      let toggle = document.querySelector(".toggle");

      toggle.classList.toggle("active");
    },
  },
};
</script>
<style scoped>
.change {
  width: 100%;
  /* border: 1px solid gray; */
  border-radius: 10px;
  background: white;
  padding: 0% 0% 1%;
  box-shadow: 0px 0px 5px #ccc;
}
.change1 {
  width: 100%;
  /* line-height: 4; */
  margin-top: 0%;
  background: white;
  height: 9%;
  border-bottom: 1px solid grey;
}
.change1 h3 {
  padding-left: 3%;
  padding-top: 2%;
  font-size: 127%;
  font-weight: 600;
  color: gray;
}
.change2 {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6%;
}
.change3 {
  width: 81%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
}
.change3 h3 {
  text-align: center;
  font-size: 102%;
  font-weight: 100;
  color: gray;
}
.change4 {
  margin-top: 0%;
}
.change5 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 3%;
  width: 84%;
  margin-left: 6%;
}
.change5 img {
  margin-left: 9%;
  cursor: pointer;
}

.change5 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.change5 label {
  position: absolute;
  top: 42%;
  left: 17px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}
.change5 span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}
.change5 input:focus ~ label,
.change5 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}
.change5 input:focus ~ span::before,
.change5 input:valid ~ span::before {
  width: 100%;
}
.change6 {
  width: 84%;
  margin-top: 10%;
  margin-left: 6%;
}
.change6 button {
  margin-left: 0%;
  width: 104%;
  line-height: 2.5;
  background: #eb0e19;
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: -1%;
  cursor: pointer;
}
.toggle {
  position: absolute;
  top: 36.5%;
  left: 30.5%;
  width: 2.5%;
  height: 2.5vh;
  background: #ff9ba0;
  border-radius: 30px;
  transition: 0.3s;
}
.toggle .toggle-button {
  position: absolute;
  top: 0;
  left: 0%;
  width: 52%;
  height: 2.5vh;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
.toggle.active {
  background: #eb0e19;
}
.toggle.active .toggle-button {
  left: 48%;
}
</style>
