<template>
  <div class="content">
    <div class="contact">
      <div class="contact1">
        <h3 class="fs-5 text-grey ps-3">Contact Us</h3>
      </div>

      <div class="contact2">
        <div class="contact3">
          <input type="text" required />
          <span></span>
          <label>Full Name</label>
        </div>
        <div class="contact3">
          <input type="password" id="password" required />
          <!-- <img src="../assets/eye.svg" id="eyeicon" width="50" height="20" @click="openEye()" > -->
          <span></span>
          <label>Email Address</label>
        </div>
        <div class="contact3">
          <input type="password" required />
          <!-- <img src="../assets/eye.svg"  width="50" height="20" > -->
          <span></span>
          <label>Confirm Password</label>
        </div>

        <div class="contact4">
          <!-- <input type="text" placeholder="Your Mesasge" /> -->
          <textarea
            id="w3review"
            name
            rows="4"
            cols="50"
            placeholder="Your Mesasge"
          ></textarea>
        </div>

        <div class="contact5">
          <button>Submit</button>
        </div>

        <div class="contact6">
          <h2>For more information you can mail on</h2>
          <h3>contact@infinitybrains.com</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "contactus",

  methods: {
    openEye() {
      let eyeicon = document.getElementById("eyeicon");
      let password = document.getElementById("eyeicon");

      eyeicon.onclick = function () {
        if (password.type == "password") {
          password.type = "text";
        } else {
          password.type = "password";
        }
      };
    },
  },
};
</script>

<style scoped>
.change {
  /* margin-top: -84%;
  margin-left: 24%; */
  width: 95vh;
  border: 1px solid;
  height: 85vh;
  border-radius: 10px;
  background: white;
}

.contact {
  /* position: absolute;  */
  /* margin-top: -84%; */
  /* margin-left: 24%; */
  width: 100%;
  /* border: 1px solid; */
  /* height: 85vh; */
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 5px #ccc;
}

.contact1 {
  background: #eb0e19;
  height: 9%;
  /* background: #eb0e19; */
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  /* line-height: 4; */
  margin-top: 0%;
  background: white;
  height: 9%;
  border-bottom: 1px solid grey;
}

.contact1 h3 {
  padding-left: 1%;
  padding-top: 3%;
}

.contact2 {
  margin-top: 7%;
}

.contact3 {
  position: relative;
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 3%;
  width: 84%;
  margin-left: 6%;
}

.contact3 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.contact3 label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.contact3 span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.contact3 input:focus ~ label,
.contact3 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.contact3 input:focus ~ span::before,
.contact3 input:valid ~ span::before {
  width: 100%;
}

.contact4 {
  width: 103%;
  margin: 2%;
  padding-left: 4%;
}
textarea {
  resize: none;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid #000;
  width: 85%;
}
/* .contact4 input {
  width: 85%;
  line-height: 10;
  outline: none;
  border: 1px solid #000;
  border-radius: 0.5rem;
} */

.contact4 input::placeholder {
  padding-bottom: 10%;
}

.contact5 {
  padding-left: 6%;
}

.contact5 button {
  /* margin-left: 12%;
  margin-top: 9%; */
  width: 90%;
  line-height: 2;
  background: #eb0e19;
  outline: none;
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 100%;
  cursor: pointer;
}
.contact6 {
  padding-bottom: 1.5rem;
}
.contact6 h2 {
  text-align: center;
  /* margin-left: 31%; */
  font-size: 84%;
  font-weight: 100;
  margin-top: 2%;
}

.contact6 h3 {
  margin-left: 38%;
  font-size: 73%;
  font-weight: 100;
  margin-top: 1%;
}
</style>
