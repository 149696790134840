<template>
  <div class="tag_checkin_btn">
    <button class="tag_btn" type="button" @click="showModal">
      <img src="../../assets/pro2.png" width="35" height="35" />
      Tag People
    </button>

    <div class="modal fade" id="TagsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      style="margin-right: 0px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Tag People</h5>
            <button type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <div class="search_bar">
              <!-- <input type="search" placeholder="Search People" v-model="searchTerm" @input="showUsers" /> -->
              <i class="fa fa-search" style="position: absolute; left: 21px; color: #a59595;"></i>
              <input type="search" class="form-control rounded" placeholder="Search" v-model="searchTerm"
                @input="showUsers" style="padding: 0 33px;" />
              <button class="btn btn-primary" type="button" @click="closeModal">Done</button>
            </div>

            <div>
              <hr>
            </div>

            <div class="tag_users" v-for="user in users" :key="user.id">
              <img :src="user.profile_photo" v-if="user.profile_photo == null" alt="Profile Picture" width="100"
                height="100" />
              <img src="../../assets/cover.png" width="30" v-else />
              <span>
                {{ user.name }}
              </span>
              <input type="checkbox" :value="user.id" @change="handleUserSelection(user.id)" style="accent-color: red;" />
              <hr style="width: 100%;" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="checkin_btn">
      <img src="../../assets/location-pin.png" width="30" height="30" />
      Check In
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    user: Object,
    selectedUsers: Array,
  },
  name: "TagModal",
  data() {
    return {
      users: [],
      searchTerm: "",
    };
  },

  methods: {
    showModal() {
      // Show the modal using Bootstrap's JavaScript API
      $('#TagsModal').modal('show');
    },

    closeModal() {
      // Hide the modal using Bootstrap's JavaScript API
      $('#TagsModal').modal('hide');
    },

    showUsers() {
      axios.get(`customers/customers?search=${this.searchTerm}`).then((res) => {
        this.users = res.data.data;
      });
    },

    handleUserSelection(userId) {
      this.$emit("user-selection", userId);
    },

    // windowClickHandler(event) {
    //   // Check if the click event occurred outside the modal container
    //   if (
    //     this.showTagModal &&
    //     !this.$el.contains(event.target) // Check if the clicked element is not within the modal
    //   ) {
    //     this.closeModal(); // Close the modal
    //   }
    // },

  },
};
</script>

<style scoped>
.tag_checkin_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag_btn {
  width: 50%;
  padding: 0.4rem 1.5rem 4px;
  display: flex;
  border: 1px solid black;
  align-items: center;
  margin-right: 23px;
  background-color: #ffff;
  border-radius: 0.5rem;
  justify-content: space-around;
  cursor: pointer;
}

.search_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  padding: 23px 13px;
}

.search_bar input {
  border: 1px solid black;
  outline: none;
  width: 100%;
  border-radius: 0.2rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0 0;
}

.checkin_btn {
  width: 50%;
  padding: 0.7rem 1.5rem 4px;
  display: flex;
  border: 1px solid black;
  justify-content: space-around;
  align-items: center;
  background-color: #ffff;
  border-radius: 0.5rem;
}

.modal-overlay {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  overflow-x: auto;
}

.modal-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  /* padding: 20px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-width: 100%;
  width: 26%;
  text-align: center;
  border-radius: 0.5rem;
  margin: 0 auto;
  overflow-y: auto;
  /* Add this line to enable vertical scrolling */
}

.modal-header {
  background-color: red;
  color: #fff;
}

.tag_users {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 13px;
}

.user_name {
  display: block;
  text-align: left;
}

.Modal-body {
  width: 100%;
  padding: 0.5rem;
  /* height: 40vmax; */
}
</style>
