<template>
  <div class="custom-animation">
    <navbar />
    <div class="row">
      <div class="col-md-4 header">
        <img class="header_img" src="../../assets/cover2.png" alt />
        <div class="header_inner">
          <!-- src="../../assets/01.jpeg" -->
          <div class="parent_header_inner">
            <img
              class="user_profile"
              v-if="
                item.profile_photo &&
                (item.user_type == 1 || item.user_type == 3)
              "
              :src="item.profile_photo"
              alt
            />

            <img
              class="user_profile"
              v-else-if="item.user_type == 2"
              :src="this.vendorProfile.business_logo"
              alt
            />
            <img class="user_profile" v-else src="../../assets/01.jpeg" alt />
            <div class="ms-1 mt-4" v-if="item.user_type == 2">
              {{ this.vendorProfile.business_name || "Hot & Fresh Pizza" }}
            </div>
            <div class="ms-1 mt-4" v-else>
              {{ item.name || "Hot & Fresh Pizza" }}
            </div>
          </div>
          <div>
            <div v-if="item.user_type == 2">
              <button class="btn btn-sm btn-success" disabled>
                Your referral code is:
                {{
                  this.promocode && this.promocode.business_promo_code
                    ? this.promocode.business_promo_code
                    : "NOCODEFOUND"
                }}
              </button>
            </div>
            <div v-if="item.user_type == 3 || item.user_type == 1">
              <button
                type="button"
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                :disabled="isSubscribed"
              >
                {{
                  isSubscribed
                    ? "YOU ARE ELITE MEMBER"
                    : "MAKE THIS ACCOUNT ELITE"
                }}
              </button>
            </div>

            <div
              v-for="plan in plans"
              :key="plan.id"
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <!-- src="../../assets/hburger.png" -->
              <div class="modal-dialog d-flex align-items-center">
                <div class="mo position-relative overflow-hidden">
                  <div
                    class="d-flex h-100 align-items-center justify-content-center position-relative"
                  >
                    <img src="../../assets/hburger.png" style="width: 100%" />
                  </div>
                  />
                  <div class="h-100 position-absolute top-0 px-3 pt-5" style="">
                    <h4 class="text-center fs-3 text-white">Elite Account</h4>
                    <h6 class="text-center fs-6 text-white mt-4" style="">
                      "Join RedPrix Elite and Get Exclusive Restaurant Discounts
                      Today!
                      <br />Do you love food and want to save money while dining
                      out? Then RedPrix Elite is for you. As an Elite member,
                      you'll have access to the best discounts and offers from
                      top restaurants and eateries from all around India. Don't
                      miss out on the chance to savor the savings. With RedPrix
                      Elite, you can take advantage of these each exclusive
                      offers once a month, giving you a fresh set of discounts
                      every time. From fine dining to quick bites, enjoy
                      delicious food at a fraction of the cost. Fine dining,
                      quick bites, and everything in between - RedPrix Elite has
                      got you covered. Upgrade your foodie experience and join
                      RedPrix Elite now. Sign up today and start saving on your
                      favorite restaurants!"
                    </h6>
                  </div>
                </div>
                <div class="modal-content mx-auto">
                  <div class="modal-header" style="height: 7vh">
                    <h4 style="margin-top: 2%; font-size: 110%">
                      Sign up as an Elite Account
                    </h4>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <!--   @submit.prevent="createPayment()" -->
                    <form class="w-100">
                      <div class="pform1">
                        <input type="text" v-model="firstname" required />

                        <label>First Name</label>
                      </div>
                      <div class="pform2">
                        <input type="text" required />

                        <label>Last Name</label>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="pform5" style="width: 48%">
                          <input type="text" required />

                          <label>GST No.</label>
                        </div>
                        <div class="pform6" style="width: 48%">
                          <input type="text" required />

                          <label style="left: 55%">Mobile No.</label>
                        </div>
                      </div>
                      <div class="reg6">
                        <select
                          id="plannem"
                          @change="AMountChangeFunction()"
                          required
                          style="
                            width: 100%;
                            margin-top: 9%;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid black;
                          "
                          v-model="selectedOption"
                        >
                          <option value="" disabled selected>
                            Subscription plan for elite account
                          </option>
                          <option
                            v-for="plan in plans"
                            :value="plan.name"
                            v-bind:key="plan.id"
                          >
                            {{
                              plan.name == "1 Year" && plan.id == "4"
                                ? plan.name + " - For Students"
                                : plan.name
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="pform8" style="margin-top: 6%">
                        <h6 style="font-size: 76%; color: gray">
                          I have promo code to use for this
                        </h6>
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <div
                            class="d-flex align-items-center"
                            style="width: 80%; margin-bottom: 1%"
                          >
                            <!-- <span class="mb-0 w-25">Code :- </span> -->
                            <input
                              type="text"
                              class="pt-2 pb-2 px-2 w-75"
                              placeholder="Code :- Enter your promo code"
                              v-model="coupon"
                              @input="getInputUpdatedValue"
                              :readonly="plan_id != '1'"
                            />
                          </div>

                          <button
                            @click.prevent="couponVerify()"
                            style="
                              font-size: 79%;
                              width: 16%;
                              border: none;
                              line-height: 1.8;
                              background: dodgerblue;
                              border-radius: 6px;
                              color: white;
                            "
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <div
                        id="emailHelp"
                        class="input-text text-success"
                        v-if="couponSuMsg"
                      >
                        {{ couponSuMsg }}
                      </div>
                      <div
                        id="emailHelp"
                        class="input-text text-danger"
                        v-if="coponerr"
                      >
                        {{ coponerr }}
                      </div>
                      <div class="pform9" style="margin-top: 6%">
                        <h6 style="font-size: 80%; color: gray">
                          Payable Amount
                        </h6>
                        <p class="mb-1">₹ {{ this.amount }}</p>
                      </div>
                      <div class="d-flex align-items-center mt-2">
                        <input
                          type="checkbox"
                          id="checkmark"
                          style="margin-top: 0.5%"
                        />
                        <label
                          style="
                            font-size: 80%;
                            margin-top: 0.5%;
                            margin-left: 1%;
                          "
                          >Please check your input for checking checkbox</label
                        >
                      </div>
                    </form>
                  </div>
                  <div
                    class="modal-footer"
                    style="
                      border: none;
                      margin-left: 0%;
                      justify-content: left;
                      margin-top: -3%;
                    "
                  >
                    <button
                      @click="signInButtonPressed()"
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      style="width: 100%; background: #eb0e19; color: white"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- modal over for elite ////////////////////////////////////////////////////////////////////// -->
            <form
              id="paymentForm"
              method="post"
              action="https://secure.payu.in/_payment"
            >
              <input type="hidden" v-model="mkey" name="key" size="64" />
              <input type="hidden" v-model="txnid" name="txnid" size="64" />
              <input type="hidden" v-model="amount" name="amount" size="64" />
              <input
                type="hidden"
                v-model="productinfo"
                name="productinfo"
                size="64"
              />
              <input
                type="hidden"
                v-model="firstname"
                name="firstname"
                size="64"
              />
              <input type="hidden" v-model="email" name="email" size="64" />
              <input type="hidden" v-model="phone" name="phone" size="64" />
              <input type="hidden" v-model="surl" name="surl" />
              <input type="hidden" v-model="furl" name="furl" />
              <input
                type="hidden"
                id="hash"
                v-model="hash"
                name="hash"
                size="64"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
      <div class="user_balance">
        <img src="../../assets/wallet.png" alt />
        <span>Availabel Blance</span>
        <span>{{ mywallet.total_amount }}</span>
      </div>
    </div> -->
      <!--
      <div class="posts">
        <div class="posts_inner">
          <span>125 Post</span>
         <router-link to="/userviewpost/mypost">
          <span>See all</span>
          </router-link>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="../../assets/01.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/02.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/03.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/04.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/05.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/06.jpg" width="30%" alt="" />
          </div>
        </div>
      </div>
      <div class="offers">
        <div class="offers_inner">
          <span>offers</span>
          <span>See all</span>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="../../assets/01.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/02.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/03.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/04.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/05.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/06.jpg" width="30%" alt="" />
          </div>
        </div>
      </div>
      <div class="tagPost">
        <div class="tagPost_inner">
          <span>Tag Post</span>
          <span>See all</span>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="../../assets/01.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/02.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/03.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/04.jpg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/05.jpeg" width="30%" alt="" />
          </div>
          <div class="grid-item">
            <img src="../../assets/06.jpg" width="30%" alt="" />
          </div>
        </div>
    </div>-->

      <!-- <div class="col-12 col-sm-12 col-md-8 col-lg-8">
      <div class="post_nav_bar">
        <div
          class="post"
          @click="changeEffectPost"
          :class="
            changePost ? 'change-post-effect ' : 'change-post-effect-none '
          "
        >
          <router-link
            to="/userviewpost/mypostnav"
            style="text-decoration: none; color: black"
            >Post</router-link
          >
        </div>
        <router-link
          to="/profile/followers"
          style="text-decoration: none; color: black"
        >
          <div
            class="follower"
            @click="changeEffectHidden"
            :class="
              changefollower
                ? 'change-post-effect '
                : 'change-post-effect-none '
            "
          >
            follower
          </div>
        </router-link>

        <router-link
          to="/profile/following"
          style="text-decoration: none; color: black"
        >
          <div
            class="follower"
            @click="changeEffectReported"
            :class="
              changefollowing
                ? 'change-post-effect '
                : 'change-post-effect-none '
            "
          >
            following
          </div>
        </router-link>

        <router-link
          to="/vendprofile/vendrequest"
          style="text-decoration: none; color: black"
        >
          <div
            class="request"
            @click="changeEffectRequest"
            :class="
              changeRequest ? 'change-post-effect ' : 'change-post-effect-none '
            "
          >
            request
          </div>
        </router-link>
      </div>
    </div> -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <router-view class="animate__animated animate__fadeIn"></router-view>
      </div>
    </div>
  </div>
</template>   
<script>
// import VendorList from "./components/vendorprofilelist/VendorProList.vue";
// import MenuList from "@/components/menu/MenuList.vue"
// import Posts from "./posts.vue";
import navbar from "../../components/nav.vue";
import axios from "axios";
export default {
  name: "UserViewPost",
  components: {
    navbar,
    // Posts,
    // VendorList,
    // MenuList
  },
  data() {
    return {
      UserType: [],
      item: [],
      changePost: true,
      changefollower: false,
      changefollowing: false,
      changeRequest: false,
      mywallet: [],
      productinfo: "yearPlan1",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      txnid: this.makeid(),
      firstname: "",
      email: "",
      phone: "",
      surl: window.location.origin + "/success",
      furl: window.location.origin + "/fail",
      plans: [],
      coupon: "",
      amount: "",
      couponSuMsg: "",
      coponerr: "",
      vendorProfile: [],
      promocode: [],
      isSubscribed: false,
      selectedOption: "",
      newPromoCode: "",
      plan_id: "",
      amountText: "",
      couponCodeText: "",
    };
  },
  methods: {
    getInputUpdatedValue(event) {
      this.couponSuMsg = "";
      // this.amount = 0;

      this.newPromoCode = event.target.value;
    },
    couponApply() {
      axios
        .get("admin/subscriptionPlan", {})
        .then((res) => {
          this.plans = res.data.data;
          // console.log("here is mypla", this.plans);
          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        })
        // this.calculate=this.amount_payPrint/100*90
        //    var remov= parseFloat(this.calculate).toFixed(0)
        //    this.amount_payPrint= remov+'.00'
        .catch((error) => {
          console.log(error);
        });
    },
    getdata() {
      axios
        .get("customers/my-profile", {
          headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0" },
        })
        .then((response) => {
          this.item = response.data.data;
          this.UserType = response.data.data.user_type;
          // alert(this.UserType)
          // console.log("userType",this.UserType)
          this.item1 = response.data.data.email;

          if (this.item.is_subscribed == "0") {
            this.isSubscribed = false;
          } else {
            this.isSubscribed = true;
          }

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        });
    },
    signInButtonPressed() {
      if (this.newPromoCode && this.newPromoCode != this.couponCodeText) {
        // console.log("Subscription With New Promo Code !!!");
        // console.log("User Id: ", this.item.id);
        // console.log("New Promo Code: ", this.newPromoCode);

        axios
          .post(`customers/subscribe`, {
            subscription_plan_id: localStorage.getItem("sub_id"),
            coupon_code: this.newPromoCode,
          })
          .then((response) => {
            response.data.data;

            console.log("Subscription Response: ", response);
            localStorage.setItem("UsingVendorCode", 1);
            this.$router.push("/success");

            // localStorage.setItem(
            //   "Subscription_Response",
            //   JSON.stringify(response.data)
            // );

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }

            // this.$router.push("/success");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        var data =
          this.mkey +
          "|" +
          this.txnid +
          "|" +
          this.amount +
          "|" +
          this.productinfo +
          "|" +
          this.firstname +
          "|" +
          this.email +
          "|||||||||||";
        var sha512 = require("js-sha512");

        var salt = "3oFxUMtWG2";
        var hash = sha512(data + salt);

        // console.log(hash);
        // console.log(data);
        document.getElementById("hash").value = hash;

        document.getElementById("paymentForm").submit();
      }
    },
    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      // console.log(text);
      localStorage.setItem("text", text);
      return text;
    },
    AMountChangeFunction() {
      let planname = document.getElementById("plannem").value;

      this.couponSuMsg = "";

      axios
        .get("admin/subscriptionPlan?search=" + planname, {})
        .then((res) => {
          localStorage.setItem("sub_id", res.data.data[0].id);
          localStorage.setItem("coupon_code", res.data.data[0].coupon);

          this.newPromoCode = "";

          this.plan_id = res.data.data[0].id;

          this.amount = res.data.data[0].amount;
          this.amountText = res.data.data[0].amount;
          this.coupon = res.data.data[0].coupon;
          this.couponCodeText = res.data.data[0].coupon;

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
        })

        .catch((error) => {
          console.log(error);
        });
    },
    couponVerify() {
      this.couponSuMsg = "";

      // console.log("New Promo Code: ", this.newPromoCode);

      if (this.plan_id == "1") {
        axios
          .get(`customers/apply-coupon/1`, {
            params: {
              coupon_code:
                this.newPromoCode && this.newPromoCode != this.couponCodeText
                  ? this.newPromoCode
                  : this.couponCodeText,
            },
          })
          .then((res) => {
            // console.log("Coupon Verify response: ", res);
            this.coponerr = "";
            this.couponSuMsg =
              "Coupon Apply successfully, You get " +
              res.data.data.discount_amount +
              " off from " +
              res.data.data.subscription_plans_amount +
              ".";
            this.amount = res.data.data.amount_pay;

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          })
          .catch((error) => {
            console.log(error);
            console.log(error);
            this.coponerr = "Invalid Coupon Code / Promo Code";
            setTimeout(() => {
              this.coponerr = "";
            }, 2000);
          });
      } else {
        axios
          .get(`customers/apply-coupon/${this.plan_id}`, {
            params: {
              coupon_code: this.couponCodeText,
            },
          })
          .then((res) => {
            // console.log("Coupon Verify response: ", res);
            this.coponerr = "";
            this.couponSuMsg =
              "Coupon Apply successfully, You get " +
              res.data.data.discount_amount +
              " off from " +
              res.data.data.subscription_plans_amount +
              ".";
            this.amount = res.data.data.amount_pay;

            if (localStorage.getItem("token")) {
              axios.defaults.headers.common["Authorization"] =
                " Bearer " + localStorage.getItem("token");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // this.calculate=this.amount_payPrint/100*90

    //    var remov= parseFloat(this.calculate).toFixed(0)
    //    this.amount_payPrint= remov+'.00'

    changeEffectPost() {
      this.changePost = !this.changePost;
      this.changefollower = false;
      this.changefollowing = false;
      this.changeRequest = false;
    },
    changeEffectHidden() {
      this.changefollower = !this.changefollower;
      this.changePost = false;
      this.changefollowing = false;
      this.changeRequest = false;
    },
    changeEffectReported() {
      this.changefollowing = !this.changefollowing;
      this.changePost = false;
      this.changefollower = false;
      this.changeRequest = false;
    },
    changeEffectRequest() {
      this.changeRequest = !this.changeRequest;
      this.changePost = false;
      this.changefollower = false;
      this.changefollowing = false;
    },
    MyWallet() {
      axios.get("customers/my-wallet").then((res) => {
        // console.log(res.data);
        this.mywallet = res.data.data;
      });
    },
    getVendorProfile() {
      axios.get("vendors/my-vendor-profile").then((res) => {
        // console.log("Vendor Profile Response: ", res);
        this.vendorProfile = res.data.data;

        this.promocode = res.data.business_promo_code;

        // console.log("Promocode: ", this.promocode);

        //Assign Data of vendor
        // this.imageUrl = this.vendorProfile.business_logo;
        // this.business_name = this.vendorProfile.business_name;
        // this.business_email = this.vendorProfile.business_email;
        // this.business_mobile = this.vendorProfile.business_mobile;
        // this.business_address = this.vendorProfile.business_address;
        // this.country_id = this.vendorProfile.country.name;
        // this.state_id = this.vendorProfile.state.name;
        // this.city_id = this.vendorProfile.city.name;
      });
    },
  },
  mounted() {
    this.getdata();
    this.getVendorProfile();
    this.MyWallet();
    this.couponApply();
    this.makeid();
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 60%;
  height: 100vh;
  margin: 0 auto;
}

.modal::-webkit-scrollbar,
.modal-dialog::-webkit-scrollbar {
  display: none !important;
}

.mo {
  width: 50%;
  height: 33rem;
}

.modal-content {
  width: 50%;
  height: 33rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media only screen and (min-width: 2080px) {
  .mo {
    height: 40rem;
  }

  .modal-content {
    height: 40rem;
  }
}

@media only screen and (max-width: 2079px) {
  .mo {
    height: 38rem;
  }

  .modal-content {
    height: 38rem;
  }
}

@media only screen and (max-width: 1460px) {
  .mo {
    height: 37rem;
  }

  .modal-content {
    height: 37rem;
  }
}

@media only screen and (max-width: 1280px) {
  .modal-dialog {
    max-width: 65%;
  }

  .mo {
    height: 35rem;
  }

  .modal-content {
    height: 35rem;
  }
}

@media only screen and (max-width: 1024px) {
  .modal-dialog {
    max-width: 75%;
  }

  .mo {
    height: 35rem;
  }

  .modal-content {
    height: 35rem;
  }
}

@media only screen and (max-width: 992px) {
  .modal-dialog {
    max-width: 90%;
    height: 100vh;
    justify-content: center;
  }

  .mo {
    display: none;
  }

  .modal-content {
    width: 60%;
    height: auto;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .modal-dialog {
    max-width: 95%;
  }

  .modal-content {
    width: 70%;
  }
}

@media only screen and (max-width: 577px) {
  .modal-content {
    width: 90%;
  }
}
.row {
  width: 80%;
  margin-left: 12%;
}
.header_img {
  width: 100%;
}
.header {
  width: 100%;
  margin-top: 1rem;
  /* border:1px solid black; */
}
.user_profile {
  position: absolute;
  width: 5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-top: -3rem;
  margin-left: 1rem;
  border: 3px solid white;
}
.header_inner {
  display: flex;
  /* border: 1px solid black; */
  width: 100%;
  box-shadow: 0px 0px 4px #d4d4d4;
  padding: 0.5rem;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0.3rem;
  margin-bottom: 0.6rem;
}
.parent_header_inner {
  position: relative;
}
.home8 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  visibility: visible;
}
.post_nav_bar {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 7px #d4d4d4;
  padding: 0.5rem;
  border-radius: 0.3rem;
  /* max-width: 130%; */
  width: 100%;
}
.user_balance {
  box-shadow: 0px 0px 6px #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.posts {
  margin-top: 0.5rem;
  box-shadow: 0px 0px 7px #d4d4d4;
  border-radius: 0.5rem;
}
.posts_inner {
  display: flex;
  padding: 0.3rem;
  justify-content: space-between;
  align-items: center;
}
.offers {
  margin-top: 0.5rem;
  box-shadow: 0px 0px 7px #d4d4d4;
  border-radius: 0.5rem;
}
.offers_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5;
}
.tagPost {
  margin-top: 0.5rem;
  box-shadow: 0px 0px 7px #d4d4d4;
  border-radius: 0.5rem;
}
.tagPost_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5;
}
.model-inner {
  display: flex;
  align-items: center;
}
/* .user_details {
  border-radius: 0.3rem;
  display: flex;
  margin-top: 0.6rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 6px #d4d4d4;
  padding: 0.6rem;
} */
.elite_ac_btn {
  width: 100%;
  line-height: 2.5;
  border: none;
  background: #eb0e19;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  /* margin-left: -3rem; */
  padding: 5px;
}
.user_details {
  border-radius: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #d4d4d4;
  padding: 0.6rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 00.3rem;
}
.grid-item {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.grid-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.user_details > div {
  flex-basis: 100%;
  margin-top: 10px; /* Add some space between "Personal Info" and "About Us" */
}
.change-post-effect {
  color: #eb0e19;
  /* padding-bottom: 32%; */
  border-bottom: 0.3rem solid #eb0e19;
}
.change-post-effect-none {
  color: #b4b4b4;
}

.pform1 {
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 0%;
  width: 100%;
  margin-left: 0%;
}

.pform1 input {
  width: 90%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.pform1 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  left: 8%;
  margin-top: 3%;
}
.pform1 span::before {
  content: "";
  position: absolute;
  top: 14.2%;
  left: 3.3%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform1 input:focus ~ label,
.pform1 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.pform1 input:focus ~ span::before,
.pform1 input:valid ~ span::before {
  width: 85.3%;
}
.pform2,
.pform3,
.pform4,
.pform5,
.pform6,
.pform7,
.pform8,
.pform9 {
  /* position: relative; */
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 100%;
  margin-left: 0%;
}

.pform2 input,
.pform3 input,
.pform4 input,
.pform5 input,
.pform6 input,
.pform7 input,
.pform8 input,
.pform9 input {
  width: 90%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}
.pform2 label,
.pform3 label,
.pform4 label,
.pform5 label,
.pform6 label,
.pform7 label,
.pform8 label,
.pform9 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  left: 7%;
  margin-top: 3%;
}
.pform2 span::before {
  content: "";
  position: absolute;
  top: 28.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform2 input:focus ~ label,
.pform2 input:valid ~ label {
  top: 53px;
  color: #eb0e19;
}

.pform2 input:focus ~ span::before,
.pform2 input:valid ~ span::before {
  width: 85.3%;
}
.pform3 span::before {
  content: "";
  position: absolute;
  top: 32.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform3 input:focus ~ label,
.pform3 input:valid ~ label {
  top: 21%;
  color: #eb0e19;
}

.pform3 input:focus ~ span::before,
.pform3 input:valid ~ span::before {
  width: 85.3%;
}
.pform4 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform4 input:focus ~ label,
.pform4 input:valid ~ label {
  top: 33%;
  color: #eb0e19;
}

.pform4 input:focus ~ span::before,
.pform4 input:valid ~ span::before {
  width: 85.3%;
}
.pform5 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform5 input:focus ~ label,
.pform5 input:valid ~ label {
  top: 23%;
  color: #eb0e19;
}

.pform5 input:focus ~ span::before,
.pform5 input:valid ~ span::before {
  width: 38%;
}
.pform6 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 50.5%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform6 input:focus ~ label,
.pform6 input:valid ~ label {
  top: 23%;
  color: #eb0e19;
}

.pform6 input:focus ~ span::before,
.pform6 input:valid ~ span::before {
  width: 38%;
}

.pform8 span::before {
  content: "";
  position: absolute;
  top: 77.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform8 input:focus ~ label,
.pform8 input:valid ~ label {
  top: 69%;
  color: #eb0e19;
}

.pform8 input:focus ~ span::before,
.pform8 input:valid ~ span::before {
  width: 85.3%;
}

@media screen and (max-width: 600px) {
  .user_profile {
    position: absolute;
    border-radius: 62%;
    margin-top: -50%;
    margin-bottom: 10%;
    border: 3px solid white;
  }
}
@media screen and (max-width: 480px) {
  .user_profile {
    position: absolute;
    border-radius: 62%;
    margin-top: -50%;
    margin-bottom: 10%;
    border: 3px solid white;
  }
}
</style>
