<template>
  <!-- <h1>Blocked Account</h1>  -->
  <div class="container px-0">
    <h5>Block Account List</h5>
    <table class="mx-auto">
      <thead>
        <th>Checkbox</th>
        <th>Name</th>

        <th></th>
      </thead>
      <tbody v-if="blockedAccounts.length > 0">
        <tr v-for="account in blockedAccounts" :key="account.id">
          <td>
            <input type="checkbox" />
            <img
              v-if="account.profile_photo == ''"
              src="./../assets/cover.png"
              style="width: 2.5rem; height: 2.3rem; border-radius: 50%"
              alt
            />
            <img
              v-else
              :src="account.profile_photo"
              style="width: 2.5rem; height: 2.3rem; border-radius: 50%"
              alt
            />
          </td>
          <td>{{ account.name }}</td>

          <td>
            <button
              class="btn btn-sm text-white"
              style="background-color: #eb0e19"
              @click="unblock(account.id)"
            >
              Unblock
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center mt-5" v-if="blockedAccounts.length == 0">
      You didn't block any one yet.
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "BlockedAccount",
  data() {
    return {
      blockedAccounts: [],
      blockAccId: "",
    };
  },
  computed: {
    // getBlockedAcc() {
    //   return this.blockAccId != "" || this.blockAccId != null
    //     ? this.blockedAccounts.filter((item) => item.id !== this.blockAccId)
    //     : this.blockedAccounts;
    // },
  },
  methods: {
    getBlockedAccounts() {
      axios
        .get(`customers/blocks/my-blockee`)
        .then((res) => {
          this.blockedAccounts = res.data.data.blockee;
          // console.log("Blocked Accounts : ", this.blockedAccounts);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.status_code == "404") {
            this.blockedAccounts = [];
          }
        });
    },
    unblock(id) {
      console.log("Unblock Account Id: ", id);
      axios
        .post(`customers/blocks/unblock`, {
          blockee_id: id,
        })
        .then(() => {
          this.blockAccId = id;
          // console.log("Unblock Accounts Response: ", res);
          // console.log("Blocked Account Array: ", this.blockedAccounts);
          this.getBlockedAccounts();
          // router.go();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.getBlockedAccounts();
  },
};
</script>
<style scoped>
.container {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  height: 40.5rem;
  /* padding: 2%; */
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 0.5rem;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  display: none;
}
.container h5 {
  font-size: 1.5rem;
  font-weight: 500;
  color: grey;

  padding: 0.6rem 1.2rem;
  border-bottom: 2px solid #ccc;
  margin-bottom: 2%;
}
table {
  width: 98%;
  border-collapse: collapse;
}
th {
  padding: 2%;
  margin: 2%;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  /* padding-right: 20px;   */
  /* width:100%;  */
}
tr {
  margin-bottom: 2%;
}
td {
  padding: 2%;
}
td input {
  width: 54%;
}
</style>