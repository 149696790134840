<template>
  <div class="content">
    <div class="about">
      <div class="about1">
        <h3 class="fs-5 ps-3 text-grey">About Us</h3>
      </div>

      <div class="about2">
        <img src="../assets/table.png" />
      </div>

      <div class="about3">
        <h3>
          RedPrix is the ultimate social media app for food lovers, offering the
          best discounts and deals on restaurants near your location. With
          RedPrix, you can discover new and delicious eats while saving big on
          your dining experiences.
          <br />
          <br />
          Here's how it works: Simply upload a selfie with your food, and our
          app will detect your location and give you the best discounts on
          restaurants near you. Book a table, fill in your details and enjoy
          your meal at a fraction of the cost. <br />
          <br />
          At RedPrix, we believe that dining experiences should be memorable,
          budget-friendly, and stress-free. That's why we've designed a
          user-friendly platform that makes booking tables and finding the
          perfect restaurant for any occasion a breeze. <br /><br />
          Join the RedPrix community today and start enjoying delicious eats
          while saving big. Whether you're looking for a romantic dinner for
          two, a fun night out with friends, or just a quick bite, RedPrix has
          got you covered. So, why wait? Download the RedPrix app now and start
          discovering the best restaurants in town!
          <br />
          <br />
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "aboutus",

  methods: {
    openEye() {
      let eyeicon = document.getElementById("eyeicon");
      let password = document.getElementById("eyeicon");

      eyeicon.onclick = function () {
        if (password.type == "password") {
          password.type = "text";
        } else {
          password.type = "password";
        }
      };
    },
  },
};
</script>
<style scoped>
.about {
  width: 100%;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 5px #ccc;
}
.about1 {
  width: 100%;
  /* line-height: 4; */
  margin-top: 0%;
  background: white;
  height: 6%;
  border-bottom: 1px solid grey;
}
.about1 h3 {
  padding-left: 1%;
  padding-top: 3%;
}
.about2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
}

.about2 img {
  width: 13rem;
}
.about3 {
  margin-top: 3%;
}
.about3 h3 {
  width: 95%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 89%;
  color: #0000008c;
}
</style>
