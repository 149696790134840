<template>
  <button
    type="button"
    class="btn-sm"
    @click="$router.push(`/viewmorecomments/${postId}`)"
  >
    <!-- <img src="../assets/comment.png" width="20" height="20" /> -->
    <!-- <i class="fa-solid fa-comment" style="font-size: 1.5rem; color: black"></i> -->
    <i
      class="fa-regular fa-comment"
      style="font-size: 1.5rem; color: black"
      :class="{ 'animate__animated animate__tada': iconClicked }"
    ></i>
    <h6
      style="
        color: black;
        cursor: pointer;
        font-size: 1rem;
        margin-bottom: 0rem;
        margin-top: 0.1rem;
      "
    >
      {{ comments_count }} Comments
    </h6>
  </button>

  <!-- Comment Modal -->
  <div class="modal" :class="{ 'is-active': isModalActive }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Comments</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <ul>
          <li v-for="comment in comments" :key="comment.id">
            {{ comment.text }}
          </li>
        </ul>
        <button @click="viewPost">View More</button>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "user-comment",
  props: {
    postId: String,
    comments_count: String,
  },
  data() {
    return {
      comments: [], // Store comments
      isModalActive: false, // Controls the visibility of the modal
      iconClicked: false,
      clickedId: null,
    };
  },
  methods: {
    clickFunction() {
      this.iconClicked = !this.iconClicked;
    },
    showComments() {
      this.commentIconClicked();
      const apiUrl = `customers/posts/comments?post_id=${this.postId}`;
      // console.log('API URL:', apiUrl);

      // Fetch comments for the specific post
      axios
        .get(apiUrl)
        .then((response) => {
          this.comments = response.data.data; // Update comments with the fetched data
          // Show the comment modal
          this.isModalActive = true;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    closeModal() {
      this.isModalActive = false;
    },
    viewPost() {
      // Redirect to the separate page for the post using the postId
      this.$router.push(`/viewmorecomments/${this.postId}`);
    },
  },
  mounted() {
    // console.log("Post Id: ", this.postId);
  },
};
</script>

<style scoped>
.btn-sm {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  gap: 8px;
}

.btn-sm p {
  font-size: 0.9rem;
}

/* Add custom CSS to style the modal */
.is-active {
  display: block;
}
</style>
