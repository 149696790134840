<template>
  <div class="deletion-policy">
    <h1>RedPrix Privacy Policy</h1>
    <ul>
      <li>
        <strong>Right to deletion:</strong> Users have the right to request the
        deletion of their personal data from RedPrix's servers.
      </li>
      <li>
        <strong>How to request deletion:</strong> Users can request deletion by
        emailing RedPrix's customer support team at
        <a href="mailto:redprixindia@gmail.com">redprixindia@gmail.com</a>. The
        request should include the user's full name, email address, and any
        other information necessary to identify the user's account.
      </li>
      <li>
        <strong>Verification:</strong> RedPrix will verify the identity of the
        user making the deletion request to prevent unauthorized deletion of
        data.
      </li>
      <li>
        <strong>Deletion timeline:</strong> RedPrix will process the deletion
        request within 30 days of receiving the request.
      </li>
      <li>
        <strong>Exceptions:</strong> RedPrix may be required to retain some
        personal data for legal, regulatory, or security purposes.
      </li>
      <li>
        <strong>Notification:</strong> RedPrix will notify the user when their
        personal data has been deleted from RedPrix's servers.
      </li>
      <li>
        <strong>Data backups:</strong> RedPrix may retain backups of user data
        for a limited period of time in order to recover from technical
        failures. Backups will be deleted as soon as they are no longer
        necessary.
      </li>
      <li>
        <strong>Data retention policy:</strong> RedPrix will retain user data
        for as long as necessary to provide services to the user, comply with
        legal or regulatory obligations, resolve disputes, and enforce
        agreements.
      </li>
      <li>
        <strong>Amendments:</strong> RedPrix may amend this privacy policy from
        time to time. Users will be notified of any changes via email or through
        the RedPrix app.
      </li>
    </ul>
    <p>
      Note: This privacy policy is a sample and may need to be customized based
      on RedPrix's specific data processing activities and legal requirements in
      India.
    </p>
  </div>
</template>
<script>
export default {
  name: "DeletionPolicy",
};
</script>
<style scoped>
.deletion-policy {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-size: 1.5rem;
  line-height: 1.5;
}
.deletion-policy h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .deletion-policy {
    max-width: 70%;
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .deletion-policy {
    max-width: 60%;
    font-size: 1.8rem;
  }
}

@media (min-width: 1440px) {
  .deletion-policy {
    max-width: 100%;
    font-size: 1.5rem;
  }
}

.deletion-policy h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .deletion-policy h1 {
    font-size: 3rem;
  }
}

.deletion-policy ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.deletion-policy li {
  margin-bottom: 10px;
}
</style>