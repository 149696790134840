<template>
  <div>
    <body class="text-center mont-font">
      <main class="form-signin">
        <div class="card w-100 shadow-sm rounded border-0 mb-3">
          <div v-if="errormas" class="alert alert-success" role="alert">
            {{ errormas }}
          </div>
          <img
            style="height: auto; width: 10rem"
            class="my-5 mx-auto"
            center
            src="../assets/success_img.png"
            alt="Center image"
          />

          <h1
            class="text-success fw-600 text-danger-600 font-xl"
            style="color: #00a00b !important"
          >
            Your Payment Was Successful !
          </h1>
          <p class="pl-5 pr-5 ml-5 mr-5 fw-300 text-danger-300 font-xssss mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
            laboriosam eligendi laudantium numquam itaque! Asperiores ex soluta
            quaerat, reiciendis ab autem modi voluptates odio cum. Rem atque ab
            quam aliquid, possimus non eius nisi ullam aliquam, est molestiae
            voluptate voluptatibus vitae fuga, et commodi tenetur ipsum culpa!
            Accusamus impedit earum consequuntur a nisi laborum nobis laudantium
            nulla fuga vel, eni!
          </p>

          <a v-bind:href="'/home'" class="fs-5 fw-600 link-hover"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left mb-1"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            Go to Home</a
          >
        </div>
      </main>
    </body>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "success",
  data() {
    return {
      errormas: "",

      coupon_code: "",

      errormasss: "",
      user_id: "",
    };
  },

  mounted() {
    // New Implementation of Payment Status Code

    if (
      localStorage.getItem("UsingVendorCode") &&
      localStorage.getItem("UsingVendorCode") == "1"
    ) {
      this.user_id = localStorage.getItem("id");
      axios
        .post("customers/paymentStatus/" + this.user_id, {
          payment_status: "1",
        })
        .then((result) => {
          console.log("Success Page Response: ", result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`customers/subscribe`, {
          subscription_plan_id: localStorage.getItem("sub_id"),
          coupon_code: localStorage.getItem("coupon_code"),
        })
        .then((response) => {
          response.data.data;

          // console.log("Subscription Response: ", response);

          // localStorage.setItem(
          //   "Subscription_Response",
          //   JSON.stringify(response.data)
          // );

          if (localStorage.getItem("token")) {
            axios.defaults.headers.common["Authorization"] =
              " Bearer " + localStorage.getItem("token");
          }
          // Pass postId to likeList
          // this.$router.push("/success");
        })
        .catch((error) => console.log(error));

      this.user_id = localStorage.getItem("id");
      axios
        .post("customers/paymentStatus/" + this.user_id, {
          payment_status: "1",
        })
        .then((result) => {
          console.log("Success Page Response: ", result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  // Old Implementation of Payment Status Code

  // this.subscribe = JSON.parse(localStorage.getItem("copondetails"));
  // if (this.subscribe) {
  //   this.coupon_code = this.subscribe.applied_coupon;
  // } else {
  //   this.coupon_code = "";
  // }

  // if (localStorage.getItem("hash")) {
  //   this.errormas =
  //     "Your subscription process is in progress. Do not refresh or leave page";
  //   axios
  //     .post("customers/subscribe", {
  //       plan_id: "1",
  //       coupon_code: this.coupon_code,
  //     })
  //     .then((result) => {
  //       if (result.data.status_code == 201) {
  //         axios
  //           .post("customers/paymentStatus/" + result.data.data.id, {
  //             payment_status: "1",
  //           })
  //           .then((result) => {
  //             if (result.data.status_code == "200")
  //               this.$router.go(this.$router.currentRoute);
  //             localStorage.removeItem("hash");
  //             localStorage.removeItem("copondetails");
  //           });
  //       }
  //     });
  // }

  // if (localStorage.getItem("expireSession")) {
  //   setTimeout(() => {
  //     localStorage.removeItem("expireSession");
  //   }, 10000);
  // } else if (!localStorage.getItem("expireSession")) {
  //   this.$router.push("/home");
  // }

  // .catch((error) => {
  //     this.errormas = error.response.data.message;

  // })

  //         this.subscribe = JSON.parse(localStorage.getItem("subscribe"));
  //         const response = axios.post('payment/'+this.subscribe.id,{
  //                payment_status:'1'
  //            }) .catch(error =>{
  //                            if(error){this.errormas=error.response.data.message}
  //                            console.log(error)})
  //                            if (response.data.status_code == '200') {
  //                             this.$router.go(this.$router.currentRoute)
  //                              localStorage.removeItem('subscribe');
  //  }

  //            console.log(response)
};
</script>
<style scoped>
body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-signin {
  width: 100%;
  max-width: 674px;

  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.link-hover {
  width: 30%;
  margin-top: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  text-decoration: none;
  border-radius: 0.6rem;
  padding: 0.4rem 0.2rem;
  color: #00a00b;
  transition: all ease-in-out 0.3s;
}

.link-hover:hover {
  background: #00a00b;
  color: white;
}
</style>