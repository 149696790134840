<template>
  <div class="animate__animated animate__fadeIn">
    <NavBar />
    <div class="tutorial-container">
      <div class="tutorial-main-div">
        <div class="tutorial-header">
          <img src="./assets/header_bg.png" alt="header_bg" class="header-bg" />
          <div class="tutorial-details">
            <div class="tutorial-header-desc">
              <img src="./assets/ib_logo.png" alt="ib_logo" />
              <h3>LEARN AND GROW WITH OUR TUTORIALS</h3>
              <h4>
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
            </div>
            <div class="tutorial-header-img-div">
              <img src="./assets/Group_44667.png" alt="header-img" />
            </div>
          </div>
        </div>
        <div class="tutorial-heading">
          <h3>HOW REDPRIX WORKS?</h3>
          <h4>Step-By-Step Guides To Enhance Your Skills</h4>
        </div>
        <div class="tutorial-first-video-section">
          <!-- RP WEB  -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(web_rp)">
              <img src="./assets/rp_laptop.png" alt="video-img" />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(web_rp)">HOW DOES REDPRIX WEBSITE WORK?</h3>
              <h4 @click="redirect(web_rp)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(web_rp)"
              >
                <div class="inside-button">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>

          <!-- RP APP -->
          <div class="tutorial-video-div">
            <div class="video-img-div" @click="redirect(app_rp)">
              <img
                src="./assets/rp_mobile.png"
                id="mobile-img"
                alt="video-img"
              />
            </div>
            <div class="video-desc-div">
              <h3 @click="redirect(app_rp)">
                HOW DOES REDPRIX APPLICATION WORK?
              </h3>
              <h4 @click="redirect(app_rp)">
                The header serves as the navigational backbone of the page. It
                includes the website logo for brand recognition, a navigation
                bar for easy access to key sections, and a search bar for quick
                tutorial lookups.
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                @click="redirect(app_rp)"
              >
                <div class="inside-button">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="tutorial-heading heading-2">
          <h3>WHAT IS INFINITYBRAINS ?</h3>
          <h4>Step-By-Step Guides To Enhance Your Skills</h4>
        </div>
        <div class="tutorial-second-video-section">
          <div class="tutorial-video-div-2" @click="redirect(video_one_hour)">
            <div class="video-img-div-2" @click="redirect(video_one_hour)">
              <img src="./assets/video-2-img.png" alt="video-2-img" />
            </div>
            <div class="video-desc-2" @click="redirect(video_one_hour)">
              <h3>
                Infinity Brains Product Showcase: How Our Apps and Web Platforms
                Work
              </h3>
              <h4>
                Welcome to Infinity Brains! In this comprehensive one-hour
                video, we showcase all of our company's innovative products
                through detailed screen recordings. You'll learn how our apps
                and websites work, making it easy for users to navigate and
                utilize our technology. Plus, we've sprinkled in some funny
                clips to keep things entertaining. Join us and discover the
                functionality and fun behind Infinity Brains' products!
              </h4>
              <button
                type="button"
                class="tutorial-btn"
                id="second-video-btn"
                @click="redirect(video_one_hour)"
              >
                <div class="inside-button">
                  <h3>WATCH NOW</h3>
                  <img src="./assets/play_icon.svg" alt="play-icon" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="red-area-div"></div>

      <div class="last-logo-div">
        <img
          src="./assets/RP_1.png"
          alt="Logo Image"
          @click="this.$router.push({ name: 'home' })"
        />
      </div>
    </div>
    <FooterComp />
  </div>
</template>

<script>
import "./style/TutorialPage.css";

import NavBar from "../nav.vue";
import FooterComp from "../Footer/FooterComp.vue";

import Swal from "sweetalert2";

export default {
  name: "TutorialPage",
  components: {
    NavBar,
    FooterComp,
  },
  data() {
    return {
      app_rp: "https://www.youtube.com/watch?v=-ITkvE28ils",

      web_rp: "https://www.youtube.com/watch?v=FHy0nbT2UKQ",

      video_one_hour: "https://youtu.be/fcODYjNaQ7s?si=TQfr6QfdZ3g2ZjyS",
    };
  },
  methods: {
    redirect(url) {
      if (url !== "") {
        window.location.href = url;
      } else {
        Swal.fire({
          title: "Video Uploading In Progress!!",
          text: "That video content will be uploaded soon!!",
          icon: "info",
        });
      }
    },
  },
};
</script>

<style scoped>
.last-logo-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  cursor: pointer;
}

.last-logo-div img {
  width: 12rem;
}

.red-area-div {
  width: 100%;
  height: 50px;
  margin-top: 24px;
  margin-bottom: 12px;
  background-color: #ed1823;
}
</style>
