<template>
  <div>
    <navbar />
    <div class="row" style="padding: 0; margin: 0">
      <div class="costom-col-md-6">
        <div class="home8 px-2 pb-3" id="home8" v-if="post" :key="post.id">
          <div class="user_details">
            <img
              src="./../assets/cover.png"
              controls
              style="width: 2.5rem; height: 2.3rem; border-radius: 50%"
              v-if="post.user.profile_photo == ''"
            />

            <img
              :src="post.user.profile_photo"
              v-else
              controls
              style="width: 2.5rem; height: 2.3rem; border-radius: 50%"
            />
            <div class="ms-1 d-flex flex-column" style="width: 100%">
              <div class="mt-1">
                <h6 class="mb-0 ms-2" style="font-size: 1.2rem">
                  {{ post.user.name }} {{ post.images[0].post_id }}
                </h6>
              </div>
              <div
                class="h3 d-flex align-items-center ms-1"
                style="margin-top: 0.1rem"
              >
                <i
                  class="fa-regular fa-clock"
                  style="font-size: 0.6rem; color: black"
                ></i>
                <h6 style="margin-bottom: 0">
                  {{ moment(post.images[0].created_at).fromNow() }}
                </h6>
              </div>
            </div>
            <div class="lst_btn">
              <list :postId="post.id" />
            </div>
          </div>

          <!-- <div class="h2">
            <div class="h3 d-flex align-items-center  ms-1" style="margin-top: 0.1rem">
              <i class="fa-regular fa-clock" style="font-size: 0.6rem; color: black"></i>
              <h6 style="margin-bottom: 0">
                {{ moment(post.images[0].created_at).fromNow() }}
              </h6>
            </div>
          </div> -->

          <div class="home10 ps-0">
            <h5 style="font-size: 1rem; color: gray; margin-left: 0.7rem">
              {{ post.description }}
            </h5>
          </div>

          <!-- <div>
                    <img :src="post.images[0].image" style="width: 100%; aspect-ratio: 1.2" />
                </div> -->

          <div v-if="post.images && post.images.length > 0">
            <div
              v-if="post.images.length > 1"
              class="d-flex overflow-x-auto scroll-div"
            >
              <div v-for="postimg in post.images" :key="postimg.id" class="p-2">
                <div class="object-fit img-1-div">
                  <img :src="postimg.image" alt="Post Image" class="" />
                </div>
              </div>
            </div>

            <div v-else class="p-2 img-2-div rounded-3">
              <img :src="post.images[0].image" alt="Post Image" class="" />
            </div>
          </div>

          <div class="home11 pb-3" style="border-bottom: 1px solid lightgray">
            <div class="ho1">
              <userlike
                :postId="post.id"
                :post-id="post.id"
                :likes_count="post.likes_count"
                :is_liked="post.is_liked == '0' ? false : true"
              />
              <usercomment
                :postId="post.id"
                :comments_count="post.comments_count"
              />
              <usershare :postId="post.id" :shares_count="post.shares_count" />
            </div>
          </div>

          <div class="home12">
            <div class="home13">
              <!-- <div v-for="comment in ShowComments" :key="comment.id" class="user_comments"> -->
              <div
                v-for="comment in displayedComments"
                :key="comment.id"
                class="user_comments"
              >
                <div class="w-100 d-flex align-items-center">
                  <img
                    src="../assets/cover.png"
                    alt
                    v-if="comment.user.profile_photo === ''"
                    style="width: 3rem; height: 2.8rem; border-radius: 50%"
                  />
                  <img
                    :src="comment.user.profile_photo"
                    v-else
                    style="width: 3rem; height: 2.8rem; border-radius: 50%"
                  />
                  <div class="usr-comments">
                    {{ comment.user.name }}
                    <br />
                    {{ comment.comment }}
                  </div>
                </div>
                <hr class="my-4" style="width: 100%" />
              </div>
            </div>

            <div
              class="more-comments-button"
              v-if="commentsToShow < ShowComments.length"
            >
              <button
                @click="loadMoreComments"
                style="border-bottom: 2px solid gray"
              >
                View more comments
              </button>
            </div>

            <div class="add_comments" :key="post.id">
              <input
                class="add_comments_input"
                v-model="post.newComment"
                placeholder="Add Comments"
              />
              <button
                class="add_comments_btn text-dark"
                @click="AddComments(post.id, post.newComment)"
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-3"></div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import userlike from "./user-like.vue";
import usercomment from "./user-comment.vue";
import usershare from "./user-share.vue";
import list from "./list.vue";
import navbar from "./nav.vue";

export default {
  props: ["postId"],
  name: "ViewMoreComnT",
  components: {
    userlike,
    usercomment,
    usershare,
    list,
    navbar,
  },

  data() {
    return {
      ShowComments: [],
      moment: moment,
      post: null, // Initialize post to null
      commentsToShow: 2,
    };
  },

  created() {
    // Get the postId parameter from the route
    const postId = this.$route.params.postId;

    // Fetch the single post and its comments based on postId
    this.fetchPostAndComments(postId);
  },

  computed: {
    displayedComments() {
      // Return the first 'commentsToShow' comments from 'ShowComments'
      return this.ShowComments.slice(0, this.commentsToShow);
    },
  },

  methods: {
    fetchPostAndComments(postId) {
      axios
        .get(`customers/posts/${postId}`)
        .then((response) => {
          if (response.data && response.data.data) {
            // Set the post data
            this.post = response.data.data;

            // Call showComments to fetch and display comments
            this.showComments(postId);

            // ...
          } else {
            console.error("Invalid response data received from the API.");
          }
        })
        .catch((error) => {
          console.error("Error fetching single post and comments:", error);
        });
    },

    showComments(id) {
      // alert()
      axios
        .post("customers/posts/comments", {
          post_id: id,
        })
        .then((response) => {
          this.ShowComments = response.data.data.comments;
          // console.log(this.ShowComments, "showComments");
        });
    },

    AddComments(postId, comments) {
      // Send the comment to your backend API
      axios
        .post("customers/comments", {
          post_id: postId,
          comment: comments,
        })
        .then((response) => {
          // Assuming your API returns the updated post data
          if (response.data && response.data.data) {
            const post = this.posts.find((post) => post.id === postId);
            if (post) {
              post.newComment = ""; // Clear the input field after posting
            }
          }
        })
        .catch((error) => {
          console.error("Error posting comment:", error);
        });
    },

    loadMoreComments() {
      // Increment 'commentsToShow' by 10 to show the next batch of comments
      this.commentsToShow += 10;
    },

    toggleMenu() {
      let togg = document.querySelector(".togg");
      togg.classList.toggle("newlist");
    },

    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },

    toggleDown() {
      let togg4 = document.querySelector(".togg4");
      togg4.classList.toggle("newlist4");
    },

    toggleSecond() {
      let togg5 = document.querySelector(".togg5");
      togg5.classList.toggle("newlist5");
    },

    postClick() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.add("postpop");
    },

    closePost() {
      let modal2 = document.getElementById("modal2");
      modal2.classList.remove("postpop");
    },

    tagPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.add("tagpop");
    },

    returnPost() {
      let modal3 = document.getElementById("modal3");
      modal3.classList.remove("tagpop");
    },
  },
};
</script>

<style scoped>
.scroll-div {
  width: 98%;
  height: 32rem;
}

.scroll-div::-webkit-scrollbar {
  display: none;
}

.img-1-div img {
  width: 31.3rem;
  height: 30rem;
}

.img-2-div {
  width: 100%;
  height: 32rem;
  overflow: hidden;
}

.img-2-div img {
  width: -webkit-fill-available;
  height: 30rem;
}
.costom-col-md-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.custom-home8 {
  width: 100%;
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.custom-user_details {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 2%;
}

.home8 {
  width: 100%;
  margin-top: 2%;
  border: 1.5px solid #e7e7e7;
  border-radius: 6px;
  visibility: visible;
  box-shadow: 0px 0px 23px 11px whitesmoke;
  margin-bottom: 4.5rem;
}

.user_details {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 1.2rem 0.6rem;
}

.nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
}

.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}

.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}

.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}

.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}

.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist3 {
  transform: scale(1);
}

.togg4 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist4 {
  transform: scale(1);
}

.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist5 {
  transform: scale(1);
}

.home {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 1%;
}

.home button {
  margin-left: 21%;
  margin-top: 3%;
  width: 57%;
  border: none;
  line-height: 2.2;
  font-size: 88%;
  border-radius: 6px;
  background: dodgerblue;
  color: white;
}

/* .home2 {
  margin-top: 29%;
  width: 71%;
  height: 40vh;
} */
.home2 img {
  width: 100%;
  padding: 1.5%;
}

.home3 {
  margin-left: 0%;
  width: 53%;
  height: 5vh;
  padding: 0rem 1rem 0rem;
}

.home6 {
  margin-top: 2%;
  margin-left: -2%;
}

.home8 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  visibility: visible;
}

.cus-all {
  visibility: hidden;
}

.togg {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist {
  transform: scale(1);
}

.newlist1 {
  transform: scale(1);
}

.home15 {
  margin-top: 2%;
}

.h2 {
  display: flex;
  width: 100%;
  margin-left: 3.7rem;
  margin-top: 0;
  margin-bottom: 0;
}

.h3,
.h4 {
  display: flex;
  width: 100%;
  margin-top: -1.5rem;
  align-items: center;
  /* padding-left: 20; */
  gap: 2%;
  margin-bottom: 0;
}

.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}

.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 1rem;
  padding-left: 1%;
}

.ho1 {
  display: flex;
  align-items: center;
  margin-top: 2%;
  padding: 0% 0.5% 0%;
  justify-content: space-between;
}

.home12 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  padding: 1rem;
}

.home13 {
  margin-top: 0.8rem;
  width: 100%;
}

.usr-comments {
  background: rgb(243, 241, 241);
  border-radius: 6px;
  width: 100%;
  padding: 13px 13px;
  margin-left: 0.8rem;
}

.user_comments {
  display: flex;
  align-items: center;
  padding: 0rem 0.2rem;
  flex-direction: column;
}

.home16 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0% 1% 0%;
  /* margin-left: 25%; */
  border: 1px solid lightgray;
  margin-top: 1.5%;

  border-radius: 6px;
  margin-bottom: 26%;
  background-color: #fff;
  padding: 0% 3% 0%;
  flex-wrap: wrap;
}

.home17 {
  width: 27%;
  border: 1px solid;
  margin: 3%;
  margin-left: -1%;
  padding: 1%;
}

.home17 img {
  width: 100%;
  padding: 0.2rem;
}

.home17 button {
  margin: 1%;
  font-size: 80%;
  line-height: 1.8;
  width: 100%;
  background: #eb0e19;
  border: none;
  color: white;
  border-radius: 6px;
  font-size: 74%;
}

.home19,
.home21 {
  width: 154%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
}

.home20 {
  border-top: 1px solid;
  margin-top: 7%;
}

.pform1 {
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 0%;
  width: 91%;
  margin-left: 0%;
}

.pform1 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.pform1 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}

.pform1 span::before {
  content: "";
  position: absolute;
  top: 14.2%;
  left: 3.3%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform1 input:focus ~ label,
.pform1 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.pform1 input:focus ~ span::before,
.pform1 input:valid ~ span::before {
  width: 85.3%;
}

.pform2,
.pform3,
.pform4,
.pform5,
.pform6,
.pform7,
.pform8,
.pform9 {
  /* position: relative; */
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 91%;
  margin-left: 0%;
}

.pform2 input,
.pform3 input,
.pform4 input,
.pform5 input,
.pform6 input,
.pform7 input,
.pform8 input,
.pform9 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.pform2 label,
.pform3 label,
.pform4 label,
.pform5 label,
.pform6 label,
.pform7 label,
.pform8 label,
.pform9 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}

.pform2 span::before {
  content: "";
  position: absolute;
  top: 28.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform2 input:focus ~ label,
.pform2 input:valid ~ label {
  top: 55px;
  color: #eb0e19;
}

.pform2 input:focus ~ span::before,
.pform2 input:valid ~ span::before {
  width: 85.3%;
}

.pform3 span::before {
  content: "";
  position: absolute;
  top: 32.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform3 input:focus ~ label,
.pform3 input:valid ~ label {
  top: 22%;
  color: #eb0e19;
}

.pform3 input:focus ~ span::before,
.pform3 input:valid ~ span::before {
  width: 85.3%;
}

.pform4 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform4 input:focus ~ label,
.pform4 input:valid ~ label {
  top: 33%;
  color: #eb0e19;
}

.pform4 input:focus ~ span::before,
.pform4 input:valid ~ span::before {
  width: 85.3%;
}

.pform5 span::before {
  content: "";
  position: absolute;
  top: 42.7%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform5 input:focus ~ label,
.pform5 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform5 input:focus ~ span::before,
.pform5 input:valid ~ span::before {
  width: 38%;
}

.pform6 span::before {
  content: "";
  position: absolute;
  top: 42.5%;
  left: 50.5%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform6 input:focus ~ label,
.pform6 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform6 input:focus ~ span::before,
.pform6 input:valid ~ span::before {
  width: 38%;
}

.pform8 span::before {
  content: "";
  position: absolute;
  top: 77.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform8 input:focus ~ label,
.pform8 input:valid ~ label {
  top: 69%;
  color: #eb0e19;
}

.pform8 input:focus ~ span::before,
.pform8 input:valid ~ span::before {
  width: 85.3%;
}

.modal2 {
  position: absolute;
  background: #0e0e0e8c;
  width: 102.1%;
  height: 307.5%;
  margin-left: -28%;
  visibility: hidden;
  margin-top: -15%;
  margin-left: -34%;
}

.postpop {
  visibility: visible;
}

.modal-pop {
  width: 38%;
  height: 23%;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-pop h3 {
  background: #eb0e19;
  margin-top: -12%;
  height: 13%;
  width: 111.5%;
  margin-left: 0.55;
  text-align: left;
  margin-left: -5.6%;
  padding-left: 3%;
  padding-top: 5%;
  color: white;
  font-size: 129%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-pop img {
  margin-left: -91%;
  margin-top: 4%;
  border-radius: 50%;
}

.modal-pop h4 {
  width: 42%;
  margin-left: 12%;
  text-align: left;
  margin-top: -9%;
  font-size: 144%;
}

.modal-pop h5 {
  text-align: left;
  width: 26%;
  margin-left: 12.2%;
  margin-top: 0.5%;
  font-size: 82%;
  font-weight: 100;
}

.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 90%;
  margin-left: 4%;
}

.text input {
  width: 82%;
  padding: -27px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
  margin-left: -17%;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.mpop {
  text-align: left;
  width: 90%;
  margin-left: 4%;
}

.mpop input {
  width: 99%;
  height: 68px;
  border-radius: 10px;
  border: 1px solid;
  outline: none;
}

.mpop1 {
  margin-left: 3%;
  margin-top: 5%;
  border: 2px dashed;
  height: 31%;
  width: 92%;
  border-radius: 10px;
}

.mpop3 {
  margin-top: -7%;
}

.mpop2 button,
.mpop3 button {
  margin-left: -54%;
  margin-top: 3%;
  width: 36%;
  background: white;
  border: 1px solid;
  border-radius: 6px;
  /* height: 43%; */
  cursor: pointer;
}

.mpop3 button {
  margin-top: -3.5%;
  margin-left: 49%;
}

.mpop2 button img,
.mpop3 button img {
  margin-left: -60%;
  margin-top: -3%;
}

.mpop2 button h4,
.mpop3 button h4 {
  width: 24%;
  /* margin-top: -11%; */
  margin-top: 1.7%;
  position: absolute;
  margin-left: 10%;
}

.mpop4 {
  margin-top: 3%;
}

.mpop4 button {
  width: 93%;
  line-height: 1.8;
  border-radius: 6px;
  font-size: 113%;
  border: none;
  background: #eb0e19;
  color: white;
  cursor: pointer;
}

.mpop5 {
  width: 9%;
}

.mpop5 img {
  width: 3.8%;
  margin-top: -10%;
  position: absolute;
  margin-left: 86%;
  cursor: pointer;
}

.mpop1 button input[type="file"] {
  position: absolute;
  transform: scale(2);

  width: 21px;
  margin-left: -18px;
  margin-top: 8px;
  cursor: pointer;
}

.more-comments-button {
  width: 100%;
}

.add_comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  width: 100%;
}

.add_comments_btn {
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 0.5rem;
  border: 2px solid #abaaaa;
  margin: 0 13px;
  width: 25%;
}

.add_comments_input {
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.5rem;
  border: 2px solid #abaaaa;
}

.exhome {
  display: flex;
  gap: 3%;
  margin-left: 1%;
  margin-top: -1%;
}

/* .lst_btn{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
} */

@media screen and (max-width: 768px) {
  /* Add responsive styles for smaller screens here */
  .costom-col-md-6 {
    flex-direction: column;
  }

  .custom-home8 {
    width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .home8 {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .costom-col-md-6 {
    width: 50%;
    margin: auto;
  }
}

@media screen and (max-width: 856px) {
  .costom-col-md-6 {
    width: 100%;
    margin: auto;
  }

  .home8 {
    width: 75%;
  }
}

@media screen and (max-width: 525px) {
  .home8 {
    width: 100%;
  }

  .h2 {
    width: 0%;
  }
}

@media screen and (min-width: 1362px) {
  .home8 {
    width: 45%;
  }
}
</style>
