<template>
  <div class="my-3">
    <div
      class="raise w-100 d-flex justify-content-evenly align-items-center"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal1"
      style="
        border-radius: 10px;
        box-shadow: 1px 1px 1px 1px gainsboro;
        cursor: pointer;
      "
    >
      <img src="../assets/Path5048.svg" alt style="width: 10%" />
      <h3 style="font-size: 1.7rem; color: #b91a1a; font-weight: 400">
        Raise a Ticket
      </h3>
    </div>

    <!-- Modal - 1 -->
    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Raise A Ticket
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="container">
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <p class="mb-4">Please Fill the Details of the Problem</p>

                <div class="mb-3 fullname w-100">
                  <label for="rtName" class="text-danger">Full Name</label>
                  <input
                    class="form-control"
                    v-model="rtName"
                    id="form-rtName"
                    type="text"
                    required
                  />
                </div>

                <div class="mb-3 email w-100 p-0">
                  <label for="rtEmail" class="text-danger">Email Address</label>
                  <input
                    v-model="rtEmail"
                    class="form-control"
                    type="email"
                    id="form-rtEmail"
                    required
                  />
                </div>

                <div class="mb-3 contactUs w-100">
                  <label for="rtContactNumber" class="text-danger"
                    >Contact Number</label
                  >
                  <input
                    v-model="rtContactNumber"
                    id="rtContactNumber"
                    class="form-control"
                    type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '1');"
                    maxlength="10"
                    required
                  />
                </div>

                <div class="mb-3 Address w-100">
                  <label for="rtAddress" class="text-danger">Address</label>
                  <input
                    v-model="rtAddress"
                    id="from-rtAddress"
                    class="form-control"
                    type="text"
                    required
                  />
                </div>

                <div class="row mb-3 Address w-100">
                  <div class="col p-0">
                    <label for="rtCountry" class="form-label text-danger"
                      >Event Country</label
                    >
                    <select
                      v-model="rtCountry"
                      @change="loadStates"
                      class="form-control"
                      id="form-rtCountry"
                      required
                    >
                      <option disabled value="">Select Country</option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.id"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="col p-0">
                    <label for="rtState" class="form-label text-danger"
                      >Event State</label
                    >
                    <select
                      v-model="rtState"
                      @change="loadCities"
                      class="form-control"
                      id="form-rtState"
                      required
                    >
                      <option disabled value="">Select State</option>
                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3 Address w-100">
                  <div class="col p-0">
                    <label for="rtCity" class="form-label text-danger"
                      >Event City</label
                    >
                    <select
                      v-model="rtCity"
                      class="form-control"
                      id="form-rtCity"
                      required
                    >
                      <option disabled value="">Select City</option>
                      <option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="col p-0">
                    <label for="rtPinCode" class="form-label text-danger"
                      >City Pin-Code</label
                    >
                    <input
                      v-model="rtPinCode"
                      type="text"
                      class="form-control"
                      id="form-rtPinCode"
                      required
                    />
                  </div>
                </div>

                <div class="file-upload w-100">
                  <div class="d-flex justify-content-between">
                    <label
                      for="documentFile"
                      class="form-label text-danger"
                      style="cursor: pointer"
                    >
                      Upload Image
                    </label>
                    <img
                      src="../assets/Group4826.svg"
                      style="width: 5rem"
                      alt="upload-image-logo"
                    />
                  </div>
                  <div
                    class="mt-2"
                    style="border-bottom: 2px solid #dd3949"
                  ></div>

                  <input
                    type="file"
                    id="documentFile"
                    name="documentFile"
                    accept="image/*, application/pdf"
                    class="form-control visually-hidden"
                    @change="handleFileSelectDocuments"
                  />
                  <div class="d-flex flex-column mt-2">
                    <p v-if="selectedFiles.length > 0" class="mb-1">
                      Selected Files:
                    </p>
                    <ul class="list-group list-group-flush">
                      <li
                        v-for="(file, index) in selectedFiles"
                        :key="index"
                        class="list-group-item"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>{{ file.name }}</span>
                          <button
                            @click="removeFile(index)"
                            type="button"
                            class="btn-close btn-close-black"
                            aria-label="Close"
                          ></button>
                        </div>
                        <hr v-if="selectedFiles.length > 0" />
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mb-3 message">
                  <label for="rtDescribeProblem" class="text-danger"
                    >Describe the Problem</label
                  >
                  <textarea
                    v-model="rtDescribeProblem"
                    id="from-rtDescribeProblem"
                    class="form-control fs-6"
                    cols="53"
                    rows="4"
                    required
                  ></textarea>
                </div>

                <div class="modal-footer w-100">
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    @click="submitForm"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RaiseTicket",

  data() {
    return {
      //   imagePreview: [],
      selectedFiles: [],

      countries: [],
      states: [],
      cities: [],

      rtName: "",
      rtEmail: "",
      rtContactNumber: "",
      rtAddress: "",
      rtCountry: "",
      rtState: "",
      rtCity: "",
      rtPinCode: "",
      rtDescribeProblem: "",
      projectName: "Red-Prix",
    };
  },
  mounted() {
    // Load countries on component mount
    this.loadCountries();
  },

  watch: {
    rtCountry: "loadStates",
    rtState: "loadCities",
  },

  methods: {
    handleFileSelectDocuments(event) {
      const files = event.target.files;
      // this.selectedFiles = event.target.files;

      if (files && files.length > 0) {
        //   // Handle the files (validate, store, etc.)
        //   console.log("Selected Files:", files);

        //   // Clear previous selection
        //   // this.selectedFiles = [];
        for (let i = 0; i < files.length; i++) {
          this.selectedFiles.push(files[i]);
          // console.log("File Details:", this.selectedFiles[i]);
          // console.log("File type:", this.selectedFiles[i].type);
        }
      }
    },

    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },

    submitForm() {
      const formData = new FormData();

      formData.append("name", this.rtName);
      formData.append("email", this.rtEmail);
      formData.append("mobileno", this.rtContactNumber);
      formData.append("address", this.rtAddress);
      formData.append("country", this.rtCountry);
      formData.append("state", this.rtState);
      formData.append("city", this.rtCity);
      formData.append("pincode", this.rtPinCode); // Uncomment if needed
      formData.append("issue", this.rtDescribeProblem);
      formData.append("project_name", this.projectName);

      // // Append each selected file to FormData
      for (let i = 0; i < this.selectedFiles.length; i++) {
        // formData.append(`raise_ticket${[i]}`, this.selectedFiles[i]);
        formData.append("image", this.selectedFiles[i]);
      }

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      // Make an API call to your backend
      axios
        .post(
          "https://riseticket.infinitybrains.com/public/api/addproject",
          formData,
          {
            headers,
          }
        )
        .then(() => {
          // Handle the success response
          // console.log(response.data);

          this.resetFormData();
        })
        .catch((error) => {
          // Handle the error
          console.error("Axios Error:-", error);
        });
    },

    resetFormData() {
      this.rtName = "";
      this.rtEmail = "";
      this.rtContactNumber = "";
      this.rtAddress = "";
      this.rtCountry = "";
      this.rtState = "";
      this.rtCity = "";
      this.rtPinCode = "";
      this.rtDescribeProblem = "";
      this.selectedFiles = [];
    },

    async loadCountries() {
      try {
        let allCountries = [];
        let shouldContinue = true;
        let page = 1;

        while (shouldContinue) {
          const response = await axios.get(
            `https://api.garbajockey.com/api/countries?page=${page}`
          );
          // console.log("Response:", response); // Log the response

          const countries = response.data && response.data.data;

          if (!countries || countries.length === 0) {
            // No more countries to fetch, break the loop
            shouldContinue = false;
          } else {
            allCountries = [...allCountries, ...countries];
            page++;
          }
        }

        this.countries = allCountries;
        // console.log("All Countries:", this.countries); // Log the final list of countries
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    },

    async loadStates() {
      try {
        const response = await axios.get(
          `https://api.garbajockey.com/api/states_of_country?country_id=${this.rtCountry}`
        );
        this.states = response.data.data; // Update states array
        // console.log("states = ", this.states);
        this.rtState = ""; // Reset state value
        this.rtCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading states:", error);
      }
    },

    async loadCities() {
      try {
        const response = await axios.get(
          `https://api.garbajockey.com/api/cities_of_state?state_id=${this.rtState}`
        );
        this.cities = response.data.data; // Update cities array
        // console.log("states = ", this.cities);
        this.rtCity = ""; // Reset city value
      } catch (error) {
        console.error("Error loading cities:", error);
      }
    },
  },
};
</script>

<style scoped>
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
