import { createRouter, createWebHistory } from "vue-router";
import Signin from "./components/Signin.vue";
import Register from "./components/Register.vue";
import Forgotpassword from "./components/Forgotpassword.vue";
import Verification from "./components/Verification.vue";
import home from "./components/home.vue";
import AllOffers from "./components/AllOffer.vue";
// import Dinein from "./components/Dinein.vue";
import notification from "./components/notification.vue";
import editprofile from "./components/editprofile.vue";
import setting from "./components/setting.vue";
import termsandcondition from "./components/termsandcondition.vue";
import privacypolicy from "./components/privacypolicy.vue";
import aboutus from "./components/aboutus.vue";
import contactus from "./components/contactus.vue";
import joininflu from "./components/joininflu.vue";
import success from "./components/success.vue";
import fail from "./components/fail.vue";
import commentshow from "./components/commentshow.vue";
import deletionpolicy from "./../src/redprixDeletionPolicy.vue";
import privacypolicy_1 from "./../src/redprixPrivacyPolicy.vue";
import BecomeVendor from "./components/menu/CreateVendor.vue";
import ViewMoreComnT from "./components/ViewMoreComments.vue";
import ModelPopup from "./components/demo.vue";
import MenuList from "./components/menu/MenuList.vue";
import VendorDetails from "./components/menu/VendorDetails.vue";
import MandatoryField from "./components/menu/MandatoryDetails.vue";
import UploadOffers from "./components/menu/UploadOffers.vue";
import VendorProfile from "./components/VendorProfile.vue";
import VendorPost from "./components/posts.vue";
// import VendorList from "./vendorprofilelist/VendorProList.vue";
import Followers from "./components/follower.vue";
import vendfollowing from "./components/VendorFollowing.vue";
import vendrequest from "./components/VendorRequest.vue";
import InfluProfile from "./components/influencerScreen/influprofile.vue";
import OffeReqList from "./components/menu/OfferRequest.vue";
import UserViewPost from "./components/user_post_section/userviewpost.vue";
import MyPostnav from "./components/user_post_section/MyPost.vue";
import MyPost from "./components/user_post_section/MyPost_list.vue";
// import postfeed from "./components/showpostfeed.vue"
import Mytags from "./components/user_post_section/user_tags.vue";
import vendoroffers from "./components/user_post_section/Myoffers.vue";
import otpverfiy from "./components/Otp_verfiy.vue";
import createnewpass from "./components/CreateNewPassword.vue";
// import Createnewpass from "./components/Createnewpass.vue";
import dinein from "./components/dinein1.vue";
// import owlslider from "./owlslider.vue";
import BlockedAccount from "./components/BlockAccount.vue";
import changePassword from "./components/setting_navlist/changepassword.vue";
import maincontactUs from "./components/ContactUsMainContactUs.vue";
import FaqsComp from "./components/FaqsComp.vue";
import PermissionComp from "./components/PermissionComp.vue";
import VendorAccount from "./components/VendorAccount.vue";
import InfluencerAccount from "./components/InfluencerAccount.vue";
import BecomeAtmanirbhar from "./components/menu/BecomeAtmanirbhar.vue";
import BecomeFrenchise from "./components/menu/BecomeFrenchise.vue";
import HelpAndSupport from "./components/menu/HelpAndSupport.vue";

import DummySignin from "./components/DummyUser/DummySignin.vue";

// IB Talks and Blogs Components
import IB_Talks_Page from "./components/IB_Talks_And_Blogs/IB_Talks_Page.vue";
import All_Blogs from "./components/IB_Talks_And_Blogs/All_Blogs.vue";
import Blogs from "./components/IB_Talks_And_Blogs/Blogs.vue";

// Tutorial Page Component
import TutorialPage from "./components/TutorialPage/TutorialPage.vue";

// Dummy Tutorial Page
import TutorialPage_Dummy from "./components/DummyUser/TutorialPage_Dummy.vue";

// Dummy IB Talks and Blogs Component
import IB_Talks_Page_Dummy from "./components/DummyUser/IB_Talks_Page_Dummy.vue";
import The_Phoenix_Tales_Dummy from "./components/DummyUser/The_Phoenix_Tales_Dummy.vue";
import All_Blogs_Dummy from "./components/DummyUser/All_Blogs_Dummy.vue";
import Blogs_Dummy from "./components/DummyUser/Blogs_Dummy.vue";

const routes = [
	// Dummy Tutorial Page
	{
		path: "/Tutorial-Page",
		component: TutorialPage_Dummy,
		name: "TutorialPage_Dummy",
	},

	// Dummy IB Talks and Blogs routes
	{
		path: "/ib-talks",
		component: IB_Talks_Page_Dummy,
		name: "IB_Talks_Page_Dummy",
	},
	{
		path: "/the-phoenix-tales",
		component: The_Phoenix_Tales_Dummy,
		name: "The_Phoenix_Tales_Dummy",
	},
	{
		path: "/all-blogs",
		component: All_Blogs_Dummy,
		name: "All_Blogs_Dummy",
	},
	{
		path: "/single-blogs/:id",
		component: Blogs_Dummy,
		name: "Blogs_Dummy",
	},
	// {
	//   name: "postfeed",
	//   path: "/Feed",
	//   component: postfeed,
	// },
	// {
	//   name: "owlslider",
	//   path: "/owlslider",
	//   component: owlslider,
	// },

	// Tutorial Page
	{
		path: "/tutorialPage",
		component: TutorialPage,
		name: "TutorialPage",
	},

	// IB Talks and Blogs routes
	{
		path: "/IBTalks",
		component: IB_Talks_Page,
		name: "IBTalksPage",
	},
	{
		path: "/AllBlogs",
		component: All_Blogs,
		name: "AllBlogs",
	},
	{
		path: "/blogs/:id",
		component: Blogs,
		name: "Blogs",
	},

	// //Dummy-User pages herr
	{
		path: "/",
		component: DummySignin,
		name: "DummySignin",
	},

	{
		name: "Register",
		path: "/Register",
		component: Register,
	},

	{
		name: "Signin",
		path: "/login",
		component: Signin,
	},
	{
		name: "Forgotpassword",
		path: "/Forgotpassword",
		component: Forgotpassword,
	},
	{
		name: "otpverfiy",
		path: "/otpverification",
		component: otpverfiy,
	},
	{
		name: "createnewpass",
		path: "/createnewpass",
		component: createnewpass,
	},
	// {
	// 	name: "Createnewpass",
	// 	path: "/reset-password",
	// 	component: Createnewpass,
	// },
	{
		name: "Verification",
		path: "/Verification",
		component: Verification,
	},
	{
		name: "home",
		path: "/home",
		component: home,
	},
	{
		name: "AllOffers",
		path: "/alloffers",
		component: AllOffers,
	},
	{
		name: "dinein",
		path: "/Dinein",
		component: dinein,
	},
	{
		name: "notification",
		path: "/notification",
		component: notification,
	},
	{
		path: "/setting",
		component: setting,
		children: [
			{
				name: "changePassword",
				component: changePassword,
				path: "/setting/Changepassword",
			},
			{
				name: "faqs",
				component: FaqsComp,
				path: "/setting/faqs",
			},
			{
				name: "permission",
				component: PermissionComp,
				path: "/setting/permission",
			},
			{
				name: "vendorAccount",
				component: VendorAccount,
				path: "/setting/vendorAccount",
			},
			{
				name: "influencerAccount",
				component: InfluencerAccount,
				path: "/setting/influencerAccount",
			},
			{
				name: "termsandcondition",
				component: termsandcondition,
				path: "/setting/termsandcondition",
			},
			{
				name: "aboutus",
				path: "/setting/aboutus",
				component: aboutus,
			},
			{
				name: "contactus",
				path: "/setting/contactus",
				component: contactus,
			},
			{
				name: "privacypolicy",
				path: "/setting/privacypolicy",
				component: privacypolicy,
			},
		],
	},

	// {
	//   name: "termsandcondition",
	//   path: "/termsandcondition",
	//   component: termsandcondition,
	// },

	{
		name: "success",
		path: "/success",
		component: success,
	},
	{
		name: "fail",
		path: "/fail",
		component: fail,
	},
	{
		name: "commentshow",
		path: "/commentshow",
		component: commentshow,
	},

	{
		name: "deletionPolicy",
		path: "/DeletionPolicy",
		component: deletionpolicy,
	},

	{
		name: "privacyPolicy_1",
		path: "/PrivacyPolicy",
		component: privacypolicy_1,
	},

	// {
	//   name: "ViewMoreComnT",
	//   component: viewmorecomments,
	//   path: "/viewmorecomments/:id",
	// },
	// {
	//   path: "/viewmorecomments/:postId",
	//   name: "ViewMoreComnT",
	//   component: ViewMoreComnT,
	//   props: true, // Pass route parameters as props to the component
	// },
	{
		path: "/viewmorecomments/:postId",
		name: "viewMoreComments",
		component: ViewMoreComnT, // Replace with the actual component for viewing a single post
		props: true,
	},

	{
		name: "ModelPopup",
		path: "/model",
		component: ModelPopup,
	},
	{
		name: "BecomeVendor",
		component: BecomeVendor,
		path: "/becomevendor",
	},
	// {
	//   name: "vendorposts",
	//   path: "/vendorposts",
	//   component: vendorposts,
	// },

	// {
	//   name: "Followers",
	//   path: "/followers",
	//   component: Followers,
	// },

	// {
	//   component: VendorProfile,
	//   path: "/vendprofile",
	//   children: [
	//     {
	//       name: "vendorposts",
	//       path: "/vendorlist/vendorposts",
	//       component: vendorposts,
	//     },
	//     {
	//       name: "Followers",
	//       path: "/vendorlist/followers",
	//       component: Followers,
	//     },
	//   ],
	// },

	{
		path: "/profile",
		component: VendorProfile,
		children: [
			{
				path: "/profile/post",
				component: VendorPost,
			},
			{
				path: "/profile/followers",
				component: Followers,
			},
			{
				path: "/profile/following",
				component: vendfollowing,
			},
			{
				path: "/profile/request",
				component: vendrequest,
			},
		],
	},
	{
		name: "InfluProfile",
		component: InfluProfile,
		path: "/influprofile",
	},

	// {
	//   component: VendorList,
	//   path: "/vendorlist",
	//   children: [
	//     {
	//       name: "posts",
	//       path: "/vendorlist/vendorposts",
	//       component: Posts,
	//     },
	//     {
	//       name: "Followers",
	//       path: "/vendorlist/followers",
	//       component: Followers,
	//     },
	//   ],
	// },

	{
		component: MenuList,
		path: "/menulist",
		children: [
			{
				name: "editprofile",
				component: editprofile,
				path: "/menuList/editprofile",
			},
			{
				name: "joininflu",
				path: "/menulist/joininflu",
				component: joininflu,
			},
			{
				name: "OffeReqList",
				path: "/menulist/offereqlist",
				component: OffeReqList,
			},
			{
				name: "BlockedAccount",
				path: "/menulist/blockedAccount",
				component: BlockedAccount,
			},
			{
				name: "BecomeAtmanirbhar",
				path: "/menulist/becomeAtmanirbhar",
				component: BecomeAtmanirbhar,
			},
			{
				name: "BecomeFrenchise",
				path: "/menulist/becomeFrenchise",
				component: BecomeFrenchise,
			},
			{
				name: "HelpAndSuport",
				path: "/menulist/helpAndSupport",
				component: HelpAndSupport,
			},
			{
				component: BecomeVendor,
				path: "/menulist/becomevendor",
				children: [
					{
						name: "VendorDetails",
						component: VendorDetails,
						path: "/menulist/becomevendor/vendordetails",
					},
					{
						name: "MandatoryField",
						component: MandatoryField,
						path: "/menulist/becomevendor/mandatoryfield",
					},
					{
						name: "UploadOffers",
						component: UploadOffers,
						path: "/menulist/becomevendor/uploadoffers",
					},
				],
			},
		],
	},
	{
		component: UserViewPost,
		path: "/userviewpost",
		children: [
			{
				component: MyPostnav,
				path: "/userviewpost/mypostnav",
				children: [
					{
						name: "MyPost",
						component: MyPost,
						path: "/userviewpost/mypostnav/mypost",
					},
					{
						name: "Mytags",
						component: Mytags,
						path: "/userviewpost/mypost/mytags",
					},
					{
						name: "vendoroffers",
						component: vendoroffers,
						path: "/userviewpost/mypost/myvendoroffer",
					},
				],
			},
		],
	},
	{
		name: "Main Contact US",
		component: maincontactUs,
		path: "/maincontactUs",
	},
];
const router = createRouter({
	history: createWebHistory(),
	routes,
});
export default router;
