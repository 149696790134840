<template>
  <div class="home3">
    <button
      class="elite_ac_btn"
      type="button"
      @click="$router.push({ name: 'Signin' })"
    >
      MAKE ELITE ACCOUNT
    </button>
  </div>
  <DummyCreatePost />

  <div
    class="home8 px-2 pb-3 animate__animated animate__zoomIn"
    id="home8"
    v-for="post in posts.filter(posts => posts.description !== 'ewtew' && posts.description !== 'null' )"
    :key="post.id"
  >
    <div class="user_details">
      <img
        src="./assets/pro2.jpg"
        controls
        style="width: 2.7rem; height: 2.3rem; border-radius: 50%"
        v-if="post.user.profile_photo == ''"
      />

      <img
        :src="post.user.profile_photo"
        v-else
        controls
        style="width: 2.7rem; height: 2.3rem; border-radius: 50%"
      />
      <div class="w-100 ms-1 d-flex flex-column">
        <div class="mt-1">
          <!-- <h6>{{ post.user.name }} {{ post.images[0].post_id }}</h6> -->
          <h6 class="mb-0 ms-1" style="font-size: 1.1rem">
            {{ post.user.name }}
          </h6>
        </div>
        <!-- <div
          class="h3 d-flex align-items-center ms-1"
          style="margin-top: 0.1rem"
        >
          <i
            class="fa-regular fa-clock"
            style="font-size: 0.6rem; color: black"
          ></i>
          <h6 style="margin-bottom: 0; margin-top: 0.2rem; font-size: 0.8rem">
            {{ moment(post.created_at).fromNow() }}
          </h6>
        </div> -->
      </div>
      <div class="list_btn ms-auto">
        <list class="me-3" />
      </div>
    </div>

    <!-- Post description -->
    <div class="home10 ps-0">
      <h5  v-if="post.description.length > 0" class="ms-2" style="font-size: 1rem; color: #4c4c4c">
        {{ post.description }}
      </h5>
    </div>

    <!-- Post image -->
    <div
      v-if="post.images && post.images.length > 0"
      class="w-100 post-img-div"
    >
      <div
        v-if="post.images.length > 1"
        class="d-flex overflow-x-auto scroll-div"
      >
        <div v-for="postimg in post.images" :key="postimg.id" class="p-2">
          <div class="object-fit img-1-div">
            <img
              :src="postimg.image ? postimg.image : defaultPostImage"
              alt="Post Image"
              class=""
            />
          </div>
        </div>
      </div>

      <div v-else class="p-2 img-2-div rounded-3">
        <img
          :src="post.images[0].image ? post.images[0].image : defaultPostImage"
          alt="Post Image"
          class=""
        />
      </div>
    </div>

    <div class="home11" style="border-bottom: 1px solid lightgray">
      <div class="ho1 my-auto">
        <userlike
          :postId="post.id"
          :post-id="post.id"
          :likes_count="post.likes_count"
          @click="$router.push({ name: 'Signin' })"
        />
        <usercomment
          :postId="post.id"
          :comments_count="post.comments_count"
          @click="$router.push({ name: 'Signin' })"
        />
        <usershare
          :postId="post.id"
          :shares_count="post.shares_count"
          @click="$router.push({ name: 'Signin' })"
        />
      </div>
    </div>

    <!-- Comments section -->
    <!-- <div v-for="comment in ShowComments[post.id]" :key="comment.id" class="user_comments"> -->
    <!-- <div class="home12">
        <div class="home13">
            <div v-for="comment in ShowComments[post.id]" :key="comment.id" class="user_comments">
                <img src="./assets/cover.png" alt v-if="comment.user.profile_photo === ''" style="width: 3rem; height: 2.8rem; border-radius: 50%" />
                <img :src="comment.user.profile_photo" v-else style="width: 3rem; height: 2.8rem; border-radius: 50%" />
                <div class="comments">
                    {{ comment.user.name }}
                    <br />
                    {{ comment.comment }}
                </div>
            </div>
        </div>
    </div> -->

    <!-- "Add Comments" section -->
    <!-- <h5 class="addComments-heading">View more comments {{ post.id }}</h5> -->
    <!-- <div class="add_comments" :key="post.id">
        <router-link to="/login" class="mb-3" style="text-decoration: none; color: black; width: 100%">
            <h5 class="addComments-heading">
                <span style="
        transition: box-shadow 0.3s, transform 0.3s;
        border-bottom: 1px solid #707070;
      ">View more comments</span>
            </h5>
        </router-link>
        <input class="add_comments_input" placeholder="Add Comments" @click="$router.push({ name: 'Signin' })" />
        <button class="add_comments_btn text-dark" @click="$router.push({ name: 'Signin' })">
            Post
        </button>
    </div> -->
    <!-- <button class="add_comments_btn text-dark" @click="AddComments(post.id, post.comments)">Post</button> -->
    <!-- <input class="add_comments_input" type="text" v-model="post.comments" placeholder="Add Comments" /> -->

    <DummyCommentSec :postId="post.id" />
  </div>

  <div class="home16 py-3 px-3">
    <header class="ms-1">Reccomnded offers for you</header>
    <router-link to="/login" custom>
      <span role="link" class="me-1" style="order: none; background: none"
        >View all</span
      >
    </router-link>
    <div class="exhome mx-auto mt-2">
      <div class="exhome-hom17">
        <div class="home17">
          <img src="./assets/poster2.jpg" />
          <h6>Coffe King</h6>
          <router-link to="/login">
            <button>Reedem Now</button>
          </router-link>
        </div>
        <div class="home17">
          <img src="./assets/poster2.jpg" />
          <h6>Coffe King</h6>
          <router-link to="/login">
            <button>Reedem Now</button>
          </router-link>
        </div>
        <div class="home17">
          <img src="./assets/poster2.jpg" />
          <h6>Coffe King</h6>
          <router-link to="/login">
            <button>Reedem Now</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="loading-spinner mx-auto mt-2 mb-5">
    <div class="spinner"></div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import userlike from "./user-like.vue";
import usercomment from "./user-comment.vue";
import usershare from "./user-share.vue";
import list from "./list.vue";
import DummyCreatePost from "./DummyCreatePost.vue";
import DummyCommentSec from "./DummyCommentSec.vue";
export default {
  name: "DummyCenterPost",
  components: {
    DummyCreatePost,
    userlike,
    usercomment,
    usershare,
    list,
    DummyCommentSec,
  },
  data() {
    return {
      posts: [],
      ShowComments: [],
      moment: moment,
      commentsToShow: 2,
      defaultImage: require("@/assets/cover.png"),
      // apiUrl: "http://uatserver.redprix.com/public/api/",
      // apiUrl: "https://api.redprix.com/api/",
      isLoadingMorePosts: false,
      endLoad: false,
    };
  },
  computed: {},
  methods: {
    //     getdata() {
    //   axios
    //     .get(`${this.apiUrl}customers/my-profile`, {
    //       headers: { DeviceID: "11111", versionName: "1.0.0", DeviceType: "0", Authorization: `Bearer ${localStorage.getItem("authToken")}`, },
    //     })
    //     .then((response) => {
    //       this.item = response.data.data;
    //       this.UserType = response.data.data.user_type;
    //       // alert(this.UserType)
    //       // console.log("userType",this.UserType)
    //     //   this.item1 = response.data.data.email;

    //     });
    // },

    async getpost() {
      try {
        const response = await axios.get(
          `posts/likes-desc?per_page=10&likes=desc`,
          {
            headers: {
              DeviceID: "11111",
              versionName: "1.0.0",
              DeviceType: "0",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        // console.log("Response of Posts: ", response);

        if (response.data && response.data.data) {
          this.posts = response.data.data;
          // console.log("Post: ", this.posts);
        } else {
          console.error("No post data found in the response.");
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },

    // async getCommentsForPost(postId) {
    //     // console.log("PostId: ", postId);
    //     try {
    //         const response = await axios.post(
    //             `${this.apiUrl}customers/posts/comments`, {
    //                 post_id: postId,
    //             }, {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    //                 },
    //             }
    //         );

    //         // console.log("Comment Response: ", response);

    //         if (
    //             response.data &&
    //             response.data.data &&
    //             response.data.data.comments
    //         ) {
    //             // Update the ShowComments property with the fetched comments
    //             this.ShowComments = response.data.data.comments || [];
    //             // this.newCommentArray = [ ...this.newCommentArray , response.data.data.comments || []];

    //             // this.$set(this.ShowComments, postId, response.data.data.comments);
    //             // console.log("Comments Array: ", this.newCommentArray, " Length: ", this.newCommentArray.length);
    //         } else {
    //             console.log("No comment data found in the response.");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // },

    async loadMorePosts() {
      // console.log("Load More Function Called!!!");

      try {
        this.isLoadingMorePosts = true;

        const page = Math.ceil(this.posts.length / 10) + 1;
        // console.log("Page Number: ", page);
        const pageSize = 10;
        const response = await axios.get(`posts/likes-desc?likes=desc`, {
          headers: {
            Authorization: `Bearer ` + localStorage.getItem("authToken"),
          },
          params: {
            page: page,
            per_page: pageSize,
          },
        });

        // console.log("More Posts Response: ", response);

        const currentPosts = response.data.data;

        if (currentPosts.length > 0) {
          this.posts = [...this.posts, ...currentPosts];
          if (this.posts.length >= response.data.total) {
            // console.log("All Posts Fetched Successfully!!");
            this.endLoad = true;
          }
        } else {
          this.isLoadingMorePosts = false;
          this.endLoad = true;
        }

        // console.log("More Posts: ", this.posts, " Length: ", this.posts.length);
      } catch (error) {
        console.error("Error fetching more posts:", error);
      } finally {
        this.isLoadingMorePosts = false;
      }
    },

    handleScroll() {
      // Clear the previous timer
      clearTimeout(this.scrollTimer);

      // Set a new timer to execute after a delay
      this.scrollTimer = setTimeout(() => {
        if (
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 200
        ) {
          if (this.endLoad === false) {
            this.loadMorePosts();
            // console.log("Load More Calling!!!");
          }
        }
      }, 1000); // Adjust the debounce delay as needed
    },
  },
  mounted() {
    // this.getdata();
    this.getpost();
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.loading-spinner {
  position: relative;
  width: 25px;
  height: 25px;
}

.spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  margin: auto;
  border: 6px solid #eb0e19;
  border-radius: 50%;
  animation: loading-spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #eb0e19 transparent transparent transparent;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.scroll-div {
  width: 98%;
  height: 32rem;
}

.scroll-div::-webkit-scrollbar {
  display: none;
}

.img-1-div img {
  width: 31.3rem;
  height: 30rem;
}

.img-2-div {
  width: 98%;
  height: 32rem;
  overflow: hidden;
  margin: 0.2rem auto;
}

.img-2-div img {
  width: -webkit-fill-available;
  height: 30rem;
}

@media only screen and (max-width: 1180px) {
  .img-2-div img {
    width: 100%;
  }
}

.row {
  width: 75%;
  margin: 0 auto;
}

@media (max-width: 530px) {
  .row {
    margin: 0 auto;
  }
}

@media (min-width: 381px) and (max-width: 580px) {
  .row {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .row {
    width: 100%;
  }

  .mid-column {
    padding: 0;
    margin: 0;
  }
}

/* .nav {
  background: white;
  box-shadow: 0px 3.5px 10px 0px #ff9ba0;
} */
.searchbar input {
  height: 4.7vh;
  width: 240px;
  border: transparent;
  outline: 0px;
  margin-left: 2px;
  background-color: #f481872b;
  padding-left: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  font-size: 70%;
}

.searchbar button {
  height: 4.7vh;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  color: transparent;
  background-color: #eb0e19;
  border: transparent;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
  width: 35px;
  margin-left: 0.1%;
  position: absolute;
}

.bar {
  display: flex;
  width: 50%;
  margin-left: 51%;
  margin-top: -4%;
}

.b1,
.b2,
.b3,
.b4 {
  width: 10%;
  margin-top: 1.5%;
  margin-left: 30%;
  font-size: 88%;
  cursor: pointer;
}

.togg3 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist3 {
  transform: scale(1);
}

.togg4 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist4 {
  transform: scale(1);
}

.togg5 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist5 {
  transform: scale(1);
}

.home {
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 1%;
}

.home button {
  margin-left: 21%;
  margin-top: 3%;
  width: 57%;
  border: none;
  line-height: 2.2;
  font-size: 88%;
  border-radius: 6px;
  background: dodgerblue;
  color: white;
}

/* .home2 {
  margin-top: 29%;
  width: 71%;
  height: 40vh;
} */
.home2 img {
  width: 100%;
  padding: 1.5%;
}

.home3 {
  /* margin: auto; */
  width: 100%;
  /* height: 5vh; */
  /* padding: 0rem 1rem 0rem; */
}

.elite_ac_btn {
  width: 100%;
  line-height: 2.5;
  border: none;
  background: #eb0e19;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  /* margin-left: -1rem; */
}

.home6 {
  margin-top: 2%;
  margin-left: -2%;
}

.home7 {
  border-top: 1px solid black;
  width: 87%;
  margin-left: 15%;
  margin-top: -3%;
}

.home8,
.home15 {
  margin-top: 2%;
  border: 1px solid lightgray;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #ccc;
  /* visibility: visible; */
}

.cus-all {
  visibility: hidden;
}

.togg {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.togg2 {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}

.newlist {
  transform: scale(1);
}

.newlist1 {
  transform: scale(1);
}

.home15 {
  margin-top: 2%;
}

.h2 {
  display: flex;
  /* width: 100%; */
  margin-left: 3.7rem;
  margin-top: 0;
  margin-bottom: 0;
}

.h3,
.h4 {
  display: flex;
  width: 50%;
  margin-top: -1.5rem;
  align-items: center;
  /* padding-left: 20; */
  gap: 2%;
  margin-bottom: 0;
}

.home9 {
  width: 7%;
  margin-left: 90%;
  margin-top: -12%;
}

.home9 button {
  border: none;
  background: none;
}

.home10 {
  margin-top: 12px;
  padding-left: 23px;
}

.ho1 {
  display: flex;
  /* margin-left: 1%; */
  /* margin-top: 2%; */
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.6rem;
  min-width: 50px;
  border-top: 1px solid #ccc;
}

.like-count,
.comment-count,
.share-count {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

/* .square {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  line-height: 20px;
  margin-right: 5px;
  border-radius: 4px;
} */

.ho1 h6 {
  font-size: 6vw;
}

.home12 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home13 {
  width: 100%;
  /* margin-left: 8%; */
  /* background: rgb(243, 241, 241); */
  border-radius: 6px;
}

.home16 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 2rem 3rem;
  /* margin-left: 25%; */
  border: 1px solid lightgray;
  margin-top: 1.5%;
  border-radius: 6px;
  margin-bottom: 4%;
  background-color: #fff;
  flex-wrap: wrap;
  box-shadow: 0px 0px 13px 0px #ccc;
}

.exhome {
  display: flex;
  justify-content: center;
  gap: 6px;
  width: 100%;
}

.exhome-hom17 {
  display: flex;
  justify-content: space-evenly;
  gap: 0.8%;
}

.home17 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0.8rem 0.6rem;
  padding: 0.4rem 0.4rem;
  border-radius: 0.4rem;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .home17 {
    margin: 0;
  }
}

.home17 img {
  width: 100%;
  margin-bottom: 0.6rem;
}

.home17 button {
  margin: 5px 0;
  line-height: 1.8;
  width: 100%;
  background: #eb0e19;
  border: none;
  color: white;
  border-radius: 0.5rem;
  font-size: 1em;
}

/* .home19_user_detials {
  display: flex;
  align-items: center;
  gap: 2%;
} */

/* @media screen and (min-width: 768px) and (max-width: 1200px) {

  .home21_user_detials {
    flex-direction: column;
    justify-content: center;
  }
} */

@media (max-width: 1080px) {
  .left-column {
    display: none;
  }

  .right-column {
    display: none;
  }

  .mid-column {
    width: 100%;
  }
}

/* @media (min-width:1080px) {} */

/* .home19_user_detials img {
  border-radius: 50%;
} */

/* .home21_user_detials {
  display: flex;
  align-items: center;
  gap: 2%;
}

.home21_user_detials img {
  border-radius: 50%;
} */

.user_names {
  display: block;
}

/* .home19 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
  padding: 0.5rem;
} */

/* .home21 {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 6%;
  padding: 0.5rem;
} */

/* .home20 {
  border-top: 1px solid;
  margin-top: 23px;
} */

.pform1 {
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 0%;
  width: 91%;
  margin-left: 0%;
}

.pform1 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.pform1 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}

.pform1 span::before {
  content: "";
  position: absolute;
  top: 14.2%;
  left: 3.3%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform1 input:focus ~ label,
.pform1 input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.pform1 input:focus ~ span::before,
.pform1 input:valid ~ span::before {
  width: 85.3%;
}

.pform2,
.pform3,
.pform4,
.pform5,
.pform6,
.pform7,
.pform8,
.pform9 {
  /* position: relative; */
  border-bottom: 1px solid black;
  /* margin: 20px 0; */
  margin-top: 4%;
  width: 91%;
  margin-left: 0%;
}

.pform2 input,
.pform3 input,
.pform4 input,
.pform5 input,
.pform6 input,
.pform7 input,
.pform8 input,
.pform9 input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.pform2 label,
.pform3 label,
.pform4 label,
.pform5 label,
.pform6 label,
.pform7 label,
.pform8 label,
.pform9 label {
  /* position: absolute; */

  position: absolute;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
  margin-left: -67%;
  margin-top: 3%;
}

.pform2 span::before {
  content: "";
  position: absolute;
  top: 28.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform2 input:focus ~ label,
.pform2 input:valid ~ label {
  top: 55px;
  color: #eb0e19;
}

.pform2 input:focus ~ span::before,
.pform2 input:valid ~ span::before {
  width: 85.3%;
}

.pform3 span::before {
  content: "";
  position: absolute;
  top: 32.5%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform3 input:focus ~ label,
.pform3 input:valid ~ label {
  top: 22%;
  color: #eb0e19;
}

.pform3 input:focus ~ span::before,
.pform3 input:valid ~ span::before {
  width: 85.3%;
}

.pform4 span::before {
  content: "";
  position: absolute;
  top: 43.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform4 input:focus ~ label,
.pform4 input:valid ~ label {
  top: 33%;
  color: #eb0e19;
}

.pform4 input:focus ~ span::before,
.pform4 input:valid ~ span::before {
  width: 85.3%;
}

.pform5 span::before {
  content: "";
  position: absolute;
  top: 42.7%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform5 input:focus ~ label,
.pform5 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform5 input:focus ~ span::before,
.pform5 input:valid ~ span::before {
  width: 38%;
}

.pform6 span::before {
  content: "";
  position: absolute;
  top: 42.5%;
  left: 50.5%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform6 input:focus ~ label,
.pform6 input:valid ~ label {
  top: 28%;
  color: #eb0e19;
}

.pform6 input:focus ~ span::before,
.pform6 input:valid ~ span::before {
  width: 38%;
}

.pform8 span::before {
  content: "";
  position: absolute;
  top: 77.2%;
  left: 4%;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.pform8 input:focus ~ label,
.pform8 input:valid ~ label {
  top: 69%;
  color: #eb0e19;
}

.pform8 input:focus ~ span::before,
.pform8 input:valid ~ span::before {
  width: 85.3%;
}

.modal2 {
  position: absolute;
  background: #0e0e0e8c;
  width: 102.1%;
  height: 307.5%;
  margin-left: -28%;
  visibility: hidden;
  margin-top: -15%;
  margin-left: -34%;
}

.postpop {
  visibility: visible;
}

.modal-pop {
  width: 38%;
  height: 23%;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 30px 30px;
  color: #333;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-pop h3 {
  background: #eb0e19;
  margin-top: -12%;
  height: 13%;
  width: 111.5%;
  margin-left: 0.55;
  text-align: left;
  margin-left: -5.6%;
  padding-left: 3%;
  padding-top: 5%;
  color: white;
  font-size: 129%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-pop img {
  margin-left: -91%;
  margin-top: 4%;
  border-radius: 50%;
}

.modal-pop h4 {
  width: 42%;
  margin-left: 12%;
  text-align: left;
  margin-top: -9%;
  font-size: 144%;
}

.modal-pop h5 {
  text-align: left;
  width: 26%;
  margin-left: 12.2%;
  margin-top: 0.5%;
  font-size: 82%;
  font-weight: 100;
}

.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 2%;
  width: 90%;
  margin-left: 4%;
}

.text input {
  width: 82%;
  padding: -27px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
  margin-left: -17%;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.mpop {
  text-align: left;
  width: 90%;
  margin-left: 4%;
}

.mpop input {
  width: 99%;
  height: 68px;
  border-radius: 10px;
  border: 1px solid;
  outline: none;
}

.mpop1 {
  margin-left: 3%;
  margin-top: 5%;
  border: 2px dashed;
  height: 31%;
  width: 92%;
  border-radius: 10px;
}

.mpop3 {
  margin-top: -7%;
}

.mpop2 button,
.mpop3 button {
  margin-left: -54%;
  margin-top: 3%;
  width: 36%;
  background: white;
  border: 1px solid;
  border-radius: 6px;
  /* height: 43%; */
  cursor: pointer;
}

.mpop3 button {
  margin-top: -3.5%;
  margin-left: 49%;
}

.mpop2 button img,
.mpop3 button img {
  margin-left: -60%;
  margin-top: -3%;
}

.mpop2 button h4,
.mpop3 button h4 {
  width: 24%;
  /* margin-top: -11%; */
  margin-top: 1.7%;
  position: absolute;
  margin-left: 10%;
}

.mpop4 {
  margin-top: 3%;
}

.mpop4 button {
  width: 93%;
  line-height: 1.8;
  border-radius: 6px;
  font-size: 113%;
  border: none;
  background: #eb0e19;
  color: white;
  cursor: pointer;
}

.mpop5 {
  width: 9%;
}

.mpop5 img {
  width: 3.8%;
  margin-top: -10%;
  position: absolute;
  margin-left: 86%;
  cursor: pointer;
}

.mpop1 button input[type="file"] {
  position: absolute;
  transform: scale(2);

  width: 21px;
  margin-left: -18px;
  margin-top: 8px;
  cursor: pointer;
}

.add_comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  flex-wrap: wrap;
}

.addComments-heading {
  font-size: 0.9rem;
  /* border-bottom: 1px solid #707070; */
  width: 100%;
  cursor: pointer;
  color: #707070;
  display: inline;
}

.addComments-heading:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  color: red;
}

.add_comments input {
  border: 1px solid black;
  border-radius: 0.5rem;
}

.add_comments_btn {
  border: 1px solid black;
  outline: 0;
  background-color: #fff;
  color: black;
  padding: 1.5%;
  width: 20%;
  border-radius: 0.3rem;
}

.add_comments_input {
  width: 74%;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.user_action_btn button {
  margin-top: 8%;
  font-size: 87%;
  width: 40%;
  margin-left: 4%;
  color: white;
  background: #eb0e19;
  border: none;
  line-height: 1.8;
  border-radius: 6px;
}

.user_details {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 1.2rem 0.6rem;
}

.user_comments {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  /* border-radius: 50%; */
}

.user_comments img {
  width: 10%;
  padding: 1%;
}

.comments {
  display: block;
  background-color: rgb(228, 228, 228);
  padding: 2%;
  width: 100%;
  margin: 1%;
  border-radius: 0.5rem;
}
</style>
