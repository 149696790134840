<template>
  <div class="content">
    <div class="main">
      <!-- Cutomer Form -->
      <div class="profile">
        <header>Update profile</header>

        <!-- <view-router></view-router> -->

        <div class="upload d-flex flex-column flex-lg-row align-items-center">
          <!-- <img    class="imgp"  src="../assets/pro2.png" width="200" height="200" /> -->
          <div class="choose mt-4 mt-lg-0 position-relative">
            <div class="">
              <img
                :src="imageUrl"
                v-if="imageUrl"
                alt="Uploaded Image"
                class="imgp"
              />
              <img class="imgp" v-else src="../assets/pro2.png" />
            </div>
            <div class="w-auto position-absolute bottom-0 end-0">
              <img class="imgp1" src="../assets/so22.png" />
              <input type="file" @change="handleFileChange" />
            </div>
          </div>

          <div class="first-form-div ps-1 pe-4">
            <div class="text">
              <input type="text" v-model="name" required />
              <span></span>
              <label>Full Name</label>
            </div>

            <div class="text">
              <input type="email" v-model="email" required />
              <span></span>
              <label>Email id</label>
            </div>
            <div class="text">
              <input type="text" v-model="mobile" required />
              <span></span>
              <label>Mobile Number</label>
            </div>
          </div>
        </div>

        <div class="w-100 px-4 mt-2">
          <div class="form-2">
            <h6>Address</h6>
            <div class="text-v">
              <input type="text" v-model="address" required />
              <span></span>
              <label>Address</label>
            </div>
          </div>

          <div class="select_boxes">
            <div class="text-s">
              <select
                id="state"
                class="s2"
                v-model="state_id"
                @change="getCity()"
                required
              >
                <option class="j" value>Select State</option>

                <option
                  class="j"
                  v-for="state in states"
                  :key="state.id"
                  :value="state.id"
                >
                  {{ state.name }}
                </option>
              </select>

              <select
                id="state"
                class="s3"
                v-model="city_id"
                @change="getCity()"
                required
              >
                <option class="j" value>Select City</option>

                <option
                  class="j"
                  v-for="city in cities"
                  :key="city.id"
                  :value="city.id"
                >
                  {{ city.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-2">
            <div class="text-v">
              <input type="text" v-model="pincode" required />
              <span></span>
              <label>Pincode</label>
            </div>
          </div>

          <!-- <div class="date">
            <label for="birth_date">Date of birth</label>
            <label for="birth_date" id="dob" v-on:click="Userdob"></label>
            <input type="date" id="birth_date" v-model="birth_date" />
          </div> -->

          <button class="btn-1" type="button" @click="updateUserProfile()">
            Update
          </button>
        </div>
      </div>

      <!-- Vendor/ Influencer Form  -->
      <!-- <div class="profile" v-if="false">
        <header>Update profile</header>

        <div class="upload d-flex align-items-center">
          <div class="choose position-relative">
            <div class="">
              <img
                :src="imageUrl"
                v-if="imageUrl"
                alt="Uploaded Image"
                class="imgp"
              />
              <img class="imgp" v-else src="../assets/pro2.png" />
            </div>
            <div class="w-auto position-absolute bottom-0 end-0">
              <img class="imgp1" src="../assets/so22.png" />
              <input type="file" @change="handleFileChange" />
            </div>
          </div>

          <div class="first-form-div ps-1 pe-4">
            <div class="text">
              <input type="text" v-model="name" required />
              <span></span>
              <label>Business Name</label>
            </div>

            <div class="text">
              <input type="email" v-model="email" required />
              <span></span>
              <label>Email id</label>
            </div>
            <div class="text">
              <input type="text" v-model="business_mobile" required />
              <span></span>
              <label>Mobile Number</label>
            </div>
          </div>
        </div>

        <div class="w-100 px-4 mt-2">
          <div class="form-2">
            <h6>Address</h6>
            <div class="text-v">
              <input type="text" v-model="business_address" required />
              <span></span>
              <label>Address</label>
            </div>
          </div>

          <div class="select_boxes">
            <div class="text-s">
              <select
                id="state"
                class="s2"
                v-model="state_id"
                @change="getCity()"
                required
              >
                <option class="j" value>Select State</option>

                <option
                  class="j"
                  v-for="state in states"
                  :key="state.id"
                  :value="state.id"
                >
                  {{ state.name }}
                </option>
              </select>

              <select
                id="state"
                class="s3"
                v-model="city_id"
                @change="getCity()"
                required
              >
                <option class="j" value>Select City</option>

                <option
                  class="j"
                  v-for="city in cities"
                  :key="city.id"
                  :value="city.id"
                >
                  {{ city.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="date">
            <label for="birth_date">Date of birth</label>
            <label for="birth_date" id="dob" v-on:click="Userdob"></label>
            <input type="date" id="birth_date" v-model="birth_date" />
          </div>

          <button
            class="btn-1"
            type="button"
            @click="updateProfile(), updateinflueProfile()"
          >
            Update
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "editProfile",
  data() {
    return {
      userType: "",
      social_media_link: [],
      vendprofile: [],
      userProfile: {},
      selectedFile: null,
      imageUrl: null,
      name: "",
      mobile: "",
      country_id: 101,
      city_id: "",
      state_id: "",
      cities: [],
      states: [],
      countries: [],
      email: "",
      address: "",
      pincode: "",
      birth_date: "",
    };
  },

  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    // updateProfile() {
    //   const id = localStorage.getItem("id");
    //   const formData = new FormData();
    //   formData.append("business_name", this.name);
    //   formData.append("business_email", this.email);
    //   formData.append("business_address", this.business_address);
    //   formData.append("business_mobile", this.business_mobile);
    //   formData.append("business_logo", this.selectedFile);
    //   formData.append("state_id", this.state_id);
    //   formData.append("country_id", this.country_id);
    //   formData.append("city_id", this.city_id);
    //   formData.append("birth_date", this.birth_date);
    //   formData.append("business_id", id);

    //   // console.log(id)
    //   // this.handelFileupload()
    //   axios
    //     .post("vendors/vendors/update/" + id, formData)
    //     .then(() => {
    //       // console.log(resp.data.data);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    // updateinflueProfile() {
    //   const id = localStorage.getItem("id");
    //   const formData = new FormData();
    //   formData.append("business_name", this.name);
    //   formData.append("business_email", this.email);
    //   formData.append("business_address", this.business_address);
    //   formData.append("business_mobile", this.business_mobile);
    //   formData.append("business_logo", this.selectedFile);
    //   formData.append("state_id", this.state_id);
    //   formData.append("country_id", this.country_id);
    //   formData.append("city_id", this.city_id);
    //   formData.append("birth_date", this.birth_date);
    //   formData.append("business_id", id);
    //   formData.append("social_media_link[]", this.social_media_link);
    //   formData.append("social_media_link[]", this.social_media_link);

    //   // console.log(id)
    //   // this.handelFileupload()
    //   axios
    //     .post("influencer/influencer/update/" + id, formData)
    //     .then(() => {
    //       // console.log(resp.data.data);
    //     })
    //     .catch((error) => {
    //       console.error(error.resp.data.message);
    //     });
    // },

    // getprofile() {
    //   axios.get("vendors/my-vendor-profile").then((res) => {
    //     console.log(res.data.data);
    //     this.vendprofile = res.data.data;
    //     // localStorage.setItem("id", res.data.data.business_category_id);
    //   });
    // },

    getUserProfile() {
      axios.get("customers/my-profile").then((res) => {
        // console.log(res.data.data);
        this.userProfile = res.data.data;
        // localStorage.setItem("id", res.data.data.business_category_id);

        // console.log("User Data: ", this.userProfile);

        //Assign Data Of User
        this.imageUrl = this.userProfile.profile_photo;
        this.name = this.userProfile.name;
        this.email = this.userProfile.email;
        this.mobile = this.userProfile.mobile;
        this.address = this.userProfile.address;
        this.country_id = this.userProfile.country.id;
        this.state_id = this.userProfile.state.id;
        // console.log("State Id: ", this.userProfile.state.id);
        this.city_id = this.userProfile.city.id;
        this.pincode = this.userProfile.pincode;
      });
    },

    updateUserProfile() {
      const id = localStorage.getItem("id");
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("mobile", this.mobile);
      formData.append("profile_photo", this.selectedFile);
      formData.append("country_id", this.country_id);
      formData.append("state_id", this.state_id);
      formData.append("city_id", this.city_id);
      formData.append("pincode", this.pincode);
      formData.append("email", this.email);
      formData.append("address", this.address);

      // console.log("User Id: ", id);
      // console.log("Form Data:");
      // formData.forEach((value, key) => {
      //   console.log(key, value);
      // });

      // console.log(id)
      axios
        .post("customers/customers/update/" + id, formData)
        .then(() => {
          // console.log(resp.data.data);
          alert("User Profile Updated Successfully!!");
          // location.reload();
          this.$router.push("/MenuList/editprofile");
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    toggleUp() {
      let togg3 = document.querySelector(".togg3");
      togg3.classList.toggle("newlist3");
    },
    getData() {
      axios
        .get("countries?is_light=1")
        .then((result) => {
          this.countries = result.data.data;
          // console.log("conutries", this.countries);
          if (this.country_id) {
            this.getState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    Userdob() {
      let inputDate = document.getElementById("birth_date");
      inputDate.addEventListener("focus", function () {
        if (this.getAttribute("type") === "date") {
          this.showPicker();
        }
      });
      //  alert("datepicker")
    },
    getState() {
      axios
        .get("states?is_light=1", {
          params: {
            country_id: this.country_id,
          },
        })
        .then((result) => {
          this.states = result.data.data;
          // this.state_id = ""; // Reset the selected state
          this.getCity();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCity() {
      axios
        .get("cities_of_state?is_light=1", {
          params: {
            state_id: this.state_id,
          },
        })
        .then((result) => {
          this.cities = result.data.data;
          // this.city_id = ""; // Reset the selected city
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.selectedFile);
    },
    // handelFileupload() {
    //   const imgp = document.querySelector(".imgp");
    //   const file = document.querySelector("#file");

    //   file.addEventListener("change", function () {
    //     const choosedFile = this.files[0];
    //     if (choosedFile) {
    //       const reader = new FileReader();
    //       reader.addEventListener("load", function () {
    //         imgp.setAttribute("src", reader.result);
    //       });

    //       reader.readAsDataURL(choosedFile);
    //       this.selectedFile = choosedFile;
    //       localStorage.setItem("image", choosedFile);
    //     }
    //   });
    // },
  },

  created() {
    this.userType = localStorage.getItem("user_type");
  },

  mounted() {
    // this.handelFileupload();
    this.getUserProfile();
    this.getData();
    this.getState();
    // this.getprofile();
  },
};
</script>

<style scoped >
.row {
  width: 100%;
}
.content {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 7px #ccc;
  border-radius: 10px;
}

.profile {
  width: 100%;
  border-radius: 1%;
  background-color: white;
}

.profile header {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  color: grey;
  line-height: 2.6;
  padding: 0rem 1.2rem;
  user-select: none;
  border-bottom: 1px solid grey;
  margin-top: 0px;
}

.imgp {
  width: 11rem;
  height: 10rem;
  border-radius: 50%;
  border: 6px solid #eaeaea;
}

.imgp1 {
  width: 40px;
  background: skyblue;
  border-radius: 50%;
}

.choose {
  width: 35%;
  text-align: center;
  cursor: pointer;
}

.choose input[type="file"] {
  transform: scale(2);
  opacity: 0;
  width: 21px;
  cursor: pointer;
}

.first-form-div {
  width: 65%;
}

@media only screen and (max-width: 992px) {
  .first-form-div {
    width: 100%;
    padding: 0 1.5rem !important;
  }

  .imgp {
    margin-left: -2.5rem;
  }
}

.text {
  position: relative;
  border-bottom: 1px solid black;
  margin: 28px 0;
  width: 100%;
}

.text input {
  width: 100%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text input:focus ~ label,
.text input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text input:focus ~ span::before,
.text input:valid ~ span::before {
  width: 100%;
}

.form-2 {
  margin-top: 2%;
}

.text-v {
  position: relative;
  border-bottom: 1px solid black;
  margin: 20px 0;
  margin-top: 0;
  width: 100%;
}

.text-v input {
  width: 82%;
  padding: 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  padding-top: 17px;
}

.text-v label {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #adadad;
  transform: translate(-10%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.text-v span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-v input:focus ~ label,
.text-v input:valid ~ label {
  top: -5px;
  color: #eb0e19;
}

.text-v input:focus ~ span::before,
.text-v input:valid ~ span::before {
  width: 100%;
}

.text-s {
  /* margin-top: 25px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

select {
  width: 47%;
  font-size: 16px;
  color: #adadad;
}

option {
  color: black;
}

.s2,
.s3 {
  margin-top: 1rem;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  padding: 2px;
}

#dob {
  background: transparent;
  height: 50%;
  position: absolute;
  top: 38%;
  left: -5%;
  /* border: 8px solid black; */
  width: 100%;
  cursor: pointer;
}

.text-s select::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 1.6px;
  background: red;
  transition: 0.5s;
}

.text-s select:focus ~ option,
.text-s select:valid ~ option {
  top: -2px;
  color: #eb0e19;
}

.date {
  margin-top: 1.6rem;
  position: relative;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  background: none;
  outline: none;
  width: 100%;
  color: #adadad;
}

.date input {
  position: relative;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  width: 82%;
  background: none;
  outline: none;
  color: #adadad;
}

.btn-1 {
  align-items: center;
  margin-top: 2.6rem;
  width: 100%;
  padding: 5px;
  text-align: center;
  background: #eb0e19;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>
