<template>
  <div class="content">
    <div class="terms">
      <div class="term1">
        <h3>Terms and Condition</h3>
      </div>

      <div class="d-flex align-items-center justify-content-center py-3">
        <img src="../assets/terms.png" width="200" height="180" />
      </div>

      <div class="term3">
        <h6 style="color: black; padding-left: 4%">
          <p style="color: black; padding-left: 0%">
            Welcome to www.redprix.com!
          </p>
        </h6>
        <p>
          <br />These terms and conditions outline the rules and regulations for
          the use of Redprix's Website, located at https://redprix.com/ By
          accessing this website we assume you accept these terms and
          conditions. Do not continue to use www.redprix.com if you do not agree
          to take all of the terms and conditions stated on this page. The
          following terminology applies to these Terms and Conditions, Privacy
          Statement and Disclaimer Notice, and all Agreements: "Client", "You"
          and "Your" refers to you, the person who logs on to this website and
          is compliant with the Company’s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves. All terms
          refer to the offer, acceptance, and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of the provision of the Company’s stated services, in
          accordance with and subject to, prevailing law of Netherlands. Any use
          of the above terminology or other words in the singular, plural,
          capitalization, and/or he/she or they, are taken as interchangeable
          and therefore as referring to the same.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">Cookies</p>
        </h6>
        <p>
          <br />We employ the use of cookies. By accessing www.redprix.com, you
          agreed to use cookies in agreement with Redprix's Privacy Policy. Most
          interactive websites use cookies to let us retrieve the user’s details
          for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">Licence</p>
        </h6>
        <p>
          <br />Unless otherwise stated, Redprix and/or its licensors own the
          intellectual property rights for all material on www.redprix.com. All
          intellectual property rights are reserved. You may access this from
          www.redprix.com for your own personal use subject to restrictions set
          in these terms and conditions. <br />You must not: <br />
          <span class="ms-3">● Republish material from www.redprix.com</span>
          <br />
          <span class="ms-3"
            >● Sell, rent, or sub-license material from www.redprix.com</span
          >
          <br />
          <span class="ms-3">
            ● Reproduce, duplicate, or copy material from www.redprix.com </span
          ><br />
          <span class="ms-3">● Redistribute content from www.redprix.com</span>
          <br />
          <br />This Agreement shall begin on the date hereof. Our Terms and
          Conditions were created with the help of the Free Terms and Conditions
          Generator.
          <br />
          <br />Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          Redprix does not filter, edit, publish, or review Comments prior to
          their presence on the website. Comments do not reflect the views and
          opinions of Redprix, its agents, and/or affiliates. Comments reflect
          the views and opinions of the person who posts their views and
          opinions. To the extent permitted by applicable laws, Redprix shall
          not be liable for the Comments or for any liability, damages, or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
          <br />
          <br />Redprix reserves the right to monitor all Comments and to remove
          any Comments which can be considered inappropriate, offensive, or
          causes a breach of these Terms and Conditions.
          <br />
          <br />You warrant and represent that:
          <br />
          <br />
          <span class="ms-3"
            >● You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so; </span
          ><br />
          <span class="ms-3"
            >● The Comments do not invade any intellectual property right,
            including without limitation copyright, patent, or trademark of any
            third party;</span
          >
          <br />
          <span class="ms-3">
            ● The Comments do not contain any defamatory, libelous, offensive,
            indecent, or otherwise unlawful material which is an invasion of
            privacy </span
          ><br />
          <span class="ms-3"
            >● The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.</span
          >
          <br />
          <br />You hereby grant Redprix a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats, or media.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">
            Hyperlinking to our Content
          </p>
        </h6>
        <p>
          <br />The following organizations may link to our Website without
          prior written approval: <br />
          <span class="ms-3">● Government agencies; </span><br />
          <span class="ms-3">● Search engines; </span><br />
          <span class="ms-3">● News organizations;</span> <br />
          <span class="ms-3"
            >● Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses;
            and </span
          ><br />
          <span class="ms-3"
            >● System-wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site. </span
          ><br />These organizations may link to our home page, to publications,
          or to other Website information so long as the link: (a) is not in any
          way deceptive; (b) does not falsely imply sponsorship, endorsement, or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
          <br />
          <br />We may consider and approve other link requests from the
          following types of organizations: <br />
          <span class="ms-3"
            >● commonly-known consumer and/or business information sources; </span
          ><br />
          <span class="ms-3">● dot.com community sites;</span>
          <br />
          <span class="ms-3"
            >● associations or other groups representing charities; <br />
            <span class="ms-3">● online directory distributors; </span></span
          ><br />
          <span class="ms-3">● internet portals; </span><br />
          <span class="ms-3">● accounting, law, and consulting firms; and</span>
          <br />
          <span class="ms-3"
            >● educational institutions and trade associations.</span
          >
          <br />
          <br />We will approve link requests from these organizations if we
          decide that: (a) the link would not make us look unfavorably to
          ourselves or to our accredited businesses; (b) the organization does
          not have any negative records with us; (c) the benefit to us from the
          visibility of the hyperlink compensates the absence of Redprix; and
          (d) the link is in the context of general resource information.
          <br />
          <br />These organizations may link to our home page so long as the
          link: (a) is not in any way deceptive; (b) does not falsely imply
          sponsorship, endorsement, or approval of the linking party and its
          products or services; and (c) fits within the context of the linking
          party’s site.
          <br />
          <br />If you are one of the organizations listed in paragraph 2 above
          and are interested in linking to our website, you must inform us by
          sending an e-mail to Redprix. Please include your name, your
          organization name, and contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Wait 2-3 weeks for a response.
          <br />
          <br />Approved organizations may hyperlink to our Website as follows
          <br />
          <span class="ms-3">● By use of our corporate name; or </span><br />
          <span class="ms-3"
            >● By use of the uniform resource locator being linked to; or </span
          ><br />
          <span class="ms-3"
            >● By use of any other description of our Website being linked to
            that makes sense within the context and format of content on the
            linking party’s site.</span
          >
          <br />
          <br />No use of Redprix's logo or other artwork will be allowed for
          linking absent a trademark license agreement.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">iFrames</p>
        </h6>
        <p>
          <br />Without prior approval and written permission, you may not
          create frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">Content Libability</p>
        </h6>
        <p>
          <br />We shall not be held responsible for any content that appears on
          your Website. You agree to protect and defend us against all claims
          that are rising on your Website. No link(s) should appear on any
          Website that may be interpreted as libelous, obscene, or criminal, or
          which infringes, otherwise violates, or advocates the infringement or
          other violation of, any third-party rights.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">Privacy</p>
        </h6>
        <p>
          <br />Please read Privacy Policy
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">Reservation Of Rights</p>
        </h6>
        <p>
          <br />We reserve the right to request that you remove all links or any
          particular link to our Website. You approve of immediately removing
          all links to our Website upon request. We also reserve the right to
          amend these terms and conditions and its linking policy at any time.
          By continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">
            Removal Links from our Website
          </p>
        </h6>
        <p>
          <br />If you find any link on our Website that is offensive for any
          reason, you are free to contact and inform us at any moment. We will
          consider requests to remove links but we are not obligated to or so or
          to respond to you directly. <br />We do not ensure that the
          information on this website is correct, we do not warrant its
          completeness or accuracy; nor do we promise to ensure that the website
          remains available or that the material on the website is kept up to
          date.
          <br />
          <br />
        </p>
        <h6 style="color: black; padding-left: 5%">
          <p style="color: black; padding-left: 0%">Desclaimer</p>
        </h6>
        <p>
          <br />To the maximum extent permitted by applicable law, we exclude
          all representations, warranties, and conditions relating to our
          website and the use of this website. Nothing in this disclaimer will:
          <br />
          <span class="ms-3"
            >● limit or exclude our or your liability for death or personal
            injury; </span
          ><br />
          <span class="ms-3"
            >● limit or exclude our or your liability for fraud or fraudulent
            misrepresentation; </span
          ><br />
          <span class="ms-3"
            >● limit any of our or your liabilities in any way that is not
            permitted under applicable law; or</span
          >
          <br />
          <span class="ms-3"
            >● exclude any of our or your liabilities that may not be excluded
            under applicable law. </span
          ><br />The limitations and prohibitions of liability set in this
          Section and elsewhere in this disclaimer: (a) are subject to the
          preceding paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort, and
          for breach of statutory duty. <br />As long as the website and the
          information and services on the website are provided free of charge,
          we will not be liable for any loss or damage of any nature.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "termsandcondition",
  components: {},

  methods: {
    openEye() {
      let eyeicon = document.getElementById("eyeicon");
      let password = document.getElementById("eyeicon");

      eyeicon.onclick = function () {
        if (password.type == "password") {
          password.type = "text";
        } else {
          password.type = "password";
        }
      };
    },
  },
};
</script>
<style scoped>
.terms {
  width: 100%;
  height: 40.5rem;
  border-radius: 10px;
  background: white;
  margin-bottom: 1%;
  box-shadow: 0px 0px 5px #ccc;
  overflow-y: auto;
}

.terms::-webkit-scrollbar {
  display: none;
}
.term1 {
  background: #eb0e19;
  height: 7%;
  /* background: #eb0e19; */
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  /* line-height: 4; */
  margin-top: 0%;
  background: white;
  border-bottom: 1px solid gray;
}

.term1 h3 {
  padding-left: 3%;
  padding-top: 2%;
  font-size: 116%;
  color: gray;
  font-weight: 600;
}
.term3 {
  margin-top: 2%;
}
.term3 h6 {
  margin-bottom: 0;
}
.term3 p {
  width: 95%;
  font-weight: 100;
  font-size: 89%;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  padding-bottom: 1rem;
  text-align: justify;
  color: #0000008c;
  margin-bottom: 0;
}
</style>

