<template>
  <div class="animate__animated animate__fadeIn">
    <DummyNavBar />
    <div class="main-div position-relative">
      <div class="width-100">
        <img
          src="./assets/Group44528.png"
          alt="Group44527"
          class="width-100 height-35 object-fit-fill header-background-img"
        />
      </div>

      <div class="absolute top-5 left-12 custom-top-left">
        <div class="width-100">
          <img
            src="./assets/Group682x.png"
            alt="Group682x"
            class="width-10 custom-image-width"
          />
        </div>

        <div class="display-flex flex-column width-100 margin-top-2">
          <h4
            class="text-c-w margin-0 padding-0 font-weight-400"
            style="font-size: 2.125rem; line-height: 2.5rem"
          >
            ELEVATE YOUR BLOGGING <br />
            EXPERIENCE
          </h4>

          <p class="text-c-w text-smaller width-50 font-weight-400">
            Step into a new realm of blogging excellence with SliderSpectra. Our
            revolutionary slider designs are crafted to elevate your content,
            captivate your audience, and transform your blog into an immersive
            journey. Say goodbye to ordinary visuals and hello to extraordinary
            storytelling. Experience the power of SliderSpectra and take your
            blogging to new heights.
          </p>
        </div>
      </div>

      <div
        class="display-flex justify-content-center align-items-center flex-column width-100 margin-x-auto margin-top-1"
      >
        <div
          class="display-flex align-items-start flex-column single-blog-heading"
        >
          <div class="display-flex gap-3 align-items-center">
            <h6 class="margin-0 padding-0 text-1xl font-400">Blog</h6>
            <div
              style="
                width: 10px;
                height: 10px;
                background-color: black;
                border-radius: 50%;
              "
            ></div>
            <h6
              class="margin-0 padding-0"
              style="font-size: 1.25rem; line-height: 2rem"
            >
              {{ singleBlog.blogger_name }}
            </h6>
          </div>

          <h1
            class="text-2xl text-c-b font-weight-bold margin-0 padding-0 width-75"
            style="line-height: inherit"
          >
            {{ singleBlog.title }}
          </h1>
        </div>

        <div
          class="display-flex align-items-start flex-column single-blog-body"
        >
          <div class="display-flex gap-3 margin-top-2 margin-bottom-1">
            <div>
              <img
                src="./assets/Group682x.png"
                alt="Group682x"
                class="single-blog-heading-logo"
                style="width: 50px"
              />
            </div>

            <div class="display-flex flex-column">
              <h6
                class="margin-0 padding-0"
                style="font-size: 1.25rem; line-height: 2rem"
              >
                {{ singleBlog.blogger_name }}
              </h6>
              <div class="gap-2 single-blog-date-sec">
                <p class="margin-0 padding-0" style="font-size: 0.9rem">
                  Post {{ formateDate(singleBlog.posted_date) }}
                </p>
                <div class="display-flex align-items-center">
                  <div
                    style="
                      width: 10px;
                      height: 10px;
                      background-color: black;
                      border-radius: 50%;
                      margin-right: 0.4rem;
                    "
                  ></div>
                  <p class="margin-0 padding-0" style="font-size: 0.9rem">
                    {{ formatTimeSince(singleBlog.posted_date) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="display-flex gap-3 align-items-center justify-content-center"
          >
            <img
              src="./assets/artificial-intelligence-new.png"
              alt="artificial-intelligence-new"
              class="width-100"
            />
          </div>

          <p
            class="text-large text-c-b font-weight-400 margin-y-1 padding-0 width-100"
            style="line-height: inherit"
          >
            {{ singleBlog.description }}
          </p>

          <!-- <div class="">
        <h4 class="text-x-larger font-weight-bold">
          Understanding Artificial Intelligence:
        </h4>
        <p class="text-smaller">
          AI, in its various forms, encompasses the simulation of human
          intelligence processes by machines. From narrow AI, which is
          task-specific, to the elusive goal of achieving general AI, which
          can perform any intellectual task that a human can, the spectrum is
          broad. Within this realm lie machine learning, deep learning, and
          other sophisticated techniques that enable computers to learn from
          data and make decisions.
        </p>
      </div> -->

          <!-- <div class="">
        <h4 class="text-x-larger font-weight-bold">
          Historical Perspectives:
        </h4>
        <p class="text-smaller">
          Tracing back to its inception, AI has undergone a fascinating
          evolution. From the theoretical concepts proposed by early
          visionaries to the groundbreaking developments in the 21st century,
          such as IBM's Deep Blue defeating chess grandmaster Garry Kasparov,
          AI's journey is marked by significant milestones that have shaped
          its trajectory.
        </p>
      </div> -->

          <!-- <div class="">
        <h4 class="text-x-larger font-weight-bold">
          Real-world Applications:
        </h4>
        <p class="text-smaller">
          The practical applications of AI are boundless. In healthcare, AI
          aids in diagnosis, personalized treatment plans, and drug discovery.
          Financial institutions utilize AI for fraud detection, risk
          assessment, and algorithmic trading. Transportation sees
          advancements with self-driving cars and predictive maintenance in
          aviation. These examples merely scratch the surface of AI's
          potential to transform industries and enhance human capabilities.
        </p>
      </div> -->

          <!-- <div class="">
        <h4 class="text-x-larger font-weight-bold">
          Ethical Considerations:
        </h4>
        <p class="text-smaller">
          As AI permeates every aspect of our lives, ethical considerations
          come to the forefront. Issues surrounding privacy, bias in
          algorithms, and the societal impact of automation raise pertinent
          questions about the ethical use of AI. It's imperative to navigate
          these complexities with caution and conscientiousness.
        </p>
      </div> -->

          <!-- <div class="">
        <h4 class="text-x-larger font-weight-bold">The Future of AI:</h4>
        <p class="text-smaller">
          Looking ahead, the future of AI holds immense promise. Advancements
          in AI research, coupled with the proliferation of data and computing
          power, pave the way for transformative innovations. From enhancing
          human capabilities to addressing global challenges, AI has the
          potential to usher in a new era of progress and prosperity.
        </p>
      </div>-->
        </div>

        <div
          class="display-flex justify-content-center align-items-center gap-4 width-75 margin-y-2"
        >
          <button
            class="text-c-w padding-2 display-flex align-items-center justify-content-space-evenly"
            style="
              background: #ed1823 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #00000029;
              cursor: pointer;
              width: 8em;
            "
          >
            Read More
            <img
              src="./assets/Icon-awesome-angle-right.svg"
              alt="Icon-awesome-angle-right"
              width="10"
            />
          </button>
        </div>

        <div class="red-area-div"></div>
      </div>

      <div class="last-logo-div">
        <img
          src="./assets/RP_1.png"
          alt="Logo Image"
          @click="this.$router.push('/')"
        />
      </div>
    </div>
    <FooterComp />
  </div>
</template>

<script>
import DummyNavBar from "./DummyNavbar.vue";

import "./style/Custom_Style.css";
import "./style/IB_Talks_And_Blogs.css";

import FooterComp from "./Dummy_Footer.vue";

import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Blogs",
  data() {
    return {
      dividerColor: "2px solid gray",
      singleBlogId: "",
      singleBlog: [],
      // base_url: "https://uatpodcast.infinitybrains.com/public/api/", // Uat Url
      base_url: "https://podcast.infinitybrains.com/public/api/", // Prod Url
    };
  },
  components: {
    DummyNavBar,
    FooterComp,
  },
  methods: {
    changeDividerColor(isHovered) {
      if (isHovered) {
        this.dividerColor = "2px solid black";
      } else {
        this.dividerColor = "2px solid gray";
      }
    },
    formateDate(dateString) {
      try {
        // Create a new Date object from the provided string
        const date = new Date(dateString);

        // Get month, day, and year components
        // eslint-disable-next-line no-unused-vars
        const month = date.getMonth() + 1; // Months are zero-indexed (January = 0)
        const day = date.getDate();
        const year = date.getFullYear();

        // Format the date in the desired format (April 08, 2024)
        return `${new Intl.DateTimeFormat("en-US", { month: "long" }).format(
          date
        )} ${day}, ${year}`;
      } catch (err) {
        console.error(err);
      }
    },
    formatTimeSince(dateString) {
      try {
        // Create Date objects for the past date and current time
        const pastDate = new Date(dateString);
        const now = new Date();

        // Calculate the difference in milliseconds
        const difference = now.getTime() - pastDate.getTime();

        // Define time units in milliseconds
        const secondsInMinute = 60 * 1000;
        const minutesInHour = 60 * secondsInMinute;
        const hoursInDay = 24 * minutesInHour;
        const daysInYear = 365 * hoursInDay; // Approximate value (ignores leap years)

        // Calculate years, months, days, hours, minutes, and seconds
        const years = Math.floor(difference / daysInYear);
        const months = Math.floor(
          (difference % daysInYear) / (daysInYear / 12)
        ); // Approximate months (ignores leap years)
        const days = Math.floor((difference % daysInYear) / hoursInDay);
        const hours = Math.floor((difference % hoursInDay) / minutesInHour);
        const minutes = Math.floor(
          (difference % minutesInHour) / secondsInMinute
        );
        const seconds = Math.floor((difference % secondsInMinute) / 1000);

        // Define a function to format the time string with pluralization
        const formatTime = (value, unit) => {
          return `${value} ${unit}${value > 1 ? "s Ago" : " Ago"}`;
        };

        // Choose the appropriate time unit based on the difference
        if (years > 0) {
          return formatTime(years, "year");
        } else if (months > 0) {
          return formatTime(months, "month");
        } else if (days > 0) {
          return formatTime(days, "day");
        } else if (hours > 0) {
          return formatTime(hours, "hour");
        } else if (minutes > 0) {
          return formatTime(minutes, "minute");
        } else if (seconds > 5) {
          // Display seconds only if greater than 5
          return formatTime(seconds, "second");
        } else {
          return "Just Posted";
        }
      } catch (err) {
        console.error(err);
      }
    },
    fetchSingleBlog(id) {
      // console.log("Fetching Single Blog of Id: ", id);
      try {
        axios.get(`${this.base_url}show/${id}`).then((response) => {
          // console.log("Single Blog Response: ", response);
          this.singleBlog = response.data.data;
          // console.log("Single Blog: ", this.singleBlog);
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.singleBlogId = this.$route.params.id;
    // this.singleBlogId = "123";
    // console.log("Single Blog Id From Created: ", this.singleBlogId);
    this.fetchSingleBlog(this.singleBlogId);
  },
  mounted() {
    window.scroll(0, 0);
  },
};
</script>

<style scoped>
.last-logo-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  cursor: pointer;
}

.last-logo-div img {
  width: 12rem;
}

.red-area-div {
  width: 100%;
  height: 50px;
  margin-top: 24px;
  margin-bottom: 12px;
  background-color: #ed1823;
}
.main-div {
  top: 5rem;
}

@media (max-width: 767px) {
  .custom-top-left {
    top: 4rem;
    left: 1rem !important;
    width: 100%;
    padding-left: 2rem !important;
  }

  .custom-image-width {
    width: 20%;
  }
}

@media (max-width: 1024px) {
  .main-div {
    top: 5.2rem;
  }
  .header-background-img {
    height: 35rem;
  }

  .custom-top-left {
    top: 5rem;
    left: 4rem;
    padding-left: 2rem;
  }
}

@media (max-width: 450px) {
  .header-background-img {
    height: 38rem !important;
  }

  .custom-top-left {
    top: 4rem !important;
    left: 1rem !important;
    padding-left: 2rem;
  }
  .custom-logo-img {
    width: 20%;
  }
}

@media only screen and (min-width: 1640px) {
  .header-background-img {
    height: 42rem;
  }
  .custom-top-left {
    top: 8rem;
  }
}
</style>